(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.ao.S === region.au.S)
	{
		return 'on line ' + region.ao.S;
	}
	return 'on lines ' + region.ao.S + ' through ' + region.au.S;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.a0,
		impl.a9,
		impl.a7,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		y: func(record.y),
		ap: record.ap,
		am: record.am
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.y;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.ap;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.am) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.a0,
		impl.a9,
		impl.a7,
		function(sendToApp, initialModel) {
			var view = impl.ba;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.a0,
		impl.a9,
		impl.a7,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.an && impl.an(sendToApp)
			var view = impl.ba;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.aU);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.a8) && (_VirtualDom_doc.title = title = doc.a8);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.a3;
	var onUrlRequest = impl.a4;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		an: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.aI === next.aI
							&& curr.ay === next.ay
							&& curr.aF.a === next.aF.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		a0: function(flags)
		{
			return A3(impl.a0, flags, _Browser_getUrl(), key);
		},
		ba: impl.ba,
		a9: impl.a9,
		a7: impl.a7
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { a_: 'hidden', aV: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { a_: 'mozHidden', aV: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { a_: 'msHidden', aV: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { a_: 'webkitHidden', aV: 'webkitvisibilitychange' }
		: { a_: 'hidden', aV: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		aM: _Browser_getScene(),
		aQ: {
			ah: _Browser_window.pageXOffset,
			ai: _Browser_window.pageYOffset,
			P: _Browser_doc.documentElement.clientWidth,
			I: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		P: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		I: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			aM: {
				P: node.scrollWidth,
				I: node.scrollHeight
			},
			aQ: {
				ah: node.scrollLeft,
				ai: node.scrollTop,
				P: node.clientWidth,
				I: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			aM: _Browser_getScene(),
			aQ: {
				ah: x,
				ai: y,
				P: _Browser_doc.documentElement.clientWidth,
				I: _Browser_doc.documentElement.clientHeight
			},
			aW: {
				ah: x + rect.left,
				ai: y + rect.top,
				P: rect.width,
				I: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}var $author$project$Main$LinkClicked = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.h) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.j),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.j);
		} else {
			var treeLen = builder.h * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.k) : builder.k;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.h);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.j) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.j);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{k: nodeList, h: (len / $elm$core$Array$branchFactor) | 0, j: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {aw: fragment, ay: host, aD: path, aF: port_, aI: protocol, g: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Main$Browse = 1;
var $author$project$LoList$all_los = _List_fromArray(
	[
		{
		a: 'A tanulási tevékenységek szakaszaiban használja az infokommunikációs eszközöket, lehetőségeket, tisztában van azok szerepével, innovációs potenciáljával és veszélyeivel is.',
		b: 12,
		c: 71,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Megismerkedik a tudatos médiafogyasztói magatartással és a közösségi média használatával.',
		b: 12,
		c: 70,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Tiszteletben tartja a másik ember értékvilágát, gondolatait és véleményét, ha szükséges, kritikusan viszonyul emberi cselekedetekhez, magatartásformákhoz.',
		b: 12,
		c: 69,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Önállóan vagy társaival együttműködve javaslatokat fogalmaz meg.',
		b: 12,
		c: 68,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Képes arra, hogy feladatait akár önálló, akár társas tanulás révén végezze el, célorientáltan képes az együttműködésre.',
		b: 12,
		c: 67,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Felismeri a véleménynyilvánítás, érvelés, a párbeszéd és a vita társadalmi hasznosságát.',
		b: 12,
		c: 66,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Értelmezi a vállalkozás indítását befolyásoló tényezőket.',
		b: 12,
		c: 65,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Életvitelébe beépülnek a tudatos fogyasztás elemei, életmódjában figyelmet fordít a környezeti terhelés csökkentésére, érvényesíti a fogyasztóvédelmi szempontokat.',
		b: 12,
		c: 64,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Felismeri és megérti a közteherviselés nemzetgazdasági, társadalmi és morális jelentőségét.',
		b: 12,
		c: 63,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Azonosítja az állam gazdasági szerepvállalásának elemeit.',
		b: 12,
		c: 62,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Megismeri a megalapozott, körültekintő hitelfelvétel szempontjait, illetve feltételeit.',
		b: 12,
		c: 61,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Saját pénzügyi döntéseit körültekintően, megalapozottan hozza meg.',
		b: 12,
		c: 60,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Megtervezi egy fiktív család költségvetését.',
		b: 12,
		c: 59,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Tájékozott a munkavállalással kapcsolatos szabályokban.',
		b: 12,
		c: 58,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'felkészülés a felnőtt szerepekre'])
	},
		{
		a: 'Életkori sajátosságainak megfelelően jártasságot szerez a jog területének mindennapi életben való alkalmazásában.',
		b: 12,
		c: 57,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'felkészülés a felnőtt szerepekre'])
	},
		{
		a: 'Azonosítja a mindennapi ügyintézés alapintézményeit.',
		b: 12,
		c: 56,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'felkészülés a felnőtt szerepekre'])
	},
		{
		a: 'Felismeri és értékeli a helyi, regionális és országos közgyűjtemények nemzeti kulturális örökség megőrzésében betöltött szerepét.',
		b: 12,
		c: 55,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a lokálpatriotizmus és a nemzeti identitás erősítése, a honvédelem szerepének, feladatainak és lehe'])
	},
		{
		a: 'Érti és felismeri a honvédelem mint nemzeti ügy jelentőségét.',
		b: 12,
		c: 54,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a lokálpatriotizmus és a nemzeti identitás erősítése, a honvédelem szerepének, feladatainak és lehe'])
	},
		{
		a: 'Felismeri a világ magyarsága mint nemzeti közösség összetartozásának jelentőségét.',
		b: 12,
		c: 53,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a lokálpatriotizmus és a nemzeti identitás erősítése, a honvédelem szerepének, feladatainak és lehe'])
	},
		{
		a: 'Véleményt alkot a nemzetállamok és a globalizáció összefüggéseiről.',
		b: 12,
		c: 52,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a lokálpatriotizmus és a nemzeti identitás erősítése, a honvédelem szerepének, feladatainak és lehe'])
	},
		{
		a: 'Megérti a nemzeti érzület sajátosságait és a hazafiság fontosságát, lehetséges megnyilvánulási formáit.',
		b: 12,
		c: 51,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a lokálpatriotizmus és a nemzeti identitás erősítése, a honvédelem szerepének, feladatainak és lehe'])
	},
		{
		a: 'Értelmezi a törvényalkotás folyamatát.',
		b: 12,
		c: 50,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Értelmezi a választójog feltételeit és a választások alapelveit.',
		b: 12,
		c: 49,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Felismeri és értelmezi az igazságosság, az esélyegyenlőség biztosításának jelentőségét és követelményeit.',
		b: 12,
		c: 48,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Megérti a honvédelem szerepét az ország biztonságának fenntartásában, megismeri a haza védelmének legfontosabb feladatcsoportjait és területeit, az egyén kötelezettségeit.',
		b: 12,
		c: 47,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Érti a társadalmi normák és az egyéni cselekedetek, akaratok, célok egyeztetésének, összehangolásának követelményét. elméleti és tapasztalati úton ismereteket szerez a társadalmi felelősségvállalásról, a segítségre szorulók támogatásának lehetőségeiről.',
		b: 12,
		c: 46,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Bemutatja magyarország alaptörvényének legfontosabb részeit: alapvetés; az állam; szabadság és felelősség.',
		b: 12,
		c: 45,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Felismeri az alapvető emberi jogok egyetemes és társadalmi jelentőségét.',
		b: 12,
		c: 44,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Értelmezi a családi szocializációnak az ember életútját befolyásoló jelentőségét.',
		b: 12,
		c: 43,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az egyéni és a családi szocializáció segítése'])
	},
		{
		a: 'Felismeri, hogy a családtagok milyen szerepet töltenek be a szocializáció folyamatában.',
		b: 12,
		c: 42,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az egyéni és a családi szocializáció segítése'])
	},
		{
		a: 'Társaival megbeszéli a párválasztás, a családtervezés fontos szakaszait, szempontjait és a gyermekvállalás demográfiai jelentőségét: tájékozódás, minták, orientáló példák, átgondolt tervezés, felelősség.',
		b: 12,
		c: 41,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az egyéni és a családi szocializáció segítése'])
	},
		{
		a: 'Értelmezi a család mint a társadalom alapvető intézményének szerepét és jellemzőit.',
		b: 12,
		c: 40,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az egyéni és a családi szocializáció segítése'])
	},
		{
		a: 'Ismeri a munka világát érintő alapvető jogi szabályozást, a munkaerőpiac jellemzőit, tájékozódik a foglalkoztatás és a szakmaszerkezet változásairól.',
		b: 12,
		c: 39,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Felismeri az életpálya-tervezés és a munkavállalás egyéni és társadalmi jelentőségét.',
		b: 12,
		c: 38,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Saját pénzügyeiben tudatos döntéseket hoz.',
		b: 12,
		c: 37,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'A mindennapi életének megszervezésében alkalmazza a jogi és gazdasági-pénzügyi ismereteit.',
		b: 12,
		c: 36,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Érti és vallja a haza védelmének, a nemzetért történő tenni akarás fontosságát.',
		b: 12,
		c: 35,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Megismeri a demokratikus jogállam működésének alapvető sajátosságait.',
		b: 12,
		c: 34,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Ismeri a választások alapelveit és a törvényhozás folyamatát.',
		b: 12,
		c: 33,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Értékeli a nemzeti identitás jelentőségét az egyén és a közösség szempontjából is.',
		b: 12,
		c: 32,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Megérti a család szerepét, alapvető feladatait az egyén és a nemzet szempontjából egyaránt.',
		b: 12,
		c: 31,
		d: 12,
		e: 'Állampolgári ismeretek@12',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Önállóan vagy segítséggel használja az infokommunikációs eszközöket.',
		b: 8,
		c: 30,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Önállóan vagy társaival együttműködve javaslatokat fogalmaz meg, tervet, tervezetet készít.',
		b: 8,
		c: 29,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Képes arra, hogy feladatai egy részét a társas tanulás keretében végezze el.',
		b: 8,
		c: 28,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Felismeri a véleménynyilvánítás, érvelés, a párbeszéd és a vita társadalmi hasznosságát.',
		b: 8,
		c: 27,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a társas együttműködés és a kommunikációs kultúra fejlesztése, a véleményalkotás támogatása'])
	},
		{
		a: 'Fogyasztási szokásaiban érvényesíti a tudatosság szempontjait is.',
		b: 8,
		c: 26,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Felismeri a közteherviselés gazdasági, társadalmi és erkölcsi jelentőségét, a társadalmi felelősségvállalás fontosságát.',
		b: 8,
		c: 25,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Értelmezi az állam gazdasági szerepvállalásának területeit.',
		b: 8,
		c: 24,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Felismeri a családi háztartás gazdasági-pénzügyi fenntarthatóságának és a környezettudatos életvitel kialakításának társadalmi jelentőségét.',
		b: 8,
		c: 23,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Ismeri a családi háztartás összetevőit, értelmezi a család gazdálkodását meghatározó és befolyásoló tényezőket.',
		b: 8,
		c: 22,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a fenntarthatóság és a pénzügyi tudatosság szemléletének és gyakorlatának továbbfejlesztése'])
	},
		{
		a: 'Információkat gyűjt és értelmez a foglalkoztatási helyzetről, a szakmaszerkezet változásairól.',
		b: 8,
		c: 21,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'felkészülés a felnőtt szerepekre'])
	},
		{
		a: 'Megismeri és értelmezi a diákmunka alapvető jogi feltételeit, kereteit.',
		b: 8,
		c: 20,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'felkészülés a felnőtt szerepekre'])
	},
		{
		a: 'Azonosítja az igazságszolgáltatás intézményeit és működésük jellemzőit, megismeri az alapvető ellátórendszereket és funkcióikat.',
		b: 8,
		c: 19,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'felkészülés a felnőtt szerepekre'])
	},
		{
		a: 'Azonosítja a mindennapi ügyintézés alapintézményeit, az alapvető ellátó rendszerek funkcióját és működési sajátosságait.',
		b: 8,
		c: 18,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'felkészülés a felnőtt szerepekre'])
	},
		{
		a: 'Megismeri és értelmezi a honvédelem jelentőségét, feladatait és szerepét.',
		b: 8,
		c: 17,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a lokálpatriotizmus és a nemzeti identitás erősítése'])
	},
		{
		a: 'Felismeri a nemzetek, nemzetállamok fontosságát a globális világban.',
		b: 8,
		c: 16,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a lokálpatriotizmus és a nemzeti identitás erősítése'])
	},
		{
		a: 'Megfogalmazza a nemzeti identitás jelentőségét az egyén és a közösség szempontjából is.',
		b: 8,
		c: 15,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a lokálpatriotizmus és a nemzeti identitás erősítése'])
	},
		{
		a: 'Ismeri településének, lakóhelyének kulturális, néprajzi értékeit, a település történetének alapvető eseményeit és fordulópontjait.',
		b: 8,
		c: 14,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'a lokálpatriotizmus és a nemzeti identitás erősítése'])
	},
		{
		a: 'Felismeri a jogok és kötelességek közötti egyensúly kialakításának és fenntartásának fontosságát, megismeri a haza iránti kötelezettségeit, feladatait.',
		b: 8,
		c: 13,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és a felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'A lakóhelyével kapcsolatos javaslatokat fogalmaz meg, tervet készít a település fejlesztésének lehetőségeiről.',
		b: 8,
		c: 12,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és a felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Ismeri a saját településének, lakóhelyének alapvető jellemzőit, értelmezi a településen működő intézmények és szervezetek szerepét és működését.',
		b: 8,
		c: 11,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és a felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Értelmezi a törvényalkotás folyamatát.',
		b: 8,
		c: 10,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és a felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Ismeri a magyar állam alapvető intézményeinek feladatkörét és működését.',
		b: 8,
		c: 9,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az aktív társadalmi cselekvés és a felelős állampolgári magatartás megalapozása'])
	},
		{
		a: 'Felismeri a családi szocializációnak az ember életútját befolyásoló jelentőségét.',
		b: 8,
		c: 8,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az egyéni és családi szocializáció segítése'])
	},
		{
		a: 'Értelmezi a családi kohézió alapelemeit, jellemzőit: együttműködés, szeretetközösség, kölcsönösség, tisztelet.',
		b: 8,
		c: 7,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az egyéni és családi szocializáció segítése'])
	},
		{
		a: 'Értelmezi a család mint a társadalom alapvető intézményének szerepét és jellemzőit.',
		b: 8,
		c: 6,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'az egyéni és családi szocializáció segítése'])
	},
		{
		a: 'Saját pénzügyeiben tudatos döntéseket hoz.',
		b: 8,
		c: 5,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Jártasságot szerez mindennapi ügyeinek intézésében.',
		b: 8,
		c: 4,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Ismeri a demokratikus jogállam működésének alapvető sajátosságait, alapvető kötelezettségeit.',
		b: 8,
		c: 3,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Lokálpatriotizmusa megerősödik, személyiségébe beépülnek a nemzeti közösséghez tartozás, a hazaszeretet emocionális összetevői.',
		b: 8,
		c: 2,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Megérti a család, mint a társadalom alapvető intézményének szerepét, és értelmezi jellemzőit.',
		b: 8,
		c: 1,
		d: 8,
		e: 'Állampolgári ismeretek@8',
		f: _List_fromArray(
			['állampolgári ismeretek', 'átfogó célként kitűzött tanulási eredmények'])
	},
		{
		a: 'Ismeri a kárpát-medence élővilágának sajátosságait, megőrzendő értékeit, ezeket összekapcsolja a hazai nemzeti parkok tevékenységével.',
		b: 9,
		c: 161,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a fenntarthatóság elve, szempontjai', 'a föld és a kárpát-medence értékei'])
	},
		{
		a: 'Érvel a föld, mint élő bolygó egyedisége mellett, tényekre alapozottan és kritikusan értékeli a természeti okokból és az emberi hatásokra bekövetkező változásokat.',
		b: 9,
		c: 160,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a fenntarthatóság elve, szempontjai', 'a föld és a kárpát-medence értékei'])
	},
		{
		a: 'Megérti a biotechnológiai eljárások és a bionika eredményeinek alkalmazási lehetőségeit, értékeli az információs technológiák alkalmazásának orvosi, biológiai jelentőségét.',
		b: 9,
		c: 159,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a fenntarthatóság elve, szempontjai', 'a fenntartható életvitel, technológia és gazdálkodás'])
	},
		{
		a: 'Történeti adatok és jelenkori esettanulmányok alapján értékeli a mezőgazdaság, erdő- és vadgazdaság, valamint a halászat természetes életközösségekre gyakorolt hatását, példák alapján bemutatja az ökológiai szempontú, fenntartható gazdálkodás technológiai lehetőségeit.',
		b: 9,
		c: 158,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a fenntarthatóság elve, szempontjai', 'a fenntartható életvitel, technológia és gazdálkodás'])
	},
		{
		a: 'Értékeli a környezet- és természetvédelem fontosságát, megérti a nemzetközi összefogások és a hazai törekvések jelentőségét, döntéshozatalai során saját személyes érdekein túl a természeti értékeket és egészség-megőrzési szempontokat is mérlegeli.',
		b: 9,
		c: 157,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a fenntarthatóság elve, szempontjai', 'a fenntartható életvitel, technológia és gazdálkodás'])
	},
		{
		a: 'Érti és elfogadja, hogy a jövőbeli folyamatokat a jelen cselekvései alakítják, tudja, hogy a folyamatok tervezése, előrejelzése számítógépes modellek alapján lehetséges.',
		b: 9,
		c: 156,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a fenntarthatóság elve, szempontjai', 'a fenntartható életvitel, technológia és gazdálkodás'])
	},
		{
		a: 'Példák alapján elemzi a levegő-, a víz- és a talajszennyeződés, az ipari és természeti katasztrófák okait és ezek következményeit, az emberi tevékenységnek az élőhelyek változásához vezető hatását, ennek alapján magyarázza egyes fajok veszélyeztetettségét.',
		b: 9,
		c: 155,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a fenntarthatóság elve, szempontjai', 'az emberi tevékenység hatása a bioszférára'])
	},
		{
		a: 'A kutatások adatai és előrejelzései alapján értelmezi a globális éghajlatváltozás élővilágra gyakorolt helyi és bioszféra szintű következményeit.',
		b: 9,
		c: 154,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a fenntarthatóság elve, szempontjai', 'az emberi tevékenység hatása a bioszférára'])
	},
		{
		a: 'Konkrét példák alapján vizsgálja a bioszférában végbemenő folyamatokat, elemzi ezek idő- és térbeli viszonyait, azonosítja az emberi tevékenységgel való összefüggésüket.',
		b: 9,
		c: 153,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a fenntarthatóság elve, szempontjai', 'az emberi tevékenység hatása a bioszférára'])
	},
		{
		a: 'Érti az ökológiai rendszerek működése és a biológiai sokféleség közötti kapcsolatot, konkrét életközösségek vizsgálata alapján táplálkozási piramist, hálózatot elemez.',
		b: 9,
		c: 152,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata', 'az életközösségek biológiai sokfélesége'])
	},
		{
		a: 'Érti az ökológiai egyensúly fogalmát, értékeli a jelentőségét, példákkal igazolja az egyensúly felborulásának lehetséges következményeit.',
		b: 9,
		c: 151,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata', 'az életközösségek biológiai sokfélesége'])
	},
		{
		a: 'Érti a biológiai sokféleség fogalmát, ismer a meghatározásra alkalmas módszereket, értékeli a bioszféra stabilitásának megőrzésében játszott szerepét.',
		b: 9,
		c: 150,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata', 'az életközösségek biológiai sokfélesége'])
	},
		{
		a: 'Felismeri a természetes élőhelyeket veszélyeztető tényezőket, kifejti álláspontját az élőhelyvédelem szükségességéről, egyéni és társadalmi megvalósításának lehetőségeiről.',
		b: 9,
		c: 149,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata', 'az élőhelyi környezethez való alkalmazkodás'])
	},
		{
		a: 'Felismeri és példákkal igazolja az állatok viselkedésének a környezethez való alkalmazkodásban játszott szerepét.',
		b: 9,
		c: 148,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata', 'az élőhelyi környezethez való alkalmazkodás'])
	},
		{
		a: 'Ismeri a levegő-, a víz- és a talajszennyezés forrásait, a szennyező anyagok típusait és példáit, konkrét esetek alapján elemzi az életközösségekre gyakorolt hatásukat.',
		b: 9,
		c: 147,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata', 'az élőhelyek jellemzői, a populációk közötti kapcsolatok'])
	},
		{
		a: 'Érti az ökológiai mutatókkal, bioindikációs vizsgálatokkal megvalósuló környezeti állapotelemzések céljait, adott esetben alkalmazza azok módszereit.',
		b: 9,
		c: 146,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata', 'az élőhelyek jellemzői, a populációk közötti kapcsolatok'])
	},
		{
		a: 'Megfigyelések, leírások és videók alapján azonosítja a populációk közötti kölcsönhatások típusait, az ezzel összefüggő etológiai jellemzőket, bemutatja ezek jellegét, jelentőségét.',
		b: 9,
		c: 145,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata', 'az élőhelyek jellemzői, a populációk közötti kapcsolatok'])
	},
		{
		a: 'Példákkal mutatja be a fontosabb hazai szárazföldi és vizes életközösségek típusait, azok jellemzőit és előfordulásait.',
		b: 9,
		c: 144,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata', 'az élőhelyek jellemzői, a populációk közötti kapcsolatok'])
	},
		{
		a: 'Szükség esetén alkalmazza a felnőtt alapszintű újraélesztés műveleteit (cpr), képes a félautomata defibrillátort alkalmazni.',
		b: 9,
		c: 143,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az egészségügyi rendszer ismerete, elsősegélynyújtás'])
	},
		{
		a: 'Képes a sérült vagy beteg személy ellátását a rendelkezésre álló eszközökkel (vagy eszköz nélkül) megkezdeni, segítséget (szükség esetén mentőt) hívni.',
		b: 9,
		c: 142,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az egészségügyi rendszer ismerete, elsősegélynyújtás'])
	},
		{
		a: 'Képes a bekövetkezett balesetet, rosszullétet felismerni,',
		b: 9,
		c: 141,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az egészségügyi rendszer ismerete, elsősegélynyújtás'])
	},
		{
		a: 'Ismeri a leggyakoribb népbetegségek (pl. szívinfarktus, stroke, cukorbetegség, allergia, asztma) kockázati tényezőit, felismeri ezek kezdeti tüneteit.',
		b: 9,
		c: 140,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az egészségügyi rendszer ismerete, elsősegélynyújtás'])
	},
		{
		a: 'Ismeri a leggyakoribb fertőző betegségek kiváltó okait, ismeri a fertőzések elkerülésének lehetőségeit és a járványok elleni védekezés módjait.',
		b: 9,
		c: 139,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az egészségügyi rendszer ismerete, elsősegélynyújtás'])
	},
		{
		a: 'Megkülönbözteti a házi- és a szakorvosi ellátás funkcióit, ismeri az orvoshoz fordulás módját, tisztában van a kórházi ellátás indokaival, jellemzőivel.',
		b: 9,
		c: 138,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az egészségügyi rendszer ismerete, elsősegélynyújtás'])
	},
		{
		a: 'Ismeri az orvosi diagnosztika, a szűrővizsgálatok és védőoltások célját, lényegét, értékeli ezek szerepét a betegségek megelőzésében és a gyógyulásban.',
		b: 9,
		c: 137,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az egészségügyi rendszer ismerete, elsősegélynyújtás'])
	},
		{
		a: 'Biológiai folyamatok alapján magyarázza a függőség kialakulását, felismeri a függőségekre vezető tényezőket, ezek kockázatait és következményeit.',
		b: 9,
		c: 136,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'a lelki egyensúly és a testi állapot összefüggése'])
	},
		{
		a: 'Az agy felépítése és funkciója alapján magyarázza az információk feldolgozásával, a tanulással összefüggő folyamatokat, értékeli a tanulási képesség jelentőségét az egyén és a közösség szempontjából.',
		b: 9,
		c: 135,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'a lelki egyensúly és a testi állapot összefüggése'])
	},
		{
		a: 'Megérti az idegsejtek közötti jelátviteli folyamatokat, és kapcsolatba hozza azokat a tanulás és emlékezés folyamataival, a drogok hatásmechanizmusával.',
		b: 9,
		c: 134,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'a lelki egyensúly és a testi állapot összefüggése'])
	},
		{
		a: 'Ismeri a mentális egészség jellemzőit, megérti annak feltételeit, ezek alapján megtervezi az egészségmegőrző magatartásához szükséges életviteli elemeket.',
		b: 9,
		c: 133,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'a lelki egyensúly és a testi állapot összefüggése'])
	},
		{
		a: 'Ismeri a gondolkodási folyamatokat és az érzelmi és motivációs működéseket meghatározó tényezőket, értékeli az érzelmi és az értelmi fejlődés kapcsolatát.',
		b: 9,
		c: 132,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'a lelki egyensúly és a testi állapot összefüggése'])
	},
		{
		a: 'A biológiai működések alapján magyarázza a stressz fogalmát, felismeri a tartós stressz egészségre gyakorolt káros hatásait, igyekszik azt elkerülni, csökkenteni.',
		b: 9,
		c: 131,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'a lelki egyensúly és a testi állapot összefüggése'])
	},
		{
		a: 'Ismeri a fogamzás feltételeit, a terhesség jeleit, bemutatja a magzat fejlődésének szakaszait, értékeli a terhesség alatti egészséges életmód jelentőségét.',
		b: 9,
		c: 130,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi nemek és a szaporodás biológiai alapjai'])
	},
		{
		a: 'Megérti a fogamzásgátlók hatékonyságáról szóló információkat, a személyre szabott, orvosilag ellenőrzött fogamzásgátlás fontosságát.',
		b: 9,
		c: 129,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi nemek és a szaporodás biológiai alapjai'])
	},
		{
		a: 'Biológiai ismereteit is figyelembe véve értékeli az emberi szexualitás párkapcsolattal és a tudatos családtervezéssel összefüggő jelentőségét.',
		b: 9,
		c: 128,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi nemek és a szaporodás biológiai alapjai'])
	},
		{
		a: 'Ismeri a férfi és a női nemi szervek felépítését és működését, a másodlagos nemi jellegeket és azok kialakulási folyamatát, ismereteit összekapcsolja a szaporító szervrendszer egészségtanával.',
		b: 9,
		c: 127,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi nemek és a szaporodás biológiai alapjai'])
	},
		{
		a: 'Felsorolja az emberi egyedfejlődés főbb szakaszait, magyarázza hogyan és miért változik a szervezetünk az életkor előrehaladásával, értékeli a fejlődési szakaszok egészségvédelmi szempontjait, önmagát is elhelyezve ebben a rendszerben.',
		b: 9,
		c: 126,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi szervezet anatómiája, élettana és egészségvédelme'])
	},
		{
		a: 'Az ideg-, hormon- és immunrendszer elemzése alapján magyarázza az emberi szervezet információs rendszerének biológiai alapjait.',
		b: 9,
		c: 125,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi szervezet anatómiája, élettana és egészségvédelme'])
	},
		{
		a: 'A táplálkozás-, a légzés-, a keringés- és a kiválasztás szervrendszerének elemzése alapján magyarázza az emberi szervezet anyag- és energiaforgalmi működésének biológiai alapjait.',
		b: 9,
		c: 124,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi szervezet anatómiája, élettana és egészségvédelme'])
	},
		{
		a: 'Az emberi test kültakarójának, váz- és izomrendszerének elemzése alapján magyarázza az ember testképének, testalkatának és mozgásképességének biológiai alapjait.',
		b: 9,
		c: 123,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi szervezet anatómiája, élettana és egészségvédelme'])
	},
		{
		a: 'Elemzi az ember mozgásképességének biokémiai, szövettani és biomechanikai alapjait, ezeket összefüggésbe hozza a mindennapi élet, a sport és a munka mozgásformáival, értékeli a rendszeres testmozgás szerepét egészségének megőrzésében.',
		b: 9,
		c: 122,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi szervezet anatómiája, élettana és egészségvédelme'])
	},
		{
		a: 'Megérti a környezeti állapot és az ember egészsége közötti összefüggéseket, azonosítja az ember egészségét veszélyeztető tényezőket, felismeri a megelőzés lehetőségeit, érvényesíti az elővigyázatosság elvét.',
		b: 9,
		c: 121,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az ember szervezete és egészsége', 'az emberi szervezet anatómiája, élettana és egészségvédelme'])
	},
		{
		a: 'Ismeri az evolúció befolyásolásának lehetséges módjait (például mesterséges szelekció, fajtanemesítés, géntechnológia), értékeli ezek előnyeit és esetleges hátrányait.',
		b: 9,
		c: 120,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a biológiai evolúció, adaptív és nem adaptív folyamatok'])
	},
		{
		a: 'Morfológiai, molekuláris biológiai adatok alapján egyszerű származástani kapcsolatokat elemez, törzsfát készít.',
		b: 9,
		c: 119,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a biológiai evolúció, adaptív és nem adaptív folyamatok'])
	},
		{
		a: 'Érti és elfogadja, hogy a mai emberek egy fajhoz tartoznak, és az evolúció során kialakult nagyrasszok értékükben nem különböznek, a biológiai és kulturális örökségük az emberiség közös kincse.',
		b: 9,
		c: 118,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a biológiai evolúció, adaptív és nem adaptív folyamatok'])
	},
		{
		a: 'Példákkal mutatja be az élővilág főbb csoportjainak evolúciós újításait, magyarázza, hogy ezek hogyan segítették elő az adott élőlénycsoport elterjedését.',
		b: 9,
		c: 117,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a biológiai evolúció, adaptív és nem adaptív folyamatok'])
	},
		{
		a: 'Példákkal igazolja, hogy a szelekció a különböző szerveződési szinteken értelmezhető tulajdonságokon keresztül egyidejűleg hat.',
		b: 9,
		c: 116,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a biológiai evolúció, adaptív és nem adaptív folyamatok'])
	},
		{
		a: 'Megérti a természetes változatosság szerveződését, az evolúciós változások eredetét és elterjedését magyarázó elemi folyamatokat, felismer és magyaráz mikro- és makroszintű evolúciós jelenségeket.',
		b: 9,
		c: 115,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a biológiai evolúció, adaptív és nem adaptív folyamatok'])
	},
		{
		a: 'Felismeri a kapcsolatot az életmód és a gének kifejeződése között, érti, hogy a sejt és az egész szervezet jellemzőinek kialakításában és fenntartásában kiemelt szerepe van a környezet általi génaktivitás-változásoknak.',
		b: 9,
		c: 114,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a változékonyság molekuláris alapjai, egyedszintű öröklődés'])
	},
		{
		a: 'Megérti a genetikai információ nemzedékek közötti átadásának törvényszerűségeit, ezeket konkrét esetek elemzésében alkalmazza.',
		b: 9,
		c: 113,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a változékonyság molekuláris alapjai, egyedszintű öröklődés'])
	},
		{
		a: 'Érti az örökítőanyagban tárolt információ és a kifejeződő tulajdonságok közötti összefüggést, megkülönbözteti a genotípust és a fenotípust.',
		b: 9,
		c: 112,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a változékonyság molekuláris alapjai, egyedszintű öröklődés'])
	},
		{
		a: 'A géntechnológia céljának és módszertani alapjainak ismeretében, kritikai szemlélettel elemzi a genetikai módosítások előnyeit és kockázatait.',
		b: 9,
		c: 111,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a változékonyság molekuláris alapjai, egyedszintű öröklődés'])
	},
		{
		a: 'Ismeri az örökítőanyag bázissorrendjének vagy bázisainak megváltozásához vezető folyamatokat, konkrét esetekben azonosítja ezek következményeit.',
		b: 9,
		c: 110,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'a változékonyság molekuláris alapjai, egyedszintű öröklődés'])
	},
		{
		a: 'Biológiai és csillagászati tények alapján mérlegeli a földön kívüli élet valószínűsíthető feltételeit és lehetőségeit.',
		b: 9,
		c: 109,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'az élet eredete és feltételei'])
	},
		{
		a: 'Érti és tényekkel igazolja az ősbaktériumok különleges élőhelyeken való életképességét.',
		b: 9,
		c: 108,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'az élet eredete és feltételei'])
	},
		{
		a: 'A földi élet keletkezését biológiai kísérletek és elméletek alapján magyarázza.',
		b: 9,
		c: 107,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése, biológiai evolúció', 'az élet eredete és feltételei'])
	},
		{
		a: 'A sejtszintű anyagcsere folyamatok alapján magyarázza a növények és állatok közötti ökológiai szintű kapcsolatot, a termelő és fogyasztó szervezetek közötti anyagforgalmat.',
		b: 9,
		c: 106,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a magasabb szerveződési szintek kapcsolata'])
	},
		{
		a: 'Vázlatrajzok, folyamatábrák és animációk alapján azonosítja a fotoszintézis és a sejtlégzés fő szakaszainak sejten belüli helyét és struktúráit, a fontosabb anyagokat és az energiaátalakítás jellemzőit.',
		b: 9,
		c: 105,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a magasabb szerveződési szintek kapcsolata'])
	},
		{
		a: 'Ismeri és példákkal bizonyítja az élőlények szén- és energiaforrásainak különféle lehetőségeit, az anyagcseretípusok közötti különbséget.',
		b: 9,
		c: 104,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a magasabb szerveződési szintek kapcsolata'])
	},
		{
		a: 'Fénymikroszkópban, ábrán vagy fotón felismeri és jellemzi a főbb állati és növényi szövettípusokat, elemzi, hogy milyen funkciók hatékony elvégzésére specializálódtak.',
		b: 9,
		c: 103,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a magasabb szerveződési szintek kapcsolata'])
	},
		{
		a: 'Ismeri az őssejt fogalmát, különféle típusait és azok jellemzőit, különbséget tesz őssejt és daganatsejt között.',
		b: 9,
		c: 102,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a magasabb szerveződési szintek kapcsolata'])
	},
		{
		a: 'Felismeri az összefüggést a rák kialakulása és a sejtciklus zavarai között, megérti, hogy mit tesz a sejt és a szervezet a daganatok kialakulásának megelőzéséért.',
		b: 9,
		c: 101,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a genom szerveződése és működése'])
	},
		{
		a: 'Összehasonlítja a sejtosztódás típusait, megfogalmazza ezek biológiai szerepét, megérti, hogy a soksejtű szervezetek a megtermékenyített petesejt és utódsejtjei meghatározott számú osztódásával és differenciálódásával alakulnak ki.',
		b: 9,
		c: 100,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a genom szerveződése és működése'])
	},
		{
		a: 'Tudja, hogy a sejtekben és a sejtek között bonyolult jelforgalmi hálózatok működnek, amelyek befolyásolják a génműködést, és felelősek lehetnek a normál és a kóros működésért is.',
		b: 9,
		c: 99,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a genom szerveződése és működése'])
	},
		{
		a: 'Ismeri az örökítőanyag többszintű szerveződését, képek, animációk alapján értelmezi a sejtekben zajló biológiai információ tárolásának, átírásának és kifejeződésének folyamatait.',
		b: 9,
		c: 98,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a genom szerveződése és működése'])
	},
		{
		a: 'Felismeri az összetett sejttípus mikroszkóppal megfigyelhető sejtalkotóit, magyarázza a sejt anyagcsere-folyamatainak lényegét.',
		b: 9,
		c: 97,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a genom szerveződése és működése'])
	},
		{
		a: 'Tényekkel igazolja a baktériumok anyagcsere sokfélesége, gyors szaporodása és alkalmazkodóképessége közötti összefüggést.',
		b: 9,
		c: 96,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a genom szerveződése és működése'])
	},
		{
		a: 'A felépítés és működés összehasonlítása alapján bemutatja a sejtes szerveződés kétféle formájának közös jellemzőit és alapvető különbségeit, értékeli ezek jelentőségét.',
		b: 9,
		c: 95,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'a sejt és a genom szerveződése és működése'])
	},
		{
		a: 'Ábrák, animációk alapján értelmezi, és biológiai tényekkel alátámasztja, hogy a vírusok az élő és élettelen határán állnak.',
		b: 9,
		c: 94,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'az élővilág egysége, a felépítés és működés alapelvei'])
	},
		{
		a: 'Magyarázza, hogy a sejt az élő szervezetek szerkezeti és működési egysége.',
		b: 9,
		c: 93,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'az élővilág egysége, a felépítés és működés alapelvei'])
	},
		{
		a: 'Értékeli és példákkal igazolja a különféle szintű biológiai szabályozás szerepét az élő rendszerek normál működési állapotának fenntartásában.',
		b: 9,
		c: 92,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'az élővilág egysége, a felépítés és működés alapelvei'])
	},
		{
		a: 'Megérti, miért és hogyan mehetnek végbe viszonylag alacsony hőmérsékleten, nagy sebességgel kémiai reakciók a sejtekben, vizsgálja az enzimműködést befolyásoló tényezőket.',
		b: 9,
		c: 91,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'az élővilág egysége, a felépítés és működés alapelvei'])
	},
		{
		a: 'Tényekkel bizonyítja az élőlények elemi összetételének hasonlóságát, a biogén elemek, a víz, az atp és a makromolekulák élő szervezetekben betöltött alapvető szerepét.',
		b: 9,
		c: 90,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'az élővilág egysége, a felépítés és működés alapelvei'])
	},
		{
		a: 'Tudja a biológiai problémákat és magyarázatokat a megfelelő szinttel összefüggésben értelmezni.',
		b: 9,
		c: 89,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'az élő rendszerek szerveződése', 'az élővilág egysége, a felépítés és működés alapelvei'])
	},
		{
		a: 'A valós és virtuális tanulási közösségekben, másokkal együttműködve megtervez és kivitelez biológiai vizsgálatokat, projekteket.',
		b: 9,
		c: 88,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'digitális kompetencia, kommunikáció és együttműködés'])
	},
		{
		a: 'Tájékozódik a biotechnológia és a bioetika kérdéseiben, ezekről folyó vitákban tudományosan megalapozott érveket alkot.',
		b: 9,
		c: 87,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'digitális kompetencia, kommunikáció és együttműködés'])
	},
		{
		a: 'Ismeri a tudományos közlések lényegi jellemzőit.',
		b: 9,
		c: 86,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'digitális kompetencia, kommunikáció és együttműködés'])
	},
		{
		a: 'Biológiai vizsgálatok során elvégzi az adatrögzítés és -rendezés műveleteit, ennek alapján tényekkel alátámasztott következtetéseket von le.',
		b: 9,
		c: 85,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'digitális kompetencia, kommunikáció és együttműködés'])
	},
		{
		a: 'A biológiai jelenségek vizsgálata során digitális szöveget, képet, videót keres, értelmez és felhasznál, vizsgálja azok megbízhatóságát, jogszerű és etikus felhasználhatóságát.',
		b: 9,
		c: 84,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'digitális kompetencia, kommunikáció és együttműködés'])
	},
		{
		a: 'Érti a biológia molekuláris szintű vizsgálati módszereinek elméleti alapjait és felhasználási lehetőségeit, értékeli a biológiai kutatásokból származó nagy mennyiségű adat feldolgozásának jelentőségét.',
		b: 9,
		c: 83,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'kutatási és gondolkodási készségek, eszközök és módszerek'])
	},
		{
		a: 'Egyénileg és másokkal együttműködve célszerűen és biztonságosan alkalmaz biológiai vizsgálati módszereket, ismeri a fénymikroszkóp működésének alapelvét, képes azt használni.',
		b: 9,
		c: 82,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'kutatási és gondolkodási készségek, eszközök és módszerek'])
	},
		{
		a: 'Biológiai vonatkozású adatokat elemez, megfelelő formába rendez, ábrázol, ezek alapján előrejelzéseket, következtetéseket fogalmaz meg, a már ábrázolt adatokat értelmezi.',
		b: 9,
		c: 81,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'kutatási és gondolkodási készségek, eszközök és módszerek'])
	},
		{
		a: 'A vizsgált biológiai jelenségek magyarázatára előfeltevést fogalmaz meg, ennek bizonyítására vagy cáfolatára kísérletet tervez és kivitelez, azonosítja és beállítja a kísérleti változókat, megfigyeléseket és méréseket végez.',
		b: 9,
		c: 80,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'kutatási és gondolkodási készségek, eszközök és módszerek'])
	},
		{
		a: 'Az élő rendszerek vizsgálata során felismeri az analógiákat, korrelációkat, alkalmazza a statisztikus és a rendszerszintű gondolkodás műveleteit, kritikusan és kreatívan mérlegeli a lehetőségeket, bizonyítékokra alapozva érvel, több szempontot is figyelembe vesz.',
		b: 9,
		c: 79,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'kutatási és gondolkodási készségek, eszközök és módszerek'])
	},
		{
		a: 'Példákkal igazolja a biológiai ismereteknek a világképünk és a technológia fejlődésében betöltött szerepét, gazdasági és társadalmi jelentőségét.',
		b: 9,
		c: 78,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'kutatási célok, társadalmi, technológiai jelentőség'])
	},
		{
		a: 'Ismeri a biológiai kutatások alapvető céljait, legfontosabb területeit, értékeli az élet megértésében, az élővilág megismerésében és megóvásában játszott szerepét.',
		b: 9,
		c: 77,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya és tanulása', 'kutatási célok, társadalmi, technológiai jelentőség'])
	},
		{
		a: 'Felismeri a helyi és a globális környezeti problémák összefüggését, érvel a föld és a kárpát-medence természeti értékeinek védelme mellett, döntéseket hoz és cselekszik a fenntarthatóság érdekében.',
		b: 9,
		c: 76,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Az emberi test és pszichikum felépítéséről és működéséről szerzett ismereteit önismeretének fejlesztésében, egészséges életvitelének kialakításában alkalmazza.',
		b: 9,
		c: 75,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Életközösségek vizsgálata alapján értelmezi a környezet és az élőlények felépítése és működése közötti összefüggést, érti az ökológiai egyensúly jelentőségét, érvel a biológiai sokféleség megőrzése mellett.',
		b: 9,
		c: 74,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Az élő rendszerek belső működése és környezettel való kapcsolataik elemzésében alkalmazza a rendszerszintű gondolkodás műveleteit.',
		b: 9,
		c: 73,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'A mindennapi élettel összefüggő problémák megoldásában alkalmazza a természettudományos gondolkodás műveleteit, rendelkezik a biológiai problémák vizsgálatához szükséges gyakorlati készségekkel.',
		b: 9,
		c: 72,
		d: 10,
		e: 'Biológia@9-10',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismer néhány, kisiskolások részére készített portált, információforrást, digitálistananyag-lelőhelyet.',
		b: 4,
		c: 195,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs technológia alkalmazása, digitális tananyagok, oktatójátékok használata'])
	},
		{
		a: 'Képes feladat, probléma megoldásához megfelelő applikáció, digitális tananyag, oktatójáték, képességfejlesztő digitális alkalmazás kiválasztására.',
		b: 4,
		c: 194,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs technológia alkalmazása, digitális tananyagok, oktatójátékok használata'])
	},
		{
		a: 'Közvetlen tapasztalatokat szerez a digitális eszközök használatával kapcsolatban.',
		b: 4,
		c: 193,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs technológia alkalmazása, digitális tananyagok, oktatójátékok használata'])
	},
		{
		a: 'Ismeri a mobileszközök alkalmazásának előnyeit, korlátait, etikai vonatkozásait.',
		b: 4,
		c: 192,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs technológia alkalmazása, digitális tananyagok, oktatójátékok használata'])
	},
		{
		a: 'Ismeri és használja a kapcsolattartás formáit és a kommunikáció lehetőségeit a digitális környezetben.',
		b: 4,
		c: 191,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs technológia alkalmazása, digitális tananyagok, oktatójátékok használata'])
	},
		{
		a: 'Tisztában van a személyes adat fogalmával, törekszik megőrzésére, ismer néhány példát az e-világ veszélyeivel kapcsolatban.',
		b: 4,
		c: 190,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'adataink védelme, internetbiztonság'])
	},
		{
		a: 'Kiválasztja a számára releváns információt, felismeri a hamis információt.',
		b: 4,
		c: 189,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'adataink védelme, internetbiztonság'])
	},
		{
		a: 'Információkat keres az interneten, egyszerű eljárásokkal meggyőződik néhány, az interneten talált információ igazságértékéről.',
		b: 4,
		c: 188,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'adataink védelme, internetbiztonság'])
	},
		{
		a: 'Alkalmaz néhány megadott algoritmust tevékenység, játék során, és néhány egyszerű esetben módosítja azokat.',
		b: 4,
		c: 187,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'kódolás, folyamatok irányítása, a robotika alapjai'])
	},
		{
		a: 'Adott feltételeknek megfelelő kódsorozatot tervez és hajtat végre, történeteket, meserészleteket jelenít meg padlórobottal vagy más eszközzel.',
		b: 4,
		c: 186,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'kódolás, folyamatok irányítása, a robotika alapjai'])
	},
		{
		a: 'A valódi vagy szimulált programozható eszköz mozgását értékeli, hiba esetén módosítja a kódsorozatot a kívánt eredmény eléréséig. tapasztalatait megfogalmazza, megvitatja társaival.',
		b: 4,
		c: 185,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'kódolás, folyamatok irányítása, a robotika alapjai'])
	},
		{
		a: 'Feladat, probléma megoldásához többféle algoritmust próbál ki.',
		b: 4,
		c: 184,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmusok vizsgálata, előállítása'])
	},
		{
		a: 'Egy adott, mindennapi életből vett algoritmust elemi lépésekre bont, értelmezi a lépések sorrendjét, megfogalmazza az algoritmus várható kimenetelét.',
		b: 4,
		c: 183,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmusok vizsgálata, előállítása'])
	},
		{
		a: 'Felismer, eljátszik, végrehajt néhány hétköznapi tevékenysége során tapasztalt, elemi lépésekből álló, adott sorrendben végrehajtandó cselekvést.',
		b: 4,
		c: 182,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmusok vizsgálata, előállítása'])
	},
		{
		a: 'Egyszerű prezentációt, ábrát, egyéb segédletet készít.',
		b: 4,
		c: 181,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'a probléma megoldásához szükséges módszerek és eszközök kiválasztása'])
	},
		{
		a: 'Információt keres az interneten más tantárgyak tanulása során, és felhasználja azt.',
		b: 4,
		c: 180,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'a probléma megoldásához szükséges módszerek és eszközök kiválasztása'])
	},
		{
		a: 'Értelmezi a problémát, a megoldási lehetőségeket eljátssza, megfogalmazza, egyszerű eszközök segítségével megvalósítja.',
		b: 4,
		c: 179,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'a probléma megoldásához szükséges módszerek és eszközök kiválasztása'])
	},
		{
		a: 'Információkat keres, a talált adatokat felhasználja digitális produktumok létrehozására.',
		b: 4,
		c: 178,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'adatok értelmezése, csoportosítása, tárolása'])
	},
		{
		a: 'Állításokat fogalmaz meg grafikonokról, infografikákról, táblázatokról; a kapott információkat felhasználja napi tevékenysége során.',
		b: 4,
		c: 177,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'adatok értelmezése, csoportosítása, tárolása'])
	},
		{
		a: 'Egy rajzos dokumentumot adott szempontok alapján értékel, módosít.',
		b: 4,
		c: 176,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'rajzos dokumentumok digitális létrehozása'])
	},
		{
		a: 'Grafikai alkalmazással egyszerű, közvetlenül hasznosuló rajzot, grafikát, dokumentumot hoz létre.',
		b: 4,
		c: 175,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'rajzos dokumentumok digitális létrehozása'])
	},
		{
		a: 'Adott szempontok alapján megfigyel néhány, grafikai alkalmazással készített produktumot; személyes véleményét megfogalmazza.',
		b: 4,
		c: 174,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'rajzos dokumentumok digitális létrehozása'])
	},
		{
		a: 'A feladathoz, problémához digitális eszközt, illetve alkalmazást, applikációt, felhasználói felületet választ; felsorol néhány érvet választásával kapcsolatosan.',
		b: 4,
		c: 173,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'gyermekeknek készített alkalmazások használata'])
	},
		{
		a: 'Kezdetben tanítói segítséggel, majd önállóan használ néhány, életkorának megfelelő alkalmazást, elsősorban információgyűjtés, gyakorlás, egyéni érdeklődésének kielégítése céljából.',
		b: 4,
		c: 172,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'gyermekeknek készített alkalmazások használata'])
	},
		{
		a: 'Önállóan vagy tanítói segítséggel választ más tantárgyak tanulásának támogatásához applikációkat, digitális tananyagot, oktatójátékot, képességfejlesztő digitális alkalmazást.',
		b: 4,
		c: 171,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'gyermekeknek készített alkalmazások használata'])
	},
		{
		a: 'Egyszerű feladatokat old meg informatikai eszközökkel. esetenként tanítói segítséggel összetett funkciókat is alkalmaz.',
		b: 4,
		c: 170,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'ismerkedés az informatikai környezettel'])
	},
		{
		a: 'Megfogalmazza, néhány példával alátámasztja, hogyan könnyíti meg a felhasználó munkáját az adott eszköz alkalmazása.',
		b: 4,
		c: 169,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'ismerkedés az informatikai környezettel'])
	},
		{
		a: 'Közvetlen otthoni vagy iskolai környezetéből megnevez néhány informatikai eszközt, felsorolja fontosabb jellemzőit.',
		b: 4,
		c: 168,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'ismerkedés az informatikai környezettel'])
	},
		{
		a: 'A rendelkezésére álló eszközökkel, forrásokból meggyőződik a talált vagy kapott információk helyességéről.',
		b: 4,
		c: 167,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Eredményétől függően módosítja a problémamegoldás folyamatában az adott, egyszerű tevékenységsorokat.',
		b: 4,
		c: 166,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Kiválasztja az általa ismert informatikai eszközök és alkalmazások közül azokat, melyek az adott probléma megoldásához szükségesek.',
		b: 4,
		c: 165,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Társaival együttműködve online és offline környezetben egyaránt megold különböző feladatokat, ötleteit, véleményét megfogalmazza, részt vesz a közös álláspont kialakításában.',
		b: 4,
		c: 164,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Megvizsgálja és értékeli az általa vagy társai által alkalmazott, létrehozott, megvalósított eljárásokat.',
		b: 4,
		c: 163,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Elmélyülten dolgozik digitális környezetben, önellenőrzést végez.',
		b: 4,
		c: 162,
		d: 4,
		e: 'Digitális kultúra@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Online gyakorolja az állampolgári jogokat és kötelességeket.',
		b: 5,
		c: 249,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs társadalom, e-világ lehetőségeinek, kockázatainak ismerete'])
	},
		{
		a: 'Ismeri az információs társadalom múltját, jelenét és várható jövőjét.',
		b: 5,
		c: 248,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs társadalom, e-világ lehetőségeinek, kockázatainak ismerete'])
	},
		{
		a: 'Ismeri az információs technológia fejlődésének gazdasági, környezeti, kulturális hatásait.',
		b: 5,
		c: 247,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs társadalom, e-világ lehetőségeinek, kockázatainak ismerete'])
	},
		{
		a: 'Ismeri a digitális környezet, az e-világ etikai problémáit.',
		b: 5,
		c: 246,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs társadalom, e-világ lehetőségeinek, kockázatainak ismerete'])
	},
		{
		a: 'Védekezik az internetes zaklatás különböző formái ellen, szükség esetén segítséget kér.',
		b: 5,
		c: 245,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'együttműködés az információs technológiák használata során'])
	},
		{
		a: 'Tisztában van a hálózatokat és a személyes információkat érintő fenyegetésekkel, alkalmazza az adatok védelmét biztosító lehetőségeket.',
		b: 5,
		c: 244,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'együttműködés az információs technológiák használata során'])
	},
		{
		a: 'Tapasztalatokkal rendelkezik az iskolai oktatáshoz kapcsolódó mobileszközökre fejlesztett alkalmazások használatában.',
		b: 5,
		c: 243,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'együttműködés az információs technológiák használata során'])
	},
		{
		a: 'Ismeri a térinformatika és a 3d megjelenítés lehetőségeit.',
		b: 5,
		c: 242,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'robotika'])
	},
		{
		a: 'Tapasztalatokkal rendelkezik az eseményvezérlésről.',
		b: 5,
		c: 241,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'robotika'])
	},
		{
		a: 'Adatokat gyűjt szenzorok segítségével.',
		b: 5,
		c: 240,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'robotika'])
	},
		{
		a: 'Mozgásokat vezérel szimulált vagy valós környezetben.',
		b: 5,
		c: 239,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'robotika'])
	},
		{
		a: 'Ismeri és betartja az elektronikus kommunikációs szabályokat.',
		b: 5,
		c: 238,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs technológián alapuló kommunikációs formák használata'])
	},
		{
		a: 'Ismeri, használja az elektronikus kommunikáció lehetőségeit, a családi és az iskolai környezetének elektronikus szolgáltatásait.',
		b: 5,
		c: 237,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információs technológián alapuló kommunikációs formák használata'])
	},
		{
		a: 'Az internetes adatbázis-kezelő rendszerek keresési űrlapját helyesen tölti ki.',
		b: 5,
		c: 236,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információkeresési technikák alkalmazása'])
	},
		{
		a: 'Önállóan keres információt, a találatokat hatékonyan szűri.',
		b: 5,
		c: 235,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információkeresési technikák alkalmazása'])
	},
		{
		a: 'Ismeri az információkeresés technikáját, stratégiáját és több keresési szempont egyidejű érvényesítésének lehetőségét.',
		b: 5,
		c: 234,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'az információkeresési technikák alkalmazása'])
	},
		{
		a: 'Vizsgálni tudja a szabályozó eszközök hatásait a tantárgyi alkalmazásokban.',
		b: 5,
		c: 233,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'tantárgyi problémák vizsgálata digitális eszközökkel'])
	},
		{
		a: 'Tapasztalatokkal rendelkezik hétköznapi jelenségek számítógépes szimulációjáról.',
		b: 5,
		c: 232,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'tantárgyi problémák vizsgálata digitális eszközökkel'])
	},
		{
		a: 'Problémákat old meg táblázatkezelő program segítségével.',
		b: 5,
		c: 231,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'tantárgyi problémák vizsgálata digitális eszközökkel'])
	},
		{
		a: 'Az adatok szemléltetéséhez diagramot készít.',
		b: 5,
		c: 230,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatok kezelése'])
	},
		{
		a: 'Cellahivatkozásokat, matematikai tudásának megfelelő képleteket, egyszerű statisztikai függvényeket használ táblázatkezelő programban.',
		b: 5,
		c: 229,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatok kezelése'])
	},
		{
		a: 'Az adatokat táblázatos formába rendezi és formázza.',
		b: 5,
		c: 228,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatok kezelése'])
	},
		{
		a: 'A probléma megoldásához vezérlési szerkezetet (szekvencia, elágazás és ciklus) alkalmaz a tanult blokkprogramozási nyelven.',
		b: 5,
		c: 227,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'a kódolás eszközeinek ismerete, a blokkprogramozás eszközeinek használata'])
	},
		{
		a: 'Ismeri és használja a blokkprogramozás alapvető építőelemeit.',
		b: 5,
		c: 226,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'a kódolás eszközeinek ismerete, a blokkprogramozás eszközeinek használata'])
	},
		{
		a: 'Ismeri és használja a programozási környezet alapvető eszközeit.',
		b: 5,
		c: 225,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'a kódolás eszközeinek ismerete, a blokkprogramozás eszközeinek használata'])
	},
		{
		a: 'Adatokat kezel a programozás eszközeivel.',
		b: 5,
		c: 224,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'a kódolás eszközeinek ismerete, a blokkprogramozás eszközeinek használata'])
	},
		{
		a: 'Ismeri a kódolás eszközeit.',
		b: 5,
		c: 223,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'a kódolás eszközeinek ismerete, a blokkprogramozás eszközeinek használata'])
	},
		{
		a: 'Egyszerű algoritmusokat elemez és készít.',
		b: 5,
		c: 222,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'egyszerű algoritmusok elemzése, készítése'])
	},
		{
		a: 'Értelmezi az algoritmus végrehajtásához szükséges adatok és az eredmények kapcsolatát.',
		b: 5,
		c: 221,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'egyszerű algoritmusok elemzése, készítése'])
	},
		{
		a: 'Megkülönbözteti, kezeli és használja az elemi adatokat.',
		b: 5,
		c: 220,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'egyszerű algoritmusok elemzése, készítése'])
	},
		{
		a: 'Érti, hogyan történik az egyszerű algoritmusok végrehajtása a digitális eszközökön.',
		b: 5,
		c: 219,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'egyszerű algoritmusok elemzése, készítése'])
	},
		{
		a: 'Bemutató-készítő vagy szövegszerkesztő programban rajzeszközökkel ábrát készít.',
		b: 5,
		c: 218,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'multimédiás elemek készítése'])
	},
		{
		a: 'Ismeri egy bittérképes rajzolóprogram használatát, azzal ábrát készít.',
		b: 5,
		c: 217,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'multimédiás elemek készítése'])
	},
		{
		a: 'Digitális képeken képkorrekciót hajt végre.',
		b: 5,
		c: 216,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'multimédiás elemek készítése'])
	},
		{
		a: 'Digitális eszközökkel önállóan rögzít és tárol képet, hangot és videót.',
		b: 5,
		c: 215,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'multimédiás elemek készítése'])
	},
		{
		a: 'Etikus módon használja fel az információforrásokat, tisztában van a hivatkozás szabályaival.',
		b: 5,
		c: 214,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'különböző típusú dokumentumok iskolai, tanórai, hétköznapi célú felhasználása'])
	},
		{
		a: 'Ismeri a prezentációkészítés alapszabályait, és azokat alkalmazza.',
		b: 5,
		c: 213,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'különböző típusú dokumentumok iskolai, tanórai, hétköznapi célú felhasználása'])
	},
		{
		a: 'A szöveges dokumentumokat többféle elrendezésben jeleníti meg papíron, tisztában van a nyomtatás környezetre gyakorolt hatásaival.',
		b: 5,
		c: 212,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'különböző típusú dokumentumok iskolai, tanórai, hétköznapi célú felhasználása'])
	},
		{
		a: 'Ismeri és kritikusan használja a nyelvi eszközöket (például helyesírás-ellenőrzés, elválasztás).',
		b: 5,
		c: 211,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'dokumentum létrehozása szövegszerkesztő és bemutató-készítő alkalmazással'])
	},
		{
		a: 'A tartalomnak megfelelően alakítja ki a szöveges vagy a multimédiás dokumentum szerkezetét, illeszti be, helyezi el és formázza meg a szükséges objektumokat.',
		b: 5,
		c: 210,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'dokumentum létrehozása szövegszerkesztő és bemutató-készítő alkalmazással'])
	},
		{
		a: 'Ismeri és tudatosan alkalmazza a szöveges és multimédiás dokumentum készítése során a szöveg formázására, tipográfiájára vonatkozó alapelveket.',
		b: 5,
		c: 209,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'dokumentum létrehozása szövegszerkesztő és bemutató-készítő alkalmazással'])
	},
		{
		a: 'Egy adott feladat kapcsán önállóan hoz létre szöveges vagy multimédiás dokumentumokat.',
		b: 5,
		c: 208,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'dokumentum létrehozása szövegszerkesztő és bemutató-készítő alkalmazással'])
	},
		{
		a: 'Tapasztalatokkal rendelkezik a digitális jelek minőségével, kódolásával, tömörítésével, továbbításával kapcsolatos problémák kezeléséről.',
		b: 5,
		c: 207,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az operációs rendszerek önálló használata'])
	},
		{
		a: 'Használja a digitális hálózatok alapszolgáltatásait.',
		b: 5,
		c: 206,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az operációs rendszerek önálló használata'])
	},
		{
		a: 'Önállóan kezeli az operációs rendszer mappáit, fájljait és a felhőszolgáltatásokat.',
		b: 5,
		c: 205,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az operációs rendszerek önálló használata'])
	},
		{
		a: 'Önállóan használja az operációs rendszer felhasználói felületét.',
		b: 5,
		c: 204,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az operációs rendszerek önálló használata'])
	},
		{
		a: 'Értelmezi az informatikai eszközöket működtető szoftverek hibajelzéseit, és azokról beszámol.',
		b: 5,
		c: 203,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az informatikai eszközök önálló használata'])
	},
		{
		a: 'Az informatikai eszközöket önállóan használja, a tipikus felhasználói hibákat elkerüli, és elhárítja az egyszerűbb felhasználói szintű hibákat.',
		b: 5,
		c: 202,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az informatikai eszközök önálló használata'])
	},
		{
		a: 'Célszerűen választ a feladat megoldásához használható informatikai eszközök közül.',
		b: 5,
		c: 201,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az informatikai eszközök önálló használata'])
	},
		{
		a: 'Megismeri a digitális társadalom elvárásait, lehetőségeit és veszélyeit.',
		b: 5,
		c: 200,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Digitális tudáselemek felhasználásával, társaival együttműködve különböző problémákat old meg.',
		b: 5,
		c: 199,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Megismeri a felmerülő problémák megoldásának módjait, beleértve az adott feladat megoldásához szükséges algoritmus értelmezését, alkotását és számítógépes program készítését és kódolását a blokkprogramozás eszközeivel.',
		b: 5,
		c: 198,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Elsajátítja a digitális írástudás eszközeit, azokkal feladatokat old meg.',
		b: 5,
		c: 197,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Önállóan használja a digitális eszközöket, az online kommunikáció eszközeit, tisztában van az ezzel járó veszélyekkel.',
		b: 5,
		c: 196,
		d: 8,
		e: 'Digitális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'A gyakorlatban alkalmazza az adatok védelmét biztosító lehetőségeket.',
		b: 9,
		c: 307,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'e-állampolgársági ismeretek, e-szolgáltatások, e-ügyintézés, e-kereskedelem'])
	},
		{
		a: 'Tisztában van a digitális személyazonosság és az információhitelesség fogalmával.',
		b: 9,
		c: 306,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'e-állampolgársági ismeretek, e-szolgáltatások, e-ügyintézés, e-kereskedelem'])
	},
		{
		a: 'Tisztában van az e-világ -- e-szolgáltatások, e-ügyintézés, e-kereskedelem, e-állampolgárság, it-gazdaság, környezet, kultúra, információvédelem -- biztonsági és jogi kérdéseivel.',
		b: 9,
		c: 305,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'e-állampolgársági ismeretek, e-szolgáltatások, e-ügyintézés, e-kereskedelem'])
	},
		{
		a: 'Az iskolai oktatáshoz kapcsolódó mobileszközökre fejlesztett alkalmazások használata során együttműködik társaival.',
		b: 9,
		c: 304,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'mobiltechnológiai ismeretek'])
	},
		{
		a: 'Az applikációkat önállóan telepíti.',
		b: 9,
		c: 303,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'mobiltechnológiai ismeretek'])
	},
		{
		a: 'Céljainak megfelelő alkalmazást választ, az alkalmazás funkcióira, kezelőfelületére vonatkozó igényeit megfogalmazza.',
		b: 9,
		c: 302,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'mobiltechnológiai ismeretek'])
	},
		{
		a: 'Ismeri és használja a mobiltechnológiát, kezeli a mobileszközök operációs rendszereit és használ mobilalkalmazásokat.',
		b: 9,
		c: 301,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'mobiltechnológiai ismeretek'])
	},
		{
		a: 'Az online kommunikáció során alkalmazza a kialakult viselkedési kultúrát és szokásokat, a szerepelvárásokat.',
		b: 9,
		c: 300,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'információkeresés és online kommunikáció'])
	},
		{
		a: 'Ismeri és alkalmazza a fogyatékkal élők közötti kommunikáció eszközeit és formáit.',
		b: 9,
		c: 299,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'információkeresés és online kommunikáció'])
	},
		{
		a: 'Használja a két- vagy többrésztvevős kommunikációs lehetőségeket és alkalmazásokat.',
		b: 9,
		c: 298,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'információkeresés és online kommunikáció'])
	},
		{
		a: 'Etikus módon használja fel az információforrásokat, tisztában van a hivatkozás szabályaival.',
		b: 9,
		c: 297,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'információkeresés és online kommunikáció'])
	},
		{
		a: 'Ismeri és alkalmazza az információkeresési stratégiákat és technikákat, a találati listát a problémának megfelelően szűri, ellenőrzi annak hitelességét.',
		b: 9,
		c: 296,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'információs technológiák', 'információkeresés és online kommunikáció'])
	},
		{
		a: 'Tapasztalatokat szerez a kezdőértékek változtatásának hatásairól a szimulációs programokban.',
		b: 9,
		c: 295,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'számítógépes szimuláció'])
	},
		{
		a: 'Hétköznapi, oktatáshoz készült szimulációs programokat használ.',
		b: 9,
		c: 294,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'számítógépes szimuláció'])
	},
		{
		a: 'Tapasztalatokkal rendelkezik hétköznapi jelenségek számítógépes szimulációjáról.',
		b: 9,
		c: 293,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'számítógépes szimuláció'])
	},
		{
		a: 'Strukturáltan tárolt nagy adathalmazokat kezel, azokból egyedi és összesített adatokat nyer ki.',
		b: 9,
		c: 292,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatkezelés adatbázis-kezelő rendszerrel'])
	},
		{
		a: 'A feladatmegoldás során az adatbázisba adatokat visz be, módosít és töröl, űrlapokat használ, jelentéseket nyomtat.',
		b: 9,
		c: 291,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatkezelés adatbázis-kezelő rendszerrel'])
	},
		{
		a: 'Az adatbázisban interaktív módon keres, rendez és szűr.',
		b: 9,
		c: 290,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatkezelés adatbázis-kezelő rendszerrel'])
	},
		{
		a: 'Ismeri az adatbázis-kezelés alapfogalmait.',
		b: 9,
		c: 289,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatkezelés adatbázis-kezelő rendszerrel'])
	},
		{
		a: 'Az adatokat diagramon szemlélteti.',
		b: 9,
		c: 288,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatkezelés táblázatkezelő alkalmazással'])
	},
		{
		a: 'Nagy adathalmazokat tud kezelni.',
		b: 9,
		c: 287,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatkezelés táblázatkezelő alkalmazással'])
	},
		{
		a: 'A problémamegoldás során függvényeket célszerűen használ.',
		b: 9,
		c: 286,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatkezelés táblázatkezelő alkalmazással'])
	},
		{
		a: 'Táblázatkezelővel adatelemzést és számításokat végez.',
		b: 9,
		c: 285,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatkezelés táblázatkezelő alkalmazással'])
	},
		{
		a: 'Adatokat táblázatba rendez.',
		b: 9,
		c: 284,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'adatkezelés táblázatkezelő alkalmazással'])
	},
		{
		a: 'A feladat megoldásának helyességét teszteli.',
		b: 9,
		c: 283,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmizálás, módszerek, eszközök használata, típusalgoritmusok'])
	},
		{
		a: 'Szekvencia, elágazás és ciklus segítségével algoritmust hoz létre, és azt egy magas szintű formális programozási nyelven kódolja.',
		b: 9,
		c: 282,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmizálás, módszerek, eszközök használata, típusalgoritmusok'])
	},
		{
		a: 'Példákban, feladatok megoldásában használja egy formális programozási nyelv fejlesztői környezetének alapszolgáltatásait.',
		b: 9,
		c: 281,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmizálás, módszerek, eszközök használata, típusalgoritmusok'])
	},
		{
		a: 'Érti egy algoritmus-leíró eszköz alapvető építőelemeit, érti a típusalgoritmusok felhasználásának lehetőségeit.',
		b: 9,
		c: 280,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmizálás, módszerek, eszközök használata, típusalgoritmusok'])
	},
		{
		a: 'Ismeri az elemi és összetett adattípusok közötti különbségeket.',
		b: 9,
		c: 279,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmizálás, módszerek, eszközök használata, típusalgoritmusok'])
	},
		{
		a: 'Ismeri a következő elemi adattípusok közötti különbségeket: egész, valós szám, karakter, szöveg, logikai.',
		b: 9,
		c: 278,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmizálás, módszerek, eszközök használata, típusalgoritmusok'])
	},
		{
		a: 'Érti az egyszerű problémák megoldásához szükséges tevékenységek lépéseit és kapcsolatukat.',
		b: 9,
		c: 277,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'problémamegoldás informatikai eszközökkel és módszerekkel', 'algoritmizálás, módszerek, eszközök használata, típusalgoritmusok'])
	},
		{
		a: 'Tisztában van a raszter-, a vektorgrafikus ábrák tárolási és szerkesztési módszereivel',
		b: 9,
		c: 276,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'grafikus ábrák készítése és képszerkesztés'])
	},
		{
		a: 'Digitálisan rögzít képet, hangot és videót, azokat manipulálja.',
		b: 9,
		c: 275,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'grafikus ábrák készítése és képszerkesztés'])
	},
		{
		a: 'Létrehoz vektorgrafikus ábrákat.',
		b: 9,
		c: 274,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'grafikus ábrák készítése és képszerkesztés'])
	},
		{
		a: 'Létrehozza az adott probléma megoldásához szükséges rasztergrafikus ábrákat.',
		b: 9,
		c: 273,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'grafikus ábrák készítése és képszerkesztés'])
	},
		{
		a: 'Ismeri a html formátumú dokumentumok szerkezeti elemeit, érti a css használatának alapelveit; több lapból álló webhelyet készít.',
		b: 9,
		c: 272,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'multimédiás és webes dokumentumok szerkesztése és készítése'])
	},
		{
		a: 'Dokumentumokat szerkeszt és helyez el tartalomkezelő rendszerben.',
		b: 9,
		c: 271,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'multimédiás és webes dokumentumok szerkesztése és készítése'])
	},
		{
		a: 'Alkalmazza az információkeresés során gyűjtött multimédiás alapelemeket új dokumentumok készítéséhez.',
		b: 9,
		c: 270,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'multimédiás és webes dokumentumok szerkesztése és készítése'])
	},
		{
		a: 'Gyakorlatot szerez a fotó-, hang-, videó-, multimédia-szerkesztő, a bemutató-készítő eszközök használatában.',
		b: 9,
		c: 269,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'multimédiás és webes dokumentumok szerkesztése és készítése'])
	},
		{
		a: 'Tapasztalatokkal rendelkezik a formanyomtatványok, a sablonok, az előre definiált stílusok használatáról.',
		b: 9,
		c: 268,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'nagyméretű szöveges dokumentumok szerkesztését elősegítő eszközök'])
	},
		{
		a: 'Speciális dokumentumokat hoz létre, alakít át és formáz meg.',
		b: 9,
		c: 267,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'nagyméretű szöveges dokumentumok szerkesztését elősegítő eszközök'])
	},
		{
		a: 'Ismeri egy adott feladat megoldásához szükséges digitális eszközök és szoftverek kiválasztásának szempontjait.',
		b: 9,
		c: 266,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'digitális írástudás', 'nagyméretű szöveges dokumentumok szerkesztését elősegítő eszközök'])
	},
		{
		a: 'Használja az állományok tömörítését és a tömörített állományok kibontását.',
		b: 9,
		c: 265,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'segédprogramok, digitális kártevők elleni védekezés, állományok tömörítése'])
	},
		{
		a: 'Tisztában van a digitális kártevők elleni védekezés lehetőségeivel.',
		b: 9,
		c: 264,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'segédprogramok, digitális kártevők elleni védekezés, állományok tömörítése'])
	},
		{
		a: 'Használja az operációs rendszer segédprogramjait, és elvégzi a munkakörnyezet beállításait.',
		b: 9,
		c: 263,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'segédprogramok, digitális kártevők elleni védekezés, állományok tömörítése'])
	},
		{
		a: 'Követi a technológiai változásokat a digitális információforrások használatával.',
		b: 9,
		c: 262,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'a mobileszközök, számítógépek, hálózatok operációs rendszerei, felhőszolgáltatások'])
	},
		{
		a: 'Igénybe veszi az operációs rendszer és a számítógépes hálózat alapszolgáltatásait.',
		b: 9,
		c: 261,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'a mobileszközök, számítógépek, hálózatok operációs rendszerei, felhőszolgáltatások'])
	},
		{
		a: 'Céljainak megfelelően használja a mobileszközök és a számítógépek operációs rendszereit.',
		b: 9,
		c: 260,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'a mobileszközök, számítógépek, hálózatok operációs rendszerei, felhőszolgáltatások'])
	},
		{
		a: 'Önállóan használja az informatikai eszközöket, elkerüli a tipikus felhasználói hibákat, elhárítja az egyszerűbb felhasználói hibákat.',
		b: 9,
		c: 259,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az informatikai eszközök felhasználásának lehetőségei, az informatikai környezet'])
	},
		{
		a: 'Tudatosan alakítja informatikai környezetét, ismeri az ergonomikus informatikai környezet jellemzőit, figyelembe veszi a digitális eszközök egészségkárosító hatásait, óvja maga és környezete egészségét.',
		b: 9,
		c: 258,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az informatikai eszközök felhasználásának lehetőségei, az informatikai környezet'])
	},
		{
		a: 'Ismeri a digitális eszközök és a számítógépek fő egységeit, ezek fejlődésének főbb állomásait, tendenciáit.',
		b: 9,
		c: 257,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az informatikai eszközök felhasználásának lehetőségei, az informatikai környezet'])
	},
		{
		a: 'Ismeri és tudja használni a célszerűen választott informatikai eszközöket és a működtető szoftvereit, ismeri a felhasználási lehetőségeket.',
		b: 9,
		c: 256,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['digitális kultúra', 'az informatikai eszközök használata', 'az informatikai eszközök felhasználásának lehetőségei, az informatikai környezet'])
	},
		{
		a: 'Ismeri az e-világ elvárásait, lehetőségeit és veszélyeit.',
		b: 9,
		c: 255,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Hatékonyan keres információt; az ikt-tudáselemek felhasználásával társaival együttműködve problémákat old meg.',
		b: 9,
		c: 254,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Megismeri az algoritmikus probléma megoldásához szükséges módszereket és eszközöket, megoldásukhoz egy magas szintű formális programozási nyelv fejlesztői környezetét önállóan használja.',
		b: 9,
		c: 253,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Megismeri az adatkezelés alapfogalmait, képes a nagyobb adatmennyiség tárolását, hatékony feldolgozását biztosító eszközök és módszerek alapszintű használatára, érti a működésüket.',
		b: 9,
		c: 252,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Gyakorlatot szerez dokumentumok létrehozását segítő eszközök használatában.',
		b: 9,
		c: 251,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Ismeri az informatikai eszközök és a működtető szoftvereik célszerű választásának alapelveit, használja a digitális hálózatok alapszolgáltatásait, az online kommunikáció eszközeit, tisztában van az ezzel járó veszélyekkel, ezzel összefüggésben ismeri a segítségnyújtási, segítségkérési lehetőségeket.',
		b: 9,
		c: 250,
		d: 12,
		e: 'Digitális kultúra@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'digitális kultúra'])
	},
		{
		a: 'Felismeri és azonosítja a dráma és a színház formanyelvi sajátosságait a látott előadásokban.',
		b: 7,
		c: 339,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megismerés és értelmezés', 'a dráma és a színház művészete'])
	},
		{
		a: 'Felismeri és megvizsgálja a problémahelyzeteket és azok lehetséges megoldási alternatíváit.',
		b: 7,
		c: 338,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megismerés és értelmezés', 'problémaközpontú gondolkodás'])
	},
		{
		a: 'Felismeri és azonosítja a dramatikus szituációk jellemzőit (szereplők, viszonyrendszer, cél, szándék, akarat, konfliktus, feloldás).',
		b: 7,
		c: 337,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megismerés és értelmezés', 'problémaközpontú gondolkodás'])
	},
		{
		a: 'A színházi előadást a dramatikus tevékenységek kiindulópontjául is használja.',
		b: 7,
		c: 336,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megismerés és értelmezés', 'színházi élmény feldolgozása'])
	},
		{
		a: 'Felismeri a színházi élmény fontosságát.',
		b: 7,
		c: 335,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megismerés és értelmezés', 'színházi élmény feldolgozása'])
	},
		{
		a: 'Értelmezi a megélt, a látott-hallott-olvasott, a kitalált történeteket a különböző dramatikus tevékenységek révén.',
		b: 7,
		c: 334,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megismerés és értelmezés', 'történetek feldolgozása'])
	},
		{
		a: 'Megkülönbözteti és alapszinten alkalmazza a dramaturgiai alapfogalmakat.',
		b: 7,
		c: 333,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megismerés és értelmezés', 'történetek feldolgozása'])
	},
		{
		a: 'Felismeri a helyzetek feldolgozása során a szerkesztésben rejlő lehetőségeket.',
		b: 7,
		c: 332,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'színjátékos eszközök használata'])
	},
		{
		a: 'Alkalmazza a tanult dramatikus technikákat a helyzetek megjelenítésében.',
		b: 7,
		c: 331,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'színjátékos eszközök használata'])
	},
		{
		a: 'Felfedezi a színházi kommunikáció erejét.',
		b: 7,
		c: 330,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'színjátékos eszközök használata'])
	},
		{
		a: 'Felfedezi a feszültség élményét és szerepét a dramatikus tevékenységekben.',
		b: 7,
		c: 329,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'improvizációs készség'])
	},
		{
		a: 'Felfedezi a kommunikációs jelek jelentéshordozó és jelentésteremtő erejét.',
		b: 7,
		c: 328,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'improvizációs készség'])
	},
		{
		a: 'Felismeri és alapszinten alkalmazza a kapcsolat létrehozásának és fenntartásának technikáit.',
		b: 7,
		c: 327,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'improvizációs készség'])
	},
		{
		a: 'Felfedezi a szerepbe lépésben és az együttjátszásban rejlő lehetőségeket.',
		b: 7,
		c: 326,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'improvizációs készség'])
	},
		{
		a: 'Használja a tér sajátosságaiban rejlő lehetőségeket.',
		b: 7,
		c: 325,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'térhasználat'])
	},
		{
		a: 'Felfedezi a tárgyi világ kínálta eszközöket, ezek művészi formáit (pl. a bábot és a maszkot).',
		b: 7,
		c: 324,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'tárgyhasználat, bábhasználat'])
	},
		{
		a: 'Az alkotótevékenység során használja a megismert kifejezési formákat.',
		b: 7,
		c: 323,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'verbális és nonverbális kommunikáció'])
	},
		{
		a: 'Adekvát módon alkalmazza a verbális és nonverbális kifejezés eszközeit.',
		b: 7,
		c: 322,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'megjelenítés, kifejezés, közvetítés', 'verbális és nonverbális kommunikáció'])
	},
		{
		a: 'Fejleszti az együttműködésre és a konszenzus kialakítására irányuló gyakorlatát.',
		b: 7,
		c: 321,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'csoportos játék és együttműködés', 'ön- és társismeret'])
	},
		{
		a: 'Értelmezi önmagát a csoport részeként, illetve a csoportos tevékenység alkotó közreműködőjeként.',
		b: 7,
		c: 320,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'csoportos játék és együttműködés', 'ön- és társismeret'])
	},
		{
		a: 'Megfigyeli, azonosítja és értelmezi a környezetéből érkező hatásokra adott saját válaszait.',
		b: 7,
		c: 319,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'csoportos játék és együttműködés', 'ön- és társismeret'])
	},
		{
		a: 'Koncentrált figyelemmel végzi a játékszabályok adta keretek között tevékenységeit.',
		b: 7,
		c: 318,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'csoportos játék és együttműködés', 'figyelem, koncentráció'])
	},
		{
		a: 'Tudatosan irányítja és összpontosítja figyelmét a környezete jelenségeire.',
		b: 7,
		c: 317,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'csoportos játék és együttműködés', 'figyelem, koncentráció'])
	},
		{
		a: 'Kitalál és alkalmaz elképzelt verbális, vokális, vizuális, kinetikus hatásokat.',
		b: 7,
		c: 316,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'csoportos játék és együttműködés', 'érzékelés, megfigyelés, felismerés, emlékezet, fantázia'])
	},
		{
		a: 'Felidézi a látott, hallott, érzékelt verbális, vokális, vizuális, kinetikus hatásokat.',
		b: 7,
		c: 315,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'csoportos játék és együttműködés', 'érzékelés, megfigyelés, felismerés, emlékezet, fantázia'])
	},
		{
		a: 'Megfigyeli, azonosítja és értelmezi a tárgyi világ jelenségeit.',
		b: 7,
		c: 314,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'csoportos játék és együttműködés', 'érzékelés, megfigyelés, felismerés, emlékezet, fantázia'])
	},
		{
		a: 'Felfedezi a tér, az idő, a tempó, a ritmus sajátosságait és összefüggéseit.',
		b: 7,
		c: 313,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['dráma és színház', 'csoportos játék és együttműködés', 'érzékelés, megfigyelés, felismerés, emlékezet, fantázia'])
	},
		{
		a: 'Megfogalmazza egy színházi előadás kapcsán élményeit, gondolatait.',
		b: 7,
		c: 312,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'dráma és színház'])
	},
		{
		a: 'Saját gondolatot, témát, üzenetet fogalmaz meg a témához általa alkalmasnak ítélt dramatikus közlésformában.',
		b: 7,
		c: 311,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'dráma és színház'])
	},
		{
		a: 'Aktívan részt vesz többféle dramatikus tevékenységben tanári irányítással, önállóan, illetve társakkal való együttműködésben.',
		b: 7,
		c: 310,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'dráma és színház'])
	},
		{
		a: 'Ismeri és alkalmazza a drámai és színházi kifejezés formáit.',
		b: 7,
		c: 309,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'dráma és színház'])
	},
		{
		a: 'Ismeri és alkalmazza a különböző verbális és nonverbális kommunikációs eszközöket.',
		b: 7,
		c: 308,
		d: 8,
		e: 'Dráma és színház@7-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'dráma és színház'])
	},
		{
		a: 'Létrehoz nagyon egyszerű írott, pár szavas szöveget szóban vagy írásban digitális felületen.',
		b: 4,
		c: 429,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'digitális eszközök és felületek használata idegen nyelven'])
	},
		{
		a: 'Megérti a tanult nyelvi elemeket életkorának megfelelő digitális tartalmakban, digitális csatornákon olvasott vagy hallott nagyon egyszerű szövegekben is.',
		b: 4,
		c: 428,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'digitális eszközök és felületek használata idegen nyelven'])
	},
		{
		a: 'Ismeri a főbb, célnyelvi kultúrához tartozó, ünnepekhez kapcsolódó alapszintű kifejezéseket, állandósult szókapcsolatokat és szokásokat.',
		b: 4,
		c: 427,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Megismeri a főbb, az adott célnyelvi kultúrákhoz tartozó országok nevét, földrajzi elhelyezkedését, főbb országismereti jellemzőit.',
		b: 4,
		c: 426,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Alapvető célzott információt megszerez a tanult témákban tudásának bővítésére.',
		b: 4,
		c: 425,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Felhasznál és létrehoz rövid, nagyon egyszerű célnyelvi szövegeket szabadidős tevékenységek során.',
		b: 4,
		c: 424,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Felismeri az idegen nyelvű írott, olvasott és hallott tartalmakat a tanórán kívül is.',
		b: 4,
		c: 423,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Egy feladat megoldásának sikerességét segítséggel értékelni tudja.',
		b: 4,
		c: 422,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'A célok eléréséhez társaival rövid feladatokban együttműködik.',
		b: 4,
		c: 421,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Szavak, kifejezések tanulásakor felismeri, ha új elemmel találkozik és rákérdez, vagy megfelelő tanulási stratégiával törekszik a megértésre.',
		b: 4,
		c: 420,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Új szavak, kifejezések tanulásakor ráismer a már korábban tanult szavakra, kifejezésekre.',
		b: 4,
		c: 419,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Közöl alapvető személyes információkat magáról, egyszerű nyelvi elemek segítségével.',
		b: 4,
		c: 418,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, a nem értés, visszakérdezés és ismétlés, kérés kifejezésére használt mindennapi nyelvi funkciókat életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 4,
		c: 417,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, a tudás és nem tudás kifejezésére használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 4,
		c: 416,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, a köszönet és az arra történő reagálás kifejezésére használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 4,
		c: 415,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, megszólításra használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 4,
		c: 414,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és alkalmazza a legegyszerűbb, bemutatkozásra használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 4,
		c: 413,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és alkalmazza a legegyszerűbb, üdvözlésre és elköszönésre használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 4,
		c: 412,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Elsajátítja a tanult szavak és állandósult szókapcsolatok célnyelvi normához közelítő kiejtését tanári minta követése által, vagy autentikus hangzó anyag, digitális technológia segítségével.',
		b: 4,
		c: 411,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Érzéseit egy-két szóval vagy begyakorolt állandósult nyelvi fordulatok segítségével kifejezi, főként rákérdezés alapján, nonverbális eszközökkel kísérve a célnyelvi megnyilatkozást.',
		b: 4,
		c: 410,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'A tanórán bekapcsolódik a már ismert, szóbeli interakciót igénylő nyelvi tevékenységekbe, a begyakorolt nyelvi elemeket tanári segítséggel a tevékenység céljainak megfelelően alkalmazza.',
		b: 4,
		c: 409,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Rövid, néhány mondatból álló párbeszédet folytat, felkészülést követően.',
		b: 4,
		c: 408,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Törekszik arra, hogy a célnyelvet eszközként alkalmazza információszerzésre.',
		b: 4,
		c: 407,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'A tanórán begyakorolt, nagyon egyszerű, egyértelmű kommunikációs helyzetekben a megtanult, állandósult beszédfordulatok alkalmazásával kérdez vagy reagál, mondanivalóját segítséggel vagy nonverbális eszközökkel kifejezi.',
		b: 4,
		c: 406,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Aktívan bekapcsolódik a közös meseolvasásba, a mese tartalmát követi.',
		b: 4,
		c: 405,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megtapasztalja a közös célnyelvi olvasás élményét.',
		b: 4,
		c: 404,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Támaszkodik az életkorának és nyelvi szintjének megfelelő írott szövegre az órai játékos alkotó, mozgásos vagy nyelvi fejlesztő tevékenységek során, kooperatív munkaformákban.',
		b: 4,
		c: 403,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Kiemeli az ismert nyelvi elemeket tartalmazó, egyszerű, írott, pár mondatos szöveg fő mondanivalóját.',
		b: 4,
		c: 402,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megérti a nyelvi szintjének megfelelő, akár vizuális eszközökkel is támogatott írott utasításokat és kérdéseket, és ezekre megfelelő válaszreakciókat ad.',
		b: 4,
		c: 401,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Csendes olvasás keretében feldolgozva megért ismert szavakat tartalmazó, pár szóból vagy mondatból álló, akár illusztrációval támogatott szöveget.',
		b: 4,
		c: 400,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Beazonosítja a célzott információt az életkorának megfelelő szituációkhoz kapcsolódó, rövid, egyszerű, a nyelvtanításhoz készült, illetve eredeti szövegben.',
		b: 4,
		c: 399,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megkülönbözteti az anyanyelvi és a célnyelvi írott szövegben a betű- és jelkészlet közti különbségeket.',
		b: 4,
		c: 398,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Figyel a célnyelvre jellemző hangok kiejtésére.',
		b: 4,
		c: 397,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Felismeri az anyanyelve és a célnyelv közötti legalapvetőbb kiejtésbeli különbségeket.',
		b: 4,
		c: 396,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmezi azokat az idegen nyelven szóban elhangzó nyelvórai szituációkat, melyeket anyanyelvén már ismer.',
		b: 4,
		c: 395,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Felismeri az anyanyelv és az idegen nyelv hangkészletét.',
		b: 4,
		c: 394,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Támaszkodik az életkorának és nyelvi szintjének megfelelő hangzó szövegre az órai alkotó jellegű nyelvi, mozgásos nyelvi és játékos nyelvi tevékenységek során.',
		b: 4,
		c: 393,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Azonosítja a célzott információt a nyelvi szintjének és életkorának megfelelő rövid hangzó szövegben.',
		b: 4,
		c: 392,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Kiszűri a lényeget az ismert nyelvi elemeket tartalmazó, nagyon rövid, egyszerű hangzó szövegből.',
		b: 4,
		c: 391,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Beazonosítja az életkorának megfelelő szituációkhoz kapcsolódó, rövid, egyszerű szövegben a tanult nyelvi elemeket.',
		b: 4,
		c: 390,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti az élőszóban elhangzó, ismert témákhoz kapcsolódó, verbális, vizuális vagy nonverbális eszközökkel segített rövid kijelentéseket, kérdéseket.',
		b: 4,
		c: 389,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Írásban megnevezi az ajánlott tématartományokban megjelölt, begyakorolt elemeket.',
		b: 4,
		c: 388,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Részt vesz kooperatív munkaformában végzett kreatív tevékenységekben, projektmunkában szavak, szószerkezetek, rövid mondatok leírásával, esetleg képi kiegészítéssel.',
		b: 4,
		c: 387,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Megold játékos írásbeli feladatokat a szavak, szószerkezetek, rövid mondatok szintjén.',
		b: 4,
		c: 386,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Lemásol tanult szavakat játékos, alkotó nyelvórai tevékenységek során.',
		b: 4,
		c: 385,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Ismeri az adott nyelv ábécéjét.',
		b: 4,
		c: 384,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Felismeri az anyanyelvén, illetve a tanult idegen nyelven történő írásmód és betűkészlet közötti különbségeket.',
		b: 4,
		c: 383,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Célnyelven megoszt egyedül vagy társaival együttműködésben megszerzett, alapvető információkat szóban, akár vizuális elemekkel támogatva.',
		b: 4,
		c: 382,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: szóbeliség'])
	},
		{
		a: 'Lebetűzi a tanult szavakat társaival közösen játékos tevékenységek kapcsán, szükség esetén segítséggel.',
		b: 4,
		c: 381,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: szóbeliség'])
	},
		{
		a: 'Lebetűzi a nevét.',
		b: 4,
		c: 380,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: szóbeliség'])
	},
		{
		a: 'Megismétli az élőszóban elhangzó egyszerű szavakat, kifejezéseket játékos, mozgást igénylő, kreatív nyelvórai tevékenységek során.',
		b: 4,
		c: 379,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: szóbeliség'])
	},
		{
		a: 'Célnyelvi tanulmányain keresztül nyitottabbá, a világ felé érdeklődőbbé válik.',
		b: 4,
		c: 378,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Törekszik a tanult nyelvi elemek megfelelő kiejtésére.',
		b: 4,
		c: 377,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Ismeri az adott célnyelvi kultúrákhoz tartozó országok fontosabb jellemzőit és a hozzájuk tartozó alapvető nyelvi elemeket.',
		b: 4,
		c: 376,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Elmondja magáról a legalapvetőbb információkat.',
		b: 4,
		c: 375,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, mindennapi nyelvi funkciókat.',
		b: 4,
		c: 374,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Követi a szintjének megfelelő, vizuális vagy nonverbális eszközökkel támogatott, ismert célnyelvi óravezetést, utasításokat.',
		b: 4,
		c: 373,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Lemásol, leír szavakat és rövid, nagyon egyszerű szövegeket.',
		b: 4,
		c: 372,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Szóban visszaad szavakat, esetleg rövid, nagyon egyszerű szövegeket hoz létre.',
		b: 4,
		c: 371,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Bekapcsolódik a szóbeliséget, írást, szövegértést vagy interakciót igénylő alapvető és korának megfelelő játékos, élményalapú élő idegen nyelvi tevékenységekbe.',
		b: 4,
		c: 370,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Megismerkedik az idegen nyelvvel, a nyelvtanulással és örömmel vesz részt az órákon.',
		b: 4,
		c: 369,
		d: 4,
		e: 'Első élő idegen nyelv@4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Találkozik az érdeklődésének megfelelő akár autentikus szövegekkel elektronikus, digitális csatornákon tanórán kívül is.',
		b: 5,
		c: 521,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Egyénileg vagy társaival együttműködve szóban vagy írásban projektmunkát vagy kiselőadást készít, és ezeket digitális eszközök segítségével is meg tudja valósítani.',
		b: 5,
		c: 520,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Következetesen alkalmazza a célnyelvi betű és jelkészletet',
		b: 5,
		c: 519,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrákhoz kapcsolódó nyelvi elemek'])
	},
		{
		a: 'Idegen nyelvi kommunikációjában ismeri és használja a célnyelv főbb jellemzőit.',
		b: 5,
		c: 518,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrákhoz kapcsolódó nyelvi elemek'])
	},
		{
		a: 'A célnyelvi kultúrákhoz kapcsolódó alapvető tanult nyelvi elemeket használja.',
		b: 5,
		c: 517,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrákhoz kapcsolódó nyelvi elemek'])
	},
		{
		a: 'Megismerkedik hazánk legfőbb országismereti és történelmi eseményeivel célnyelven.',
		b: 5,
		c: 516,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'a hazai kultúra közvetítése célnyelven'])
	},
		{
		a: 'Találkozik a célnyelvi, életkorának és érdeklődésének megfelelő hazai és nemzetközi legfőbb hírekkel, eseményekkel.',
		b: 5,
		c: 515,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'a hazai kultúra közvetítése célnyelven'])
	},
		{
		a: 'Találkozik célnyelvi országismereti tartalmakkal.',
		b: 5,
		c: 514,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Célnyelvi kommunikációjába beépíti a tanult interkulturális ismereteket.',
		b: 5,
		c: 513,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'További országismereti tudásra tesz szert.',
		b: 5,
		c: 512,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Megismeri a célnyelvi országok főbb jellemzőit és kulturális sajátosságait.',
		b: 5,
		c: 511,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Értelmez egyszerű, szórakoztató kisfilmeket',
		b: 5,
		c: 510,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'digitális nyelvhasználat'])
	},
		{
		a: 'Digitális eszközöket és felületeket is használ nyelvtudása fejlesztésére,',
		b: 5,
		c: 509,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'digitális nyelvhasználat'])
	},
		{
		a: 'Felhasználja a célnyelvet szórakozásra és játékos nyelvtanulásra.',
		b: 5,
		c: 508,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'valós nyelvhasználat'])
	},
		{
		a: 'A tanórán kívüli, akár játékos nyelvtanulási lehetőségeket felismeri, és törekszik azokat kihasználni.',
		b: 5,
		c: 507,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'valós nyelvhasználat'])
	},
		{
		a: 'Társai haladásának értékelésében segítően részt vesz.',
		b: 5,
		c: 506,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'haladás értékelése'])
	},
		{
		a: 'Nyelvi haladását többnyire fel tudja mérni,',
		b: 5,
		c: 505,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'haladás értékelése'])
	},
		{
		a: 'Céljai eléréséhez társaival párban és csoportban együttműködik.',
		b: 5,
		c: 504,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Céljai eléréséhez megtalálja és használja a megfelelő eszközöket.',
		b: 5,
		c: 503,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Egy összetettebb nyelvi feladat, projekt végéig tartó célokat tűz ki magának.',
		b: 5,
		c: 502,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Ismer szavakat, szókapcsolatokat a célnyelven a témakörre jellemző, életkorának és érdeklődésének megfelelő más tudásterületen megcélzott tartalmakból.',
		b: 5,
		c: 501,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Hibáit többnyire észreveszi és javítja.',
		b: 5,
		c: 500,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Tudatosan használ alapszintű nyelvtanulási és nyelvhasználati stratégiákat.',
		b: 5,
		c: 499,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Ismerős és gyakori alapvető helyzetekben, akár telefonon vagy digitális csatornákon is, többnyire helyesen és érthetően fejezi ki magát az ismert nyelvi eszközök segítségével.',
		b: 5,
		c: 498,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Megoszt alapvető személyes információkat és szükségleteket magáról egyszerű nyelvi elemekkel.',
		b: 5,
		c: 497,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Nem értés esetén a meg nem értett kulcsszavak vagy fordulatok ismétlését vagy magyarázatát kéri, visszakérdez, betűzést kér.',
		b: 5,
		c: 496,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Alkalmaz nyelvi funkciókat rövid társalgás megkezdéséhez, fenntartásához és befejezéséhez.',
		b: 5,
		c: 495,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Ismeretlen szavak valószínű jelentését szövegösszefüggések alapján kikövetkezteti az életkorának és érdeklődésének megfelelő, konkrét, rövid szövegekben.',
		b: 5,
		c: 494,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'A tanult nyelvi eszközökkel és nonverbális elemek segítségével tisztázza mondanivalójának lényegét.',
		b: 5,
		c: 493,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Egyszerű mondatokat összekapcsolva mond el egymást követő eseményekből álló történetet, vagy leírást ad valamilyen témáról.',
		b: 5,
		c: 492,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Összekapcsolja az ismert nyelvi elemeket egyszerű kötőszavakkal (például: és, de, vagy).',
		b: 5,
		c: 491,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Találkozik életkorának és nyelvi szintjének megfelelő célnyelvi szórakoztató tartalmakkal.',
		b: 5,
		c: 490,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Használja a célnyelvet életkorának és nyelvi szintjének megfelelő aktuális témákban és a hozzájuk tartozó szituációkban.',
		b: 5,
		c: 489,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Felhasználja a célnyelvet ismeretszerzésre.',
		b: 5,
		c: 488,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Értelmez és használja az idegen nyelvű írott, olvasott és hallott tartalmakat a tanórán kívül is,',
		b: 5,
		c: 487,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Kifejez és érvekkel alátámasztva mutat be szükségességet, lehetőséget, képességet, bizonyosságot, bizonytalanságot.',
		b: 5,
		c: 486,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Kifejez alapvető érzéseket, például örömöt, sajnálkozást, bánatot, elégedettséget, elégedetlenséget, bosszúságot, csodálkozást, reményt.',
		b: 5,
		c: 485,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Kifejez kérést, javaslatot, meghívást, kínálást és ezekre reagálást.',
		b: 5,
		c: 484,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Információt cserél, információt kér, információt ad.',
		b: 5,
		c: 483,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Kifejez tetszést, nem tetszést, akaratot, kívánságot, tudást és nem tudást, ígéretet, szándékot, dicséretet, kritikát.',
		b: 5,
		c: 482,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Egyszerűen megfogalmazza személyes véleményét, másoktól véleményük kifejtését kéri, és arra reagál, elismeri vagy cáfolja mások állítását, kifejezi egyetértését vagy egyet nem értését.',
		b: 5,
		c: 481,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Néhány szóból vagy mondatból álló jegyzetet készít írott szöveg alapján.',
		b: 5,
		c: 480,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'írásbeli tudásmegosztás'])
	},
		{
		a: 'Találkozik az életkorának és nyelvi szintjének megfelelő célnyelvi ismeretterjesztő tartalmakkal.',
		b: 5,
		c: 479,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'írásbeli tudásmegosztás'])
	},
		{
		a: 'Felhasználja a célnyelvet tudásmegosztásra.',
		b: 5,
		c: 478,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'szóbeli tudásmegosztás'])
	},
		{
		a: 'Rövid, egyszerű, ismert nyelvi eszközökből álló kiselőadást tart változatos feladatok kapcsán, hagyományos vagy digitális alapú vizuális eszközök támogatásával.',
		b: 5,
		c: 477,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'szóbeli tudásmegosztás'])
	},
		{
		a: 'Véleményét írásban, egyszerű nyelvi eszközökkel megfogalmazza, és arról írásban interakciót folytat.',
		b: 5,
		c: 476,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'íráskészség: írásbeli interakció'])
	},
		{
		a: 'Üzeneteket ír,',
		b: 5,
		c: 475,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'íráskészség: írásbeli interakció'])
	},
		{
		a: 'Bekapcsolódik a tanórán az interakciót igénylő nyelvi tevékenységekbe, abban társaival közösen részt vesz, a begyakorolt nyelvi elemeket tanári segítséggel a játék céljainak megfelelően alkalmazza.',
		b: 5,
		c: 474,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Váratlan, előre nem kiszámítható eseményekre, jelenségekre és történésekre is reagál egyszerű célnyelvi eszközökkel, személyes vagy online interakciókban.',
		b: 5,
		c: 473,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'A tanult nyelvi elemeket többnyire megfelelően használja, beszédszándékainak megfelelően, egyszerű spontán helyzetekben.',
		b: 5,
		c: 472,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Véleményét, gondolatait, érzéseit egyre magabiztosabban fejezi ki a tanult nyelvi eszközökkel.',
		b: 5,
		c: 471,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Az életkorának megfelelő mindennapi helyzetekben a tanult nyelvi eszközökkel megfogalmazott kérdéseket tesz fel, és válaszol a hozzá intézett kérdésekre.',
		b: 5,
		c: 470,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Kommunikációt kezdeményez egyszerű hétköznapi témában, a beszélgetést követi, egyszerű, nyelvi eszközökkel fenntartja és lezárja.',
		b: 5,
		c: 469,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Megért és használ szavakat, szókapcsolatokat a célnyelvi, az életkorának és érdeklődésének megfelelő hazai és nemzetközi legfőbb hírekkel, eseményekkel kapcsolatban',
		b: 5,
		c: 468,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Érdeklődése erősödik a célnyelvi irodalmi alkotások iránt.',
		b: 5,
		c: 467,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'A nyomtatott vagy digitális alapú írott szöveget felhasználja szórakozásra és ismeretszerzésre önállóan is.',
		b: 5,
		c: 466,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Alkalmazza az életkorának és nyelvi szintjének megfelelő írott, nyomtatott vagy digitális alapú szöveget a változatos nyelvórai tevékenységek és feladatmegoldás során.',
		b: 5,
		c: 465,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Összetettebb írott instrukciókat értelmez.',
		b: 5,
		c: 464,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megkülönbözteti a főbb, életkorának megfelelő írott szövegtípusokat.',
		b: 5,
		c: 463,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Kiemel, kiszűr konkrét információkat a nyelvi szintjének megfelelő szövegből, és azokat összekapcsolja más iskolai vagy iskolán kívül szerzett ismereteivel.',
		b: 5,
		c: 462,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megérti a nem kizárólag ismert nyelvi elemeket tartalmazó rövid írott szöveg tartalmát.',
		b: 5,
		c: 461,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Értelmezi az életkorának megfelelő szituációkhoz kapcsolódó, írott szövegekben megjelenő összetettebb információkat.',
		b: 5,
		c: 460,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Hallgat az érdeklődésének megfelelő autentikus szövegeket elektronikus, digitális csatornákon, tanórán kívül is, szórakozásra vagy ismeretszerzésre.',
		b: 5,
		c: 459,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Felismeri a főbb, életkorának megfelelő hangzószöveg-típusokat.',
		b: 5,
		c: 458,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmez életkorának megfelelő nyelvi helyzeteket hallott szöveg alapján.',
		b: 5,
		c: 457,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Alkalmazza az életkorának és nyelvi szintjének megfelelő hangzó szöveget a változatos nyelvórai tevékenységek és a feladatmegoldás során.',
		b: 5,
		c: 456,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Kiemel, kiszűr konkrét információkat a nyelvi szintjének megfelelő, élőszóban vagy digitális felületen elhangzó szövegből, és azokat összekapcsolja egyéb ismereteivel.',
		b: 5,
		c: 455,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti a nem kizárólag ismert nyelvi elemeket tartalmazó, élőszóban vagy digitális felületen elhangzó rövid szöveg tartalmát.',
		b: 5,
		c: 454,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmezi az életkorának megfelelő, élőszóban vagy digitális felületen elhangzó szövegekben a beszélők gondolatmenetét.',
		b: 5,
		c: 453,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmezi az életkorának és nyelvi szintjének megfelelő, egyszerű, hangzó szövegben a tanult nyelvi elemeket.',
		b: 5,
		c: 452,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti a szintjének megfelelő, kevésbé ismert elemekből álló, nonverbális vagy vizuális eszközökkel támogatott célnyelvi óravezetést és utasításokat, kérdéseket.',
		b: 5,
		c: 451,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'A szövegek létrehozásához nyomtatott, illetve digitális alapú segédeszközt, szótárt használ.',
		b: 5,
		c: 450,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Rövid, összefüggő, papíralapú vagy ikt-eszközökkel segített írott projektmunkát készít önállóan vagy kooperatív munkaformákban.',
		b: 5,
		c: 449,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Rövid szövegek írását igénylő kreatív munkát hoz létre önállóan.',
		b: 5,
		c: 448,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Rövid, egyszerű, összefüggő szövegeket ír a tanult nyelvi szerkezetek felhasználásával az ismert szövegtípusokban, az ajánlott tématartományokban.',
		b: 5,
		c: 447,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Megold játékos és változatos írásbeli feladatokat rövid szövegek szintjén.',
		b: 5,
		c: 446,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Változatos, kognitív kihívást jelentő szóbeli feladatokat old meg önállóan vagy kooperatív munkaformában, a tanult nyelvi eszközökkel, szükség szerint tanári segítséggel, élőszóban és digitális felületen.',
		b: 5,
		c: 445,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Képet jellemez röviden, egyszerűen, ismert nyelvi fordulatok segítségével, segítő tanári kérdések alapján, önállóan.',
		b: 5,
		c: 444,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Egyszerű nyelvi eszközökkel, felkészülést követően röviden, összefüggően beszél az ajánlott tématartományokhoz tartozó témákban, élőszóban és digitális felületen.',
		b: 5,
		c: 443,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Elmesél rövid történetet, egyszerűsített olvasmányt egyszerű nyelvi eszközökkel, önállóan, a cselekményt lineárisan összefűzve.',
		b: 5,
		c: 442,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Egyre magabiztosabban kapcsolódik be történetek kreatív alakításába, átfogalmazásába kooperatív munkaformában.',
		b: 5,
		c: 441,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Aktívan részt vesz az életkorának és érdeklődésének megfelelő gyermek-, illetve ifjúsági irodalmi alkotások közös előadásában.',
		b: 5,
		c: 440,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Érti a nyelvtudás fontosságát, és motivációja a nyelvtanulásra tovább erősödik.',
		b: 5,
		c: 439,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Törekszik a célnyelvi normához illeszkedő kiejtés, beszédtempó és intonáció megközelítésére.',
		b: 5,
		c: 438,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Alkalmazza nyelvtudását kommunikációra, közvetítésre, szórakozásra, ismeretszerzésre hagyományos és digitális csatornákon.',
		b: 5,
		c: 437,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Használ életkorának és nyelvi szintjének megfelelő hagyományos és digitális alapú nyelvtanulási forrásokat és eszközöket.',
		b: 5,
		c: 436,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Nyelvtudását egyre inkább képes fejleszteni tanórán kívüli helyzetekben is különböző eszközökkel és lehetőségekkel.',
		b: 5,
		c: 435,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Kommunikációs szándékának megfelelően alkalmazza a tanult nyelvi funkciókat és a megszerzett szociolingvisztikai, pragmatikai és interkulturális jártasságát.',
		b: 5,
		c: 434,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'A tanult nyelvi elemek és kommunikációs stratégiák segítségével írásbeli és szóbeli interakciót folytat, valamint közvetít az élő idegen nyelven.',
		b: 5,
		c: 433,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Értelmez korának és nyelvi szintjének megfelelő hallott és írott célnyelvi szövegeket az ismert témákban és szövegtípusokban.',
		b: 5,
		c: 432,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Szóban és írásban létrehoz rövid szövegeket, ismert nyelvi eszközökkel, a korának megfelelő szövegtípusokban.',
		b: 5,
		c: 431,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Szóban és írásban megold változatos kihívásokat igénylő feladatokat az élő idegen nyelven.',
		b: 5,
		c: 430,
		d: 8,
		e: 'Első élő idegen nyelv@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Alkalmazza az életkorának és érdeklődésének megfelelő digitális műfajok főbb jellemzőit.',
		b: 9,
		c: 687,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Digitális eszközökön és csatornákon keresztül is megfelelő nyelvi eszközökkel alkot szöveget szóban és írásban.',
		b: 9,
		c: 686,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Digitális eszközökön és csatornákon keresztül is folytat a természeteshez közelítő célnyelvi interakciót az ismert nyelvi eszközök segítségével.',
		b: 9,
		c: 685,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Digitális eszközökön és csatornákon keresztül is folytat célnyelvi interakciót az ismert nyelvi eszközök segítségével.',
		b: 9,
		c: 684,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Digitális eszközökön és csatornákon keresztül is alkalmazza az ismert témához kapcsolódó írott vagy hallott szövegeket.',
		b: 9,
		c: 683,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Digitális eszközökön és csatornákon keresztül is megérti az ismert témához kapcsolódó írott vagy hallott szövegeket.',
		b: 9,
		c: 682,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Digitális eszközökön és csatornákon keresztül is alkot szöveget szóban és írásban.',
		b: 9,
		c: 681,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Megérti a legfőbb nyelvi dialektusok egyes elemeit is tartalmazó szóbeli közléseket.',
		b: 9,
		c: 680,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'nyelvi változatok'])
	},
		{
		a: 'Alkalmazza a nyelvi változatokról megszerzett ismereteit informális kommunikációjában.',
		b: 9,
		c: 679,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'nyelvi változatok'])
	},
		{
		a: 'Megfogalmaz főbb hasonlóságokat és különbségeket az ismert nyelvi változatok között.',
		b: 9,
		c: 678,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'nyelvi változatok'])
	},
		{
		a: 'Felismeri a legfőbb hasonlóságokat és különbségeket az ismert nyelvi változatok között.',
		b: 9,
		c: 677,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'nyelvi változatok'])
	},
		{
		a: 'Interkulturális ismeretei segítségével társasági szempontból is megfelelő kommunikációt folytat írásban és szóban.',
		b: 9,
		c: 676,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrákhoz kapcsolódó nyelvi elemek'])
	},
		{
		a: 'A célnyelvi kultúrákhoz kapcsolódó tanult nyelvi elemeket magabiztosan használja.',
		b: 9,
		c: 675,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrákhoz kapcsolódó nyelvi elemek'])
	},
		{
		a: 'Kikövetkezteti a célnyelvi kultúrákhoz kapcsolódó egyszerű, ismeretlen nyelvi elemeket.',
		b: 9,
		c: 674,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrákhoz kapcsolódó nyelvi elemek'])
	},
		{
		a: 'Környezetének kulturális értékeit célnyelven közvetíti.',
		b: 9,
		c: 673,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'hazai kultúra közvetítése célnyelven'])
	},
		{
		a: 'Interkulturális tudatosságára építve felismeri a célnyelvi és saját hazájának kultúrája közötti hasonlóságokat és különbségeket, és a magyar értékek átadására képessé válik.',
		b: 9,
		c: 672,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'hazai kultúra közvetítése célnyelven'])
	},
		{
		a: 'Ismeri a célnyelvi és saját hazájának kultúrája közötti hasonlóságokat és különbségeket.',
		b: 9,
		c: 671,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'hazai kultúra közvetítése célnyelven'])
	},
		{
		a: 'Átadja célnyelven a magyar értékeket.',
		b: 9,
		c: 670,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'hazai kultúra közvetítése célnyelven'])
	},
		{
		a: 'Ismeri és keresi a főbb hasonlóságokat és különbségeket saját anyanyelvi és a célnyelvi közösség szokásai, értékei, attitűdjei és meggyőződései között.',
		b: 9,
		c: 669,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'hazai kultúra közvetítése célnyelven'])
	},
		{
		a: 'Tájékozott, és alkalmazni is tudja a célnyelvi országokra jellemző alapvető érintkezési és udvariassági szokásokat.',
		b: 9,
		c: 668,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Tájékozott a célnyelvi országok jellemzőiben és kulturális sajátosságaiban.',
		b: 9,
		c: 667,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Ismeri a célnyelvi országok történelmének és jelenének legfontosabb vonásait,',
		b: 9,
		c: 666,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Alkalmazza a célnyelvi kultúráról megszerzett ismereteit informális kommunikációjában,',
		b: 9,
		c: 665,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Digitális eszközöket és felületeket is használ a célnyelven ismeretszerzésre és szórakozásra.',
		b: 9,
		c: 664,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'digitális nyelvhasználat'])
	},
		{
		a: 'Digitális eszközöket és felületeket is magabiztosan használ nyelvtudása fejlesztésére.',
		b: 9,
		c: 663,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'digitális nyelvhasználat'])
	},
		{
		a: 'Törekszik releváns digitális tartalmak használatára beszédkészségének, szókincsének és kiejtésének továbbfejlesztése céljából.',
		b: 9,
		c: 662,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'digitális nyelvhasználat'])
	},
		{
		a: 'Nyelvi produkciójában és recepciójában önállóságot mutat, és egyre kevesebb korlát akadályozza.',
		b: 9,
		c: 661,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'valós nyelvhasználat'])
	},
		{
		a: 'Beszéd- és írásprodukcióját tudatosan megtervezi, hiányosságait igyekszik kompenzálni.',
		b: 9,
		c: 660,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'valós nyelvhasználat'])
	},
		{
		a: 'Az ismert nyelvi elemeket vizsgahelyzetben is használja.',
		b: 9,
		c: 659,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'valós nyelvhasználat'])
	},
		{
		a: 'Használja a célnyelvet életkorának és nyelvi szintjének megfelelő aktuális témákban és a hozzájuk tartozó szituációkban.',
		b: 9,
		c: 658,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'valós nyelvhasználat'])
	},
		{
		a: 'Nyelvtanulási céljai érdekében él a valós nyelvhasználati lehetőségekkel.',
		b: 9,
		c: 657,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'valós nyelvhasználat'])
	},
		{
		a: 'Hiányosságait, hibáit felismeri, azokat egyre hatékonyabban kompenzálja, javítja a tanult stratégiák felhasználásával.',
		b: 9,
		c: 656,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'a haladás értékelése'])
	},
		{
		a: 'Használja az ön-, tanári, vagy társai értékelését nyelvtudása fenntartására és fejlesztésére.',
		b: 9,
		c: 655,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'a haladás értékelése'])
	},
		{
		a: 'Egyre tudatosabban használja az ön-, tanári, vagy társai értékelését nyelvtudása fenntartására és fejlesztésére.',
		b: 9,
		c: 654,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'a haladás értékelése'])
	},
		{
		a: 'Használ önértékelési módokat nyelvtudása felmérésére.',
		b: 9,
		c: 653,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'a haladás értékelése'])
	},
		{
		a: 'Nyelvi haladását fel tudja mérni.',
		b: 9,
		c: 652,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'a haladás értékelése'])
	},
		{
		a: 'Az első idegen nyelvből sikeres érettségit tesz legalább középszinten.',
		b: 9,
		c: 651,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Céljai eléréséhez önszabályozóan is dolgozik.',
		b: 9,
		c: 650,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Hatékonyan alkalmazza a tanult nyelvtanulási és nyelvhasználati stratégiákat.',
		b: 9,
		c: 649,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Használja a nyelvtanulási és nyelvhasználati stratégiákat nyelvtudása fenntartására és fejlesztésére.',
		b: 9,
		c: 648,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Beazonosít nyelvtanulási célokat és ismeri az ezekhez tartozó nyelvtanulási és nyelvhasználati stratégiákat.',
		b: 9,
		c: 647,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Céljai eléréséhez társaival párban és csoportban is együttműködik.',
		b: 9,
		c: 646,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Céljai eléréséhez megtalálja és használja a megfelelő eszközöket, módokat.',
		b: 9,
		c: 645,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Nyelvtanulási céljai érdekében tudatosabban foglalkozik a célnyelvvel.',
		b: 9,
		c: 644,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Megfogalmaz hosszú távú nyelvtanulási célokat saját maga számára.',
		b: 9,
		c: 643,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Egy összetettebb nyelvi feladat, projekt végéig tartó célokat tűz ki magának.',
		b: 9,
		c: 642,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Hibáiból levont következtetéseire többnyire épít nyelvtudása fejlesztése érdekében.',
		b: 9,
		c: 641,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Hibáit az esetek többségében önállóan is képes javítani.',
		b: 9,
		c: 640,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Használ ismeretterjesztő anyagokat nyelvtudása fejlesztésére.',
		b: 9,
		c: 639,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Használ célnyelvi tartalmakat ismeretszerzésre.',
		b: 9,
		c: 638,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Használ célnyelvi elemeket más tudásterületen megcélzott tartalmakból.',
		b: 9,
		c: 637,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Felhasználja a célnyelvű, legfőbb hazai és nemzetközi híreket ismeretszerzésre és szórakozásra.',
		b: 9,
		c: 636,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Célzottan keresi az érdeklődésének megfelelő autentikus szövegeket tanórán kívül is, ismeretszerzésre és szórakozásra.',
		b: 9,
		c: 635,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Nyelvtanulási céljai érdekében alkalmazza a tanórán kívüli nyelvtanulási lehetőségeket.',
		b: 9,
		c: 634,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Megértést nehezítő hibáit önállóan javítani tudja.',
		b: 9,
		c: 633,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Az ismert nyelvi elemeket vizsgahelyzetben is használja.',
		b: 9,
		c: 632,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'valós nyelvhasználat'])
	},
		{
		a: 'Szükség esetén eltér az előre elgondoltaktól, és mondandóját a beszédpartnerekhez, hallgatósághoz igazítja.',
		b: 9,
		c: 631,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'valós nyelvhasználat'])
	},
		{
		a: 'Szociokulturális ismeretei (például célnyelvi társadalmi szokások, testbeszéd) már lehetővé teszik azt, hogy társasági szempontból is megfelelő kommunikációt folytasson.',
		b: 9,
		c: 630,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'valós nyelvhasználat'])
	},
		{
		a: 'A tanult nyelvi funkciókat és nyelvi eszköztárát életkorának megfelelő élethelyzetekben megfelelően alkalmazza.',
		b: 9,
		c: 629,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'valós nyelvhasználat'])
	},
		{
		a: 'Szóbeli és írásbeli közlései során változatos nyelvi struktúrákat használ.',
		b: 9,
		c: 628,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'valós nyelvhasználat'])
	},
		{
		a: 'A tanult nyelvi elemeket adaptálni tudja kevésbé begyakorolt helyzetekhez is.',
		b: 9,
		c: 627,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'valós nyelvhasználat'])
	},
		{
		a: 'Mondanivalóját kifejti kevésbé ismerős helyzetekben is nyelvi eszközök széles körének használatával.',
		b: 9,
		c: 626,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'valós nyelvhasználat'])
	},
		{
		a: 'Nem értés esetén képes a tartalom tisztázására.',
		b: 9,
		c: 625,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'kommunikációs stratégiák'])
	},
		{
		a: 'A társalgás vagy eszmecsere menetének fenntartásához alkalmazza a rendelkezésére álló nyelvi és stratégiai eszközöket.',
		b: 9,
		c: 624,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'kommunikációs stratégiák'])
	},
		{
		a: 'Félreértéshez vezető hibáit kijavítja, ha beszédpartnere jelzi a problémát; a kommunikáció megszakadása esetén más stratégiát alkalmazva újrakezdi a mondandóját.',
		b: 9,
		c: 623,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'kommunikációs stratégiák'])
	},
		{
		a: 'Ismert témákban a szövegösszefüggés alapján kikövetkezteti az ismeretlen szavak jelentését, megérti az ismeretlen szavakat is tartalmazó mondat jelentését.',
		b: 9,
		c: 622,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'kommunikációs stratégiák'])
	},
		{
		a: 'Körülírással közvetíti a jelentéstartalmat, ha a megfelelő szót nem ismeri.',
		b: 9,
		c: 621,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'kommunikációs stratégiák'])
	},
		{
		a: 'Használ kiemelést, hangsúlyozást, helyesbítést.',
		b: 9,
		c: 620,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'kommunikációs stratégiák'])
	},
		{
		a: 'Képes rendszerezni kommunikációját: jelzi szándékát, kezdeményez, összefoglal és lezár.',
		b: 9,
		c: 619,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'kommunikációs stratégiák'])
	},
		{
		a: 'Több különálló elemet összekapcsol összefüggő lineáris szempontsorrá.',
		b: 9,
		c: 618,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'pragmatikai megfelelés'])
	},
		{
		a: 'A kohéziós eszközök szélesebb körét alkalmazza szóbeli vagy írásbeli megnyilatkozásainak érthetőbb, koherensebb szöveggé szervezéséhez.',
		b: 9,
		c: 617,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'pragmatikai megfelelés'])
	},
		{
		a: 'Összekapcsolja a mondatokat megfelelő kötőszavakkal, így követhető leírást ad, vagy nem kronológiai sorrendben lévő eseményeket is elbeszél.',
		b: 9,
		c: 616,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'pragmatikai megfelelés'])
	},
		{
		a: 'Tanult kifejezések alkalmazásával és az alapvető nyelvi szokások követésével kifejez emlékezést és nem emlékezést.',
		b: 9,
		c: 615,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'szociolingvisztikai megfelelés'])
	},
		{
		a: 'Tanult kifejezések alkalmazásával és az alapvető nyelvi szokások követésével kifejez ok-okozat viszony vagy cél meghatározását.',
		b: 9,
		c: 614,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'szociolingvisztikai megfelelés'])
	},
		{
		a: 'Tanult kifejezések alkalmazásával és az alapvető nyelvi szokások követésével kifejez segítségkérést, ajánlást és ezekre történő reagálást.',
		b: 9,
		c: 613,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'szociolingvisztikai megfelelés'])
	},
		{
		a: 'Tanult kifejezések alkalmazásával és az alapvető nyelvi szokások követésével kifejez ítéletet, kritikát, tanácsadást.',
		b: 9,
		c: 612,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'szociolingvisztikai megfelelés'])
	},
		{
		a: 'Tanult kifejezések alkalmazásával és az alapvető nyelvi szokások követésével kifejez kötelezettséget, szándékot, kívánságot, engedélykérést, feltételezést.',
		b: 9,
		c: 611,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'szociolingvisztikai megfelelés'])
	},
		{
		a: 'Tanult kifejezések alkalmazásával és az alapvető nyelvi szokások követésével kifejez érdeklődést és érdektelenséget, szemrehányást, reklamálást.',
		b: 9,
		c: 610,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'szociolingvisztikai megfelelés'])
	},
		{
		a: 'Tanult kifejezések alkalmazásával és az alapvető nyelvi szokások követésével további alapvető érzéseket fejez ki (pl. aggódást, félelmet, kételyt).',
		b: 9,
		c: 609,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'mindennapi idegennyelv-használat', 'szociolingvisztikai megfelelés'])
	},
		{
		a: 'Írásban közvetít célnyelvi tartalmakat valós nyelvi interakciót leképező szituációkban.',
		b: 9,
		c: 608,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'írásbeli tudásmegosztás'])
	},
		{
		a: 'Írott szöveget igénylő projektmunkát készít olvasóközönségnek.',
		b: 9,
		c: 607,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'írásbeli tudásmegosztás'])
	},
		{
		a: 'Környezeti témákban a kommunikációs helyzetek széles körében hatékonyan ad át és cserél információt.',
		b: 9,
		c: 606,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'írásbeli tudásmegosztás'])
	},
		{
		a: 'Összefoglal és lejegyzetel, írásban közvetít rövid olvasott vagy hallott szövegeket.',
		b: 9,
		c: 605,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'írásbeli tudásmegosztás'])
	},
		{
		a: 'Ismer más tantárgyi tartalmakat, részinformációkat célnyelven,',
		b: 9,
		c: 604,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'írásbeli tudásmegosztás'])
	},
		{
		a: 'Használ célnyelvi tartalmakat tudásmegosztásra.',
		b: 9,
		c: 603,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'szóbeli tudásmegosztás'])
	},
		{
		a: 'Egyénileg vagy kooperáció során létrehozott projektmunkával kapcsolatos kiselőadást tart önállóan, összefüggő és folyékony előadásmóddal, digitális eszközök segítségével, felkészülést követően.',
		b: 9,
		c: 602,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'szóbeli tudásmegosztás'])
	},
		{
		a: 'Összetett információkat ad át és cserél.',
		b: 9,
		c: 601,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'információközvetítés idegen nyelven', 'szóbeli tudásmegosztás'])
	},
		{
		a: 'Írásban és szóban, valós nyelvi interakciók során jó nyelvhelyességgel, megfelelő szókinccsel, a természeteshez közelítő szinten vesz részt az egyes tématartományokban és az idetartozó érettségi témákban.',
		b: 9,
		c: 600,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'íráskészség: írásbeli interakció'])
	},
		{
		a: 'Írásban átad nyelvi szintjének megfelelő célnyelvi tartalmakat valós nyelvi interakciók során.',
		b: 9,
		c: 599,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'íráskészség: írásbeli interakció'])
	},
		{
		a: 'Véleményét írásban változatos nyelvi eszközökkel megfogalmazza és arról interakciót folytat.',
		b: 9,
		c: 598,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'íráskészség: írásbeli interakció'])
	},
		{
		a: 'Véleményét írásban, tanult nyelvi eszközökkel megfogalmazza és arról írásban interakciót folytat.',
		b: 9,
		c: 597,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'íráskészség: írásbeli interakció'])
	},
		{
		a: 'Informális és életkorának megfelelő formális írásos üzeneteket ír, digitális felületen is.',
		b: 9,
		c: 596,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'íráskészség: írásbeli interakció'])
	},
		{
		a: 'Szóban és írásban, valós nyelvi interakciók során jó nyelvhelyességgel, megfelelő szókinccsel, a természeteshez közelítő szinten vesz részt az egyes tématartományokban és az idetartozó érettségi témákban.',
		b: 9,
		c: 595,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Váratlan, előre nem kiszámítható eseményekre, jelenségekre és történésekre jellemzően célnyelvi eszközökkel is reagál tanórai szituációkban.',
		b: 9,
		c: 594,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Egyre szélesebb körű témákban, nyelvi kommunikációt igénylő helyzetekben reagál megfelelő módon, felhasználva általános és nyelvi háttértudását, ismereteit, alkalmazkodva a társadalmi normákhoz.',
		b: 9,
		c: 593,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Társaival a kooperatív munkaformákban és a projektfeladatok megoldása során is törekszik a célnyelvi kommunikációra.',
		b: 9,
		c: 592,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Aktívan, kezdeményezően és magabiztosan vesz részt a változatos szóbeli interakciót és kognitív kihívást igénylő nyelvórai tevékenységekben.',
		b: 9,
		c: 591,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'A mindennapi élet különböző területein, a kommunikációs helyzetek széles körében tesz fel releváns kérdéseket információszerzés céljából, és válaszol megfelelő módon a hozzá intézett célnyelvi kérdésekre.',
		b: 9,
		c: 590,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Érzelmeit, véleményét változatos nyelvi eszközökkel szóban megfogalmazza és arról interakciót folytat.',
		b: 9,
		c: 589,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Előkészület nélkül részt tud venni személyes jellegű, vagy érdeklődési körének megfelelő ismert témáról folytatott társalgásban,',
		b: 9,
		c: 588,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'A társalgást hatékonyan és udvariasan fenntartja, törekszik mások bevonására, és szükség esetén lezárja azt, akár ismeretlen beszélgetőtárs esetében is.',
		b: 9,
		c: 587,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Társalgást kezdeményez, a megértést fenntartja, törekszik mások bevonására, és szükség esetén lezárja azt az egyes tématartományokon belül, akár anyanyelvű beszélgetőtárs esetében is.',
		b: 9,
		c: 586,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'A társalgásba aktívan, kezdeményezően és egyre magabiztosabban bekapcsolódik az érdeklődési körébe tartozó témák esetén vagy az ajánlott tématartományokon belül.',
		b: 9,
		c: 585,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Szóban ad át nyelvi szintjének megfelelő célnyelvi tartalmakat valós nyelvi interakciót leképező szituációkban.',
		b: 9,
		c: 584,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Részt vesz a változatos szóbeli interakciót és kognitív kihívást igénylő nyelvórai tevékenységekben.',
		b: 9,
		c: 583,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Egyre változatosabb, hosszabb, összetettebb és elvontabb szövegeket, tartalmakat értelmez és használ.',
		b: 9,
		c: 582,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Keresi az érdeklődésének megfelelő, célnyelvi, autentikus szövegeket szórakozásra és ismeretszerzésre tanórán kívül is.',
		b: 9,
		c: 581,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Alkalmazza az írott szövegből nyert információt feladatok megoldása során.',
		b: 9,
		c: 580,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Kiszűr konkrét információkat nyelvi szintjének megfelelő szövegből, és azokat összekapcsolja egyéb ismereteivel.',
		b: 9,
		c: 579,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megérti és értelmezi az írott szövegben megjelenő összetettebb részinformációkat.',
		b: 9,
		c: 578,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megérti az ismeretlen nyelvi elemeket is tartalmazó írott szöveg tartalmát.',
		b: 9,
		c: 577,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'A szövegkörnyezet alapján kikövetkezteti a szövegben előforduló ismeretlen szavak jelentését.',
		b: 9,
		c: 576,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Értelmezi a számára ismerős, elvontabb tartalmú szövegekben megjelenő ismeretlen nyelvi elemeket.',
		b: 9,
		c: 575,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megérti és értelmezi az összefüggéseket és a részleteket az ajánlott tématartományokhoz kapcsolódó összefüggő, akár autentikus írott szövegekben.',
		b: 9,
		c: 574,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megérti és értelmezi a lényeget az ajánlott tématartományokhoz kapcsolódó összefüggő, akár autentikus írott szövegekben.',
		b: 9,
		c: 573,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Elolvas és értelmez nyelvi szintjének megfelelő irodalmi szövegeket.',
		b: 9,
		c: 572,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megért szokványos tempóban folyó autentikus szórakoztató és ismeretterjesztő tartalmakat, változatos csatornákon.',
		b: 9,
		c: 571,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti és értelmezi a legtöbb televíziós hírműsort.',
		b: 9,
		c: 570,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'A tanult nyelvi elemek segítségével megérti a hangzó szöveg lényegét akár anyanyelvi beszélők köznyelvi kommunikációjában a számára kevésbé ismert témákban és szituációkban is.',
		b: 9,
		c: 569,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'A tanult nyelvi elemek segítségével megérti a hangzó szöveg lényegét számára kevésbé ismert témákban és szituációkban is.',
		b: 9,
		c: 568,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Célzottan keresi az érdeklődésének megfelelő autentikus szövegeket tanórán kívül is, ismeretszerzésre és szórakozásra.',
		b: 9,
		c: 567,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Alkalmazza a hangzó szövegből nyert információt feladatok megoldása során.',
		b: 9,
		c: 566,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti az elvontabb tartalmú hangzószövegek lényegét, valamint a beszélők véleményét is.',
		b: 9,
		c: 565,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti a hangzó szövegben megjelenő összetettebb részinformációkat.',
		b: 9,
		c: 564,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti az ismeretlen nyelvi elemeket is tartalmazó hangzó szöveg lényegi tartalmát.',
		b: 9,
		c: 563,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti és értelmezi az összetettebb, az ajánlott témakörökhöz kapcsolódó összefüggő szövegeket, és értelmezi a szövegben megjelenő összefüggéseket.',
		b: 9,
		c: 562,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti, értelmezi és összefoglalja az összetettebb, a tématartományhoz kapcsolódó összefüggő hangzó szöveget, és értelmezi a szövegben megjelenő összefüggéseket.',
		b: 9,
		c: 561,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmezi a szövegben megjelenő összefüggéseket.',
		b: 9,
		c: 560,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Kikövetkezteti a szövegben megjelenő elvontabb nyelvi elemek jelentését az ajánlott témakörökhöz kapcsolódó témákban.',
		b: 9,
		c: 559,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti a célnyelvi, életkorának és érdeklődésének megfelelő hazai és nemzetközi hírek, események lényegét.',
		b: 9,
		c: 558,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmezi a szintjének megfelelő célnyelvi, komplexebb tanári magyarázatokat a nyelvórákon.',
		b: 9,
		c: 557,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'A megfelelő szövegtípusok jellegzetességeit követi.',
		b: 9,
		c: 556,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Beszámol akár az érdeklődési körén túlmutató közügyekkel, szórakozással kapcsolatos eseményről a cselekmény, a körülmények, az érzések és gondolatok ismert nyelvi eszközökkel történő összetettebb, részletes és világos jellemzésével.',
		b: 9,
		c: 555,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Beszámol saját élményen, tapasztalaton alapuló, akár az érdeklődési körén túlmutató vagy elképzelt személyes eseményről a cselekmény, a körülmények, az érzések és gondolatok ismert nyelvi eszközökkel történő összetettebb, részletes és világos jellemzésével.',
		b: 9,
		c: 554,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'A szövegek létrehozásához nyomtatott vagy digitális segédeszközt, szótárt használ.',
		b: 9,
		c: 553,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Összefüggő szövegeket ír önállóan, akár elvontabb témákban.',
		b: 9,
		c: 552,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Papíralapú vagy ikt-eszközökkel segített írott projektmunkát készít önállóan vagy kooperatív munkaformában.',
		b: 9,
		c: 551,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Megold változatos írásbeli, feladatokat szövegszinten.',
		b: 9,
		c: 550,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Használ szövegkohéziós és figyelemvezető eszközöket.',
		b: 9,
		c: 549,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Alkalmazza a formális és informális regiszterhez köthető sajátosságokat.',
		b: 9,
		c: 548,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Információt vagy véleményt közlő és kérő, összefüggő feljegyzéseket, üzeneteket ír.',
		b: 9,
		c: 547,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Leír összetettebb cselekvéssort, történetet, személyes élményeket, elvontabb témákban.',
		b: 9,
		c: 546,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Írásban röviden indokolja érzéseit, gondolatait, véleményét már elvontabb témákban.',
		b: 9,
		c: 545,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Alkalmazza a célnyelvi normához illeszkedő, természeteshez közelítő kiejtést, beszédtempót és intonációt.',
		b: 9,
		c: 544,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Összefüggően, világosan és nagyrészt folyékonyan beszél az ajánlott tématartományhoz tartozó és az idevágó érettségi témákban, akár elvontabb tartalmakra is kitérve.',
		b: 9,
		c: 543,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Beszámol akár az érdeklődési körén túlmutató környezeti eseményről a cselekmény, a körülmények, az érzések és gondolatok ismert nyelvi eszközökkel történő összetettebb, részletes és világos jellemzésével.',
		b: 9,
		c: 542,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Kreatív, változatos műfajú szövegeket alkot szóban, kooperatív munkaformákban.',
		b: 9,
		c: 541,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Összefüggő, folyékony előadásmódú szóbeli prezentációt tart önállóan, felkészülést követően, az érettségi témakörök közül szabadon választott témában, ikt-eszközökkel támogatva mondanivalóját.',
		b: 9,
		c: 540,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Közép- és emelt szintű nyelvi érettségi szóbeli feladatokat old meg.',
		b: 9,
		c: 539,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Összefoglalja ismert témában nyomtatott vagy digitális alapú ifjúsági tartalmak lényegét röviden és érthetően.',
		b: 9,
		c: 538,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Ajánlott tématartományhoz kapcsolódó képi hatás kapcsán saját gondolatait, véleményét és érzéseit is kifejti az ismert nyelvi eszközökkel.',
		b: 9,
		c: 537,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Beszámol saját élményen, tapasztalaton alapuló vagy elképzelt eseményről a cselekmény, a körülmények, az érzések és gondolatok ismert nyelvi eszközökkel történő rövid jellemzésével.',
		b: 9,
		c: 536,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Összefüggően, érthetően és nagyrészt folyékonyan beszél az ajánlott tématartományokhoz tartozó és az érettségi témákban a tanult nyelvi eszközökkel, felkészülést követően.',
		b: 9,
		c: 535,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Visszaad tankönyvi vagy más tanult szöveget, elbeszélést, nagyrészt folyamatos és érthető történetmeséléssel, a cselekményt logikusan összefűzve.',
		b: 9,
		c: 534,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['első élő idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség'])
	},
		{
		a: 'Első idegen nyelvéből sikeresen érettségit tesz a céljainak megfelelő szinten.',
		b: 9,
		c: 533,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Beazonosítja nyelvtanulási céljait és egyéni különbségeinek tudatában, ezeknek megfelelően fejleszti nyelvtudását.',
		b: 9,
		c: 532,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Törekszik a célnyelvi normához illeszkedő kiejtés, beszédtempó és intonáció megközelítésére.',
		b: 9,
		c: 531,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Alkalmazza nyelvtudását kommunikációra, közvetítésre, szórakozásra, ismeretszerzésre hagyományos és digitális csatornákon.',
		b: 9,
		c: 530,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Használ hagyományos és digitális alapú nyelvtanulási forrásokat és eszközöket.',
		b: 9,
		c: 529,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Felkészül az aktív nyelvtanulás eszközeivel az egész életen át történő tanulásra.',
		b: 9,
		c: 528,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Nyelvtudását képes fejleszteni tanórán kívüli eszközökkel, lehetőségekkel és helyzetekben is, valamint a tanultakat és gimnáziumban a második idegen nyelv tanulásában is alkalmazza.',
		b: 9,
		c: 527,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Kommunikációs szándékának megfelelően alkalmazza a nyelvi funkciókat és megszerzett szociolingvisztikai, pragmatikai és interkulturális jártasságát.',
		b: 9,
		c: 526,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'A tanult nyelvi elemek és kommunikációs stratégiák segítségével írásbeli és szóbeli interakciót folytat és tartalmakat közvetít idegen nyelven.',
		b: 9,
		c: 525,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Értelmez nyelvi szintjének megfelelő hallott és írott célnyelvi szövegeket kevésbé ismert témákban és szövegtípusokban is.',
		b: 9,
		c: 524,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Szóban és írásban létrehoz szövegeket különböző szövegtípusokban.',
		b: 9,
		c: 523,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Szóban és írásban is megold változatos kihívásokat igénylő, többnyire valós kommunikációs helyzeteket leképező feladatokat az élő idegen nyelven.',
		b: 9,
		c: 522,
		d: 12,
		e: 'Első élő idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'első élő idegen nyelv'])
	},
		{
		a: 'Életkori sajátosságának megfelelően képessé válik a zeneművek érzelmi és intellektuális befogadására.',
		b: 1,
		c: 715,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'A tanári instrukciók alapján alakít, finomít zenei előadásmódján.',
		b: 1,
		c: 714,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'Megismeri a gyermekdalokhoz kapcsolódó játékokat.',
		b: 1,
		c: 713,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Aktívan részt vesz az iskola vagy a helyi közösség hagyományos ünnepein, tematikus projektjein.',
		b: 1,
		c: 712,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Rövid dallamsorokat rögtönöz.',
		b: 1,
		c: 711,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'Különböző hangszíneket, hangmagasságokat, ellentétes dinamikai szinteket hallás után megfigyel és reprodukál.',
		b: 1,
		c: 710,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'Egyszerű ritmussorokat rögtönöz.',
		b: 1,
		c: 709,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'Adott szempontok alapján figyeli meg a hallgatott zeneművet.',
		b: 1,
		c: 708,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'A zeneművek befogadásában kreatívan használja képzeletét.',
		b: 1,
		c: 707,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Reprodukálja a tanult ritmusokat mozgással, testhangszerrel, valamint egyszerű ritmushangszerekkel.',
		b: 1,
		c: 706,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Érzékeli, hogy ugyanaz a dallamrészlet különböző magasságokban írható, olvasható.',
		b: 1,
		c: 705,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Tanári segítséggel képes leírni és olvasni egyszerű ritmusokat, dallamfordulatokat.',
		b: 1,
		c: 704,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'A dalokat tanári segítséggel szolmizálva énekli, kézjelekkel mutatja.',
		b: 1,
		c: 703,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Ismeri a tanult, énekelt zenei anyaghoz köthető szolmizációs hangokat, kézjelről énekel.',
		b: 1,
		c: 702,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Megismeri, énekli és alkalmazza a pentaton hangkészlet hangjait.',
		b: 1,
		c: 701,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'A tanár által énekelt dalokat belső hallással követi.',
		b: 1,
		c: 700,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Érzékeli a hangok magasságának változásait, különböző hangszíneket, ellentétes dinamikai szinteket, ezeket felismeri az őt körülvevő világ hangjaiban, tanult dalokban, zeneművekben.',
		b: 1,
		c: 699,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Ritmizálva szólaltat meg mondókákat, gyermekverseket.',
		b: 1,
		c: 698,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Megkülönbözteti a páros és a páratlan lüktetést.',
		b: 1,
		c: 697,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Felismeri és hangoztatja az összetett ritmusokat (szinkópa, nyújtott és éles ritmus), az egész értékű kottát, a pontozott fél értékű kottát és az egyedül álló nyolcadot azok szüneteivel, valamint tájékozódik a 4/4-es és 3/4-es ütemben.',
		b: 1,
		c: 696,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Felismeri és hangoztatja a negyed, nyolcadpár, fél értékű ritmusokat, a negyed és a fél értékű szünetet, tájékozódik a 2/4-es ütemben, felismeri és használja az ütemvonalat, záróvonalat, az ismétlőjelet.',
		b: 1,
		c: 695,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Érzékeli és hangoztatja az egyenletes lüktetést, az ütemhangsúlyt a tanult dalokban, zenei szemelvényekben.',
		b: 1,
		c: 694,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Fogalmi szinten megkülönbözteti az egyenletes lüktetést és a ritmust.',
		b: 1,
		c: 693,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'A tanult dalokhoz kapcsolódó játékokban, táncokban, dramatizált előadásokban osztálytársaival aktívan részt vesz.',
		b: 1,
		c: 692,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Hangszerkíséretes dalokat énekel tanára vagy hangszeren játszó osztálytársa kíséretével.',
		b: 1,
		c: 691,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'A tanult dalok, zenei részletek éneklésekor változatosan tudja használni hangerejét a zenei kifejezésnek megfelelően.',
		b: 1,
		c: 690,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Ismer legalább 180 gyermekdalt, magyar népdalt.',
		b: 1,
		c: 689,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Csoportosan vagy önállóan, életkorának és hangi sajátosságainak megfelelő hangmagasságban énekel, törekszik a tiszta intonációra és a daloknak megfelelő tempóra.',
		b: 1,
		c: 688,
		d: 4,
		e: 'Ének-zene@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'ének-zene'])
	},
		{
		a: 'Önálló beszámolókat készít internetes és egyéb zenei adatbázisok, gyűjtemények felhasználásával.',
		b: 5,
		c: 761,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'Ismer és használ internetes zenei adatbázisokat, gyűjteményeket.',
		b: 5,
		c: 760,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'A tanári instrukciók alapján alakít, finomít zenei előadásmódján.',
		b: 5,
		c: 759,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'Megfigyeli és felismeri az összefüggéseket zene és szövege, zenei eszközök és zenei mondanivaló között.',
		b: 5,
		c: 758,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'Képessé válik a zeneművek érzelmi és intellektuális befogadására.',
		b: 5,
		c: 757,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'Aktívan részt vesz az iskola vagy a helyi közösség hagyományos ünnepein és tematikus projektjeiben.',
		b: 5,
		c: 756,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'A tanult népdalokat tájegységekhez, azok ma is élő hagyományaihoz, jellegzetes népművészeti motívumaihoz, ételeihez köti.',
		b: 5,
		c: 755,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Megkülönbözteti a műzenét, népzenét és a népdalfeldolgozásokat.',
		b: 5,
		c: 754,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Megkülönbözteti a giusto, parlando és rubato előadásmódú népdalokat.',
		b: 5,
		c: 753,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Hangzás és látvány alapján felismeri a legelterjedtebb népi hangszereket és hangszeregyütteseket.',
		b: 5,
		c: 752,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Ismer néhány magyar és más kultúrákra jellemző hangszert.',
		b: 5,
		c: 751,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'A zeneműveket műfajok és zenei korszakok szerint értelmezi, ismeri történelmi, kulturális és társadalmi hátterüket.',
		b: 5,
		c: 750,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'A zeneművek befogadásának előkészítése során részt vesz olyan közös kreatív zenélési formákban, melyek segítenek a remekművek közelébe jutni, felhasználja énekhangját, az akusztikus környezet hangjait, ütőhangszereket, egyszerűbb dallamhangszereket.',
		b: 5,
		c: 749,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'A zeneművektől inspirálódva produktívan használja képzeletét, megfogalmazza a zene keltette érzéseit, gondolatait, véleményét.',
		b: 5,
		c: 748,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'A tanult ritmikai és dallami elemeket alkalmazva egyszerű ritmussorokat, dallamokat kiegészít és improvizál.',
		b: 5,
		c: 747,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'Megtalálja a kapcsolatot a zeneművek által közvetített élethelyzetek és a saját élethelyzete között.',
		b: 5,
		c: 746,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Néhány mondattal összefoglalja a zenemű mondanivalóját (például miért íródott, kinek szól, milyen gondolatokat, érzelmeket fejez ki).',
		b: 5,
		c: 745,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Kérdéseket vet fel a zenemű üzenetére és kifejezőeszközeire vonatkozóan, többféle szempontot érvényesítve alkot véleményt.',
		b: 5,
		c: 744,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Megfigyeli az egyes hangszerek hangszínének a hangszerjáték és az előadásmód különbözőségéből adódó karakterét.',
		b: 5,
		c: 743,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Követni tudja a zenei elemek összetartozását, egymást kiegészítő vagy egymástól eltérő struktúráját, a zenei kifejezésben betöltött funkcióját.',
		b: 5,
		c: 742,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Azonosítja a tanult zenéket, megnevezi azok alkotóit és keletkezésüknek ismert körülményeit.',
		b: 5,
		c: 741,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Megfigyeli, összehasonlítja a zenét és a cselekményt, azonosítja a témát és a szereplőket, hangulatot, műfajt, korszakot.',
		b: 5,
		c: 740,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Azonosítani tudja a zenetörténeti stílusok főbb jellemzőit, a hozzájuk tartozó műfajokat, jellegzetes hangszereket, hangszer-összeállításokat, ismeri történelmi, kulturális és társadalmi hátterüket, mely segíti zeneértésüket.',
		b: 5,
		c: 739,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Életkori sajátosságainak megfelelően megadott szempontok segítségével értelmezi a különböző műfajú és stílusú zenéket, követi a lineáris és vertikális zenei folyamatokat.',
		b: 5,
		c: 738,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Felismeri a homofon és polifon szerkesztést.',
		b: 5,
		c: 737,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Érti és azonosítja a különböző formarészek zenén belüli szerepét.',
		b: 5,
		c: 736,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Ismeretlen kotta esetében is vizuálisan nyomon tudja követni a hallott ritmikai és dallami folyamatokat.',
		b: 5,
		c: 735,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Felismerő kottaolvasással együttesen követi a ritmikai és dallami elemeket.',
		b: 5,
		c: 734,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Ütemmutató, violin- és basszuskulcsok.',
		b: 5,
		c: 733,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Megnevezi és beazonosítja a kottakép alapvető elemeit, például tempójelzés.',
		b: 5,
		c: 732,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Fogalmi szinten ismeri a tiszta, kis- és nagy hangközöket (t1-t8), és a fél és egész hangos építkezés logikáját.',
		b: 5,
		c: 731,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Érti a hangköz és hármashangzat fogalmát és fogalmi szinten a hangközök harmóniaalkotó szerepét.',
		b: 5,
		c: 730,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Felismerő kottaolvasással követi és értelmezi a módosított hangok szerepét a dalokban.',
		b: 5,
		c: 729,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Ismeri az előjegyzésekhez kapcsolódó abszolút hangneveket.',
		b: 5,
		c: 728,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Ismeri és jártasságot szerez a hétfokú skála szolmizációs hangjainak írásában és olvasásában.',
		b: 5,
		c: 727,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Hallás útján megfigyeli a tanult zeneművekben a dúr és moll hangzását, melyeket zenei karakterekhez, hangulatokhoz kapcsol.',
		b: 5,
		c: 726,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Érzékeli a tanult dalokban a váltakozó ütemek lüktetését.',
		b: 5,
		c: 725,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Különböző ritmusképleteket eltérő tempókban is reprodukál.',
		b: 5,
		c: 724,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Ismeri és alkalmazza az alapritmusok relációit (egész-, fél-, negyedérték, nyolcadpár, fél- és negyedszünet) és az összetett ritmusokat (szinkópa, kis- és nagy nyújtott, kis- és nagy éles, triola, tizenhatodos ritmusok), grafikai jelüket és értéküket.',
		b: 5,
		c: 723,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'A tanult dalokhoz kapcsolódó dramatizált előadásokban osztálytársaival aktívan részt vesz.',
		b: 5,
		c: 722,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'Hangszerkíséretes dalokat énekel, tanára vagy hangszeren játszó osztálytársa kíséretével.',
		b: 5,
		c: 721,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'Változatosan tudja alkalmazni a tempó és dinamikai, előadási utasításokat (tempo giusto, parlando, rubato, piano, mezzoforte, forte).',
		b: 5,
		c: 720,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'Emlékezetből énekli a himnuszt és a szózatot.',
		b: 5,
		c: 719,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: '127 új dalt ismer.',
		b: 5,
		c: 718,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'A zenei karaktereket differenciáltan tudja megszólaltatni a népi vagy klasszikus stílusjegyeknek megfelelően egyszerű többszólamúságban is.',
		b: 5,
		c: 717,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'Csoportosan vagy önállóan, életkorának és hangi sajátosságainak megfelelő hangmagasságban énekel, törekszik a tiszta intonációra, kifejező, a zene stílusának megfelelő előadásra.',
		b: 5,
		c: 716,
		d: 8,
		e: 'Ének-zene@5-8',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'Zenei ízlése az értékek mentén fejlődik.',
		b: 9,
		c: 800,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'Önálló kutatást végez feladatai megoldásához nyomtatott és digitális forrásokban.',
		b: 9,
		c: 799,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'A tanult dalok üzenetét, saját életének, környezetének jelenségeire tudja vonatkoztatni.',
		b: 9,
		c: 798,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'Értelmezi és önállóan véleményezi a zenéhez társított szöveg és a zene kapcsolatát.',
		b: 9,
		c: 797,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'Mondanivalójáról, és azt az adott zeneműből vett példákkal illusztrálja.',
		b: 9,
		c: 796,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'Órai tapasztalatai és saját ismeretanyaga alapján önálló véleményt alkot a zenemű',
		b: 9,
		c: 795,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'A hallott zeneműveket érzelmi és intellektuális módon közelíti meg, érti és értékeli a művészeti alkotásokat.',
		b: 9,
		c: 794,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'önálló tanulás fejlesztése'])
	},
		{
		a: 'A zeneműveket zenetörténeti kontextusba tudja helyezni, kapcsolatot talál történelmi, irodalmi, kultúrtörténeti vonatkozásokkal, azonosítja a műfaji jellemzőket',
		b: 9,
		c: 793,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Aktívan részt vesz az iskola vagy a helyi közösség hagyományos ünnepein, tematikus projektjein.',
		b: 9,
		c: 792,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Fogalmi szinten tájékozott a zenének a viselkedésre, fejlődésre és agyműködésre gyakorolt hatásaiban.',
		b: 9,
		c: 791,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Ismeri a főbb fővárosi zenei intézményeket (például zeneakadémia, magyar állami operaház, müpa), főbb vidéki zenei centrumokat (például kodály központ -- pécs, kodály intézet -- kecskemét), továbbá lakóhelye művelődési intézményeit.',
		b: 9,
		c: 790,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Konkrét, a tanár által választott műalkotásokon keresztül összehasonlítja a különböző művészeti ágak kifejezőeszközeit, például hogyan fejez ki azonos érzelmet, mondanivalót a zene és más művészetek.',
		b: 9,
		c: 789,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Konkrét művek példáin keresztül tanári segítséggel elkülöníti és egymással kapcsolatba hozza az irodalomhoz és különböző művészeti ágakhoz (film, képzőművészet, tánc) tartozó alkotások jellemző vonásait a nyelvi, vizuális, mozgási és multimédiás művészetekben és a zenében.',
		b: 9,
		c: 788,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'A tanult népdalokat tájegységekhez, azok ma is élő hagyományaihoz, jellegzetes népművészeti motívumaihoz, ételeihez köti.',
		b: 9,
		c: 787,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Értelmezi a népdalok szövegét, mondanivalóját, megtalálja bennük önmagát.',
		b: 9,
		c: 786,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Ismer magyar és más kultúrákra jellemző zenei sajátságokat, s ezeket újonnan hallott zeneművekben is felfedezi.',
		b: 9,
		c: 785,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'kulturális műveltség és kulturális befogadókészség'])
	},
		{
		a: 'Részt vesz kreatív zenélési formákban.',
		b: 9,
		c: 784,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'Az elsajátított zenei anyagot élményszerűen alkalmazza tematikus projektekben.',
		b: 9,
		c: 783,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'Alapszintű ismereteket alkalmaz a digitális technika zenei felhasználásában.',
		b: 9,
		c: 782,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'Társítani tudja a zeneművekben megfogalmazott gondolatokat hangszerelési, szerkesztési megoldásokkal, kompozíciós technikákkal, formai megoldásokkal.',
		b: 9,
		c: 781,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei alkotókészség'])
	},
		{
		a: 'A zeneműveket összekapcsolja élethelyzetekkel, melyeket saját élete és környezete jelenségeire, problémáira tud vonatkoztatni.',
		b: 9,
		c: 780,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'A dalok és a zeneművek befogadásához, azok előadásához felhasználja eddigi ritmikai, dallami, harmóniai ismereteit.',
		b: 9,
		c: 779,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Megfigyeli a különböző zenei interpretációk közötti különbségeket, s azokat véleményezi.',
		b: 9,
		c: 778,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Kezdetben tanári segítséggel, majd önállóan azonosítja a zenében megjelenő társadalmi, erkölcsi, vallási, és kulturális mintákat.',
		b: 9,
		c: 777,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Kérdéseket vet fel a zenemű üzenetére és kifejezőeszközeire vonatkozóan.',
		b: 9,
		c: 776,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'A zeneműveket tanári segítséggel történelmi, földrajzi és társadalmi kontextusba helyezi.',
		b: 9,
		c: 775,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Kezdetben tanári segítséggel, majd önállóan felismeri adott zeneműben a stílus és korszak hatását.',
		b: 9,
		c: 774,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Különbséget tud tenni korszakok, műfajok között.',
		b: 9,
		c: 773,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei befogadókészség'])
	},
		{
		a: 'Érti és azonosítja a különböző formarészek zenén belüli szerepét.',
		b: 9,
		c: 772,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Fogalmi szinten ismeri a hangközök harmóniaalkotó szerepét.',
		b: 9,
		c: 771,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Ismeretlen kotta esetében is vizuálisan nyomon tudja követni a hallott ritmikai és dallami folyamatokat.',
		b: 9,
		c: 770,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Megnevezi és beazonosítja a kottakép alapvető elemeit, például tempójelzés, ütemmutató, violin- és basszuskulcsok.',
		b: 9,
		c: 769,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'Felismerő kottaolvasással követi a ritmikai és dallami elemeket.',
		b: 9,
		c: 768,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'ritmikai és hallásfejlesztés'])
	},
		{
		a: 'A tanult dalokhoz kapcsolódó dramatizált előadásokban osztálytársaival aktívan részt vesz.',
		b: 9,
		c: 767,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'Hangszerkíséretes dalokat énekel, tanára vagy hangszeren játszó osztálytársa kíséretével, a műdalok előadásában alkalmazkodni tud a hangszerkísérethez.',
		b: 9,
		c: 766,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: '43 új dalt ismer.',
		b: 9,
		c: 765,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'Emlékezetből és kottakép segítségével énekel régi és új rétegű magyar népdalokat, más népek dalait, műdalokat, kánonokat.',
		b: 9,
		c: 764,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'A tanult dalokat stílusosan, kifejezően adja elő.',
		b: 9,
		c: 763,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'Csoportosan vagy önállóan, életkorának és hangi sajátosságainak megfelelő hangmagasságban énekel, törekszik a tiszta intonációra.',
		b: 9,
		c: 762,
		d: 10,
		e: 'Ének-zene@9-10',
		f: _List_fromArray(
			['ének-zene', 'zenei előadókészség', 'éneklés'])
	},
		{
		a: 'A naponta használt csomagolóeszközök kiválasztásában megindokolja, hogy milyen elvek alkalmazása támogatja a környezetvédelmi szempontok érvényesülését, és ezekre társai figyelmét is felhívja.',
		b: 1,
		c: 850,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társadalmi felelősségvállalás és elköteleződés a fenntartható jövő iránt'])
	},
		{
		a: 'Játékvásárlási szokásaiban példát hoz olyan elemekre, amelyek révén figyelembe vehetők a környezetvédelmi szempontok, és felhívja társai figyelmét is ezekre.',
		b: 1,
		c: 849,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társadalmi felelősségvállalás és elköteleződés a fenntartható jövő iránt'])
	},
		{
		a: 'A szabályok jelentőségét különböző kontextusokban azonosítja és társaival megvitatja, a szabályszegés lehetséges következményeit megfogalmazza.',
		b: 1,
		c: 848,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társadalmi felelősségvállalás és elköteleződés a fenntartható jövő iránt'])
	},
		{
		a: 'Ismer néhány kihalt vagy kihalófélben lévő élőlényt, tájékozott a jelenség magyarázatában, és ezekről információt gyűjt fizikai és digitális környezetben is.',
		b: 1,
		c: 847,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társadalmi felelősségvállalás és elköteleződés a fenntartható jövő iránt'])
	},
		{
		a: 'A bibliai történetekben megnyilvánuló igazságos és megbocsátó magatartásra saját életéből példákat hoz, vagy megkezdett történetet a megadott szempont szerint fejez be.',
		b: 1,
		c: 846,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'személyes vélemény kialakítása a világvallások emberképéről és etikai tanításáról'])
	},
		{
		a: 'Azonosítja az olvasott vagy hallott bibliai tanításokban, mondákban, mesékben a megjelenő együttélési szabályokat.',
		b: 1,
		c: 845,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'személyes vélemény kialakítása a világvallások emberképéről és etikai tanításáról'])
	},
		{
		a: 'Más vallások ünnepei közül ismer néhányat.',
		b: 1,
		c: 844,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'személyes vélemény kialakítása a világvallások emberképéről és etikai tanításáról'])
	},
		{
		a: 'A bibliai szövegekre támaszkodó történetek megismerése alapján értelmezi, milyen vallási eseményhez kapcsolódik egy-egy adott ünnep.',
		b: 1,
		c: 843,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'személyes vélemény kialakítása a világvallások emberképéről és etikai tanításáról'])
	},
		{
		a: 'Magatartásával az igazságosság, a fair play elveinek betartására törekszik, és ezáltal igyekszik mások bizalmát elnyerni.',
		b: 1,
		c: 842,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'közösségi aktivitás tervezése a jogrendszerek ismeretére támaszkodó gondolkodás alapján'])
	},
		{
		a: 'Irodalmi szemelvények alapján példákat azonosít igazságos és igazságtalan cselekedetekre, saját élmény alapján példát hoz ilyen helyzetekre, valamint részt vesz ezek megbeszélésében, tanítói vezetéssel.',
		b: 1,
		c: 841,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'közösségi aktivitás tervezése a jogrendszerek ismeretére támaszkodó gondolkodás alapján'])
	},
		{
		a: 'Tájékozott a képességek kibontakoztatását érintő gyermeki jogokról, ennek családi, iskolai és iskolán kívüli következményeiről, a gyermekek kötelességeiről.',
		b: 1,
		c: 840,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'közösségi aktivitás tervezése a jogrendszerek ismeretére támaszkodó gondolkodás alapján'])
	},
		{
		a: 'Tájékozott a testi és érzelmi biztonságra vonatkozó gyermeki jogokról.',
		b: 1,
		c: 839,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'közösségi aktivitás tervezése a jogrendszerek ismeretére támaszkodó gondolkodás alapján'])
	},
		{
		a: 'A lakóhelyén élő nemzetiségek tagjai, hagyományai iránt nyitott, ezekről információkat gyűjt fizikai és digitális környezetben.',
		b: 1,
		c: 838,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'a nemzeti és európai identitást meghatározó kulturális értékek megismerése és pozitív értékelése'])
	},
		{
		a: 'Az életkorához illeszkedő mélységben ismeri a nemzeti, az állami ünnepek, egyházi ünnepkörök jelentését, a hozzájuk kapcsolódó jelképeket.',
		b: 1,
		c: 837,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'a nemzeti és európai identitást meghatározó kulturális értékek megismerése és pozitív értékelése'])
	},
		{
		a: 'Érdeklődést mutat magyarország történelmi emlékei iránt, ismer közülük néhányat.',
		b: 1,
		c: 836,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'a nemzeti és európai identitást meghatározó kulturális értékek megismerése és pozitív értékelése'])
	},
		{
		a: 'A közvetlen lakóhelyéhez kapcsolódó nevezetességeket ismeri, ezekről információkat gyűjt fizikai és digitális környezetben, társaival együtt meghatározott formában bemutatót készít.',
		b: 1,
		c: 835,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'a nemzeti és európai identitást meghatározó kulturális értékek megismerése és pozitív értékelése'])
	},
		{
		a: 'Képes azonosítani a szeretet és az elfogadás jelzéseit.',
		b: 1,
		c: 834,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'aktív szerepvállalás a családban és a családon kívüli közösségek életében'])
	},
		{
		a: 'Szerepet vállal iskolai rendezvényeken, illetve azok előkészítésében, vagy iskolán belül szervezett szabadidős programban vesz részt.',
		b: 1,
		c: 833,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'aktív szerepvállalás a családban és a családon kívüli közösségek életében'])
	},
		{
		a: 'Megérti az ünneplés jelentőségét, elkülöníti a családi, a nemzeti, az állami és egyéb ünnepeket, és az egyházi ünnepköröket, aktív résztvevője a közös ünnepek előkészületeinek.',
		b: 1,
		c: 832,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'aktív szerepvállalás a családban és a családon kívüli közösségek életében'])
	},
		{
		a: 'Megérti a családi szokások jelentőségét és ezek természetes különbözőségét (alkalmazkodik az éjszakai pihenéshez, az étkezéshez, a testi higiénéhez fűződő, a tanulási és a játékidőt meghatározó családi szokásokhoz).',
		b: 1,
		c: 831,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'aktív szerepvállalás a családban és a családon kívüli közösségek életében'])
	},
		{
		a: 'Erkölcsi érzékenységgel reagál az igazmondást, a becsületességet, a személyes és szellemi tulajdont, valamint az emberi méltóság tiszteletben tartását érintő helyzetekre fizikai és digitális környezetben is.',
		b: 1,
		c: 830,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'értékekre és erkölcsi alapelvekre alapozott döntéshozatal'])
	},
		{
		a: 'Felismeri a „jó\" és „rossz\" közötti különbséget a közösen megbeszélt eseményekben és történetekben.',
		b: 1,
		c: 829,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'értékekre és erkölcsi alapelvekre alapozott döntéshozatal'])
	},
		{
		a: 'Alkalmazza az asszertív viselkedés elemeit konfliktushelyzetben és másokkal kezdeményezett interakcióban, baráti kapcsolatokat tart fenn.',
		b: 1,
		c: 828,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése; interperszonális készségek'])
	},
		{
		a: 'Saját érdekeit másokat nem bántó módon fejezi ki, az ehhez illeszkedő kifejezésmódokat ismeri és alkalmazza.',
		b: 1,
		c: 827,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése; interperszonális készségek'])
	},
		{
		a: 'Megkülönbözteti a sértő és tiszteletteljes közlési módokat fizikai és digitális környezetben egyaránt, barátsággá alakuló kapcsolatokat kezdeményez.',
		b: 1,
		c: 826,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése; interperszonális készségek'])
	},
		{
		a: 'Megkülönbözteti a felnőttekkel és társakkal folytatott társas helyzeteket.',
		b: 1,
		c: 825,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése; interperszonális készségek'])
	},
		{
		a: 'Különbséget tesz verbális és nem verbális jelzések között.',
		b: 1,
		c: 824,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése; interperszonális készségek'])
	},
		{
		a: 'Mások helyzetébe tudja képzelni magát, és megérti a másik személy érzéseit.',
		b: 1,
		c: 823,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése; interperszonális készségek'])
	},
		{
		a: 'Ismeri az életkorának megfelelő beszélgetés alapvető szabályait.',
		b: 1,
		c: 822,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése; interperszonális készségek'])
	},
		{
		a: 'Felismeri annak fontosságát, hogy sorsfordító családi események kapcsán saját érzelmeit felismerje, megélje, feldolgozza, s azokat elfogadható módon kommunikálja a környezete felé.',
		b: 1,
		c: 821,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'a nehéz élethelyzetekkel történő megküzdés készségei (reziliencia)'])
	},
		{
		a: 'Megfogalmazza a nehéz élethelyzettel (pl.:új családtag érkezése vagy egy családtag eltávozása) kapcsolatos érzéseit.',
		b: 1,
		c: 820,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'a nehéz élethelyzetekkel történő megküzdés készségei (reziliencia)'])
	},
		{
		a: 'Felismeri az őt ért bántalmazást, ismer néhány olyan segítő bizalmi személyt, akihez segítségért fordulhat.',
		b: 1,
		c: 819,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'a nehéz élethelyzetekkel történő megküzdés készségei (reziliencia)'])
	},
		{
		a: 'Rendelkezik a stresszhelyzetben keletkezett negatív érzelmek kezeléséhez saját módszerekkel.',
		b: 1,
		c: 818,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'a nehéz élethelyzetekkel történő megküzdés készségei (reziliencia)'])
	},
		{
		a: 'Ismeri a testi-lelki egészség fogalmát és főbb szempontjait , motivált a krízisek megelőzésében, és a megoldáskeresésben.',
		b: 1,
		c: 817,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'a nehéz élethelyzetekkel történő megküzdés készségei (reziliencia)'])
	},
		{
		a: 'Céljai megvalósítása során önkontrollt, siker esetén önjutalmazást gyakorol.',
		b: 1,
		c: 816,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Meggyőződése, hogy a hiányosságok javíthatók, a gyengeségek fejleszthetők, és ehhez teljesíthető rövid távú célokat tűz maga elé saját tudásának és képességeinek fejlesztése céljából.',
		b: 1,
		c: 815,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Célokat tűz ki maga elé, és azonosítja a saját céljai eléréséhez szükséges főbb lépéseket.',
		b: 1,
		c: 814,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Felismeri, milyen tevékenységeket, helyzeteket kedvel, illetve nem kedvel, azonosítja saját viselkedésének jellemző elemeit.',
		b: 1,
		c: 813,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'A csoportos tevékenységek keretében felismeri és megjeleníti az alapérzelmeket, az alapérzelmeken kívül is felismeri és megnevezi a saját érzelmi állapotokat.',
		b: 1,
		c: 812,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Megismeri az élet tiszteletének és a felelősségvállalásának az elveit, hétköznapi szokásai alakításánál tekintettel van társas és fizikai környezetére.',
		b: 1,
		c: 811,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Erkölcsi fogalomkészlete tudatosodik és gazdagodik, az erkölcsi értékfogalmak, a segítség, önzetlenség, tisztelet, szeretet, felelősség, igazságosság, méltányosság, lelkiismeretesség, mértékletesség jelentésével.',
		b: 1,
		c: 810,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Megfogalmazza gondolatait az élet néhány fontos kérdéséről és a róluk tanított elképzelésekkel kapcsolatosan.',
		b: 1,
		c: 809,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Érdeklődést tanúsít a gyermeki jogok és kötelezettségek megismerése iránt a családban, az iskolában és az iskolán kívüli közösségekben.',
		b: 1,
		c: 808,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Pozitív attitűddel viszonyul a nemzeti és a nemzetiségi hagyományok, a nemzeti ünnepek és az egyházi ünnepkörök iránt.',
		b: 1,
		c: 807,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Felismeri saját lelkiismerete működését.',
		b: 1,
		c: 806,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Törekszik szabadságra, alkalmazkodásra, bizalomra, őszinteségre, tiszteletre és igazságosságra a különféle közösségekben.',
		b: 1,
		c: 805,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Felismeri az egyes cselekvésekre vonatkozó erkölcsi szabályokat, viselkedése szervezésénél figyelembe veszi ezeket.',
		b: 1,
		c: 804,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Törekszik az érzelmek konstruktív és együttérző kifejezésmódjainak alkalmazására.',
		b: 1,
		c: 803,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Felismeri a hatékony kommunikációt segítő és akadályozó verbális és nonverbális elemeket és magatartásformákat.',
		b: 1,
		c: 802,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Azonosítja saját helyzetét, erősségeit és fejlesztendő területeit, és ennek tudatában rövid távú célokat tartalmazó tervet tud kialakítani saját egyéni fejlődésére vonatkozóan.',
		b: 1,
		c: 801,
		d: 4,
		e: 'Etika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Értelmezi a teremtett rend, világ, a fenntarthatóság összefüggéseit, az emberiség ökológiai cselekvési lehetőségeit.',
		b: 5,
		c: 898,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'társadalmi felelősségvállalás és elköteleződés a fenntartható jövő iránt'])
	},
		{
		a: 'Megismeri és véleményezi a természeti erőforrások felhasználására, a környezetszennyeződés, a globális és társadalmi egyenlőtlenségek problémájára vonatkozó etikai felvetéseket.',
		b: 5,
		c: 897,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'társadalmi felelősségvállalás és elköteleződés a fenntartható jövő iránt'])
	},
		{
		a: 'Folyamatosan frissíti az emberi tevékenység környezetre gyakorolt hatásaival kapcsolatos ismereteit fizikai és digitális környezetében, mérlegelő szemlélettel vizsgálja a technikai fejlődés lehetőségeit.',
		b: 5,
		c: 896,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'társadalmi felelősségvállalás és elköteleződés a fenntartható jövő iránt'])
	},
		{
		a: 'Saját életét meghatározó világnézeti meggyőződés birtokában a kölcsönös tolerancia elveit valósítja meg a tőle eltérő nézetű személyekkel való kapcsolata során.',
		b: 5,
		c: 895,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'személyes vélemény kialakítása a vallás etikai tanításáról'])
	},
		{
		a: 'Egyéni cselekvési lehetőségeket fogalmaz meg az erkölcsi értékek érvényesítésére.',
		b: 5,
		c: 894,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'személyes vélemény kialakítása a vallás etikai tanításáról'])
	},
		{
		a: 'Értelmezi a szeretetnek, az élet tisztelete elvének a kultúrára gyakorolt hatását.',
		b: 5,
		c: 893,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'személyes vélemény kialakítása a vallás etikai tanításáról'])
	},
		{
		a: 'Feltárja, hogyan jelenik meg a hétköznapok során a vallás emberi életre vonatkozó tanítása.',
		b: 5,
		c: 892,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'személyes vélemény kialakítása a vallás etikai tanításáról'])
	},
		{
		a: 'Életkorának megfelelő szinten értelmezi a családi élet mindennapjait befolyásoló fontosabb jogszabályok nyújtotta lehetőségeket (például családi pótlék, családi adókedvezmény, gyermekvédelmi támogatás).',
		b: 5,
		c: 891,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'közösségi aktivitás tervezése a jogrendszerek ismeretére támaszkodó gondolkodás alapján'])
	},
		{
		a: 'Azonosítja az egyéni, családi és a társadalmi boldogulás, érvényesülés feltételeit.',
		b: 5,
		c: 890,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'közösségi aktivitás tervezése a jogrendszerek ismeretére támaszkodó gondolkodás alapján'])
	},
		{
		a: 'Értelmezi a norma- és szabályszegés következményeit, és etikai kérdéseket vet fel velük kapcsolatban.',
		b: 5,
		c: 889,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'közösségi aktivitás tervezése a jogrendszerek ismeretére támaszkodó gondolkodás alapján'])
	},
		{
		a: 'Részt vesz a különleges bánásmódot igénylő tanulók megértését segítő feladatokban, programokban, kifejti saját véleményét.',
		b: 5,
		c: 888,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'közösségi aktivitás tervezése a jogrendszerek ismeretére támaszkodó gondolkodás alapján'])
	},
		{
		a: 'Ismeri a rá vonatkozó gyermekjogokat, az ezeket szabályozó főbb dokumentumokat, értelmezi kötelezettségeit, részt vesz a szabályalkotásban.',
		b: 5,
		c: 887,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'közösségi aktivitás tervezése a jogrendszerek ismeretére támaszkodó gondolkodás alapján'])
	},
		{
		a: 'Összefüggéseket gyűjt a keresztény, keresztyén vallás és az európai, nemzeti értékvilágról, a közös jelképekről, szimbólumokról, az egyházi ünnepkörökről.',
		b: 5,
		c: 886,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'a nemzeti és európai identitást meghatározó kulturális értékek megismerése és pozitív értékelése'])
	},
		{
		a: 'Azonosítja a nemzeti és az európai értékek közös jellemzőit, az európai kulturális szellemiség, értékrendszer meghatározó elemeit.',
		b: 5,
		c: 885,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'a nemzeti és európai identitást meghatározó kulturális értékek megismerése és pozitív értékelése'])
	},
		{
		a: 'Ismeri a nemzeti identitást meghatározó kulturális értékeket, indokolja miért fontos ezek megőrzése.',
		b: 5,
		c: 884,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'a nemzeti és európai identitást meghatározó kulturális értékek megismerése és pozitív értékelése'])
	},
		{
		a: 'Fizikai vagy digitális környezetben információt gyűjt és megosztja tudását a sport, a technika vagy a művészetek területén a nemzet és európa kultúráját meghatározó kiemelkedő személyiségekről és tevékenységükről.',
		b: 5,
		c: 883,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'a nemzeti és európai identitást meghatározó kulturális értékek megismerése és pozitív értékelése'])
	},
		{
		a: 'Megfogalmazza, hogy a szeretetnek, hűségnek, elkötelezettségnek, bizalomnak, tiszteletnek milyen szerepe van a társas lelkületi kapcsolatokban.',
		b: 5,
		c: 882,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'aktív szerepvállalás a családban és a családon kívüli közösségek életében'])
	},
		{
		a: 'Felismeri saját családjának viszonyrendszerét, átéli a családot összetartó érzelmeket és társas lelkületi értékeket, a különböző generációk családot összetartó szerepét.',
		b: 5,
		c: 881,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'aktív szerepvállalás a családban és a családon kívüli közösségek életében'])
	},
		{
		a: 'Érzékeli a családban előforduló, bizalmat érintő konfliktusos területeket.',
		b: 5,
		c: 880,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'aktív szerepvállalás a családban és a családon kívüli közösségek életében'])
	},
		{
		a: 'Azonosítja és összehasonlítja a családban betöltött szerepeket és feladatokat.',
		b: 5,
		c: 879,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'aktív szerepvállalás a családban és a családon kívüli közösségek életében'])
	},
		{
		a: 'A csoporthoz való csatlakozás vagy az onnan való kiválás esetén összeveti a csoportnormákat és saját értékrendjét, mérlegeli az őt érő előnyöket és hátrányokat.',
		b: 5,
		c: 878,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'értékekre és erkölcsi alapelvekre alapozott döntéshozatal'])
	},
		{
		a: 'Azonosítja, értékeli az etikus és nem etikus cselekvések lehetséges következményeit.',
		b: 5,
		c: 877,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'értékekre és erkölcsi alapelvekre alapozott döntéshozatal'])
	},
		{
		a: 'Azonosítja a számára fontos közösségi értékeket, indokolja, hogy ezek milyen szerepet játszanak a saját életében.',
		b: 5,
		c: 876,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'értékekre és erkölcsi alapelvekre alapozott döntéshozatal'])
	},
		{
		a: 'Értelmezi a szabadság és önkorlátozás, a tolerancia és a szeretet megjelenését és határait egyéni élethelyzeteiben.',
		b: 5,
		c: 875,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése és interperszonális készségek'])
	},
		{
		a: 'Nyitott és segítőkész a nehéz helyzetben lévő személyek iránt.',
		b: 5,
		c: 874,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése és interperszonális készségek'])
	},
		{
		a: 'Törekszik mások helyzetének megértésére, felismeri a mások érzelmi állapotára és igényeire utaló jelzéseket, a fizikai és a digitális környezetben egyaránt.',
		b: 5,
		c: 873,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése és interperszonális készségek'])
	},
		{
		a: 'Azonosítja a csoportban elfoglalt helyét és szerepét, törekszik a személyiségének legjobban megfelelő feladatok ellátására.',
		b: 5,
		c: 872,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése és interperszonális készségek'])
	},
		{
		a: 'Felismeri a konfliktus kialakulására utaló jelzéseket, vannak megoldási javaslatai a konfliktusok békés megoldására.',
		b: 5,
		c: 871,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése és interperszonális készségek'])
	},
		{
		a: 'Képes a saját véleményétől eltérő véleményekhez tisztelettel viszonyulni, a saját álláspontja mellett érvelni, konszenzusra törekszik.',
		b: 5,
		c: 870,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'társas helyzetek észlelése és interperszonális készségek'])
	},
		{
		a: 'Azonosítja a valós és virtuális térben történő zaklatások fokozatait és módjait, van terve a zaklatások elkerülésére, kivédésére, tudja, hogy hová fordulhat segítségért.',
		b: 5,
		c: 869,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'a nehéz élethelyzetekkel történő megküzdés készségei'])
	},
		{
		a: 'Megismer olyan mintákat és lehetőségeket, amelyek segítségével a krízishelyzetek megoldhatók, és tudja, hogy adott esetben hová fordulhat segítségért.',
		b: 5,
		c: 868,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'a nehéz élethelyzetekkel történő megküzdés készségei'])
	},
		{
		a: 'Ismer testi és mentális egészséget őrző tevékenységeket és felismeri a saját egészségét veszélyeztető hatásokat; megfogalmazza saját intim terének határait.',
		b: 5,
		c: 867,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'a nehéz élethelyzetekkel történő megküzdés készségei'])
	},
		{
		a: 'Kérdőívek használatával felismeri pályaérdeklődését és továbbtanulási céljait.',
		b: 5,
		c: 866,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Valósághűen elmondja, hogy a saját érzelmi állapotai milyen hatást gyakorolnak a társas kapcsolatai alakítására, a tanulási tevékenységére.',
		b: 5,
		c: 865,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Dramatikus eszközökkel megjelenített helyzetekben különböző érzelmi állapotok által vezérelt viselkedést eljátszik, és ennek a másik személyre tett hatását a csoporttal közösen megfogalmazza.',
		b: 5,
		c: 864,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Reflektív tanulási gyakorlatot alakít ki, önálló tanulási feladatot kezdeményez.',
		b: 5,
		c: 863,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Megfelelő döntéseket hoz arról, hogy az online térben milyen információkat oszthat meg önmagáról.',
		b: 5,
		c: 862,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Különbséget tesz a valóságos és a virtuális identitás között, megérti a virtuális identitás jellemzőit.',
		b: 5,
		c: 861,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Megismeri az identitás fogalmát és jellemzőit, azonosítja saját identitásának néhány elemét.',
		b: 5,
		c: 860,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['etika', 'önismereti és önszabályozási készségek'])
	},
		{
		a: 'Strukturált önmegfigyelésre alapozva megismeri személyiségének egyes jellemzőit, saját érdeklődési körét, speciális pályaérdeklődését.',
		b: 5,
		c: 859,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'A személyes életben is megvalósítható tevékenységeket végez, ami összhangban van a teremtett rend megőrzésével, a fenntartható jövővel.',
		b: 5,
		c: 858,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Véleményt formál a zsidó keresztény, keresztyén értékrenden alapuló vallások erkölcsi értékeiről, feltárja, hogy hogyan jelennek meg ezek az értékek az emberi viselkedésben a közösségi szabályokban.',
		b: 5,
		c: 857,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Elfogadó attitűdöt tanúsít az eltérő társadalmi, kulturális helyzetű vagy különleges bánásmódot igénylő tanulók iránt.',
		b: 5,
		c: 856,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Felismeri saját és mások érzelmi állapotát, az adott érzelmi állapotot kezelő viselkedést tud választani; a helyzethez igazodó társas konfliktus-megoldási eljárás alkalmazására törekszik.',
		b: 5,
		c: 855,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Azonosítja saját szerepét a családi viszonyrendszerben, felismeri azokat az értékeket, amelyek a család összetartozását, harmonikus működését és az egyén egészséges fejlődését biztosítják.',
		b: 5,
		c: 854,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Környezetében felismeri azokat a személyeket, akiknek tevékenysége példaként szolgálhat mások számára; ismeri néhány kiemelkedő személyiség életművét, és elismeri hozzájárulását a tudomány, a technológia, a művészetek gyarapításához, nemzeti és európai történelmünk alakításához.',
		b: 5,
		c: 853,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Erkölcsi szempontok alapján vizsgálja személyes helyzetét; etikai alapelvek és az alapvető jogok szerint értékeli saját és mások élethelyzetét, valamint néhány társadalmi problémát.',
		b: 5,
		c: 852,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Fogalmi rendszerében kialakul az erkölcsi jó és rossz, mint minősítő kategória, az erkölcsi dilemma és az erkölcsi szabályok fogalma, ezeket valós vagy fiktív példákhoz tudja kötni.',
		b: 5,
		c: 851,
		d: 8,
		e: 'Etika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'etika'])
	},
		{
		a: 'Használ mérésre, adatelemzésre, folyamatelemzésre alkalmas összetett szoftvereket (például hang és mozgókép kezelésére alkalmas programokat).',
		b: 9,
		c: 1063,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'mérési adatok számítógépes kiértékelése, táblázatok, grafikonok készítése'])
	},
		{
		a: 'A vizsgálatok során kinyert adatokat egyszerű táblázatkezelő szoftver segítségével elemzi, az adatokat grafikonok segítségével értelmezi.',
		b: 9,
		c: 1062,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'mérési adatok számítógépes kiértékelése, táblázatok, grafikonok készítése'])
	},
		{
		a: 'A projektfeladatok megoldása során önállóan, illetve a csoporttagokkal közösen különböző médiatartalmakat, prezentációkat, rövidebb-hosszabb szöveges produktumokat hoz létre a tapasztalatok, eredmények, elemzések, illetve következtetések bemutatására.',
		b: 9,
		c: 1061,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'számítógépes prezentációk készítése fizikai információk bemutatására, megosztására'])
	},
		{
		a: 'Az egyszerű vizsgálatok eredményeinek, az elemzések, illetve a következtetések bemutatására prezentációt készít.',
		b: 9,
		c: 1060,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'számítógépes prezentációk készítése fizikai információk bemutatására, megosztására'])
	},
		{
		a: 'A forrásokból gyűjtött információkat számítógépes prezentációban mutatja be.',
		b: 9,
		c: 1059,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'számítógépes prezentációk készítése fizikai információk bemutatására, megosztására'])
	},
		{
		a: 'Az interneten talált tartalmakat több forrásból is ellenőrzi.',
		b: 9,
		c: 1058,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'az internet kritikus használata fizikai információk gyűjtésére'])
	},
		{
		a: 'Fizikai szövegben, videóban el tudja különíteni a számára világos, valamint nem érthető, további magyarázatra szoruló részeket.',
		b: 9,
		c: 1057,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'az internet kritikus használata fizikai információk gyűjtésére'])
	},
		{
		a: 'Készségszinten alkalmazza a különböző kommunikációs eszközöket, illetve az internetet a főként magyar, illetve idegen nyelvű, fizikai tárgyú tartalmak keresésére.',
		b: 9,
		c: 1056,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'az internet kritikus használata fizikai információk gyűjtésére'])
	},
		{
		a: 'Ismer magyar és idegen nyelvű megbízható fizikai tárgyú honlapokat.',
		b: 9,
		c: 1055,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'az internet kritikus használata fizikai információk gyűjtésére'])
	},
		{
		a: 'A vizsgált fizikai jelenségeket, kísérleteket bemutató animációkat, videókat keres és értelmez.',
		b: 9,
		c: 1054,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'fizikai adatbázisok, interaktív szimulációk használata'])
	},
		{
		a: 'Használ helymeghatározó szoftvereket, a közeli és távoli környezetünket leíró adatbázisokat, szoftvereket.',
		b: 9,
		c: 1053,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'fizikai adatbázisok, interaktív szimulációk használata'])
	},
		{
		a: 'A legegyszerűbb esetekben azonosítja az alapvető fizikai kölcsönhatások és törvények szerepét a világegyetem felépítésében és időbeli változásaiban.',
		b: 9,
		c: 1052,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'a világegyetem'])
	},
		{
		a: 'Átlátja és szemlélteti a természetre jellemző fizikai mennyiségek nagyságrendjeit (atommag, élőlények, naprendszer, univerzum).',
		b: 9,
		c: 1051,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'a világegyetem'])
	},
		{
		a: 'Ismeri a nap, mint csillag legfontosabb fizikai tulajdonságait, a nap várható jövőjét, a csillagok lehetséges fejlődési folyamatait.',
		b: 9,
		c: 1050,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'a világegyetem'])
	},
		{
		a: 'Szabad szemmel vagy távcsővel megfigyeli a holdat, a hold felszínének legfontosabb jellemzőit, a holdfogyatkozás jelenségét, a látottakat fizikai ismeretei alapján értelmezi.',
		b: 9,
		c: 1049,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'a világegyetem'])
	},
		{
		a: 'Megvizsgálja a naprendszer bolygóin és holdjain uralkodó, a földétől eltérő fizikai környezet legjellemzőbb példáit, azonosítja ezen eltérések okát, a legfontosabb esetekben megmutatja, hogyan érvényesülnek a fizika törvényei a föld és a hold mozgása során.',
		b: 9,
		c: 1048,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'a világegyetem'])
	},
		{
		a: 'Ismeri a felezési idő, aktivitás fogalmát, a sugárvédelem lehetőségeit.',
		b: 9,
		c: 1047,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Ismeri a radioaktív sugárzások típusait, az alfa-, béta- és gamma-sugárzások leírását és tulajdonságait.',
		b: 9,
		c: 1046,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Átlátja, hogy a maghasadás és magfúzió miért alkalmas energiatermelésre, ismeri a gyakorlati megvalósulásuk lehetőségeit, az atomerőművek működésének alapelvét, a csillagok energiatermelésének lényegét.',
		b: 9,
		c: 1045,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Ismeri az atommag felépítését, a nukleonok típusait, az izotóp fogalmát, a nukleáris kölcsönhatás jellemzőit.',
		b: 9,
		c: 1044,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Átlátja, hogyan használják a vonalas színképet az anyagvizsgálat során.',
		b: 9,
		c: 1043,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Megmagyarázza az elektronmikroszkóp működését az elektron hullámtermészetének segítségével.',
		b: 9,
		c: 1042,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Ismeri az atomról alkotott elképzelések változásait, a rutherford-modellt és bohr-modellt, látja a modellek hiányosságait.',
		b: 9,
		c: 1041,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Ismeri rutherford szórási kísérletét, mely az atommag felfedezéséhez vezetett.',
		b: 9,
		c: 1040,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Megfigyeli a fényelektromos jelenséget, tisztában van annak einstein által kidolgozott magyarázatával, a frekvencia (hullámhossz) és a foton energiája kapcsolatával.',
		b: 9,
		c: 1039,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Ismeri az optikai leképezés fogalmát, a valódi és látszólagos kép közötti különbséget. egyszerű kísérleteket tud végezni tükrökkel és lencsékkel.',
		b: 9,
		c: 1038,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'A fókuszpont fogalmának felhasználásával értelmezi, hogyan térítik el a fényt a domború és homorú tükrök, a domború és homorú lencsék.',
		b: 9,
		c: 1037,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Ismeri a fénytörés és visszaverődés törvényét, megmagyarázza, hogyan alkot képet a síktükör.',
		b: 9,
		c: 1036,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Ismeri a színek és a fény frekvenciája közötti kapcsolatot, a fehér fény összetett voltát, a kiegészítő színek fogalmát, a szivárvány színeit.',
		b: 9,
		c: 1035,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Ismeri az elektromágneses hullámok jellemzőit (frekvencia, hullámhossz, terjedési sebesség), azt, hogy milyen körülmények határozzák meg ezeket. a mennyiségek kapcsolatára vonatkozó egyszerű számításokat végez.',
		b: 9,
		c: 1034,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Tudja, hogy a fény elektromágneses hullám, és hogy terjedéséhez nem kell közeg.',
		b: 9,
		c: 1033,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az atomok és a fény'])
	},
		{
		a: 'Ismeri az elektromágneses indukció jelenségének lényegét, fontosabb gyakorlati vonatkozásait, a váltakozó áram fogalmát.',
		b: 9,
		c: 1032,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Megmagyarázza, hogyan működnek az általa megfigyelt egyszerű felépítésű elektromos motorok: a mágneses mező erőt fejt ki az árammal átjárt vezetőre.',
		b: 9,
		c: 1031,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Elektromágnes készítése közben megfigyeli és alkalmazza, hogy az elektromos áram mágneses mezőt hoz létre.',
		b: 9,
		c: 1030,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Értelmezni tud egyszerűbb kapcsolási rajzokat, ismeri kísérleti vizsgálatok alapján a soros és a párhuzamos kapcsolások legfontosabb jellemzőit.',
		b: 9,
		c: 1029,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Ismeri az egyszerű áramkör és egyszerűbb hálózatok alkotórészeit, felépítését.',
		b: 9,
		c: 1028,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Ki tudja számolni egyenáramú fogyasztók teljesítményét, az általuk felhasznált energiát.',
		b: 9,
		c: 1027,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Érti ohm törvényét, egyszerű esetekben alkalmazza a feszültség, áramerősség, ellenállás meghatározására, tudja, hogy az ellenállás függ a hőmérséklettől.',
		b: 9,
		c: 1026,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Gyakorlati szinten ismeri az egyenáramok jellemzőit, a feszültség, áramerősség és ellenállás fogalmát.',
		b: 9,
		c: 1025,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Tudja, hogy az áram a töltött részecskék rendezett mozgása, és ez alapján szemléletes elképzelést alakít ki az elektromos áramról.',
		b: 9,
		c: 1024,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Tudja, hogy az elektromos kölcsönhatást az elektromos mező közvetíti.',
		b: 9,
		c: 1023,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Érti coulomb törvényét, egyszerű esetekben alkalmazza elektromos töltéssel rendelkező testek közötti erő meghatározására.',
		b: 9,
		c: 1022,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Átlátja, hogy az elektromos állapot kialakulása a töltések egyenletes eloszlásának megváltozásával van kapcsolatban.',
		b: 9,
		c: 1021,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Ismeri az elektrosztatikus alapjelenségeket (dörzselektromosság, töltött testek közötti kölcsönhatás, földelés), ezek gyakorlati alkalmazásait.',
		b: 9,
		c: 1020,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Ismeri a hidrosztatika alapjait, a felhajtóerő fogalmát, hétköznapi példákon keresztül értelmezi a felemelkedés, elmerülés, úszás, lebegés jelenségét, tudja az ezt meghatározó tényezőket, ismeri a jelenségkörre épülő gyakorlati eszközöket.',
		b: 9,
		c: 1019,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Tisztában van a repülés elvével, a légellenállás jelenségével.',
		b: 9,
		c: 1018,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Ismeri a nyomás, hőmérséklet, páratartalom fogalmát, a levegő mint ideális gáz viselkedésének legfontosabb jellemzőit. egyszerű számításokat végez az állapothatározók megváltozásával kapcsolatban.',
		b: 9,
		c: 1017,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Ismeri az időjárás elemeit, a csapadékformákat, a csapadékok kialakulásának fizikai leírását.',
		b: 9,
		c: 1016,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Ismeri a víz különleges tulajdonságait (rendhagyó hőtágulás, nagy olvadáshő, forráshő, fajhő), ezek hatását a természetben, illetve mesterséges környezetünkben.',
		b: 9,
		c: 1015,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Tisztában van a megfordítható és nem megfordítható folyamatok közötti különbséggel.',
		b: 9,
		c: 1014,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Ismeri a hőtan első főtételét, és tudja alkalmazni néhány egyszerűbb gyakorlati szituációban (palackba zárt levegő, illetve állandó nyomású levegő melegítése).',
		b: 9,
		c: 1013,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Ismeri a hőtágulás jelenségét, jellemző nagyságrendjét.',
		b: 9,
		c: 1012,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Tisztában van a halmazállapot-változások energetikai viszonyaival, anyagszerkezeti magyarázatával, tudja, mit jelent az olvadáshő, forráshő, párolgáshő, egyszerű számításokat végez a halmazállapot-változásokat kísérő hőközlés meghatározására.',
		b: 9,
		c: 1011,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Tudja a halmazállapot-változások típusait (párolgás, forrás, lecsapódás, olvadás, fagyás, szublimáció).',
		b: 9,
		c: 1010,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Értelmezi az anyag viselkedését hőközlés során, tudja, mit jelent az égéshő, a fűtőérték és a fajhő.',
		b: 9,
		c: 1009,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Gyakorlati példákon keresztül ismeri a hővezetés, hőáramlás és hősugárzás jelenségét, a hőszigetelés lehetőségeit, ezek anyagszerkezeti magyarázatát.',
		b: 9,
		c: 1008,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Ismeri a celsius- és az abszolút hőmérsékleti skálát, a gyakorlat szempontjából nevezetes néhány hőmérsékletet, a termikus kölcsönhatás jellemzőit.',
		b: 9,
		c: 1007,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Néhány egyszerűbb, konkrét esetben (mérleg, libikóka) a forgatónyomatékok meghatározásának segítségével vizsgálja a testek egyensúlyi állapotának feltételeit, összeveti az eredményeket a megfigyelések és kísérletek tapasztalataival.',
		b: 9,
		c: 1006,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az energia'])
	},
		{
		a: 'Konkrét esetekben alkalmazza a munkatételt, a mechanikai energia megmaradásának elvét a mozgás értelmezésére, a sebesség kiszámolására.',
		b: 9,
		c: 1005,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az energia'])
	},
		{
		a: 'Ismeri a mechanikai munka fogalmát, kiszámításának módját, mértékegységét, a helyzeti energia, a mozgási energia, a rugalmas energia, a belső energia fogalmát.',
		b: 9,
		c: 1004,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az energia'])
	},
		{
		a: 'Érti a tömegvonzás általános törvényét, és azt, hogy a gravitációs erő bármely két test között hat.',
		b: 9,
		c: 1003,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Érti a testek súlya és a tömege közötti különbséget, a súlytalanság állapotát, a gravitációs mező szerepét a gravitációs erő közvetítésében.',
		b: 9,
		c: 1002,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Tudja, mit jelentenek a kozmikus sebességek (körsebesség, szökési sebesség).',
		b: 9,
		c: 1001,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Ismeri a bolygók, üstökösök mozgásának jellegzetességeit.',
		b: 9,
		c: 1000,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Egyszerűbb esetekben kiszámolja a mechanikai kölcsönhatásokban fellépő erőket (nehézségi erő, nyomóerő, fonálerő, súlyerő, súrlódási erők, rugóerő), meghatározza az erők eredőjét.',
		b: 9,
		c: 999,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Tisztában van az erő mint fizikai mennyiség jelentésével, mértékegységével, ismeri a newtoni dinamika alaptörvényeit, egyszerűbb esetekben alkalmazza azokat a gyorsulás meghatározására, a korábban megismert mozgások értelmezésére.',
		b: 9,
		c: 998,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Egyszerűbb esetekben alkalmazza a lendület-megmaradás törvényét, ismeri ennek általános érvényességét.',
		b: 9,
		c: 997,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Egyszerű esetekben kiszámolja a testek lendületének nagyságát, meghatározza irányát.',
		b: 9,
		c: 996,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Érti, hogyan alakulnak ki és terjednek a mechanikai hullámok, ismeri a hullámhossz és a terjedési sebesség fogalmát.',
		b: 9,
		c: 995,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Ismeri az egyenletes körmozgást leíró fizikai mennyiségeket (pályasugár, kerületi sebesség, fordulatszám, keringési idő, centripetális gyorsulás), azok jelentését, egymással való kapcsolatát.',
		b: 9,
		c: 994,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Ismeri a periodikus mozgásokat (ingamozgás, rezgőmozgás) jellemző fizikai mennyiségeket, néhány egyszerű esetben tudja mérni a periódusidőt, megállapítani az azt befolyásoló tényezőket.',
		b: 9,
		c: 993,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Egyszerű számításokat végez az állandó gyorsulással mozgó testek esetében.',
		b: 9,
		c: 992,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Ismeri a szabadesés jelenségét, annak leírását, tud esésidőt számolni, mérni, becsapódási sebességet számolni.',
		b: 9,
		c: 991,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Tud számításokat végezni az egyenes vonalú egyenletes mozgás esetében: állandó sebességű mozgások esetén a sebesség ismeretében meghatározza az elmozdulást, a sebesség nagyságának ismeretében a megtett utat, a céltól való távolság ismeretében a megérkezéshez szükséges időt.',
		b: 9,
		c: 990,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Helyesen használja az út, a pálya és a hely fogalmát, valamint a sebesség, átlagsebesség, pillanatnyi sebesség, gyorsulás, elmozdulás fizikai mennyiségeket a mozgás leírására.',
		b: 9,
		c: 989,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Átlátja a gyakran alkalmazott orvosdiagnosztikai vizsgálatok, illetve egyes kezelések fizikai megalapozottságát, felismeri a sarlatán, tudományosan megalapozatlan kezelési módokat.',
		b: 9,
		c: 988,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai vonatkozásai'])
	},
		{
		a: 'Ismeri a szervezet energiaháztartásának legfontosabb tényezőit, az élelmiszerek energiatartalmának szerepét.',
		b: 9,
		c: 987,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai vonatkozásai'])
	},
		{
		a: 'Tisztában van az elektromos áram élettani hatásaival, az emberi test áramvezetési tulajdonságaival, az idegi áramvezetés jelenségével.',
		b: 9,
		c: 986,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai vonatkozásai'])
	},
		{
		a: 'Ismeri a radioaktív izotópok néhány orvosi alkalmazását (nyomjelzés).',
		b: 9,
		c: 985,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai vonatkozásai'])
	},
		{
		a: 'Ismeri az emberi szemet mint képalkotó eszközt, a látás mechanizmusát, a gyakori látáshibák (rövid- és távollátás) okát, a szemüveg és a kontaktlencse jellemzőit, a dioptria fogalmát.',
		b: 9,
		c: 984,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai vonatkozásai'])
	},
		{
		a: 'Átlátja a húros hangszerek és a sípok működésének elvét, az ultrahang szerepét a gyógyászatban, ismeri a zajszennyezés fogalmát.',
		b: 9,
		c: 983,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai vonatkozásai'])
	},
		{
		a: 'Ismeri az emberi hangérzékelés fizikai alapjait, a hang mint hullám jellemzőit, keltésének eljárásait.',
		b: 9,
		c: 982,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai vonatkozásai'])
	},
		{
		a: 'Érti a generátor, a motor és a transzformátor működési elvét, gyakorlati hasznát.',
		b: 9,
		c: 981,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Ismeri az elektromos hálózatok kialakítását a lakásokban, épületekben, az elektromos kapcsolási rajzok használatát.',
		b: 9,
		c: 980,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Ismeri az elektromos fogyasztók használatára vonatkozó balesetvédelmi szabályokat.',
		b: 9,
		c: 979,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Tisztában van az elektromos áram veszélyeivel, a veszélyeket csökkentő legfontosabb megoldásokkal (gyerekbiztos csatlakozók, biztosíték, földvezeték szerepe).',
		b: 9,
		c: 978,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Tisztában van az elektromágneses hullámok frekvenciatartományaival, a rádióhullámok, mikrohullámok, infravörös hullámok, a látható fény, az ultraibolya hullámok, a röntgensugárzás, a gamma-sugárzás gyakorlati felhasználásával.',
		b: 9,
		c: 977,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Ismeri a digitális fényképezőgép működésének elvét.',
		b: 9,
		c: 976,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Ismeri az elektromágneses hullámok szerepét az információ- (hang-, kép-) átvitelben, ismeri a mobiltelefon legfontosabb tartozékait (sim kártya, akkumulátor stb.), azok kezelését, funkcióját.',
		b: 9,
		c: 975,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Ismeri a mindennapi életben használt legfontosabb elektromos energiaforrásokat, a gépkocsi-, mobiltelefon-akkumulátorok legfontosabb jellemzőit.',
		b: 9,
		c: 974,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Tisztában van az aktuálisan használt világító eszközeink működési elvével, energiafelhasználásának sajátosságaival, a korábban alkalmazott megoldásokhoz képesti előnyeivel.',
		b: 9,
		c: 973,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Ismeri az egyszerű gépek elvének megjelenését a hétköznapokban, mindennapi eszközeinkben.',
		b: 9,
		c: 972,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Átlátja a jelen közlekedése, közlekedésbiztonsága szempontjából releváns gyakorlati ismereteket, azok fizikai hátterét.',
		b: 9,
		c: 971,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Tisztában van a konyhai tevékenységek (melegítés, főzés, hűtés) fizikai vonatkozásaival.',
		b: 9,
		c: 970,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Ismeri a háztartásban használt fontosabb elektromos eszközöket, az elektromosság szerepét azok működésében, szemléletes képe van a váltakozó áramról.',
		b: 9,
		c: 969,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Átlátja a korszerű lakások és házak hőszabályozásának fizikai kérdéseit (fűtés, hűtés, hőszigetelés).',
		b: 9,
		c: 968,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Érti a legfontosabb közlekedési eszközök -- gépjárművek, légi és vízi járművek -- működésének fizikai elveit.',
		b: 9,
		c: 967,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai'])
	},
		{
		a: 'Ismeri a légnyomás változó jellegét, a légnyomás és az időjárás kapcsolatát.',
		b: 9,
		c: 966,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a legáltalánosabb természeti jelenségek fizikai alapjai', 'a természeti jelenségek és technikai eszközök, technológiák fizikája'])
	},
		{
		a: 'Ismeri a villámok veszélyét, a villámhárítók működését, a helyes magatartást zivataros, villámcsapás-veszélyes időben.',
		b: 9,
		c: 965,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a legáltalánosabb természeti jelenségek fizikai alapjai', 'a természeti jelenségek és technikai eszközök, technológiák fizikája'])
	},
		{
		a: 'Tudja, hogyan jönnek létre a természet színei, és hogyan észleljük azokat.',
		b: 9,
		c: 964,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a legáltalánosabb természeti jelenségek fizikai alapjai', 'a természeti jelenségek és technikai eszközök, technológiák fizikája'])
	},
		{
		a: 'Ismeri a legfontosabb természeti jelenségeket (például légköri jelenségek, az égbolt változásai, a vízzel kapcsolatos jelenségek), azok megfelelően egyszerűsített, a fizikai mennyiségeken és törvényeken alapuló magyarázatait.',
		b: 9,
		c: 963,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a legáltalánosabb természeti jelenségek fizikai alapjai', 'a természeti jelenségek és technikai eszközök, technológiák fizikája'])
	},
		{
		a: 'Adatokat gyűjt és dolgoz fel a legismertebb fizikusok életével, tevékenységével, annak gazdasági, társadalmi hatásával, valamint emberi vonatkozásaival kapcsolatban (galileo galilei, michael faraday, james watt, eötvös loránd, marie curie, ernest rutherford, niels bohr, albert einstein, szilárd leó, wigner jenő, teller ede).',
		b: 9,
		c: 962,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'neves fizikusok élete, munkássága'])
	},
		{
		a: 'Átlátja az emberiség és a világegyetem kapcsolatának kulcskérdéseit.',
		b: 9,
		c: 961,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizika és a társadalmi-gazdasági fejlődés összefüggései'])
	},
		{
		a: 'El tudja helyezni lakóhelyét a földön, a föld helyét a naprendszerben, a naprendszer helyét a galaxisunkban és az univerzumban.',
		b: 9,
		c: 960,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizika és a társadalmi-gazdasági fejlődés összefüggései'])
	},
		{
		a: 'Néhány konkrét példa alapján felismeri a fizika tudásrendszerének fejlődése és a társadalmi-gazdasági folyamatok, történelmi események közötti kapcsolatot.',
		b: 9,
		c: 959,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizika és a társadalmi-gazdasági fejlődés összefüggései'])
	},
		{
		a: 'Tisztában van az űrkutatás ipari-technikai civilizációra gyakorolt hatásával, valamint az űrkutatás tágabb értelemben vett céljaival (értelmes élet keresése, új nyersanyagforrások felfedezése).',
		b: 9,
		c: 958,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a globális problémák fizikai háttere'])
	},
		{
		a: 'Ismeri az űrkutatás történetének főbb fejezeteit, jövőbeli lehetőségeit, tervezett irányait.',
		b: 9,
		c: 957,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a globális problémák fizikai háttere'])
	},
		{
		a: 'Tisztában van az éghajlatváltozás kérdésével, az üvegházhatás jelenségével a természetben, a jelenség erőssége és az emberi tevékenység kapcsolatával.',
		b: 9,
		c: 956,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a globális problémák fizikai háttere'])
	},
		{
		a: 'Átlátja az ózonpajzs szerepét a földet ért ultraibolya sugárzással kapcsolatban.',
		b: 9,
		c: 955,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a globális problémák fizikai háttere'])
	},
		{
		a: 'Tudja, hogy a föld elsődleges energiaforrása a nap, ismeri a napenergia felhasználási lehetőségeit, a napkollektor és a napelem mibenlétét, a közöttük lévő különbséget.',
		b: 9,
		c: 954,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'az energiagazdálkodás fizikai kérdései'])
	},
		{
		a: 'Az emberiség energiafelhasználásával kapcsolatos adatokat gyűjt, az információkat szemléletesen mutatja be.',
		b: 9,
		c: 953,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'az energiagazdálkodás fizikai kérdései'])
	},
		{
		a: 'Ismeri a megújuló és a nem megújuló energiaforrások használatának és az energia szállításának legfontosabb gyakorlati kérdéseit.',
		b: 9,
		c: 952,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'az energiagazdálkodás fizikai kérdései'])
	},
		{
		a: 'Ismeri a környezet szennyezésének leggyakoribb forrásait, fizikai vonatkozásait.',
		b: 9,
		c: 951,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizika szerepe a környezet megóvásában'])
	},
		{
		a: 'Érti az atomreaktorok működésének lényegét, a radioaktív hulladékok elhelyezésének problémáit.',
		b: 9,
		c: 950,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizika szerepe a környezet megóvásában'])
	},
		{
		a: 'Tisztában van a különböző típusú erőművek használatának előnyeivel és környezeti kockázatával.',
		b: 9,
		c: 949,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizika szerepe a környezet megóvásában'])
	},
		{
		a: 'Kialakult véleményét mérési eredményekkel, érvekkel támasztja alá.',
		b: 9,
		c: 948,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'a tudományos érvelés és vita, az eredmények prezentálása'])
	},
		{
		a: 'Ismeri a fizika főbb szakterületeit, néhány új eredményét.',
		b: 9,
		c: 947,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'a fizika tudománya, legújabb eredményei, módszerei'])
	},
		{
		a: 'Felismeri a tudomány által vizsgálható jelenségeket, azonosítani tudja a tudományos érvelést, kritikusan vizsgálja egy elképzelés tudományos megalapozottságát.',
		b: 9,
		c: 946,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'a fizika tudománya, legújabb eredményei, módszerei'])
	},
		{
		a: 'Tisztában van azzal, hogy a fizika átfogó törvényeket ismer fel, melyek alkalmazhatók jelenségek értelmezésére, egyes események minőségi és mennyiségi előrejelzésére.',
		b: 9,
		c: 945,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'a fizika tudománya, legújabb eredményei, módszerei'])
	},
		{
		a: 'Tudja, hogyan születnek az elismert, új tudományos felismerések, ismeri a tudományosság kritériumait.',
		b: 9,
		c: 944,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'a fizika tudománya, legújabb eredményei, módszerei'])
	},
		{
		a: 'Gyakorlati oldalról ismeri a tudományos megismerési folyamatot: megfigyelés, mérés, a tapasztalatok, mérési adatok rögzítése, rendszerezése, ezek összevetése valamilyen egyszerű modellel vagy matematikai összefüggéssel, a modell (összefüggés) továbbfejlesztése.',
		b: 9,
		c: 943,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'El tudja választani egyszerű fizikai rendszerek esetén a lényeges elemeket a lényegtelenektől.',
		b: 9,
		c: 942,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Egyszerű, a megértést segítő számolási feladatokat old meg, táblázatokat, ábrákat, grafikonokat értelmez, következtetést von le, összehasonlít.',
		b: 9,
		c: 941,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Megismételt mérések segítségével, illetve a mérés körülményeinek ismeretében következtet a mérés eredményét befolyásoló tényezőkre.',
		b: 9,
		c: 940,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'A mérések és a kiértékelés során alkalmazza a rendelkezésre álló számítógépes eszközöket, programokat.',
		b: 9,
		c: 939,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Ismeri a legfontosabb mértékegységek jelentését, helyesen használja a mértékegységeket számításokban, illetve az eredmények összehasonlítása során.',
		b: 9,
		c: 938,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Fizikai kísérleteket önállóan is el tud végezni.',
		b: 9,
		c: 937,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Egyszerű méréseket, kísérleteket végez, az eredményeket rögzíti.',
		b: 9,
		c: 936,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Tudományos ismereteit érveléssel meg tudja védeni, vita során ki tudja fejteni véleményét, érveit és ellenérveit, mérlegelni tudja egy elképzelés tudományos megalapozottságát.',
		b: 9,
		c: 935,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tud önállóan fizikai témájú ismeretterjesztő szövegeket olvasni, a lényeget kiemelni, el tudja különíteni a számára világos, valamint a nem érthető, további magyarázatra szoruló részeket.',
		b: 9,
		c: 934,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Látja a fizikai ismeretek bővülése és a társadalmi-gazdasági folyamatok, történelmi események közötti kapcsolatot.',
		b: 9,
		c: 933,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tájékozott a földünket és környezetünket fenyegető globális problémákban, ismeri az emberi tevékenység szerepét ezek kialakulásában.',
		b: 9,
		c: 932,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Egyszerű fizikai rendszerek esetén a lényeges elemeket a lényegtelenektől el tudja választani, az egyszerűbb számításokat el tudja végezni és a helyes logikai következtetéseket le tudja vonni, illetve táblázatokat, ábrákat, grafikonokat tud értelmezni.',
		b: 9,
		c: 931,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Gyakorlati oldalról ismeri a tudományos megismerési folyamatot: megfigyel, mér, adatait összeveti az egyszerű modellekkel, korábbi ismereteivel. ennek alapján következtet, megerősít, cáfol.',
		b: 9,
		c: 930,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismeri a világot leíró legfontosabb természeti jelenségeket, az azokat leíró fizikai mennyiségeket, azok jelentését, jellemző nagyságrendjeit.',
		b: 9,
		c: 929,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Felismeri, hogyan jelennek meg a fizikai ismeretek a mindennapi tevékenységek során, valamint a gyakran használt technikai eszközök működésében.',
		b: 9,
		c: 928,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tisztában van azzal, hogy a fizika átfogó törvényeket ismer fel, melyek alkalmazhatók jelenségek értelmezésére, egyes események minőségi és mennyiségi előrejelzésére.',
		b: 9,
		c: 927,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismeri a helyét a világegyetemben, látja a világegyetem időbeli fejlődését, lehetséges jövőjét, az emberiség és a világegyetem kapcsolatának kulcskérdéseit.',
		b: 9,
		c: 926,
		d: 10,
		e: 'Fizika@9-10',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Bemutatja az egyén társadalmi szerepvállalásának lehetőségeit, a tevékeny közreműködés példáit a környezet védelme érdekében, illetve érvényesíti saját döntéseiben a környezeti szempontokat.',
		b: 9,
		c: 1148,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Értelmezi a fenntartható gazdaság, a fenntartható gazdálkodás fogalmát, érveket fogalmaz meg a fenntarthatóságot szem előtt tartó gazdaság, illetve gazdálkodás fontossága mellett.',
		b: 9,
		c: 1147,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Megnevez a környezet védelmében, illetve humanitárius céllal tevékenykedő hazai és nemzetközi szervezeteket, példákat említ azok tevékenységére, belátja és igazolja a nemzetközi összefogás szükségességét.',
		b: 9,
		c: 1146,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'A lakóhely adottságaiból kiindulva értelmezi a fenntartható fejlődés társadalmi, természeti, gazdasági, környezetvédelmi kihívásait.',
		b: 9,
		c: 1145,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Megkülönbözteti a fogyasztói társadalom és a tudatos fogyasztói közösség jellemzőit.',
		b: 9,
		c: 1144,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Megfogalmazza az energiahatékony, nyersanyag-takarékos, illetve „zöld\" gazdálkodás lényegét, valamint példákat nevez meg a környezeti szempontok érvényesíthetőségére a termelésben és a fogyasztásban.',
		b: 9,
		c: 1143,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Globális problémákhoz vezető, földünkön egy időben jelenlévő, különböző természeti és társadalmi-gazdasági eredetű problémákat elemez, feltárja azok összefüggéseit, bemutatja mérséklésük lehetséges módjait és azok nehézségeit.',
		b: 9,
		c: 1142,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Példákkal igazolja a természetkárosítás és a természeti, illetve környezeti katasztrófák társadalmi következményeit, a környezetkárosodás életkörülményekre, életminőségre gyakorolt hatását, a lokális szennyeződés globális következményeit.',
		b: 9,
		c: 1141,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Rendszerezi a geoszférákat ért környezetkárosító hatásokat, bemutatja a folyamatok kölcsönhatásait.',
		b: 9,
		c: 1140,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Felismeri és azonosítja a földrajzi tartalmú természeti, társadalmi-gazdasági és környezeti problémákat, megnevezi kialakulásuk okait, és javaslatokat fogalmaz meg megoldásukra.',
		b: 9,
		c: 1139,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Alkalmazza megszerzett ismereteit pénzügyi döntéseiben, belátja a körültekintő, felelős pénzügyi tervezés és döntéshozatal fontosságát.',
		b: 9,
		c: 1138,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a monetáris világ összefüggéseiben'])
	},
		{
		a: 'Pénzügyi lehetőségeit mérlegelve egyszerű költségvetést készít, értékeli a hitelfelvétel előnyeit és kockázatait.',
		b: 9,
		c: 1137,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a monetáris világ összefüggéseiben'])
	},
		{
		a: 'Pénzügyi döntéshelyzeteket, aktuális pénzügyi folyamatokat értelmez és megfogalmazza a lehetséges következményeket.',
		b: 9,
		c: 1136,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a monetáris világ összefüggéseiben'])
	},
		{
		a: 'Bemutatja a működőtőke- és a pénztőkeáramlás sajátos vonásait, magyarázza eltérésük okait.',
		b: 9,
		c: 1135,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a monetáris világ összefüggéseiben'])
	},
		{
		a: 'Magyarázza a monetáris világ működésének alapvető fogalmait, folyamatait és azok összefüggéseit, ismer nemzetközi pénzügyi szervezeteket.',
		b: 9,
		c: 1134,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a monetáris világ összefüggéseiben'])
	},
		{
		a: 'Értékeli hazánk környezeti állapotát, megnevezi jelentősebb környezeti problémáit.',
		b: 9,
		c: 1133,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Bemutatja a területi fejlettségi különbségek okait és következményeit magyarországon, megfogalmazza a felzárkózás lehetőségeit.',
		b: 9,
		c: 1132,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Példák alapján jellemzi és értékeli magyarország társadalmi-gazdasági szerepét annak szűkebb és tágabb nemzetközi környezetében, az európai unióban.',
		b: 9,
		c: 1131,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Ismerteti az európai unió működésének földrajzi alapjait, példák segítségével bemutatja az európai unión belüli társadalmi-gazdasági fejlettségbeli különbségeket, és megnevezi a felzárkózást segítő eszközöket.',
		b: 9,
		c: 1130,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Összefüggéseiben mutatja be a perifériatérség társadalmi-gazdasági fejlődésének jellemző vonásait, a felzárkózás nehézségeit.',
		b: 9,
		c: 1129,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Összehasonlítja az európai, ázsiai és amerikai erőterek gazdaságilag meghatározó jelentőségű országainak, országcsoportjainak szerepét a globális világban.',
		b: 9,
		c: 1128,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Megnevezi a világgazdaság működése szempontjából tipikus térségeket, országokat.',
		b: 9,
		c: 1127,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Példák alapján bemutatja a globalizáció társadalmi-gazdasági és környezeti következményeit, mindennapi életünkre gyakorolt hatását.',
		b: 9,
		c: 1126,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Értelmezi a globalizáció fogalmát, a globális világ kialakulásának és működésének feltételeit, jellemző vonásait.',
		b: 9,
		c: 1125,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Ismerteti a világpolitika és a világgazdaság működését befolyásoló nemzetközi szervezetek, együttműködések legfontosabb jellemzőit.',
		b: 9,
		c: 1124,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Megnevezi és értékeli a gazdasági integrációk és a regionális együttműködések kialakulásában szerepet játszó tényezőket.',
		b: 9,
		c: 1123,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Modellezi a piacgazdaság működését.',
		b: 9,
		c: 1122,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Értékeli az eltérő adottságok, erőforrások szerepét a társadalmi-gazdasági fejlődésben.',
		b: 9,
		c: 1121,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Értelmezi és értékeli a társadalmi-gazdasági fejlettség összehasonlítására alkalmas mutatók adatait, a társadalmi-gazdasági fejlettség területi különbségeit a föld különböző térségeiben.',
		b: 9,
		c: 1120,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Ismerteti a gazdaság szerveződését befolyásoló telepítő tényezők szerepének átalakulását, bemutatja az egyes gazdasági ágazatok jellemzőit, értelmezi a gazdasági szerkezetváltás folyamatát.',
		b: 9,
		c: 1119,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Érti és követi a lakóhelye környékén zajló település- és területfejlődési, valamint demográfiai folyamatokat.',
		b: 9,
		c: 1118,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Különböző szempontok alapján csoportosítja és jellemzi az egyes településtípusokat, bemutatja szerepkörük és szerkezetük változásait.',
		b: 9,
		c: 1117,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Különböző népességi, társadalmi és kulturális jellemzők alapján bemutat egy kontinenst, országot, országcsoportot.',
		b: 9,
		c: 1116,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Bemutatja a népességszám-változás időbeli és területi különbségeit, ismerteti okait és következményeit, összefüggését a fiatalodó és az öregedő társadalmak jellemző folyamataival és problémáival.',
		b: 9,
		c: 1115,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Érti az ember környezet átalakító szerepét, ember és környezete kapcsolatrendszerét, illetve példák alapján igazolja az egyes geoszférák folyamatainak, jelenségeinek gazdasági következményeit, összefüggéseit.',
		b: 9,
		c: 1114,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Bemutatja a felszínformálás többtényezős összefüggéseit, ismeri és felismeri a különböző felszínformáló folyamatokhoz (szél, víz, jég) és kőzettípusokhoz kapcsolódóan kialakuló, felszíni és felszín alatti formakincset.',
		b: 9,
		c: 1113,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Összefüggéseiben mutatja be a talajképződés folyamatát, tájékozott a talajok gazdasági jelentőségével kapcsolatos kérdésekben, ismeri magyarország fontosabb talajtípusait.',
		b: 9,
		c: 1112,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Összefüggéseiben, kölcsönhatásaiban mutatja be a földrajzi övezetesség rendszerének egyes elemeit, a természeti jellemzők társadalmi-gazdasági vonatkozásait.',
		b: 9,
		c: 1111,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Tudatában van a személyes szerepvállalások értékének a globális vízgazdálkodás és éghajlatváltozás rendszerében.',
		b: 9,
		c: 1110,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Ismeri a vízburokkal kapcsolatos környezeti veszélyek okait, és reálisan számol a várható következményekkel.',
		b: 9,
		c: 1109,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Igazolja a felszíni és felszín alatti vizek egyre fontosabbá váló erőforrásszerepét és gazdasági vonatkozásait, bizonyítja a víz társadalmi folyamatokat befolyásoló természetét, védelmének szükségességét.',
		b: 9,
		c: 1108,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Ismeri a felszíni és felszín alatti vizek főbb típusait, azok jellemzőit, mennyiségi és minőségi viszonyaikat befolyásoló tényezőket, a víztípusok közötti összefüggéseket.',
		b: 9,
		c: 1107,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Magyarázza az éghajlatváltozás okait, valamint helyi, regionális, globális következményeit.',
		b: 9,
		c: 1106,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Megnevezi a légkör legfőbb szennyező forrásait és a szennyeződés következményeit, érti a lokálisan ható légszennyező folyamatok globális következményeit.',
		b: 9,
		c: 1105,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'A légkör globális változásaival foglalkozó forrásokat kritikusan elemzi, érveken alapuló véleményt fogalmaz meg a témával összefüggésben.',
		b: 9,
		c: 1104,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Felismeri a szélsőséges időjárási helyzeteket és tud a helyzetnek megfelelően cselekedni.',
		b: 9,
		c: 1103,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Tudja az időjárási térképeket és előrejelzéseket értelmezni egyszerű prognózisok készítésére.',
		b: 9,
		c: 1102,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Összefüggéseiben mutatja be a légköri folyamatokat és jelenségeket, illetve összekapcsolja ezeket az időjárás alakulásával.',
		b: 9,
		c: 1101,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Ismeri a légkör szerkezetét, fizikai és kémiai jellemzőit, magyarázza az ezekben bekövetkező változások mindennapi életre gyakorolt hatását.',
		b: 9,
		c: 1100,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Érti a különböző kőzettani felépítésű területek eltérő környezeti érzékenysége, terhelhetősége közti összefüggéseket.',
		b: 9,
		c: 1099,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Ismeri a kőzetburok folyamataihoz kapcsolódó földtani veszélyek okait, következményeit, tér- és időbeli jellemzőit, illetve elemzi az alkalmazkodási, kármegelőzési lehetőségeket.',
		b: 9,
		c: 1098,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Felismeri az alapvető ásványokat és kőzeteket, tud példákat említeni azok gazdasági és mindennapi életben való hasznosítására.',
		b: 9,
		c: 1097,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Párhuzamot tud vonni a jelenlegi és múltbeli földrajzi folyamatok között.',
		b: 9,
		c: 1096,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Felismeri a történelmi és a földtörténeti idő eltérő nagyságrendjét, ismeri a geoszférák fejlődésének időbeli szakaszait, meghatározó jelentőségű eseményeit.',
		b: 9,
		c: 1095,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Összefüggéseiben mutatja be a lemeztektonika és az azt kísérő jelenségek (földrengések, vulkanizmus, hegységképződés) kapcsolatát, térbeliségét, illetve magyarázza a kőzetlemez-mozgások lokális és az adott helyen túlmutató globális hatásait.',
		b: 9,
		c: 1094,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Ismeri a föld felépítésének törvényszerűségeit.',
		b: 9,
		c: 1093,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Egyszerű csillagászati és időszámítással kapcsolatos feladatokat, számításokat végez.',
		b: 9,
		c: 1092,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a kozmikus térben és az időben'])
	},
		{
		a: 'Értelmezi a nap és a naprendszer jelenségeit, folyamatait, azok földi hatásait.',
		b: 9,
		c: 1091,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a kozmikus térben és az időben'])
	},
		{
		a: 'Ismeri a föld, a hold és a bolygók jellemzőit, mozgásait és ezek következményeit, összefüggéseit.',
		b: 9,
		c: 1090,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a kozmikus térben és az időben'])
	},
		{
		a: 'Érti a világegyetem tér- és időbeli léptékeit, elhelyezi a földet a világegyetemben és a naprendszerben.',
		b: 9,
		c: 1089,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a kozmikus térben és az időben'])
	},
		{
		a: 'Térszemlélettel rendelkezik a csillagászati és a földrajzi térben.',
		b: 9,
		c: 1088,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a kozmikus térben és az időben'])
	},
		{
		a: 'Képes problémaközpontú feladatok megoldására, környezeti változások összehasonlító elemzésére térképek és légi- vagy űrfelvételek párhuzamos használatával.',
		b: 9,
		c: 1087,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a kozmikus térben és az időben'])
	},
		{
		a: 'Tudatosan használja a földrajzi és a kozmikus térben való tájékozódást segítő hagyományos és digitális eszközöket, ismeri a légi- és űrfelvételek sajátosságait, alkalmazási területeit.',
		b: 9,
		c: 1086,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a kozmikus térben és az időben'])
	},
		{
		a: 'A közvetlen környezetének földrajzi megismerésére terepvizsgálódást tervez és kivitelez.',
		b: 9,
		c: 1085,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Megadott szempontok alapján alapvető földrajzi-földtani folyamatokkal, tájakkal, országokkal kapcsolatos földrajzi tartalmú szövegeket, képi információhordozókat dolgoz fel.',
		b: 9,
		c: 1084,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Adatokat rendszerez és ábrázol hagyományos és digitális eszközök segítségével.',
		b: 9,
		c: 1083,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Digitális eszközök segítségével bemutat és értelmez földrajzi jelenségeket, folyamatokat, törvényszerűségeket, összefüggéseket.',
		b: 9,
		c: 1082,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Földrajzi tartalmú szövegek alapján lényegkiemelő összegzést készít szóban és írásban.',
		b: 9,
		c: 1081,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Céljainak megfelelően kiválasztja és önállóan használja a hagyományos, illetve digitális információforrásokat, adatbázisokat.',
		b: 9,
		c: 1080,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Önálló, érvekkel alátámasztott véleményt fogalmaz meg földrajzi kérdésekben.',
		b: 9,
		c: 1079,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Megkülönbözteti a tényeket a véleményektől, adatokat, információkat kritikusan szemlél.',
		b: 9,
		c: 1078,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Feltárja a földrajzi folyamatok, jelenségek közötti hasonlóságokat és eltéréseket, különböző szempontok alapján rendszerezi azokat.',
		b: 9,
		c: 1077,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Földrajzi megfigyelést, vizsgálatot, kísérletet tervez és valósít meg, az eredményeket értelmezi.',
		b: 9,
		c: 1076,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Földrajzi tartalmú adatok, információk alapján következtetéseket von le, tendenciákat ismer fel, és várható következményeket (prognózist) fogalmaz meg.',
		b: 9,
		c: 1075,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Alkalmazza a más tantárgyak tanulása során megszerzett ismereteit földrajzi problémák megoldása során.',
		b: 9,
		c: 1074,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Nyitott a különböző szintű pénzügyi folyamatok és összefüggések megismerése iránt.',
		b: 9,
		c: 1073,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Döntéseit a környezeti szempontok figyelembevételével mérlegeli, felelős fogyasztói magatartást tanúsít.',
		b: 9,
		c: 1072,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Elkötelezett a természeti és a kulturális értékek, a kulturális sokszínűség megőrzése iránt.',
		b: 9,
		c: 1071,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Földrajzi tartalmú projektfeladatokat valósít meg társaival.',
		b: 9,
		c: 1070,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Véleményt alkot aktuális társadalmi-gazdasági és környezeti kérdésekben, véleménye alátámasztására logikus érveket fogalmaz meg.',
		b: 9,
		c: 1069,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Összetettebb földrajzi számítási feladatokat megold, az eredmények alapján következtetéseket fogalmaz meg.',
		b: 9,
		c: 1068,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Adott természeti, társadalmi-gazdasági témához kapcsolódóan írásbeli vagy szóbeli beszámolót készít, prezentációt állít össze.',
		b: 9,
		c: 1067,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Képes összetettebb földrajzi tartalmú szövegek értelmezésére.',
		b: 9,
		c: 1066,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismeretei alapján biztonsággal tájékozódik a valós és a digitális eszközök által közvetített virtuális földrajzi térben, földrajzi tartalmú adatokban, a különböző típusú térképeken.',
		b: 9,
		c: 1065,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tudatosan és kritikusan használja a földrajzi tartalmú nyomtatott és elektronikus információforrásokat a tanulásban és tudása önálló bővítésekor.',
		b: 9,
		c: 1064,
		d: 10,
		e: 'Földrajz@9-10',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Meghatározott helyzetekben önálló véleményt tud alkotni, a társak véleményének meghallgatását követően álláspontját felül tudja bírálni, döntéseit át tudja értékelni.',
		b: 6,
		c: 1170,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a kommunikációs készség, a társas együttműködés fejlesztése, a véleményalkotás gyakorlatának erősít'])
	},
		{
		a: 'Tevékenyen részt vesz a csoportmunkában zajló együttműködő alkotási folyamatban, digitális források közös elemzésében.',
		b: 6,
		c: 1169,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a kommunikációs készség, a társas együttműködés fejlesztése, a véleményalkotás gyakorlatának erősít'])
	},
		{
		a: 'Felismeri a csoportban elfoglalt helyét és szerepét, törekszik a személyiségének, készségeinek és képességeinek, érdeklődési körének legjobban megfelelő feladatok vállalására.',
		b: 6,
		c: 1168,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a kommunikációs készség, a társas együttműködés fejlesztése, a véleményalkotás gyakorlatának erősít'])
	},
		{
		a: 'Szöveges és képi források, digitalizált archívumok elemzése, feldolgozása alapján bővíti tudását.',
		b: 6,
		c: 1167,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'az önálló ismeretszerzésben való jártasság növelése'])
	},
		{
		a: 'Önálló néprajzi gyűjtés során, digitális archívumok, írásos dokumentumok tanulmányozásával ismereteket szerez családja, lakóhelye múltjáról, hagyományairól.',
		b: 6,
		c: 1166,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'az önálló ismeretszerzésben való jártasság növelése'])
	},
		{
		a: 'Megismeri a gazdálkodó életmódra jellemző újrahasznosítás elvét, és saját életében is megpróbálja alkalmazni.',
		b: 6,
		c: 1165,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a környezettudatos szemlélet fejlesztése'])
	},
		{
		a: 'Belátja, hogy a természet kínálta lehetőségek felhasználásának elsődleges szempontja a szükségletek kielégítése, a mértéktartás alapelvének követése.',
		b: 6,
		c: 1164,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a környezettudatos szemlélet fejlesztése'])
	},
		{
		a: 'Megérti a természeti környezet meghatározó szerepét a más tájakon élő emberek életmódbeli különbségében.',
		b: 6,
		c: 1163,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a környezettudatos szemlélet fejlesztése'])
	},
		{
		a: 'Megbecsüli és megismeri az idősebb családtagok tudását, tapasztalatait, nyitott a korábbi nemzedékek életmódjának, normarendszerének megismerésére.',
		b: 6,
		c: 1162,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a nemzedékek közötti távolság csökkentése'])
	},
		{
		a: 'Érdeklődő attitűdjével erősíti a nemzedékek közötti párbeszédet.',
		b: 6,
		c: 1161,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a nemzedékek közötti távolság csökkentése'])
	},
		{
		a: 'Tiszteletben tartja más kultúrák értékeit.',
		b: 6,
		c: 1160,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a család, a családtagok, a közösség emberi értékei iránti érzékenység fejlesztése'])
	},
		{
		a: 'Felismeri a néphagyományok közösségformáló, közösségmegtartó erejét.',
		b: 6,
		c: 1159,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a család, a családtagok, a közösség emberi értékei iránti érzékenység fejlesztése'])
	},
		{
		a: 'Nyitottá válik a hagyományos családi és közösségi értékek befogadására.',
		b: 6,
		c: 1158,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a család, a családtagok, a közösség emberi értékei iránti érzékenység fejlesztése'])
	},
		{
		a: 'Megtapasztalja a legszűkebb közösséghez, a családhoz, a lokális közösséghez tartozás érzését.',
		b: 6,
		c: 1157,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a család, a családtagok, a közösség emberi értékei iránti érzékenység fejlesztése'])
	},
		{
		a: 'Megéli a közösséghez tartozást, nemzeti önazonosságát, kialakul benne a haza iránti szeretet és elköteleződés.',
		b: 6,
		c: 1156,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a lokálpatriotizmus és a nemzeti identitás erősítése'])
	},
		{
		a: 'Megbecsüli szűkebb lakókörnyezetének épített örökségét, természeti értékeit, helyi hagyományait.',
		b: 6,
		c: 1155,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a lokálpatriotizmus és a nemzeti identitás erősítése'])
	},
		{
		a: 'Megismeri a közvetlen környezetében található helyi értékeket, felhasználva a digitálisan elérhető adatbázisokat is.',
		b: 6,
		c: 1154,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['hon- és népismeret', 'a lokálpatriotizmus és a nemzeti identitás erősítése'])
	},
		{
		a: 'Képes az együttműködésre csoportmunkában, alkalmazza a tevékenységek lezárásakor az önértékelést és a társak értékelését.',
		b: 6,
		c: 1153,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'hon- és népismeret'])
	},
		{
		a: 'Az önálló ismeretszerzés során alkalmazni tudja az interjúkészítés, a forráselemzés módszerét.',
		b: 6,
		c: 1152,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'hon- és népismeret'])
	},
		{
		a: 'Ismeri és adekvát módon használja az ismeretkör tartalmi kulcsfogalmait.',
		b: 6,
		c: 1151,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'hon- és népismeret'])
	},
		{
		a: 'Megismeri magyarország és a kárpát-medence kulturális hagyományait, tiszteletben tartja más kultúrák értékeit.',
		b: 6,
		c: 1150,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'hon- és népismeret'])
	},
		{
		a: 'Képessé válik a nemzedékek közötti párbeszédre.',
		b: 6,
		c: 1149,
		d: 6,
		e: 'Hon- és népismeret@6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'hon- és népismeret'])
	},
		{
		a: 'Tudja, hogy a fehérjék aminosavakból épülnek fel, ismeri az aminosavak általános szerkezetét és azok legfontosabb tulajdonságait, ismeri a fehérjék elsődleges, másodlagos, harmadlagos és negyedleges szerkezetét, érti e fajlagos molekulák szerkezetének a kialakulását, példát mond a fehérjék szervezetben és élelmiszereinkben betöltött szerepére, ismeri a fehérjék kicsapásának módjait és ennek jelentőségét a mérgezések kapcsán.',
		b: 9,
		c: 1331,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'biológiai szempontból jelentős szerves vegyületek'])
	},
		{
		a: 'Ismeri a szénhidrátok legalapvetőbb csoportjait, példát mond mindegyik csoportból egy-két képviselőre, ismeri a szőlőcukor képletét, összefüggéseket talál a szőlőcukor szerkezete és tulajdonságai között, ismeri a háztartásban található szénhidrátok besorolását a megfelelő csoportba, valamint köznapi tulajdonságaikat (ízük, oldhatóságuk) és felhasználásukat, összehasonlítja a keményítő és a cellulóz molekulaszerkezetét és tulajdonságait, valamint szerepüket a szervezetben és a táplálékaink között.',
		b: 9,
		c: 1330,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'biológiai szempontból jelentős szerves vegyületek'])
	},
		{
		a: 'Ismeri a lipid gyűjtőnevet, tudja, hogy ebbe a csoportba hasonló oldhatósági tulajdonságokkal rendelkező vegyületek tartoznak, felsorolja a lipidek legfontosabb képviselőit, felismeri azokat szerkezeti képlet alapján, ismeri a lipidek csoportjába tartozó vegyületek egy-egy fontos szerepét az élő szervezetben.',
		b: 9,
		c: 1329,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'biológiai szempontból jelentős szerves vegyületek'])
	},
		{
		a: 'Ismeri a biológiai szempontból fontos szerves vegyületek építőelemeit (kémiai összetételét, a nagyobbak alkotó molekuláit).',
		b: 9,
		c: 1328,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'biológiai szempontból jelentős szerves vegyületek'])
	},
		{
		a: 'Szerkezetük alapján felismeri az aminok és az amidok egyszerűbb képviselőit, ismeri az aminocsoportot és az amidcsoportot.',
		b: 9,
		c: 1327,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Az etil-acetát példáján bemutatja a kis szénatomszámú észterek jellemző tulajdonságait, tudja, hogy a zsírok, az olajok, a foszfatidok, a viaszok egyaránt az észterek csoportjába tartoznak.',
		b: 9,
		c: 1326,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Ismeri, és vegyületek képletében felismeri a karboxilcsoportot és az észtercsoportot, ismeri az egyszerűbb és fontosabb karbonsavak (hangyasav, ecetsav, zsírsavak) szerkezetét és lényeges tulajdonságaikat.',
		b: 9,
		c: 1325,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Felismeri az aldehidcsoportot, ismeri a formaldehid tulajdonságait, az aldehidek kimutatásának módját, felismeri a ketocsoportot, ismeri az aceton tulajdonságait, felhasználását.',
		b: 9,
		c: 1324,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Ismeri az alkoholok fontosabb képviselőit (metanol, etanol, glikol, glicerin), azok fontosabb tulajdonságait, élettani hatásukat és felhasználásukat.',
		b: 9,
		c: 1323,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Ismeri, és vegyületek képletében felismeri a legegyszerűbb oxigéntartalmú funkciós csoportokat: a hidroxilcsoportot, az oxocsoportot, az étercsoportot.',
		b: 9,
		c: 1322,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Példát mond közismert halogéntartalmú szerves vegyületre (pl. kloroform, vinil-klorid, freonok, ddt, tetrafluoretén), és ismeri felhasználásukat.',
		b: 9,
		c: 1321,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Felismeri az aromás szerkezetet egy egyszerű vegyületben, ismeri a benzol molekulaszerkezetét és fontosabb tulajdonságait, tudja, hogy számos illékony aromás szénhidrogén mérgező.',
		b: 9,
		c: 1320,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Ismeri a legegyszerűbb szerves kémiai reakciótípusokat.',
		b: 9,
		c: 1319,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Ismeri a telítetlen szénhidrogének fogalmát, az etén és az acetilén szerkezetét és fontosabb tulajdonságait, ismeri és reakcióegyenletekkel leírja a telítetlen szénhidrogének jellemző reakciótípusait, az égést, az addíciót és a polimerizációt.',
		b: 9,
		c: 1318,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Érti az izoméria jelenségét, példákat mond konstitúciós izomerekre.',
		b: 9,
		c: 1317,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Ismeri a telített szénhidrogének homológ sorának felépülési elvét és fontosabb képviselőiket, ismeri a metán fontosabb tulajdonságait, jellemzi az anyagok szempontrendszere alapján, ismeri a homológ soron belül a forráspont változásának az okát, valamint a szénhidrogének oldhatóságát, ismeri és egy-egy kémiai egyenlettel leírja az égés, a szubsztitúció és a hőbontás folyamatát.',
		b: 9,
		c: 1316,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Ismeri a szerves vegyületeket felépítő organogén elemeket, érti a szerves vegyületek megkülönböztetésének, külön csoportban tárgyalásának az okát, az egyszerűbb szerves vegyületeket szerkezeti képlettel és összegképlettel jelöli.',
		b: 9,
		c: 1315,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'egyszerű szerves vegyületek'])
	},
		{
		a: 'Ismer eljárásokat fémek ércekből történő előállítására (vas, alumínium).',
		b: 9,
		c: 1314,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'fémek és fémvegyületek'])
	},
		{
		a: 'Ismeri az elektrolizáló cella felépítését és az elektrolízis lényegét a hidrogén-klorid-oldat grafitelektródos elektrolízise kapcsán, érti, hogy az elektromos áram kémiai reakciók végbemenetelét segíti, példát ad ezek gyakorlati felhasználására (alumíniumgyártás, galvanizálás).',
		b: 9,
		c: 1313,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'fémek és fémvegyületek'])
	},
		{
		a: 'Érti az elektromos áram és a kémiai reakciók közötti összefüggéseket: a galvánelemek áramtermelésének és az elektrolízisnek a lényegét.',
		b: 9,
		c: 1312,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'fémek és fémvegyületek'])
	},
		{
		a: 'Tisztában van az elektrokémiai áramforrások felépítésével és működésével, ismeri a daniell-elem felépítését és az abban végbemenő folyamatokat, az elem áramtermelését.',
		b: 9,
		c: 1311,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'fémek és fémvegyületek'])
	},
		{
		a: 'Ismeri a fémek köznapi szempontból legfontosabb vegyületeit, azok alapvető tulajdonságait (nacl, na2co3, nahco3, na3po4, caco3, ca3(po4)2, al2o3, fe2o3, cuso4).',
		b: 9,
		c: 1310,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'fémek és fémvegyületek'])
	},
		{
		a: 'Ismeri a fémek helyét a periódusos rendszerben, megkülönbözteti az alkálifémeket, az alkáliföldfémeket, ismeri a vas, az alumínium, a réz, valamint a nemesfémek legfontosabb tulajdonságait.',
		b: 9,
		c: 1309,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'fémek és fémvegyületek'])
	},
		{
		a: 'Kísérletek tapasztalatainak ismeretében értelmezi a fémek egymáshoz viszonyított reakciókészségét oxigénnel, sósavval, vízzel és más fémionok oldatával, érti a fémek redukáló sorának felépülését, következtet fémek reakciókészségére a sorban elfoglalt helyük alapján.',
		b: 9,
		c: 1308,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'fémek és fémvegyületek'])
	},
		{
		a: 'Ismeri a fontosabb fémek (na, k, mg, ca, al, fe, cu, ag, au, zn) fizikai és kémiai tulajdonságait.',
		b: 9,
		c: 1307,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'fémek és fémvegyületek'])
	},
		{
		a: 'Összehasonlítja a gyémánt és a grafit szerkezetét és tulajdonságait, különbséget tesz a természetes és mesterséges szenek között, ismeri a természetes szenek felhasználását, ismeri a koksz és az aktív szén felhasználását, példát mond a szén reakcióira (pl. égés), ismeri a szén oxidjainak (co, co2) a tulajdonságait, élettani hatását, valamint a szénsavat és sóit, a karbonátokat.',
		b: 9,
		c: 1306,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'nemfémes elemek és vegyületeik'])
	},
		{
		a: 'Ismeri a vörösfoszfort és a foszforsavat, fontosabb tulajdonságaikat és a foszfor gyufagyártásban betöltött szerepét.',
		b: 9,
		c: 1305,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'nemfémes elemek és vegyületeik'])
	},
		{
		a: 'Ismeri a nitrogént, az ammóniát, a nitrogén-dioxidot és a salétromsavat.',
		b: 9,
		c: 1304,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'nemfémes elemek és vegyületeik'])
	},
		{
		a: 'Ismeri a ként, a kén-dioxidot és a kénsavat.',
		b: 9,
		c: 1303,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'nemfémes elemek és vegyületeik'])
	},
		{
		a: 'Ismeri az oxigént és a vizet, ismeri az ózont, mint az oxigén allotróp módosulatát, ismeri mérgező hatását (szmogban) és uv-elnyelő hatását (ózonpajzsban).',
		b: 9,
		c: 1302,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'nemfémes elemek és vegyületeik'])
	},
		{
		a: 'Ismeri a halogének képviselőit, jellemzi a klórt, ismeri a hidrogén-klorid és a nátrium-klorid tulajdonságait.',
		b: 9,
		c: 1301,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'nemfémes elemek és vegyületeik'])
	},
		{
		a: 'Alkalmazza az anyagok jellemzésének szempontjait a hidrogénre, kapcsolatot teremt az anyag szerkezete és tulajdonságai között.',
		b: 9,
		c: 1300,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'nemfémes elemek és vegyületeik'])
	},
		{
		a: 'Ismeri a hidrogén, a halogének, a kalkogének, a nitrogén, a szén és fontosabb vegyületeik fizikai és kémiai sajátságait, különös tekintettel a köznapi életben előforduló anyagokra.',
		b: 9,
		c: 1299,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'nemfémes elemek és vegyületeik'])
	},
		{
		a: 'Ismeri az anyagok jellemzésének logikus szempontrendszerét: anyagszerkezet -- fizikai tulajdonságok -- kémiai tulajdonságok -- előfordulás -- előállítás -- felhasználás.',
		b: 9,
		c: 1298,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'nemfémes elemek és vegyületeik'])
	},
		{
		a: 'Konkrét példákon keresztül értelmezi a redoxireakciókat oxigénfelvétel és oxigénleadás alapján, ismeri a redoxireakciók tágabb értelmezését elektronátmenet alapján is, konkrét példákon bemutatja a redoxireakciót, eldönti egy egyszerű redoxireakció egyenlete ismeretében az elektronátadás irányát, az oxidációt és redukciót, megadja az oxidálószert és a redukálószert.',
		b: 9,
		c: 1297,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'kémiai változások'])
	},
		{
		a: 'Ismeri a fontosabb savakat, bázisokat, azok nevét, képletét, brønsted sav-bázis elmélete alapján értelmezi a sav és bázis fogalmát, ismeri a savak és bázisok erősségének és értékűségének a jelentését, konkrét példát mond ezekre a vegyületekre, érti a víz sav-bázis tulajdonságait, ismeri az autoprotolízis jelenségét és a víz autoprotolízisének a termékeit.',
		b: 9,
		c: 1296,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'kémiai változások'])
	},
		{
		a: 'Ismer egyirányú és egyensúlyra vezető kémiai reakciókat, érti a dinamikus egyensúly fogalmát, ismeri és alkalmazza az egyensúly eltolásának lehetőségeit le châtelier elve alapján.',
		b: 9,
		c: 1295,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'kémiai változások'])
	},
		{
		a: 'Érti a katalizátorok hatásának az elvi alapjait.',
		b: 9,
		c: 1294,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'kémiai változások'])
	},
		{
		a: 'Konkrét reakciókat termokémiai egyenlettel is felír, érti a termokémiai egyenlet jelentését, ismeri a reakcióhő fogalmát, a reakcióhő ismeretében megadja egy reakció energiaváltozását, energiadiagramot rajzol, értelmez, ismeri a termokémia főtételét és jelentőségét a többlépéses reakciók energiaváltozásának a meghatározásakor.',
		b: 9,
		c: 1293,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'kémiai változások'])
	},
		{
		a: 'A kémiai reakciókat szimbólumokkal írja le.',
		b: 9,
		c: 1292,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'kémiai változások'])
	},
		{
		a: 'Ismeri a kémiai reakciók csoportosítását többféle szempont szerint: a reagáló és a képződő anyagok száma, a reakció energiaváltozása, időbeli lefolyása, iránya, a reakcióban részt vevő anyagok halmazállapota szerint.',
		b: 9,
		c: 1291,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'kémiai változások'])
	},
		{
		a: 'Ismeri a kémiai reakciók végbemenetelének feltételeit, ismeri, érti és alkalmazza a tömegmegmaradás törvényét a kémiai reakciókra.',
		b: 9,
		c: 1290,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'kémiai változások'])
	},
		{
		a: 'Érti a fizikai és kémiai változások közötti különbségeket.',
		b: 9,
		c: 1289,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'kémiai változások'])
	},
		{
		a: 'Adott szempontok alapján összehasonlítja a három halmazállapotba (gáz, folyadék, szilárd) tartozó anyagok általános jellemzőit, ismeri avogadro gáztörvényét és egyszerű számításokat végez gázok térfogatával standard körülmények között, érti a halmazállapot-változások lényegét és energiaváltozását.',
		b: 9,
		c: 1288,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'szerkezet és tulajdonság kapcsolata'])
	},
		{
		a: 'Érti a „hasonló a hasonlóban jól oldódik\" elvet, ismeri az oldatok töménységével és az oldhatósággal kapcsolatos legfontosabb ismereteket, egyszerű számítási feladatokat old meg az oldatok köréből (tömegszázalék, anyagmennyiség-koncentráció).',
		b: 9,
		c: 1287,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'szerkezet és tulajdonság kapcsolata'])
	},
		{
		a: 'Ismeri az anyagok csoportosításának a módját a kémiai összetétel alapján, ismeri ezeknek az anyagcsoportoknak a legfontosabb közös tulajdonságait, példákat mond minden csoport képviselőire, tudja, hogy az oldatok a keverékek egy csoportja.',
		b: 9,
		c: 1286,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'szerkezet és tulajdonság kapcsolata'])
	},
		{
		a: 'Ismeri a fémrács szerkezetét és az ebből adódó alapvető fizikai tulajdonságokat.',
		b: 9,
		c: 1285,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'szerkezet és tulajdonság kapcsolata'])
	},
		{
		a: 'Ismeri a fémek helyét a periódusos rendszerben, érti a fémes kötés kialakulásának és a fémek kristályszerkezetének a lényegét, érti a kapcsolatot a fémek kristályszerkezete és fontosabb tulajdonságai között, konkrét példák segítségével (pl. fe, al, cu) jellemzi a fémes tulajdonságokat, összehasonlításokat végez.',
		b: 9,
		c: 1284,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'szerkezet és tulajdonság kapcsolata'])
	},
		{
		a: 'Ismeri az egyszerű ionok atomokból való létrejöttének módját, ezt konkrét példákkal szemlélteti, ismeri a fontosabb összetett ionok molekulákból való képződésének módját, tudja a nevüket, összegképletüket, érti egy ionvegyület képletének a megszerkesztését az azt alkotó ionok képlete alapján, érti az ionrács felépülési elvét, az ionvegyület képletének jelentését, konkrét példák segítségével jellemzi az ionvegyületek fontosabb tulajdonságait.',
		b: 9,
		c: 1283,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'szerkezet és tulajdonság kapcsolata'])
	},
		{
		a: 'Érti a részecske szerkezete és az anyag fizikai és kémiai tulajdonságai közötti alapvető összefüggéseket.',
		b: 9,
		c: 1282,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'szerkezet és tulajdonság kapcsolata'])
	},
		{
		a: 'Meghatározza egyszerű molekulák polaritását, és ennek alapján következtet a közöttük kialakuló másodrendű kémiai kötésekre, valamint oldhatósági jellemzőikre, érti, hogy a moláris tömeg és a molekulák között fellépő másodrendű kötések hogyan befolyásolják az olvadás- és forráspontot, ezeket konkrét példákkal támasztja alá.',
		b: 9,
		c: 1281,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri a molekulaképződés szabályait, ismeri az elektronegativitás fogalmát, és érti a kötéspolaritás lényegét, a kovalens kötést jellemzi száma és polaritása szerint, megalkotja egyszerű molekulák szerkezeti képletét, ismeri a legalapvetőbb molekulaalakokat (lineáris, síkháromszög, tetraéder, piramis, v-alak), valamint ezek meghatározó szerepét a molekulák polaritása szempontjából.',
		b: 9,
		c: 1280,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Értelmezi a periódusos rendszer fontosabb adatait (vegyjel, rendszám, relatív atomtömeg), alkalmazza a periódusszám és a (fő)csoportszám jelentését a héjak és a vegyértékelektronok szempontjából, ismeri a periódusos rendszer fontosabb csoportjainak a nevét, és az azokat alkotó elemek vegyjelét.',
		b: 9,
		c: 1279,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri az atom elektronszerkezetének kiépülését a bohr-féle atommodell szintjén, tisztában van a vegyértékelektronok kémiai reakciókban betöltött szerepével.',
		b: 9,
		c: 1278,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri az atom felépítését, az elemi részecskéket, valamint azok jellemzőit, ismeri az izotópok legfontosabb tulajdonságait, érti a radioaktivitás lényegét és példát mond a radioaktív izotópok gyakorlati felhasználására.',
		b: 9,
		c: 1277,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri a legfontosabb (n-, p-, k-tartalmú) műtrágyák kémiai összetételét, előállítását és felhasználásának szükségszerűségét.',
		b: 9,
		c: 1276,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'vegyszerismeret, biztonságos vegyszerhasználat'])
	},
		{
		a: 'Ismeri a mindennapi életben előforduló növényvédő szerek használatának alapvető szabályait, értelmezi a növényvédő szereknek a leírását, felhasználási útmutatóját, példát mond a növényvédő szerekre a múltból és a jelenből (bordói lé, korszerű peszticidek), ismeri ezek hatásának elvi alapjait.',
		b: 9,
		c: 1275,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'vegyszerismeret, biztonságos vegyszerhasználat'])
	},
		{
		a: 'Ismeri a kemény víz és a lágy víz közötti különbséget, érti a kemény víz és egyes mosószerek közötti kölcsönhatás (kicsapódás) folyamatát.',
		b: 9,
		c: 1274,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Ismeri a mosó- és tisztítószerek, valamint a fertőtlenítőszerek fogalmi megkülönböztetését, példát mond a környezetéből gyakran használt mosó-és tisztítószerre és fertőtlenítőszerre, ismeri a szappan összetételét és a szappangyártás módját, ismeri a hypo kémiai összetételét és felhasználási módját, érti a mosószerek mosóaktív komponenseinek (a felületaktív részecskéknek) a mosásban betöltött szerepét.',
		b: 9,
		c: 1273,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Ismeri a műanyag fogalmát és a műanyagok csoportosításának lehetőségeit eredetük, illetve hővel szemben mutatott viselkedésük alapján, konkrét példákat mond műanyagokra a környezetéből, érti azok felhasználásának előnyeit, ismeri a polimerizáció fogalmát, példát ad monomerekre és polimerekre, ismeri a műanyagok felhasználásának előnyeit és hátrányait, környezetre gyakorolt hatásukat.',
		b: 9,
		c: 1272,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Ismeri a fosszilis energiahordozók fogalmát és azok legfontosabb képviselőit, érti a kőolaj ipari lepárlásának elvét, ismeri a legfontosabb párlatok nevét, összetételét és felhasználási lehetőségeit, példát mond motorhajtó anyagokra, ismeri a töltőállomásokon kapható üzemanyagok típusait és azok felhasználását.',
		b: 9,
		c: 1271,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Érti, hogy a fémek többsége a természetben vegyületek formájában van jelen, ismeri a legfontosabb redukciós eljárásokat (szenes, elektrokémiai redukció), ismeri a legfontosabb ötvözeteket, érti az ötvözetek felhasználásának előnyeit.',
		b: 9,
		c: 1270,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Érti a mészkőalapú építőanyagok kémiai összetételét és átalakulásait (mészkő, égetett mész, oltott mész), ismeri a beton alapvető összetételét, előállítását és felhasználásának lehetőségeit, ismeri a legfontosabb hőszigetelő anyagokat.',
		b: 9,
		c: 1269,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Képes az ismeretein alapuló tudatos vásárlással és tudatos életvitellel a környezetének megóvására.',
		b: 9,
		c: 1268,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Érti, hogy az ipari (vegyipari) termelés során különféle, akár a környezetre vagy szervezetre káros anyagok is keletkezhetnek, amelyek közömbösítése, illetve kezelése fontos feladat.',
		b: 9,
		c: 1267,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Ismeri a különböző nyersanyagokból előállítható legfontosabb termékeket.',
		b: 9,
		c: 1266,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Érti az anyagok átalakításának hasznát, valamint konkrét példákat mond vegyipari termékek előállítására.',
		b: 9,
		c: 1265,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Ismeri a természetben megtalálható legfontosabb nyersanyagokat.',
		b: 9,
		c: 1264,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a mindennapi életben használt legfontosabb anyagok fizikai és kémiai tulajdonságai, átalakulásai, e'])
	},
		{
		a: 'Ismeri a méreg fogalmának jelentését, érti az anyagok mennyiségének jelentőségét a mérgező hatásuk tekintetében, példát mond növényi, állati és szintetikus mérgekre, ismeri a mérgek szervezetbe jutásának lehetőségeit (tápcsatorna, bőr, tüdő), ismeri és felismeri a különböző anyagok csomagolásán a mérgező anyag piktogramját, képes ezeknek az anyagoknak a felelősségteljes használatára, ismeri a köznapi életben előforduló leggyakoribb mérgeket, mérgezéseket (pl. szén-monoxid, penészgomba-toxinok, gombamérgezések, helytelen égetés során keletkező füst anyagai, drogok, nehézfémek), tudja, hogy a mérgező hatás nem az anyag szintetikus eredetének a következménye.',
		b: 9,
		c: 1263,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'táplálkozás, egészségvédelem'])
	},
		{
		a: 'Ismeri a leggyakrabban használt élvezeti szerek (szeszes italok, dohánytermékek, kávé, energiaitalok, drogok) hatóanyagát, ezen szerek használatának veszélyeit, érti az illegális drogok használatával kapcsolatos alapvető problémákat, példákat mond illegális drogokra, ismeri a doppingszer fogalmát, megérti és értékeli a doppingszerekkel kapcsolatos információkat.',
		b: 9,
		c: 1262,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'táplálkozás, egészségvédelem'])
	},
		{
		a: 'Ismeri a gyógyszer fogalmát és a gyógyszerek fontosabb csoportjait hatásuk alapján, alapvető szinten értelmezi a gyógyszerek mellékelt betegtájékoztatóját.',
		b: 9,
		c: 1261,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'táplálkozás, egészségvédelem'])
	},
		{
		a: 'Ismeri az élelmiszereink legfontosabb összetevőinek, a szénhidrátoknak, a fehérjéknek, valamint a zsíroknak és olajoknak a molekulaszerkezetét és a tulajdonságait, felsorolja a háztartásban megtalálható legfontosabb élelmiszerek tápanyagait, példát mond bizonyos összetevők (fehérjék, redukáló cukrok, keményítő) kimutatására, ismeri a legfontosabb élelmiszeradalék-csoportokat, alapvető szinten értelmezi egy élelmiszer tájékoztató címkéjét.',
		b: 9,
		c: 1260,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'táplálkozás, egészségvédelem'])
	},
		{
		a: 'Látja az áltudományos megközelítés lényegét (feltételezés, szubjektivitás, bizonyítatlanság), felismeri az áltudományosságra utaló legfontosabb jeleket.',
		b: 9,
		c: 1259,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'tudomány és áltudomány megkülönböztetése, a tényeken alapuló döntések jelentősége'])
	},
		{
		a: 'Ismeri a tudományos megközelítés lényegét (objektivitás, reprodukálhatóság, ellenőrizhetőség, bizonyíthatóság).',
		b: 9,
		c: 1258,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'tudomány és áltudomány megkülönböztetése, a tényeken alapuló döntések jelentősége'])
	},
		{
		a: 'Érti a különbséget a tudományos és áltudományos információk között, konkrét példát mond a köznapi életből tudományos és áltudományos ismeretekre, információkra.',
		b: 9,
		c: 1257,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'tudomány és áltudomány megkülönböztetése, a tényeken alapuló döntések jelentősége'])
	},
		{
		a: 'Érti a kőzetek és a környezeti tényezők talajképző szerepét, példát mond alapvető kőzetekre, ásványokra, érti a különbséget a hulladék és a szemét fogalmi megkülönböztetése között, ismeri a hulladékok típusait, kezelésük módját, környezetre gyakorolt hatásait.',
		b: 9,
		c: 1256,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'globális problémák kémiai vonatkozásai'])
	},
		{
		a: 'Ismeri a természetes vizek típusait, azok legfontosabb kémiai összetevőit a víz körforgásának és tulajdonságainak tükrében, példákat mond vízszennyező anyagokra, azok forrására, a szennyezés lehetséges következményeire, ismeri a víztisztítás folyamatának alapvető lépéseit, valamint a tiszta ivóvíz előállításának a módját.',
		b: 9,
		c: 1255,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'globális problémák kémiai vonatkozásai'])
	},
		{
		a: 'Ismeri a légkör kémiai összetételét és az azt alkotó gázok fontosabb tulajdonságait, példákat mond a légkör élőlényekre és élettelen környezetre gyakorolt hatásaira, ismeri a legfontosabb légszennyező gázokat, azok alapvető tulajdonságait, valamint a környezetszennyezésének hatásait, ismeri a légkört érintő globális környezeti problémák kémiai hátterét és ezen problémák megoldására tett erőfeszítéseket.',
		b: 9,
		c: 1254,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'globális problémák kémiai vonatkozásai'])
	},
		{
		a: 'Kiselőadás vagy projektmunka keretében mutatja be a 20. század néhány nagy környezeti katasztrófáját és azt, hogy milyen tanulságokat vonhatunk le azok megismeréséből.',
		b: 9,
		c: 1253,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'globális problémák kémiai vonatkozásai'])
	},
		{
		a: 'Példákon keresztül szemlélteti az antropogén tevékenységek kémiai vonatkozású környezeti következményeit.',
		b: 9,
		c: 1252,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'globális problémák kémiai vonatkozásai'])
	},
		{
		a: 'Ismeri az emberiség előtt álló legnagyobb kihívásokat, kiemelten azok kémiai vonatkozásai (energiahordozók, környezetszennyezés, fenntarthatóság, új anyagok előállítása).',
		b: 9,
		c: 1251,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'globális problémák kémiai vonatkozásai'])
	},
		{
		a: 'Példákkal szemlélteti az emberiség legégetőbb globális problémáit (globális éghajlatváltozás, ózonlyuk, ivóvízkészlet csökkenése, energiaforrások kimerülése), és azok kémiai vonatkozásait.',
		b: 9,
		c: 1250,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'globális problémák kémiai vonatkozásai'])
	},
		{
		a: 'Ismeri a bioüzemanyagok legfontosabb típusait.',
		b: 9,
		c: 1249,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'energiagazdálkodás: energiaforrások és energiahordozók megismerése és összehasonlítása'])
	},
		{
		a: 'Példákkal szemlélteti egyes kémiai technológiák, illetve bizonyos anyagok felhasználásának környezetre gyakorolt pozitív és negatív hatásait.',
		b: 9,
		c: 1248,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: veszélyes és különleges anyagok, nyersanyagok, anyagkörforgalom'])
	},
		{
		a: 'Ismeri a legfontosabb környezetszennyező forrásokat és anyagokat, valamint ezeknek az anyagoknak a környezetre gyakorolt hatását.',
		b: 9,
		c: 1247,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: veszélyes és különleges anyagok, nyersanyagok, anyagkörforgalom'])
	},
		{
		a: 'Ismeri a zöld kémia lényegét, a környezetbarát folyamatok előtérbe helyezését, példákat mond újonnan előállított, az emberiség jólétét befolyásoló anyagokra (pl. új gyógyszerek, lebomló műanyagok, intelligens textíliák).',
		b: 9,
		c: 1246,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: veszélyes és különleges anyagok, nyersanyagok, anyagkörforgalom'])
	},
		{
		a: 'Érti a környezetünk megóvásának a jelentőségét az emberi civilizáció fennmaradása szempontjából.',
		b: 9,
		c: 1245,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: veszélyes és különleges anyagok, nyersanyagok, anyagkörforgalom'])
	},
		{
		a: 'Alapvető szinten ismeri a természetes környezetet felépítő légkör, vízburok, kőzetburok és élővilág kémiai összetételét.',
		b: 9,
		c: 1244,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: veszélyes és különleges anyagok, nyersanyagok, anyagkörforgalom'])
	},
		{
		a: 'Mobiltelefonos, táblagépes alkalmazások segítségével médiatartalmakat, illetve bemutatókat hoz létre.',
		b: 9,
		c: 1243,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a digitális kompetencia fejlesztése', 'alkotás digitális eszközökkel'])
	},
		{
		a: 'A különböző, megbízható forrásokból gyűjtött információkat számítógépes prezentációban mutatja be.',
		b: 9,
		c: 1242,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a digitális kompetencia fejlesztése', 'alkotás digitális eszközökkel'])
	},
		{
		a: 'Magabiztosan használ magyar és idegen nyelvű mobiltelefonos, táblagépes applikációkat kémiai tárgyú információk keresésére.',
		b: 9,
		c: 1241,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a digitális kompetencia fejlesztése', 'szövegek, képek, adatok keresése, értelmezése, kritikus és etikus felhasználása'])
	},
		{
		a: 'Ismer megbízható magyar és idegen nyelvű internetes forrásokat kémiai tárgyú, elemekkel és vegyületekkel kapcsolatos képek és szövegek gyűjtésére.',
		b: 9,
		c: 1240,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a digitális kompetencia fejlesztése', 'szövegek, képek, adatok keresése, értelmezése, kritikus és etikus felhasználása'])
	},
		{
		a: 'Használja a fémek redukáló sorát a fémek tulajdonságainak megjóslására, tulajdonságaik alátámasztására.',
		b: 9,
		c: 1239,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a természettudományos gondolkodás alapvető műveletei'])
	},
		{
		a: 'Analógiás gondolkodással következtet a szerves vegyület tulajdonságára a funkciós csoportja ismeretében.',
		b: 9,
		c: 1238,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a természettudományos gondolkodás alapvető műveletei'])
	},
		{
		a: 'Ismeri az anyagmennyiség és a mól fogalmát, érti bevezetésük szükségességét és egyszerű számításokat végez m, n és m segítségével.',
		b: 9,
		c: 1237,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a természettudományos gondolkodás alapvető műveletei'])
	},
		{
		a: 'Kémiai vizsgálatainak tervezése során alkalmazza az analógiás gondolkodás alapjait és használja az „egyszerre csak egy tényezőt változtatunk\" elvet.',
		b: 9,
		c: 1236,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a természettudományos gondolkodás alapvető műveletei'])
	},
		{
		a: 'Egyedül vagy csoportban elvégez összetettebb, halmazállapot-változással és oldódással kapcsolatos kísérleteket és megbecsüli azok várható eredményét.',
		b: 9,
		c: 1235,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a kémiai kísérlet mint megismerési módszer'])
	},
		{
		a: 'Egyedül vagy csoportban elvégez egyszerű kémiai kísérleteket leírás vagy szóbeli útmutatás alapján és értékeli azok eredményét.',
		b: 9,
		c: 1234,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a kémiai kísérlet mint megismerési módszer'])
	},
		{
		a: 'Tisztában van a háztartásban leggyakrabban előforduló anyagok felhasználásának előnyeivel és veszélyeivel, a biztonságos vegyszerhasználat szabályaival.',
		b: 9,
		c: 1233,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismeri a kémiának az egyén és a társadalom életében betöltött szerepét.',
		b: 9,
		c: 1232,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tudja használni a részecskemodellt az anyagok tulajdonságainak és átalakulásainak értelmezésére.',
		b: 9,
		c: 1231,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Képes számítógépes prezentáció formájában kémiával kapcsolatos eredmények, információk bemutatására, megosztására, a mérési adatok számítógépes feldolgozására, szemléltetésére.',
		b: 9,
		c: 1230,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Képes az analógiás, a korrelatív és a mérlegelő gondolkodásra kémiai kontextusban.',
		b: 9,
		c: 1229,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Alkalmazza a természettudományos problémamegoldás lépéseit egyszerű kémiai problémák megoldásában.',
		b: 9,
		c: 1228,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Önállóan vagy csoportban el tud végezni egyszerű kémiai kísérleteket és megbecsüli azok várható eredményét.',
		b: 9,
		c: 1227,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismeri a mindennapi életben előforduló fontosabb vegyülettípusokat, tisztában van az élettelen és élő természet legfontosabb kémiai fogalmaival, jelenségeivel és az azokat működtető reakciótípusokkal.',
		b: 9,
		c: 1226,
		d: 10,
		e: 'Kémia@9-10',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Megfigyeli a növények csírázásának és növekedésének feltételeit, ezekre vonatkozóan egyszerű kísérletet végez.',
		b: 3,
		c: 1415,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Megfigyeli a mozgások sokféleségét, csoportosítja a mozgásformákat: hely- és helyzetváltoztató mozgás.',
		b: 3,
		c: 1414,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Megnevezi az időjárás fő elemeit, időjárási megfigyeléseket tesz, méréseket végez.',
		b: 3,
		c: 1413,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Megfogalmazza a tűz és az égés szerepét az ember életében.',
		b: 3,
		c: 1412,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Tanítói segítséggel égéssel kapcsolatos egyszerű kísérleteket végez, csoportosítja a megvizsgált éghető és éghetetlen anyagokat.',
		b: 3,
		c: 1411,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Megismeri és modellezi a víz természetben megtett útját, felismeri a folyamat ciklikus jellegét.',
		b: 3,
		c: 1410,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Egyszerű kísérletek során megfigyeli a halmazállapot-változásokat: fagyás, olvadás, forrás, párolgás, lecsapódás.',
		b: 3,
		c: 1409,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Azonosítja az anyagok halmazállapotait, megnevezi és összehasonlítja azok alapvető jellemzőit.',
		b: 3,
		c: 1408,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Adott szempontok alapján élettelen anyagokat és élőlényeket összehasonlít, csoportosít.',
		b: 3,
		c: 1407,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Felismeri az élőlényeken, élettelen anyagokon az érzékelhető és mérhető tulajdonságokat.',
		b: 3,
		c: 1406,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'anyagok és folyamatok', 'az élőlények és az élettelen anyagok tulajdonságai'])
	},
		{
		a: 'Felelősségtudattal rendelkezik a szűkebb, illetve tágabb környezete iránt.',
		b: 3,
		c: 1405,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember és környezete'])
	},
		{
		a: 'Elsajátít olyan szokásokat és viselkedésformákat, amelyek a károsítások megelőzésére irányulnak (pl. hulladékminimalizálás -- anyagtakarékosság, újrahasználat és -felhasználás, tömegközlekedés, gyalogos vagy kerékpáros közlekedés előnyben részesítése, energiatakarékosság).',
		b: 3,
		c: 1404,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember és környezete'])
	},
		{
		a: 'Felismeri, mely anyagok szennyezhetik környezetünket a mindennapi életben, mely szokások vezetnek környezetünk károsításához, egyéni és közösségi környezetvédelmi cselekvési formákat ismer meg és gyakorol közvetlen környezetében (pl. madárbarát kert, iskolakert kiépítésében, fenntartásában való részvétel, iskolai környezet kialakításában, rendben tartásában való részvétel, települési természet- és környezetvédelmi tevékenységben való részvétel).',
		b: 3,
		c: 1403,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember és környezete'])
	},
		{
		a: 'Tisztában van az egészséges életmód alapelveivel, összetevőivel, az emberi szervezet egészséges testi és lelki fejlődéséhez szükséges szokásokkal,azokat igyekszik betartani.',
		b: 3,
		c: 1402,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember és környezete'])
	},
		{
		a: 'Felismeri az egészséges, gondozott környezet jellemzőit, megfogalmazza, milyen hatással van a környezet az egészségére.',
		b: 3,
		c: 1401,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember és környezete'])
	},
		{
		a: 'Ismer betegségeket, felismeri a legjellemzőbb betegségtüneteket, a betegségek megelőzésének alapvető módjait.',
		b: 3,
		c: 1400,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember szervezete'])
	},
		{
		a: 'Belátja az érzékszervek védelmének fontosságát, és ismeri ezek eszközeit, módjait.',
		b: 3,
		c: 1399,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember szervezete'])
	},
		{
		a: 'Megnevezi az érzékszerveket és azok szerepét a megismerési folyamatokban.',
		b: 3,
		c: 1398,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember szervezete'])
	},
		{
		a: 'Megnevezi az emberi test részeit, fő szerveit, ismeri ezek működését, szerepét.',
		b: 3,
		c: 1397,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember szervezete'])
	},
		{
		a: 'Ismeri az emberi szervezet fő életfolyamatait.',
		b: 3,
		c: 1396,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember szervezete'])
	},
		{
		a: 'Megnevezi az ember életszakaszait.',
		b: 3,
		c: 1395,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'az ember szervezete'])
	},
		{
		a: 'Egyéni és közösségi környezetvédelmi cselekvési formákat ismer meg és gyakorol közvetlen környezetében.',
		b: 3,
		c: 1394,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Példákkal mutatja be az emberi tevékenység természeti környezetre gyakorolt hatását, felismeri a természetvédelem jelentőségét.',
		b: 3,
		c: 1393,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Felismeri az egyes életközösségek növényei és állatai közötti jellegzetes kapcsolatokat.',
		b: 3,
		c: 1392,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Felismeri, hogy az egyes fajok környezeti igényei eltérőek.',
		b: 3,
		c: 1391,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Megfigyeléseit mérésekkel (például időjárási elemek, testméret), modellezéssel, egyszerű kísérletek végzésével (például láb- és csőrtípusok) egészíti ki.',
		b: 3,
		c: 1390,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Felismeri a lakóhelyéhez közeli életközösségek és az ott élő élőlények közötti különbségeket (pl. természetes -- mesterséges életközösség, erdő -- mező, rét -- víz, vízpart -- park, díszkert -- zöldséges, gyümölcsöskert esetében).',
		b: 3,
		c: 1389,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Konkrét példán keresztül megfigyeli és felismeri az élőhely, életmód és testfelépítés kapcsolatát.',
		b: 3,
		c: 1388,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Megnevezi a megismert életközösségekre jellemző élőlényeket, használja az életközösségekhez kapcsolódó kifejezéseket.',
		b: 3,
		c: 1387,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Algoritmus alapján megfigyeli és összehasonlítja hazánk természetes és mesterséges élőhelyein, életközösségeiben élő növények és állatok jellemzőit, a megfigyelt jellemzőik alapján csoportokba rendezi azokat.',
		b: 3,
		c: 1386,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Ismeri a lakóhelyéhez közeli életközösségek (erdő, mező-rét, víz-vízpart) főbb jellemzőit.',
		b: 3,
		c: 1385,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Algoritmus alapján megfigyeli és összehasonlítja a saját lakókörnyezetében fellelhető, jellemző növények és állatok jellemzőit, a megfigyelt tulajdonságok alapján csoportokba rendezi azokat.',
		b: 3,
		c: 1384,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'életközösségek'])
	},
		{
		a: 'Felismeri, megnevezi és megfigyeli egy konkrét állat választott részeit, algoritmus alapján a részek tulajdonságait, megfogalmazza, mi a megismert rész szerepe az állat életében.',
		b: 3,
		c: 1383,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'a növények és az állatok'])
	},
		{
		a: 'Növényt ültet és gondoz, megfigyeli a fejlődését, tapasztalatait rajzos formában rögzíti.',
		b: 3,
		c: 1382,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'a növények és az állatok'])
	},
		{
		a: 'Felismeri, megnevezi és megfigyeli egy konkrét növény választott részeit, algoritmus alapján a részek tulajdonságait, megfogalmazza, mi a növényi részek szerepe a növény életében.',
		b: 3,
		c: 1381,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'a növények és az állatok'])
	},
		{
		a: 'Felismeri, megnevezi és megfigyeli az életfeltételeket, életjelenségeket.',
		b: 3,
		c: 1380,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'élő környezet', 'a növények és az állatok'])
	},
		{
		a: 'Térkép segítségével megmutatja hazánk nagytájait, felismeri azok jellemző felszínformáit.',
		b: 3,
		c: 1379,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['topográfiai ismeretek', 'környezetismeret', 'tájékozódás a térben'])
	},
		{
		a: 'Térkép segítségével megnevezi magyarország jellemző felszínformáit (síkság, hegy, hegység, domb, dombság), vizeit (patak, folyó, tó), ezeket terepasztalon vagy saját készítésű modellen előállítja.',
		b: 3,
		c: 1378,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['topográfiai ismeretek', 'környezetismeret', 'tájékozódás a térben'])
	},
		{
		a: 'Térkép segítségével megnevezi hazánk szomszédos országait, megyéit, saját megyéjét, megyeszékhelyét, környezetének nagyobb településeit, hazánk fővárosát, és ezeket megtalálja a térképen is.',
		b: 3,
		c: 1377,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['topográfiai ismeretek', 'környezetismeret', 'tájékozódás a térben'])
	},
		{
		a: 'Domborzati térképen felismeri a felszínformák és vizek jelölését.',
		b: 3,
		c: 1376,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'Felismeri lakóhelyének jellegzetes felszínformáit.',
		b: 3,
		c: 1375,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'Felismeri a különböző domborzati formákat, felszíni vizeket, ismeri jellemzőiket, ezeket terepasztalon vagy saját készítésű modellen előállítja.',
		b: 3,
		c: 1374,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'Tájékozódik az iskola környékéről és településéről készített térképvázlattal és térképpel, az iskola környezetéről egyszerű térképvázlatot készít.',
		b: 3,
		c: 1373,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'A tanterméről, otthona valamely helyiségéről egyszerű alaprajzot készít és leolvas.',
		b: 3,
		c: 1372,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'Felismeri és használja az alapvető térképjeleket: felszínformák, vizek, települések, útvonalak, államhatárok.',
		b: 3,
		c: 1371,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'Megkülönböztet néhány térképfajtát: domborzati, közigazgatási, turista, autós.',
		b: 3,
		c: 1370,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'Irányokat ad meg viszonyítással.',
		b: 3,
		c: 1369,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'Megnevezi és iránytű segítségével megállapítja a fő- és mellékvilágtájakat.',
		b: 3,
		c: 1368,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'Ismeri és használja az életkorának megfelelő térbeli relációs szókincset.',
		b: 3,
		c: 1367,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'tájékozódás a térben', 'térképészeti ismeretek'])
	},
		{
		a: 'Analóg és digitális óráról leolvassa a pontos időt.',
		b: 3,
		c: 1366,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['tájékozódás az időben', 'környezetismeret'])
	},
		{
		a: 'Napirendet tervez és használ.',
		b: 3,
		c: 1365,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['tájékozódás az időben', 'környezetismeret'])
	},
		{
		a: 'Naptárt használ, időintervallumokat számol, adott eseményeket időrend szerint sorba rendez.',
		b: 3,
		c: 1364,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['tájékozódás az időben', 'környezetismeret'])
	},
		{
		a: 'Figyelemmel kísér rövidebb-hosszabb ideig tartó folyamatokat (például víz körforgása, emberi élet szakaszai, növények csírázása, növekedése).',
		b: 3,
		c: 1363,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['tájékozódás az időben', 'környezetismeret'])
	},
		{
		a: 'Az évszakokra vonatkozó megfigyeléseket végez, tapasztalatait rögzíti, és az adatokból következtetéseket von le.',
		b: 3,
		c: 1362,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['tájékozódás az időben', 'környezetismeret'])
	},
		{
		a: 'Felismeri a napszakok, évszakok változásai, valamint a föld mozgásai közötti összefüggéseket.',
		b: 3,
		c: 1361,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['tájékozódás az időben', 'környezetismeret'])
	},
		{
		a: 'Megfelelő sorrendben sorolja fel a napszakokat, a hét napjait, a hónapokat, az évszakokat, ismeri ezek időtartamát, relációit.',
		b: 3,
		c: 1360,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['tájékozódás az időben', 'környezetismeret'])
	},
		{
		a: 'Megfelelően eligazodik az időbeli relációkban, ismeri és használja az életkorának megfelelő időbeli relációs szókincset.',
		b: 3,
		c: 1359,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['tájékozódás az időben', 'környezetismeret'])
	},
		{
		a: 'A feladatvégzés során társaival együttműködik.',
		b: 3,
		c: 1358,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'kísérletezés'])
	},
		{
		a: 'A vizsgálatok tapasztalatait megfogalmazza, rajzban, írásban rögzíti.',
		b: 3,
		c: 1357,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'kísérletezés'])
	},
		{
		a: 'Figyelemmel kísér rövidebb-hosszabb ideig tartó folyamatokat (például a természet változásai, időjárási elemek).',
		b: 3,
		c: 1356,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'kísérletezés'])
	},
		{
		a: 'A kísérletek tapasztalatait a mindennapi életben alkalmazza.',
		b: 3,
		c: 1355,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'kísérletezés'])
	},
		{
		a: 'Az adott kísérlethez választott eszközöket megfelelően használja.',
		b: 3,
		c: 1354,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'kísérletezés'])
	},
		{
		a: 'A tanító által felvetett problémákkal kapcsolatosan hipotézist fogalmaz meg, a vizsgálatok eredményét összeveti hipotézisével.',
		b: 3,
		c: 1353,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'kísérletezés'])
	},
		{
		a: 'A kísérletezés elemi lépéseit annak algoritmusa szerint megvalósítja.',
		b: 3,
		c: 1352,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'kísérletezés'])
	},
		{
		a: 'Tanítói segítséggel egyszerű kísérleteket végez.',
		b: 3,
		c: 1351,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'kísérletezés'])
	},
		{
		a: 'A méréseket és azok tapasztalatait a mindennapi életben alkalmazza.',
		b: 3,
		c: 1350,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'mérés'])
	},
		{
		a: 'A mérésekhez kapcsolódó ismereteit felidézi.',
		b: 3,
		c: 1349,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'mérés'])
	},
		{
		a: 'Az adott alkalmi vagy szabvány mérőeszközt megfelelően használja.',
		b: 3,
		c: 1348,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'mérés'])
	},
		{
		a: 'A méréshez megválasztja az alkalmi vagy szabvány mérőeszközt, mértékegységet.',
		b: 3,
		c: 1347,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'mérés'])
	},
		{
		a: 'Algoritmus szerint, előzetes viszonyítás, majd becslés után méréseket végez, becsült és mért eredményeit összehasonlítja.',
		b: 3,
		c: 1346,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'mérés'])
	},
		{
		a: 'Felismeri az élettelen anyagokon és az élőlényeken a mérhető tulajdonságokat.',
		b: 3,
		c: 1345,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'mérés'])
	},
		{
		a: 'A megfigyelésekhez, összehasonlításokhoz és csoportosításokhoz kapcsolódó ismereteit felidézi.',
		b: 3,
		c: 1344,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'megfigyelés, összehasonlítás, csoportosítás'])
	},
		{
		a: 'Figyelemmel kísér rövidebb-hosszabb ideig tartó folyamatokat (például olvadás, forrás, fagyás, párolgás, lecsapódás, égés, ütközés).',
		b: 3,
		c: 1343,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'megfigyelés, összehasonlítás, csoportosítás'])
	},
		{
		a: 'Megfigyeléseinek, összehasonlításainak és csoportosításainak tapasztalatait szóban, rajzban, írásban rögzíti, megfogalmazza.',
		b: 3,
		c: 1342,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'megfigyelés, összehasonlítás, csoportosítás'])
	},
		{
		a: 'Adott szempontok alapján képes élőlényeket összehasonlítani, csoportosítani.',
		b: 3,
		c: 1341,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'megfigyelés, összehasonlítás, csoportosítás'])
	},
		{
		a: 'Adott szempontok alapján képes élettelen anyagokat összehasonlítani, csoportosítani.',
		b: 3,
		c: 1340,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'megfigyelés, összehasonlítás, csoportosítás'])
	},
		{
		a: 'Összehasonlítja az élőlényeket és az élettelen anyagokat.',
		b: 3,
		c: 1339,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'megfigyelés, összehasonlítás, csoportosítás'])
	},
		{
		a: 'Felismeri az élőlényeken, élettelen dolgokon az érzékelhető tulajdonságokat.',
		b: 3,
		c: 1338,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'megfigyelés, összehasonlítás, csoportosítás'])
	},
		{
		a: 'Adott szempontok alapján algoritmus szerint élettelen anyagokon és élőlényeken megfigyeléseket végez.',
		b: 3,
		c: 1337,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['környezetismeret', 'megismerési módszerek', 'megfigyelés, összehasonlítás, csoportosítás'])
	},
		{
		a: 'Szöveggel, táblázattal és jelekkel adott információkat értelmez.',
		b: 3,
		c: 1336,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'környezetismeret'])
	},
		{
		a: 'Felismeri a helyi természet- és környezetvédelmi problémákat.',
		b: 3,
		c: 1335,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'környezetismeret'])
	},
		{
		a: 'Projektmunkában, csoportos tevékenységekben vesz részt.',
		b: 3,
		c: 1334,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'környezetismeret'])
	},
		{
		a: 'Megfigyelés, mérés és kísérletezés közben szerzett tapasztalatairól szóban, rajzban, írásban beszámol.',
		b: 3,
		c: 1333,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'környezetismeret'])
	},
		{
		a: 'Ismeretei bővítéséhez nyomtatott és digitális forrásokat használ.',
		b: 3,
		c: 1332,
		d: 4,
		e: 'Környezetismeret@3-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'környezetismeret'])
	},
		{
		a: 'Megismeri saját lakóhelyének irodalmi és kulturális értékeit.',
		b: 1,
		c: 1551,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kultúra helyszínei'])
	},
		{
		a: 'Megismer a szűkebb környezetéhez kötődő irodalmi és kulturális emlékeket, emlékhelyeket.',
		b: 1,
		c: 1550,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kultúra helyszínei'])
	},
		{
		a: 'Részt vesz gyerekeknek szóló kiállítások megismerésében, alkotásaival hozzájárul létrehozásukhoz.',
		b: 1,
		c: 1549,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kultúra helyszínei'])
	},
		{
		a: 'Megismer gyermekirodalmi alkotás alapján készült filmet, médiaterméket.',
		b: 1,
		c: 1548,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kultúra helyszínei'])
	},
		{
		a: 'Ismerkedik régi magyar mesterségekkel, irodalmi művek olvasásával és gyűjtőmunkával.',
		b: 1,
		c: 1547,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kulturális emlékezethez és nemzeti hagyományhoz kapcsolódó szövegek'])
	},
		{
		a: 'Ismeri a keresztény, keresztyén ünnepköröket (karácsony, húsvét, pünkösd), jelképeket, nemzeti és állami ünnepeket (március 15., augusztus 20., október 23.), népszokásokat (márton-nap, luca-nap, betlehemezés, húsvéti locsolkodás, pünkösdölés).',
		b: 1,
		c: 1546,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kulturális emlékezethez és nemzeti hagyományhoz kapcsolódó szövegek'])
	},
		{
		a: 'Törekszik a világ tapasztalati úton történő megismerésére, értékeinek tudatos megóvására.',
		b: 1,
		c: 1545,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kulturális emlékezethez és nemzeti hagyományhoz kapcsolódó szövegek'])
	},
		{
		a: 'Képes családjából származó közösségi élményeit megfogalmazni, összevetni az iskolai élet adottságaival, a témakört érintő beszélgetésekben aktívan részt venni.',
		b: 1,
		c: 1544,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kulturális emlékezethez és nemzeti hagyományhoz kapcsolódó szövegek'])
	},
		{
		a: 'Nyitottá válik a magyarság értékeinek megismerésére, megalapozódik nemzeti identitástudata, történelmi szemlélete.',
		b: 1,
		c: 1543,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kulturális emlékezethez és nemzeti hagyományhoz kapcsolódó szövegek'])
	},
		{
		a: 'Megfigyeli az ünnepek, hagyományok éves körforgását.',
		b: 1,
		c: 1542,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kulturális emlékezethez és nemzeti hagyományhoz kapcsolódó szövegek'])
	},
		{
		a: 'Megismer a jeles napokhoz, ünnepekhez kapcsolódó szövegeket, dalokat, szokásokat, népi gyermekjátékokat.',
		b: 1,
		c: 1541,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kulturális emlékezethez és nemzeti hagyományhoz kapcsolódó szövegek'])
	},
		{
		a: 'Megéli és az általa választott formában megjeleníti a közösséghez tartozás élményét.',
		b: 1,
		c: 1540,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kulturális emlékezethez és nemzeti hagyományhoz kapcsolódó szövegek'])
	},
		{
		a: 'Jellemző és ismert részletek alapján azonosítja a nemzeti ünnepeken elhangzó költemények részleteit, szerzőjüket megnevezi.',
		b: 1,
		c: 1539,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'a kulturális emlékezethez és nemzeti hagyományhoz kapcsolódó szövegek'])
	},
		{
		a: 'Részt vesz legalább két hosszabb terjedelmű magyar gyermekirodalmi alkotás feldolgozásában.',
		b: 1,
		c: 1538,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'alkotók, művek a gyermekirodalomból'])
	},
		{
		a: 'A tanult verseket, mondókákat, rövidebb szövegeket szöveghűen, érthetően tolmácsolja.',
		b: 1,
		c: 1537,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'alkotók, művek a gyermekirodalomból'])
	},
		{
		a: 'Segítséggel, majd önállóan szöveghűen felidéz néhány könnyen tanulható, rövidebb verset, mondókát, versrészletet, prózai és dramatikus szöveget, szövegrészletet.',
		b: 1,
		c: 1536,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'alkotók, művek a gyermekirodalomból'])
	},
		{
		a: 'Élményt és tapasztalatot szerez különböző ritmikájú lírai művek megismerésével a kortárs és a klasszikus magyar gyermeklírából és a népköltészeti alkotásokból.',
		b: 1,
		c: 1535,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'alkotók, művek a gyermekirodalomból'])
	},
		{
		a: 'Megismer néhány klasszikus verset a magyar irodalomból.',
		b: 1,
		c: 1534,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'alkotók, művek a gyermekirodalomból'])
	},
		{
		a: 'Megismer néhány mesét és történetet a magyar és más népek irodalmából.',
		b: 1,
		c: 1533,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'alkotók, művek a gyermekirodalomból'])
	},
		{
		a: 'Részt vesz rövid mesék, történetek dramatikus, bábos és egyéb vizuális, digitális eszközökkel történő megjelenítésében, saját gondolkodási és nyelvi szintjén megfogalmazza a szöveg hatására benne kialakult képet.',
		b: 1,
		c: 1532,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Szövegszerűen felidézi kölcsey ferenc: himnusz, vörösmarty mihály: szózat, petőfi sándor: nemzeti dal című verseinek részleteit.',
		b: 1,
		c: 1531,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Felismeri, indokolja a cím és a szöveg közötti összefüggést, azonosítja a történetekben, elbeszélő költeményekben a helyszínt, a szereplőket, a konfliktust és annak megoldását.',
		b: 1,
		c: 1530,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'A tanító vagy társai segítségével, együttműködésével verssorokat, versrészleteket memorizál.',
		b: 1,
		c: 1529,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'A versek hangulatát kifejezi különféle érzékszervi tapasztalatok segítségével (színek, hangok, illatok, tapintási élmények stb.).',
		b: 1,
		c: 1528,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Érzékeli és átéli a vers ritmusát és hangulatát.',
		b: 1,
		c: 1527,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Megtapasztalja a vershallgatás, a versmondás, a versolvasás örömét és élményét.',
		b: 1,
		c: 1526,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Olvas és megért rövidebb nép- és műköltészeti alkotásokat, rövidebb epikai műveket, verseket.',
		b: 1,
		c: 1525,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Segítséggel vagy önállóan előad ritmuskísérettel verseket.',
		b: 1,
		c: 1524,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Részt vesz ismert szövegek (magyar népi mondókák, kiszámolók, nyelvtörők, népdalok, klasszikus és kortárs magyar gyerekversek, mesék) mozgásos-játékos feldolgozásában, dramatikus elemekkel történő élményszerű megjelenítésében, érzületileg, lelkületileg átérzi azokat.',
		b: 1,
		c: 1523,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Megfigyeli a költői nyelv sajátosságait; élményeit az általa választott módon megfogalmazza, megjeleníti.',
		b: 1,
		c: 1522,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Megtapasztalja az életkorának, érdeklődésének megfelelő szövegek befogadásának és előadásának élményét és örömét.',
		b: 1,
		c: 1521,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'A közös olvasás, szövegfeldolgozás során megismer néhány életkorának megfelelő mesét, elbeszélést.',
		b: 1,
		c: 1520,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Részt vesz különböző műfajú és megjelenésű szövegek olvasásában és feldolgozásában.',
		b: 1,
		c: 1519,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Élményeket és tapasztalatokat szerez néhány szövegtípusról és műfajról, szépirodalmi és ismeretközlő szövegről.',
		b: 1,
		c: 1518,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Élményeket és tapasztalatokat szerez változatos irodalmi szövegtípusok és műfajok -- magyar klasszikus, kortárs magyar alkotások -- megismerésével.',
		b: 1,
		c: 1517,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek', 'az irodalmi nyelv sajátosságai, irodalmi műfajok'])
	},
		{
		a: 'Különféle módokon megjeleníti az ismert szólások, közmondások jelentését.',
		b: 1,
		c: 1516,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'állandósult szókapcsolatok, szólások, közmondások'])
	},
		{
		a: 'Megérti és használja az ismert állandósult szókapcsolatokat.',
		b: 1,
		c: 1515,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'állandósult szókapcsolatok, szólások, közmondások'])
	},
		{
		a: 'Ismer és ért számos egyszerű közmondást és szólást, szóláshasonlatot, közmondást, találós kérdést, nyelvtörőt, kiszámolót, mondókát.',
		b: 1,
		c: 1514,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'állandósult szókapcsolatok, szólások, közmondások'])
	},
		{
		a: 'Megkülönbözteti a múltban, jelenben és jövőben zajló cselekvéseket, történéseket.',
		b: 1,
		c: 1513,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'Felismeri, önállóan vagy segítséggel helyesen leírja az ismert cselekvést kifejező szavakat.',
		b: 1,
		c: 1512,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'Felismeri és önállóan vagy segítséggel helyesen leírja a tulajdonságot kifejező szavakat és azok fokozott alakjait.',
		b: 1,
		c: 1511,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'Megkülönbözteti a szavak egyes és többes számát.',
		b: 1,
		c: 1510,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'Ellentétes jelentésű és rokon értelmű kifejezéseket gyűjt, azokat a beszédhelyzetnek megfelelően használja az írásbeli és szóbeli szövegalkotásban.',
		b: 1,
		c: 1509,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'A kiejtéstől eltérő ismert szavakat megfigyelés, szóelemzés alkalmazásával megfelelően leírja.',
		b: 1,
		c: 1508,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'A kiejtéssel megegyező rövid szavak leírásában követi a helyesírás szabályait.',
		b: 1,
		c: 1507,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'Helyesen alkalmazza a szóbeli és írásbeli szövegalkotásában az idő kifejezésének nyelvi eszközeit.',
		b: 1,
		c: 1506,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'Biztosan szótagol, alkalmazza az elválasztás szabályait.',
		b: 1,
		c: 1505,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'Önállóan felismeri és elkülöníti az egytövű ismert szavakban a szótövet és a toldalékot.',
		b: 1,
		c: 1504,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'Kérdésre adott válaszában helyesen toldalékolja a szavakat.',
		b: 1,
		c: 1503,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'a nyelvi, nyelvtani egységek, nyelvi elemzőkészség'])
	},
		{
		a: 'Törekszik a tanult helyesírási ismeretek alkalmazására.',
		b: 1,
		c: 1502,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'hangtani, alaktani és helyesírási tudatosság'])
	},
		{
		a: 'A személyneveket, állatneveket és a lakóhelyhez kötődő helyneveket nagy kezdőbetűvel írja le.',
		b: 1,
		c: 1501,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'hangtani, alaktani és helyesírási tudatosság'])
	},
		{
		a: 'A több hasonló élőlény, tárgy nevét kis kezdőbetűvel írja.',
		b: 1,
		c: 1500,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'hangtani, alaktani és helyesírási tudatosság'])
	},
		{
		a: 'Felismeri, jelentésük alapján csoportosítja, és önállóan vagy segítséggel helyesen leírja az élőlények, tárgyak, gondolati dolgok nevét.',
		b: 1,
		c: 1499,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'hangtani, alaktani és helyesírási tudatosság'])
	},
		{
		a: 'Biztosan ismeri a kis- és nagybetűs ábécét, azonos és különböző betűkkel kezdődő szavakat betűrendbe sorol; a megismert szabályokat alkalmazza digitális felületen való kereséskor is.',
		b: 1,
		c: 1498,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'hangtani, alaktani és helyesírási tudatosság'])
	},
		{
		a: 'A mondatot nagybetűvel kezdi, alkalmazza a mondat hanglejtésének, a beszélő szándékának megfelelő mondatvégi írásjeleket.',
		b: 1,
		c: 1497,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'hangtani, alaktani és helyesírási tudatosság'])
	},
		{
		a: 'A hangjelölés megismert szabályait jellemzően helyesen alkalmazza a tanult szavakban.',
		b: 1,
		c: 1496,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'hangtani, alaktani és helyesírási tudatosság'])
	},
		{
		a: 'Különbséget tesz az egyjegyű, a kétjegyű és a háromjegyű betűk között.',
		b: 1,
		c: 1495,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'hangtani, alaktani és helyesírási tudatosság'])
	},
		{
		a: 'Megfigyeli, és tapasztalati úton megkülönbözteti egymástól a magánhangzókat és a mássalhangzókat, valamint időtartamukat.',
		b: 1,
		c: 1494,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek', 'hangtani, alaktani és helyesírási tudatosság'])
	},
		{
		a: 'Írásbeli munkáját segítséggel vagy önállóan ellenőrzi és javítja.',
		b: 1,
		c: 1493,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'önálló feladatvégzés'])
	},
		{
		a: 'Bővíti a témáról szerzett ismereteit egyéb források feltárásával, gyűjtőmunkával, könyvtárhasználattal, filmek, médiatermékek megismerésével.',
		b: 1,
		c: 1492,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'önálló feladatvégzés'])
	},
		{
		a: 'Információkat, adatokat gyűjt a szövegből, kiemeli a bekezdések lényegét; tanítói segítséggel vagy önállóan megfogalmazza azt.',
		b: 1,
		c: 1491,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'önálló feladatvégzés'])
	},
		{
		a: 'Gyakorolja az ismeretfeldolgozás egyszerű technikáit.',
		b: 1,
		c: 1490,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'önálló feladatvégzés'])
	},
		{
		a: 'Ismer és alkalmaz néhány alapvető tanulási technikát.',
		b: 1,
		c: 1489,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'önálló feladatvégzés'])
	},
		{
		a: 'Képzeletét a megértés érdekében mozgósítja.',
		b: 1,
		c: 1488,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'önálló feladatvégzés'])
	},
		{
		a: 'A feladatvégzéshez szükséges személyes élményeit, előzetes tudását felidézi.',
		b: 1,
		c: 1487,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'önálló feladatvégzés'])
	},
		{
		a: 'Részt vesz dramatikus játékokban.',
		b: 1,
		c: 1486,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'kulturált véleménynyilvánítás, vitakultúra'])
	},
		{
		a: 'Megfigyeli és összehasonlítja a történetek tartalmát és a saját élethelyzetét.',
		b: 1,
		c: 1485,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'kulturált véleménynyilvánítás, vitakultúra'])
	},
		{
		a: 'Különbséget tesz mesés és valószerű történetek között.',
		b: 1,
		c: 1484,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'kulturált véleménynyilvánítás, vitakultúra'])
	},
		{
		a: 'Megfogalmazza, néhány érvvel alátámasztja saját álláspontját; meghallgatja társai véleményét.',
		b: 1,
		c: 1483,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'kulturált véleménynyilvánítás, vitakultúra'])
	},
		{
		a: 'A mesék, történetek szereplőinek cselekedeteiről kérdéseket fogalmaz meg, véleményt alkot.',
		b: 1,
		c: 1482,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'gondolkodás, véleményalkotás', 'kulturált véleménynyilvánítás, vitakultúra'])
	},
		{
		a: 'Ismer és használ az életkorának megfelelő nyomtatott és digitális forrásokat az ismeretei bővítéséhez, rendszerezéséhez.',
		b: 1,
		c: 1481,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés', 'hagyományos és digitális szövegek olvasása'])
	},
		{
		a: 'Különböző célú, rövidebb tájékoztató, ismeretterjesztő szövegeket olvas hagyományos és digitális felületen.',
		b: 1,
		c: 1480,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés', 'hagyományos és digitális szövegek olvasása'])
	},
		{
		a: 'Részt vesz népmesék és műmesék közös olvasásában, feldolgozásában.',
		b: 1,
		c: 1479,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés', 'irodalmi alkotások befogadása'])
	},
		{
		a: 'Verbális és vizuális módon vagy dramatikus eszközökkel reflektál a szövegre, megfogalmazza a szöveg alapján benne kialakult képet.',
		b: 1,
		c: 1478,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés', 'irodalmi alkotások befogadása'])
	},
		{
		a: 'Részt vesz az adott közösség kultúrájának megfelelő gyermekirodalmi mű közös olvasásában, és nyitott annak befogadására.',
		b: 1,
		c: 1477,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés', 'irodalmi alkotások befogadása'])
	},
		{
		a: 'Ajánlással, illetve egyéni érdeklődésének és az életkori sajátosságainak megfelelően választott irodalmi alkotást ismer meg.',
		b: 1,
		c: 1476,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés', 'irodalmi alkotások befogadása'])
	},
		{
		a: 'Könyvet kölcsönöz a könyvtárból, és azt el is olvassa, élményeit, gondolatait megosztja.',
		b: 1,
		c: 1475,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés', 'irodalmi alkotások befogadása'])
	},
		{
		a: 'Nyitott az irodalmi művek befogadására.',
		b: 1,
		c: 1474,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés', 'irodalmi alkotások befogadása'])
	},
		{
		a: 'Alapvető hagyományos és digitális kapcsolattartó formákat alkalmaz.',
		b: 1,
		c: 1473,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'kreatív és digitális szövegalkotás'])
	},
		{
		a: 'A megismert irodalmi szövegekhez, iskolai eseményekhez plakátot, meghívót, saját programjaihoz meghívót készít hagyományosan és digitálisan.',
		b: 1,
		c: 1472,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'kreatív és digitális szövegalkotás'])
	},
		{
		a: 'Megadott szempontok alapján rövid mesét ír, kiegészít vagy átalakít.',
		b: 1,
		c: 1471,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'kreatív és digitális szövegalkotás'])
	},
		{
		a: 'Tanítói segítséggel megadott rímpárokból, különböző témákban 2--4 soros verset alkot.',
		b: 1,
		c: 1470,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'kreatív és digitális szövegalkotás'])
	},
		{
		a: 'A szövegalkotáskor törekszik a megismert helyesírási szabályok alkalmazására, meglévő szókincsének aktivizálására.',
		b: 1,
		c: 1469,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'írásbeli szövegalkotás'])
	},
		{
		a: 'Gondolatait, érzelmeit, véleményét a kommunikációs helyzetnek megfelelően, néhány mondatban írásban is megfogalmazza.',
		b: 1,
		c: 1468,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'írásbeli szövegalkotás'])
	},
		{
		a: 'Egyéni képességeinek megfelelően alkot szövegeket írásban.',
		b: 1,
		c: 1467,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'írásbeli szövegalkotás'])
	},
		{
		a: 'A hallás és olvasás alapján megfigyelt szavakat, szószerkezeteket, mondatokat önállóan leírja.',
		b: 1,
		c: 1466,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'írásbeli szövegalkotás'])
	},
		{
		a: 'A tanult verseket, mondókákat, rövidebb szövegeket szöveghűen, érthetően tolmácsolja.',
		b: 1,
		c: 1465,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'szóbeli szövegalkotás'])
	},
		{
		a: 'Bekapcsolódik párbeszédek, dramatikus helyzetgyakorlatok, szituációs játékok megalkotásába.',
		b: 1,
		c: 1464,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'szóbeli szövegalkotás'])
	},
		{
		a: 'Megadott szempontok alapján szóban mondatokat és 3-4 mondatos szöveget alkot.',
		b: 1,
		c: 1463,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'szóbeli szövegalkotás'])
	},
		{
		a: 'Élményeiről segítséggel vagy önállóan beszámol.',
		b: 1,
		c: 1462,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'szóbeli szövegalkotás'])
	},
		{
		a: 'Használja a kapcsolat-felvételi, kapcsolattartási, kapcsolatlezárási formákat: köszönés, kérés, megszólítás, kérdezés; testtartás, testtávolság, tekintettartás, hangsúly, hanglejtés, hangerő, hangszín, megköszönés, elköszönés.',
		b: 1,
		c: 1461,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'szóbeli szövegalkotás'])
	},
		{
		a: 'Részt vesz a kortársakkal és felnőttekkel való kommunikációban, beszélgetésben, vitában, és alkalmazza a megismert kommunikációs szabályokat.',
		b: 1,
		c: 1460,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'szóbeli szövegalkotás'])
	},
		{
		a: 'Adottságaihoz mérten, életkorának megfelelően érthetően, az élethelyzethez igazodva kommunikál.',
		b: 1,
		c: 1459,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'szóbeli szövegalkotás'])
	},
		{
		a: 'Az egyéni sajátosságaihoz mérten olvashatóan ír, törekszik a rendezett írásképre, esztétikus füzetvezetésre.',
		b: 1,
		c: 1458,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'íráskészséget megalapozó képességek'])
	},
		{
		a: 'Szavakat, szószerkezeteket, 3-4 szavas mondatokat leír megfigyelés, illetve diktálás alapján.',
		b: 1,
		c: 1457,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'íráskészséget megalapozó képességek'])
	},
		{
		a: 'Saját tempójában elsajátítja az anyanyelvi írás jelrendszerét.',
		b: 1,
		c: 1456,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'íráskészséget megalapozó képességek'])
	},
		{
		a: 'Tér- és síkbeli tájékozódást fejlesztő feladatokat megold.',
		b: 1,
		c: 1455,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'íráskészséget megalapozó képességek'])
	},
		{
		a: 'Részt vesz nagymozgást és finommotorikát fejlesztő tevékenységekben, érzékelő játékokban.',
		b: 1,
		c: 1454,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás', 'íráskészséget megalapozó képességek'])
	},
		{
		a: 'Megérti az életkorának megfelelő nyelvi és nem nyelvi üzeneteket, és azokra a kommunikációs helyzetnek megfelelően reflektál.',
		b: 1,
		c: 1453,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'hallás utáni megértés'])
	},
		{
		a: 'Mozgósítja a hallott szöveg tartalmával kapcsolatos ismereteit, élményeit, tapasztalatait, és összekapcsolja azokat.',
		b: 1,
		c: 1452,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'hallás utáni megértés'])
	},
		{
		a: 'Megérti a szóbeli utasításokat, kérdéseket, az adottságainak és életkorának megfelelő szöveg tartalmát.',
		b: 1,
		c: 1451,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'hallás utáni megértés'])
	},
		{
		a: 'Egyszerű, játékos formában megismerkedik a szövegek különböző modalitásával, médiumok szövegalkotó sajátosságainak alapjaival.',
		b: 1,
		c: 1450,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Az olvasott szövegekben kulcsszavakat azonosít, a főbb szerkezeti egységeket önállóan vagy segítséggel elkülöníti.',
		b: 1,
		c: 1449,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Az olvasott szöveghez illusztrációt készít, a hiányos illusztrációt kiegészíti, vagy a meglévőt társítja a szöveggel.',
		b: 1,
		c: 1448,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Alkalmaz alapvető olvasási stratégiákat.',
		b: 1,
		c: 1447,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Önállóan, képek vagy tanítói segítség alapján a szöveg terjedelmétől függően kiemeli annak lényeges elemeit, összefoglalja azt.',
		b: 1,
		c: 1446,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'A szöveg megértését igazoló feladatokat végez.',
		b: 1,
		c: 1445,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Felkészülés után tagolt szöveget érthetően olvas hangosan.',
		b: 1,
		c: 1444,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Értő figyelemmel követi a tanító, illetve társai felolvasását.',
		b: 1,
		c: 1443,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Önállóan, képek, grafikai szervezők (kerettörténet, történettérkép, mesetáblázat, karakter-térkép, történetpiramis stb.) segítségével vagy tanítói segédlettel a szöveg terjedelmétől függően összefoglalja a történetet.',
		b: 1,
		c: 1442,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Megérti a közösen olvasott rövid szövegeket, részt vesz azok olvasásában, feldolgozásában.',
		b: 1,
		c: 1441,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Néma olvasás útján megérti az írott utasításokat, közléseket, kérdéseket, azokra adekvát módon reflektál.',
		b: 1,
		c: 1440,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Rövid meséket közösen olvas, megért, feldolgoz.',
		b: 1,
		c: 1439,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Részt vesz népmesék és műmesék, regék, mondák, történetek közös olvasásában és feldolgozásában.',
		b: 1,
		c: 1438,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Adottságaihoz mérten, életkorának megfelelően szöveget hangos vagy néma olvasás útján megért.',
		b: 1,
		c: 1437,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szövegértés, olvasási stratégiák'])
	},
		{
		a: 'Használ életkorának megfelelő digitális és hagyományos szótárakat.',
		b: 1,
		c: 1436,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szókincsfejlesztés'])
	},
		{
		a: 'A megismert szavakat, kifejezéseket a nyelvi fejlettségi szintjén alkalmazza.',
		b: 1,
		c: 1435,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szókincsfejlesztés'])
	},
		{
		a: 'Egyszerű magyarázat, szemléltetés (szóbeli, képi, dramatikus tevékenység) alapján megérti az új kifejezés jelentését.',
		b: 1,
		c: 1434,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szókincsfejlesztés'])
	},
		{
		a: 'Felismeri, értelmezi a szövegben a számára ismeretlen szavakat, kifejezéseket; digitális forrásokat is használ.',
		b: 1,
		c: 1433,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'szókincsfejlesztés'])
	},
		{
		a: 'Biztosan ismeri az olvasás jelrendszerét.',
		b: 1,
		c: 1432,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'olvasási készséget megalapozó képességek'])
	},
		{
		a: 'Hangokból, szótagokból szavakat épít.',
		b: 1,
		c: 1431,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'olvasási készséget megalapozó képességek'])
	},
		{
		a: 'A szavakat hangokra, szótagokra bontja.',
		b: 1,
		c: 1430,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'olvasási készséget megalapozó képességek'])
	},
		{
		a: 'Beszédlégzése és artikulációja megfelelő; figyelmet fordít a hangok időtartamának helyes ejtésére, a beszéd helyes ritmusára, hangsúlyára, tempójára, az élethelyzetnek megfelelő hangerőválasztásra.',
		b: 1,
		c: 1429,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'olvasási készséget megalapozó képességek'])
	},
		{
		a: 'Észleli, illetve megérti a nyelv alkotóelemeit, hangot, betűt, szótagot, szót, mondatot, szöveget, és azokra válaszokat fogalmaz meg.',
		b: 1,
		c: 1428,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'olvasási készséget megalapozó képességek'])
	},
		{
		a: 'Megérti és használja a tér- és időbeli tájékozódáshoz szükséges szókincset.',
		b: 1,
		c: 1427,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'olvasási készséget megalapozó képességek'])
	},
		{
		a: 'Részt vesz a testséma-tudatosságot fejlesztő tevékenységekben (szem-kéz koordináció, térérzékelés, irányok, arányok, jobb-bal oldal összehangolása, testrészek tudatosítása) és érzékelő játékokban.',
		b: 1,
		c: 1426,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés', 'olvasási készséget megalapozó képességek'])
	},
		{
		a: 'Élményeket és tapasztalatokat szerez változatos irodalmi szövegek megismerésével, olvasásával.',
		b: 1,
		c: 1425,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'A tanult nyelvi, nyelvtani, helyesírási ismereteket képességeihez mérten alkalmazza.',
		b: 1,
		c: 1424,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'Egyéni sajátosságaihoz mérten törekszik a rendezett írásképre, esztétikus füzetvezetésre.',
		b: 1,
		c: 1423,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'Megfogalmazza saját álláspontját, véleményét.',
		b: 1,
		c: 1422,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'Érdeklődésének megfelelően, hagyományos és digitális szövegek által bővíti ismereteit.',
		b: 1,
		c: 1421,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'Segítséggel egyéni érdeklődésének megfelelő olvasmányt választ, amelyről beszámol.',
		b: 1,
		c: 1420,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'Az életkorának és egyéni képességeinek megfelelően alkot szövegeket szóban és írásban.',
		b: 1,
		c: 1419,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'Életkorának megfelelően és adottságaihoz mérten kifejezően, érthetően, az élethelyzethez igazodva kommunikál.',
		b: 1,
		c: 1418,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'Felkészülés után tagolt szöveget érthetően és pontosan olvas hangosan.',
		b: 1,
		c: 1417,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'Az életkorának és egyéni adottságainak megfelelő, hallott és olvasott szövegeket megérti.',
		b: 1,
		c: 1416,
		d: 4,
		e: 'Magyar nyelv és irodalom@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'magyar nyelv és irodalom'])
	},
		{
		a: 'A feladatvégzés során hatékony közös munkára, együttműködésre törekszik.',
		b: 5,
		c: 1618,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'Személyes tapasztalatait összeköti a művekben megismert konfliktusokkal, érzelmi állapotokkal.',
		b: 5,
		c: 1617,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'Megérti mások álláspontját, elfogadja azt, vagy a sajátja mellett érveket fogalmaz meg.',
		b: 5,
		c: 1616,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'Személyes véleményt alakít ki a szövegek és művek által felvetett problémákról (pl. döntési helyzetek, motivációk, konfliktusok), és véleményét indokolja.',
		b: 5,
		c: 1615,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'Megfogalmazza vagy társaival együttműködve drámajátékban megjeleníti egy mű megismerése során szerzett tapasztalatait, élményeit.',
		b: 5,
		c: 1614,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'A tanult szövegeket szöveghűen és mások számára követhetően tolmácsolja.',
		b: 5,
		c: 1613,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Egy általa elolvasott művet ajánl kortársainak.',
		b: 5,
		c: 1612,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Érthetően, kifejezően és pontosan olvas.',
		b: 5,
		c: 1611,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Életkorának megfelelő irodalmi szövegeket olvas.',
		b: 5,
		c: 1610,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Egyszerű rímes és rímtelen verset alkot.',
		b: 5,
		c: 1609,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Tanári segítséggel kreatív szöveget alkot a megismert műhöz kapcsolódóan hagyományos és digitális formában.',
		b: 5,
		c: 1608,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Az egyéni sajátosságaihoz mérten tagolt, rendezett, áttekinthető írásképpel, egyértelmű javításokkal alkot szöveget.',
		b: 5,
		c: 1607,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'A szövegalkotás során alkalmazza a tanult helyesírási és szerkesztési szabályokat, használja a hagyományos és a digitális helyesírási szabályzatot és szótárt.',
		b: 5,
		c: 1606,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Az általa tanult hagyományos és digitális szövegtípusok megfelelő tartalmi és műfaji követelményeinek megfelelően alkot szövegeket.',
		b: 5,
		c: 1605,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'A tanult szövegeket szöveghűen és mások számára követhetően tolmácsolja.',
		b: 5,
		c: 1604,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Gondolatait, érzelmeit, véleményét a kommunikációs helyzetnek megfelelően, érvekkel alátámasztva fogalmazza meg, és mások véleményét is figyelembe veszi.',
		b: 5,
		c: 1603,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Érthetően, a kommunikációs helyzetnek megfelelően beszél.',
		b: 5,
		c: 1602,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'A tanulási tevékenységében hagyományos és digitális forrásokat használ, ezt mérlegelő gondolkodással és etikusan teszi.',
		b: 5,
		c: 1601,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Az életkorának megfelelő szöveg alapján jegyzetet, vázlatot készít.',
		b: 5,
		c: 1600,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Szóbeli vagy képi módszerekkel megfogalmazza, megjeleníti a szöveg alapján kialakult érzéseit, gondolatait.',
		b: 5,
		c: 1599,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Az olvasott szövegeket szerkezeti egységekre tagolja.',
		b: 5,
		c: 1598,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Összekapcsolja ismereteit a szöveg tartalmával, és reflektál azok összefüggéseire.',
		b: 5,
		c: 1597,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Alkalmazza a különböző olvasási típusokat és szöveg-feldolgozási módszereket.',
		b: 5,
		c: 1596,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Különbséget tesz a jelentésszerkezetben a szó szerinti és metaforikus értelmezés között.',
		b: 5,
		c: 1595,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'A pedagógus irányításával kiválasztja a rendelkezésre álló digitális forrásokból a megfelelő információkat.',
		b: 5,
		c: 1594,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'A lábjegyzetek, a digitális és nyomtatott szótárak használatával önállóan értelmezi az olvasott szöveget.',
		b: 5,
		c: 1593,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Felismeri és szükség szerint a tanár segítségével értelmezi a szövegben számára ismeretlen kifejezéseket.',
		b: 5,
		c: 1592,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Kifejezően tudja olvasni és értelmezni az életkorának megfelelő különböző műfajú és megjelenésű szövegeket. a tanuló felismeri és a tanár segítségével értelmezi a számára ismeretlen kifejezéseket.',
		b: 5,
		c: 1591,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Megérti az életkorának megfelelő hallott és olvasott szövegeket.',
		b: 5,
		c: 1590,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'A nemzeti hagyomány szempontjából meghatározó néhány mű esetén bemutatja a szerzőhöz és a korszakhoz kapcsolódó legfőbb jellemzőket.',
		b: 5,
		c: 1589,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Drámai műveket olvas és értelmez, előadásokat, feldolgozásokat megnéz (pl.: mesedráma, vígjáték, ifjúsági regény adaptációja).',
		b: 5,
		c: 1588,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Szöveghűen, értőn mondja el a memoriterként tanult lírai műveket.',
		b: 5,
		c: 1587,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Ismer és felismer néhány alapvető lírai műfajt.',
		b: 5,
		c: 1586,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Felismeri a tanult alapvető rímképleteket (páros rím, keresztrím, bokorrím), és azonosítja az olvasott versek ritmikai, hangzásbeli hasonlóságait és különbségeit.',
		b: 5,
		c: 1585,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Azonosítja a versben a lírai én különböző megszólalásait, és elkülöníti a mű szerkezeti egységeit.',
		b: 5,
		c: 1584,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'A szövegből vett idézetekkel támasztja alá, és saját szavaival fogalmazza meg a lírai szöveg hangulati jellemzőit, a befogadás során keletkezett érzéseit és gondolatait.',
		b: 5,
		c: 1583,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Felismeri és a szövegből vett példával igazolja az elbeszélő és a szereplő nézőpontja közötti eltérést.',
		b: 5,
		c: 1582,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Elkülöníti és jellemzi a fő- és mellékszereplőket, megkülönbözteti a helyszíneket, az idősíkokat, azonosítja az előre- és visszautalásokat.',
		b: 5,
		c: 1581,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'A megismert epikus művek cselekményét összefoglalja, fordulópontjait önállóan ismerteti.',
		b: 5,
		c: 1580,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Az irodalmi szövegek befogadása során felismer és értelmez néhány alapvető nyelvi-stilisztikai eszközt: szóképek, alakzatok stb..',
		b: 5,
		c: 1579,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Felismeri, és bemutatja egy adott műfaj főbb jellemzőit.',
		b: 5,
		c: 1578,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Összehasonlít egy adott irodalmi művet annak adaptációival (film, festmény, stb.).',
		b: 5,
		c: 1577,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Elkülöníti az irodalmi művek főbb szerkezeti egységeit.',
		b: 5,
		c: 1576,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Összekapcsol irodalmi műveket különböző szempontok alapján (téma, műfaj, nyelvi kifejezőeszközök).',
		b: 5,
		c: 1575,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Megismeri és elkülöníti a műnemeket, illetve a műnemekhez tartozó főbb műfajokat, felismeri és megnevezi azok poétikai és retorikai jellemzőit.',
		b: 5,
		c: 1574,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'A tanult fogalmakat használva beszámol a megismert műről.',
		b: 5,
		c: 1573,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Megérti az irodalmi mű szövegszerű és elvont jelentéseit.',
		b: 5,
		c: 1572,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Elolvassa a kötelező olvasmányokat, és saját örömére is olvas.',
		b: 5,
		c: 1571,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'A tanuló etikusan és kritikusan használja a hagyományos papíralapú, illetve a világhálón található és egyéb digitális adatbázisokat.',
		b: 5,
		c: 1570,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Társai és saját munkájában a tanult formáktól eltérő, gyakran előforduló helyesírási hibákat felismeri és javítja.',
		b: 5,
		c: 1569,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri és alkalmazza helyesírásunk alapelveit: kiejtés, szóelemzés, hagyomány, egyszerűsítés.',
		b: 5,
		c: 1568,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Alkalmazza az általa tanult nyelvi, nyelvtani, helyesírási, nyelvhelyességi ismereteket.',
		b: 5,
		c: 1567,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Felismeri a kommunikáció főbb zavarait, alkalmaz korrekciós lehetőségeket.',
		b: 5,
		c: 1566,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Megfigyeli és értelmezi a tömegkommunikáció társadalmat befolyásoló szerepét.',
		b: 5,
		c: 1565,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Használja a digitális kommunikáció eszközeit, megnevezi azok főbb jellemző tulajdonságait.',
		b: 5,
		c: 1564,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Felismeri és megnevezi a nyelvi és nem nyelvi kommunikáció elemeit.',
		b: 5,
		c: 1563,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Megfigyeli, elkülöníti és funkciót társítva értelmezi a környezetében előforduló nyelvváltozatokat (nyelvjárások, csoportnyelvek, rétegnyelvek).',
		b: 5,
		c: 1562,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Tanári irányítással, néhány szempont alapján összehasonlítja az anyanyelv és a tanult idegen nyelv sajátosságait.',
		b: 5,
		c: 1561,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Felismeri és megnevezi a főbb szóalkotási módokat: szóösszetétel, szóképzés, néhány ritkább szóalkotási mód.',
		b: 5,
		c: 1560,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Érti és megnevezi a tanult nyelvi egységek szövegbeli szerepét.',
		b: 5,
		c: 1559,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Megfigyeli és elemzi a mondat szórendjét, a szórendi változatok, valamint a környező szöveg kölcsönhatását.',
		b: 5,
		c: 1558,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Felismeri és elemzi a főbb szóelemek mondat- és szövegbeli szerepét, törekszik helyes alkalmazásukra.',
		b: 5,
		c: 1557,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Szerkezetük alapján megkülönbözteti az egyszerű és összetett mondatokat.',
		b: 5,
		c: 1556,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'A szövegben felismer és funkciójuk alapján azonosít alapvető és gyakori szószerkezeteket (alanyos, határozós, jelzős, tárgyas).',
		b: 5,
		c: 1555,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Funkciójuk alapján felismeri és megnevezi a szóelemeket és szófajokat.',
		b: 5,
		c: 1554,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri a magyar hangrendszer főbb jellemzőit és a hangok kapcsolódási szabályait. írásban helyesen jelöli.',
		b: 5,
		c: 1553,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Elkülöníti a nyelv szerkezeti egységeit, megnevezi a tanult elemeket.',
		b: 5,
		c: 1552,
		d: 8,
		e: 'Magyar nyelv és irodalom@5-8',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'A kommunikációs helyzetnek és a célnak megfelelően tudatosan alkalmazza a beszélt és írott nyelvet, reflektál saját és társai nyelvhasználatára.',
		b: 9,
		c: 1697,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'A feladatokat komplex szempontoknak megfelelően oldja meg, azokat kiegészíti saját szempontjaival.',
		b: 9,
		c: 1696,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'Feladatai megoldásához önálló kutatómunkát végez nyomtatott és digitális forrásokban, a források tartalmát mérlegelő módon gondolja végig.',
		b: 9,
		c: 1695,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'Vitahelyzetben figyelembe veszi mások álláspontját, a lehetséges ellenérveket is.',
		b: 9,
		c: 1694,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'Megnyilvánulásaiban, a vitákban alkalmazza az érvelés alapvető szabályait.',
		b: 9,
		c: 1693,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'A környező világ jelenségeiről, szövegekről, műalkotásokról véleményt alkot, és azt érvekkel támasztja alá.',
		b: 9,
		c: 1692,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'mérlegelő gondolkodás, véleményalkotás'])
	},
		{
		a: 'Részt vesz irodalmi mű kreatív feldolgozásában, bemutatásában (pl. animáció, dramaturgia, átirat).',
		b: 9,
		c: 1691,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Önismeretét irodalmi művek révén fejleszti.',
		b: 9,
		c: 1690,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Választott olvasmányaira is vonatkoztatja a tanórán megismert kontextusteremtő eljárások tanulságait.',
		b: 9,
		c: 1689,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Társai érdeklődését figyelembe véve ajánl olvasmányokat.',
		b: 9,
		c: 1688,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Olvasmányai kiválasztásakor figyelembe veszi az alkotások kulturális regiszterét.',
		b: 9,
		c: 1687,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Tudatosan keresi a történeti és esztétikai értékekkel rendelkező olvasmányokat, műalkotásokat.',
		b: 9,
		c: 1686,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'A kötelező olvasmányokat elolvassa, és saját örömére is olvas.',
		b: 9,
		c: 1685,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'olvasóvá nevelés'])
	},
		{
		a: 'Megadott vagy önállóan kiválasztott szempontok alapján az irodalmi művekről elemző esszét ír.',
		b: 9,
		c: 1684,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Különböző, a munka világában is használt hivatalos szövegeket alkot hagyományos és digitális felületeken (pl. kérvény, beadvány, nyilatkozat, egyszerű szerződés, meghatalmazás, önéletrajz, motivációs levél).',
		b: 9,
		c: 1683,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Ismeri, érti és etikusan alkalmazza a hagyományos, digitális és multimédiás szemléltetést.',
		b: 9,
		c: 1682,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Érvelő esszét alkot megadott szempontok vagy szövegrészletek alapján.',
		b: 9,
		c: 1681,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Az írásbeli szövegalkotáskor alkalmazza a tanult szerkesztési, stilisztikai ismereteket és a helyesírási szabályokat.',
		b: 9,
		c: 1680,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'A tanult szövegtípusoknak megfelelő tartalommal és szerkezettel önállóan alkot különféle írásbeli szövegeket.',
		b: 9,
		c: 1679,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Írásbeli és szóbeli nyelvhasználata, stílusa az adott kommunikációs helyzetnek megfelelő. írásképe tagolt, beszéde érthető, artikulált.',
		b: 9,
		c: 1678,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Felismeri és megnevezi a szóbeli előadásmód hatáskeltő eszközeit, hatékonyan alkalmazza azokat.',
		b: 9,
		c: 1677,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Rendelkezik korának megfelelő retorikai ismeretekkel.',
		b: 9,
		c: 1676,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Képes eltérő műfajú szóbeli szövegek alkotására: felelet, kiselőadás, hozzászólás, felszólalás.',
		b: 9,
		c: 1675,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Megadott szempontrendszer alapján szóbeli feleletet készít.',
		b: 9,
		c: 1674,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegalkotás'])
	},
		{
		a: 'Ismeri a tanult tantárgyak, tudományágak szakszókincsét, azokat a beszédhelyzetnek megfelelően használja.',
		b: 9,
		c: 1673,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Önállóan értelmezi az ismeretlen kifejezéseket a szövegkörnyezet vagy digitális, illetve nyomtatott segédeszközök használatával.',
		b: 9,
		c: 1672,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Törekszik arra, hogy a különböző típusú, stílusú és regiszterű szövegekben megismert, számára új kifejezéseket beépítse szókincsébe, azokat adekvát módon használja.',
		b: 9,
		c: 1671,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Megtalálja a közös és eltérő jellemzőket a hagyományos és a digitális technikával előállított, tárolt szövegek között, és véleményt formál azok sajátosságairól.',
		b: 9,
		c: 1670,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Felismeri és értelmezi a szövegben a kétértelműséget és a félrevezető információt, valamint elemzi és értelmezi a szerző szándékát.',
		b: 9,
		c: 1669,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Feladatai megoldásához önálló kutatómunkát végez nyomtatott és digitális forrásokban, ezek eredményeit szintetizálja.',
		b: 9,
		c: 1668,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Folyamatos és nem folyamatos, hagyományos és digitális szövegeket olvas és értelmez maga által választott releváns szempontok alapján.',
		b: 9,
		c: 1667,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Felismeri és értelmezésében figyelembe veszi a hallott és az írott szövegek közötti funkcionális és stiláris különbségeket.',
		b: 9,
		c: 1666,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Összefüggő szóbeli szöveg (előadás, megbeszélés, vita) alapján önállóan vázlatot készít.',
		b: 9,
		c: 1665,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Különböző típusú és célú szövegeket hallás alapján értelmez és megfelelő stratégia alkalmazásával értékel és összehasonlít.',
		b: 9,
		c: 1664,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Egymással összefüggésben értelmezi a szöveg tartalmi elemeit és a hozzá kapcsolódó illusztrációkat, ábrákat.',
		b: 9,
		c: 1663,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Azonosítja a szöveg szerkezeti elemeit, és figyelembe veszi azok funkcióit a szöveg értelmezésekor.',
		b: 9,
		c: 1662,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Hosszabb terjedelmű szöveg alapján többszintű vázlatot vagy részletes gondolattérképet készít.',
		b: 9,
		c: 1661,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'A megismert szöveg tartalmi és nyelvi minőségéről érvekkel alátámasztott véleményt alkot.',
		b: 9,
		c: 1660,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'A különböző olvasási típusokat és a szövegfeldolgozási stratégiákat a szöveg típusának és az olvasás céljának megfelelően választja ki és kapcsolja össze.',
		b: 9,
		c: 1659,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Különböző megjelenésű, típusú, műfajú, korú és összetettségű szövegeket olvas, értelmez.',
		b: 9,
		c: 1658,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'szövegértés'])
	},
		{
		a: 'Tanulmányai során ismereteket szerez a kulturális intézmények (múzeum, könyvtár, színház) és a nyomtatott, illetve digitális formában megjelenő kulturális folyóiratok, adatbázisok működéséről.',
		b: 9,
		c: 1657,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Tájékozottságot szerez régiója magyar irodalmáról.',
		b: 9,
		c: 1656,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'A mű értelmezésében összekapcsolja a szöveg poétikai tulajdonságait a mű nemzeti hagyományban betöltött szerepével.',
		b: 9,
		c: 1655,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Szükség esetén a mű értelmezéséhez felhasználja történeti ismereteit.',
		b: 9,
		c: 1654,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'A tantárgyhoz kapcsolódó fogalmakkal bemutatja a lírai mű hangulati és hangnemi sajátosságait, hivatkozik a mű verstani felépítésére.',
		b: 9,
		c: 1653,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'A líra mű értelmezésében alkalmazza az általa tanult líraelméleti és líratörténeti fogalmakat (pl. lírai én, beszédhelyzetek, beszédmódok, ars poetica, szereplíra).',
		b: 9,
		c: 1652,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'A drámai mű értelmezésében alkalmazza az általa tanult drámaelméleti és drámatörténeti fogalmakat (pl. analitikus és abszurd dráma, epikus színház, elidegenedés).',
		b: 9,
		c: 1651,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Epikai művekben értelmezi a különböző elbeszélésmódok szerepét (tudatábrázolás, egyenes és függő beszéd, mindentudó és korlátozott elbeszélő stb.).',
		b: 9,
		c: 1650,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Epikai és drámai művekben rendszerbe foglalja a szereplők viszonyait, valamint összekapcsolja azok motivációját és cselekedeteit.',
		b: 9,
		c: 1649,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Epikai és drámai művekben önállóan értelmezi a cselekményszálak, a szerkezet, az időszerkezet (lineáris, nem lineáris), a helyszínek és a jellemek összefüggéseit.',
		b: 9,
		c: 1648,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Összehasonlít egy adott irodalmi művet annak adaptációival (film, festmény, zenemű, animáció, stb.), összehasonlításkor figyelembe veszi az adott művészeti ágak jellemző tulajdonságait.',
		b: 9,
		c: 1647,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Összekapcsol irodalmi műveket különböző szempontok alapján (motívumok, történelmi, erkölcsi kérdésfelvetések, művek és parafrázisaik).',
		b: 9,
		c: 1646,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Összekapcsolja az irodalmi művek szövegének lehetséges értelmezéseit azok társadalmi-történelmi szerepével, jelentőségével.',
		b: 9,
		c: 1645,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Az irodalmi mű értelmezése során figyelembe veszi a mű keletkezéstörténeti hátterét, a műhöz kapcsolható filozófiai, eszmetörténeti szempontokat is.',
		b: 9,
		c: 1644,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Összekapcsolja az irodalmi művek szerkezeti felépítését, nyelvi sajátosságait azok tartalmával és értékszerkezetével.',
		b: 9,
		c: 1643,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Értelmezésében felhasználja irodalmi és művészeti, történelmi, művelődéstörténeti ismereteit.',
		b: 9,
		c: 1642,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Megérti, elemzi az irodalmi mű jelentésszerkezetének szintjeit.',
		b: 9,
		c: 1641,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Felismeri és elkülöníti a műnemeket, illetve a műnemekhez tartozó műfajokat, megnevezi azok poétikai és retorikai jellemzőit.',
		b: 9,
		c: 1640,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Elolvassa a kötelező olvasmányokat, és saját örömére is olvas.',
		b: 9,
		c: 1639,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'irodalmi kultúra, irodalmi ismeretek'])
	},
		{
		a: 'Etikusan és kritikusan használja a hagyományos, papír alapú, illetve a világhálón található és egyéb digitális adatbázisokat.',
		b: 9,
		c: 1638,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Tud helyesen írni, szükség esetén nyomtatott és digitális helyesírási segédleteket használ.',
		b: 9,
		c: 1637,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri a magyar nyelv helyesírási, nyelvhelyességi szabályait.',
		b: 9,
		c: 1636,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Érti, hogy nyelvünk a történelemben folyamatosan változik, ismeri a magyar nyelvtörténet nagy korszakait, kiemelkedő jelentőségű nyelvemlékeit.',
		b: 9,
		c: 1635,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri a magyar nyelv eredetének hipotéziseit, és azok tudományosan megalapozott bizonyítékait.',
		b: 9,
		c: 1634,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri és érti a nyelvrokonság fogalmát, annak kritériumait.',
		b: 9,
		c: 1633,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'A retorikai ismereteit a gyakorlatban is alkalmazza.',
		b: 9,
		c: 1632,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Alkalmazza az általa tanult nyelvi, nyelvtani, helyesírási, nyelvhelyességi ismereteket.',
		b: 9,
		c: 1631,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri a nyelvhasználatban előforduló különféle nyelvváltozatokat (nyelvjárások, csoportnyelvek, rétegnyelvek), összehasonlítja azok főbb jellemzőit.',
		b: 9,
		c: 1630,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Szövegelemzéskor felismeri az alakzatokat és a szóképeket, értelmezi azok hatását, szerepét, megnevezi típusaikat.',
		b: 9,
		c: 1629,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri a stílus fogalmát, a stíluselemeket, a stílushatást, a stíluskorszakokat, stílusrétegeket, ismereteit a szöveg befogadása és alkotása során alkalmazza.',
		b: 9,
		c: 1628,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Felismeri és alkalmazza a szövegösszetartó grammatikai és jelentésbeli elemeket, szövegépítése arányos és koherens.',
		b: 9,
		c: 1627,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri a szöveg fogalmát, jellemzőit, szerkezeti sajátosságait, valamint a különféle szövegtípusokat és megjelenésmódokat.',
		b: 9,
		c: 1626,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Felismeri és megnevezi a magyar és a tanult idegen nyelv közötti hasonlóságokat és eltéréseket.',
		b: 9,
		c: 1625,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri a magyar nyelv hangtanát, alaktanát, szófajtanát, mondattanát, ismeri és alkalmazza a tanult elemzési eljárásokat.',
		b: 9,
		c: 1624,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Ismeri az anyanyelvét, annak szerkezeti felépítését, nyelvhasználata tudatos és helyes.',
		b: 9,
		c: 1623,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Reflektál saját kommunikációjára, szükség esetén változtat azon.',
		b: 9,
		c: 1622,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Felismeri és elemzi a tömegkommunikáció befolyásoló eszközeit, azok céljait és hatásait.',
		b: 9,
		c: 1621,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Felismeri a kommunikáció zavarait, kezelésükre stratégiát dolgoz ki.',
		b: 9,
		c: 1620,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Az anyanyelvről szerzett ismereteit alkalmazva képes a kommunikációjában a megfelelő nyelvváltozat kiválasztására, használatára.',
		b: 9,
		c: 1619,
		d: 12,
		e: 'Magyar nyelv és irodalom@9-12',
		f: _List_fromArray(
			['magyar nyelv és irodalom', 'anyanyelvi kultúra, anyanyelvi ismeretek'])
	},
		{
		a: 'Találkozik az érdeklődésének megfelelő akár autentikus szövegekkel elektronikus, digitális csatornákon tanórán kívül is.',
		b: 9,
		c: 2269,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Egyénileg vagy társaival együttműködve szóban vagy írásban projektmunkát vagy kiselőadást készít, és ezeket digitális eszközök segítségével is meg tudja valósítani.',
		b: 9,
		c: 2268,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'digitális eszközök és felületek használata idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Következetesen alkalmazza a célnyelvi betű és jelkészletet',
		b: 9,
		c: 2267,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrákhoz kapcsolódó nyelvi elemek'])
	},
		{
		a: 'Idegen nyelvi kommunikációjában ismeri és használja a célnyelv főbb jellemzőit.',
		b: 9,
		c: 2266,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrákhoz kapcsolódó nyelvi elemek'])
	},
		{
		a: 'A célnyelvi kultúrákhoz kapcsolódó alapvető tanult nyelvi elemeket használja.',
		b: 9,
		c: 2265,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrákhoz kapcsolódó nyelvi elemek'])
	},
		{
		a: 'Megismerkedik hazánk legfőbb országismereti és történelmi eseményeivel célnyelven.',
		b: 9,
		c: 2264,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interkulturalitás, országismeret', 'a hazai kultúra közvetítése célnyelven'])
	},
		{
		a: 'Találkozik a célnyelvi, életkorának és érdeklődésének megfelelő hazai és nemzetközi legfőbb hírekkel, eseményekkel.',
		b: 9,
		c: 2263,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interkulturalitás, országismeret', 'a hazai kultúra közvetítése célnyelven'])
	},
		{
		a: 'Találkozik célnyelvi országismereti tartalmakkal.',
		b: 9,
		c: 2262,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Célnyelvi kommunikációjába beépíti a tanult interkulturális ismereteket.',
		b: 9,
		c: 2261,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'További országismereti tudásra tesz szert.',
		b: 9,
		c: 2260,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Megismeri a célnyelvi országok főbb jellemzőit és kulturális sajátosságait.',
		b: 9,
		c: 2259,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interkulturalitás, országismeret', 'célnyelvi kultúrák'])
	},
		{
		a: 'Értelmez egyszerű, szórakoztató kisfilmeket',
		b: 9,
		c: 2258,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'digitális nyelvhasználat'])
	},
		{
		a: 'Digitális eszközöket és felületeket is használ nyelvtudása fejlesztésére,',
		b: 9,
		c: 2257,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'digitális nyelvhasználat'])
	},
		{
		a: 'Felhasználja a célnyelvet szórakozásra és játékos nyelvtanulásra.',
		b: 9,
		c: 2256,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'valós nyelvhasználat'])
	},
		{
		a: 'A tanórán kívüli, akár játékos nyelvtanulási lehetőségeket felismeri, és törekszik azokat kihasználni.',
		b: 9,
		c: 2255,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'valós nyelvhasználat'])
	},
		{
		a: 'Társai haladásának értékelésében segítően részt vesz.',
		b: 9,
		c: 2254,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'haladás értékelése'])
	},
		{
		a: 'Nyelvi haladását többnyire fel tudja mérni,',
		b: 9,
		c: 2253,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'haladás értékelése'])
	},
		{
		a: 'Céljai eléréséhez társaival párban és csoportban együttműködik.',
		b: 9,
		c: 2252,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Céljai eléréséhez megtalálja és használja a megfelelő eszközöket.',
		b: 9,
		c: 2251,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Egy összetettebb nyelvi feladat, projekt végéig tartó célokat tűz ki magának.',
		b: 9,
		c: 2250,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási célok'])
	},
		{
		a: 'Ismer szavakat, szókapcsolatokat a célnyelven a témakörre jellemző, életkorának és érdeklődésének megfelelő más tudásterületen megcélzott tartalmakból.',
		b: 9,
		c: 2249,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Hibáit többnyire észreveszi és javítja.',
		b: 9,
		c: 2248,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Tudatosan használ alapszintű nyelvtanulási és nyelvhasználati stratégiákat.',
		b: 9,
		c: 2247,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'önálló nyelvtanulás', 'nyelvtanulási és nyelvhasználati stratégiák'])
	},
		{
		a: 'Ismerős és gyakori alapvető helyzetekben, akár telefonon vagy digitális csatornákon is, többnyire helyesen és érthetően fejezi ki magát az ismert nyelvi eszközök segítségével.',
		b: 9,
		c: 2246,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Megoszt alapvető személyes információkat és szükségleteket magáról egyszerű nyelvi elemekkel.',
		b: 9,
		c: 2245,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Nem értés esetén a meg nem értett kulcsszavak vagy fordulatok ismétlését vagy magyarázatát kéri, visszakérdez, betűzést kér.',
		b: 9,
		c: 2244,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Alkalmaz nyelvi funkciókat rövid társalgás megkezdéséhez, fenntartásához és befejezéséhez.',
		b: 9,
		c: 2243,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Ismeretlen szavak valószínű jelentését szövegösszefüggések alapján kikövetkezteti az életkorának és érdeklődésének megfelelő, konkrét, rövid szövegekben.',
		b: 9,
		c: 2242,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'A tanult nyelvi eszközökkel és nonverbális elemek segítségével tisztázza mondanivalójának lényegét.',
		b: 9,
		c: 2241,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Egyszerű mondatokat összekapcsolva mond el egymást követő eseményekből álló történetet, vagy leírást ad valamilyen témáról.',
		b: 9,
		c: 2240,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Összekapcsolja az ismert nyelvi elemeket egyszerű kötőszavakkal (például: és, de, vagy).',
		b: 9,
		c: 2239,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Találkozik életkorának és nyelvi szintjének megfelelő célnyelvi szórakoztató tartalmakkal.',
		b: 9,
		c: 2238,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Használja a célnyelvet életkorának és nyelvi szintjének megfelelő aktuális témákban és a hozzájuk tartozó szituációkban.',
		b: 9,
		c: 2237,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Felhasználja a célnyelvet ismeretszerzésre.',
		b: 9,
		c: 2236,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Értelmez és használja az idegen nyelvű írott, olvasott és hallott tartalmakat a tanórán kívül is,',
		b: 9,
		c: 2235,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Kifejez és érvekkel alátámasztva mutat be szükségességet, lehetőséget, képességet, bizonyosságot, bizonytalanságot.',
		b: 9,
		c: 2234,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Kifejez alapvető érzéseket, például örömöt, sajnálkozást, bánatot, elégedettséget, elégedetlenséget, bosszúságot, csodálkozást, reményt.',
		b: 9,
		c: 2233,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Kifejez kérést, javaslatot, meghívást, kínálást és ezekre reagálást.',
		b: 9,
		c: 2232,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Információt cserél, információt kér, információt ad.',
		b: 9,
		c: 2231,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Kifejez tetszést, nem tetszést, akaratot, kívánságot, tudást és nem tudást, ígéretet, szándékot, dicséretet, kritikát.',
		b: 9,
		c: 2230,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Egyszerűen megfogalmazza személyes véleményét, másoktól véleményük kifejtését kéri, és arra reagál, elismeri vagy cáfolja mások állítását, kifejezi egyetértését vagy egyet nem értését.',
		b: 9,
		c: 2229,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'mindennapi idegennyelv-használat'])
	},
		{
		a: 'Néhány szóból vagy mondatból álló jegyzetet készít írott szöveg alapján.',
		b: 9,
		c: 2228,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'írásbeli tudásmegosztás', 'második idegen nyelv'])
	},
		{
		a: 'Találkozik az életkorának és nyelvi szintjének megfelelő célnyelvi ismeretterjesztő tartalmakkal.',
		b: 9,
		c: 2227,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'írásbeli tudásmegosztás', 'második idegen nyelv'])
	},
		{
		a: 'Felhasználja a célnyelvet tudásmegosztásra.',
		b: 9,
		c: 2226,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'szóbeli tudásmegosztás'])
	},
		{
		a: 'Rövid, egyszerű, ismert nyelvi eszközökből álló kiselőadást tart változatos feladatok kapcsán, hagyományos vagy digitális alapú vizuális eszközök támogatásával.',
		b: 9,
		c: 2225,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['információközvetítés idegen nyelven', 'második idegen nyelv', 'szóbeli tudásmegosztás'])
	},
		{
		a: 'Véleményét írásban, egyszerű nyelvi eszközökkel megfogalmazza, és arról írásban interakciót folytat.',
		b: 9,
		c: 2224,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'íráskészség: írásbeli interakció'])
	},
		{
		a: 'Üzeneteket ír,',
		b: 9,
		c: 2223,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'íráskészség: írásbeli interakció'])
	},
		{
		a: 'Bekapcsolódik a tanórán az interakciót igénylő nyelvi tevékenységekbe, abban társaival közösen részt vesz, a begyakorolt nyelvi elemeket tanári segítséggel a játék céljainak megfelelően alkalmazza.',
		b: 9,
		c: 2222,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Váratlan, előre nem kiszámítható eseményekre, jelenségekre és történésekre is reagál egyszerű célnyelvi eszközökkel, személyes vagy online interakciókban.',
		b: 9,
		c: 2221,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'A tanult nyelvi elemeket többnyire megfelelően használja, beszédszándékainak megfelelően, egyszerű spontán helyzetekben.',
		b: 9,
		c: 2220,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Véleményét, gondolatait, érzéseit egyre magabiztosabban fejezi ki a tanult nyelvi eszközökkel.',
		b: 9,
		c: 2219,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Az életkorának megfelelő mindennapi helyzetekben a tanult nyelvi eszközökkel megfogalmazott kérdéseket tesz fel, és válaszol a hozzá intézett kérdésekre.',
		b: 9,
		c: 2218,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Kommunikációt kezdeményez egyszerű hétköznapi témában, a beszélgetést követi, egyszerű, nyelvi eszközökkel fenntartja és lezárja.',
		b: 9,
		c: 2217,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Megért és használ szavakat, szókapcsolatokat a célnyelvi, az életkorának és érdeklődésének megfelelő hazai és nemzetközi legfőbb hírekkel, eseményekkel kapcsolatban',
		b: 9,
		c: 2216,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Érdeklődése erősödik a célnyelvi irodalmi alkotások iránt.',
		b: 9,
		c: 2215,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'A nyomtatott vagy digitális alapú írott szöveget felhasználja szórakozásra és ismeretszerzésre önállóan is.',
		b: 9,
		c: 2214,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Alkalmazza az életkorának és nyelvi szintjének megfelelő írott, nyomtatott vagy digitális alapú szöveget a változatos nyelvórai tevékenységek és feladatmegoldás során.',
		b: 9,
		c: 2213,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Összetettebb írott instrukciókat értelmez.',
		b: 9,
		c: 2212,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megkülönbözteti a főbb, életkorának megfelelő írott szövegtípusokat.',
		b: 9,
		c: 2211,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Kiemel, kiszűr konkrét információkat a nyelvi szintjének megfelelő szövegből, és azokat összekapcsolja más iskolai vagy iskolán kívül szerzett ismereteivel.',
		b: 9,
		c: 2210,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megérti a nem kizárólag ismert nyelvi elemeket tartalmazó rövid írott szöveg tartalmát.',
		b: 9,
		c: 2209,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Értelmezi az életkorának megfelelő szituációkhoz kapcsolódó, írott szövegekben megjelenő összetettebb információkat.',
		b: 9,
		c: 2208,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Hallgat az érdeklődésének megfelelő autentikus szövegeket elektronikus, digitális csatornákon, tanórán kívül is, szórakozásra vagy ismeretszerzésre.',
		b: 9,
		c: 2207,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Felismeri a főbb, életkorának megfelelő hangzószöveg-típusokat.',
		b: 9,
		c: 2206,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmez életkorának megfelelő nyelvi helyzeteket hallott szöveg alapján.',
		b: 9,
		c: 2205,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Alkalmazza az életkorának és nyelvi szintjének megfelelő hangzó szöveget a változatos nyelvórai tevékenységek és a feladatmegoldás során.',
		b: 9,
		c: 2204,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Kiemel, kiszűr konkrét információkat a nyelvi szintjének megfelelő, élőszóban vagy digitális felületen elhangzó szövegből, és azokat összekapcsolja egyéb ismereteivel.',
		b: 9,
		c: 2203,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti a nem kizárólag ismert nyelvi elemeket tartalmazó, élőszóban vagy digitális felületen elhangzó rövid szöveg tartalmát.',
		b: 9,
		c: 2202,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmezi az életkorának megfelelő, élőszóban vagy digitális felületen elhangzó szövegekben a beszélők gondolatmenetét.',
		b: 9,
		c: 2201,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmezi az életkorának és nyelvi szintjének megfelelő, egyszerű, hangzó szövegben a tanult nyelvi elemeket.',
		b: 9,
		c: 2200,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti a szintjének megfelelő, kevésbé ismert elemekből álló, nonverbális vagy vizuális eszközökkel támogatott célnyelvi óravezetést és utasításokat, kérdéseket.',
		b: 9,
		c: 2199,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'A szövegek létrehozásához nyomtatott, illetve digitális alapú segédeszközt, szótárt használ.',
		b: 9,
		c: 2198,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Rövid, összefüggő, papíralapú vagy ikt-eszközökkel segített írott projektmunkát készít önállóan vagy kooperatív munkaformákban.',
		b: 9,
		c: 2197,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Rövid szövegek írását igénylő kreatív munkát hoz létre önállóan.',
		b: 9,
		c: 2196,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Rövid, egyszerű, összefüggő szövegeket ír a tanult nyelvi szerkezetek felhasználásával az ismert szövegtípusokban, az ajánlott tématartományokban.',
		b: 9,
		c: 2195,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Megold játékos és változatos írásbeli feladatokat rövid szövegek szintjén.',
		b: 9,
		c: 2194,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Változatos, kognitív kihívást jelentő szóbeli feladatokat old meg önállóan vagy kooperatív munkaformában, a tanult nyelvi eszközökkel, szükség szerint tanári segítséggel, élőszóban és digitális felületen.',
		b: 9,
		c: 2193,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Képet jellemez röviden, egyszerűen, ismert nyelvi fordulatok segítségével, segítő tanári kérdések alapján, önállóan.',
		b: 9,
		c: 2192,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Egyszerű nyelvi eszközökkel, felkészülést követően röviden, összefüggően beszél az ajánlott tématartományokhoz tartozó témákban, élőszóban és digitális felületen.',
		b: 9,
		c: 2191,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Elmesél rövid történetet, egyszerűsített olvasmányt egyszerű nyelvi eszközökkel, önállóan, a cselekményt lineárisan összefűzve.',
		b: 9,
		c: 2190,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Egyre magabiztosabban kapcsolódik be történetek kreatív alakításába, átfogalmazásába kooperatív munkaformában.',
		b: 9,
		c: 2189,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Aktívan részt vesz az életkorának és érdeklődésének megfelelő gyermek-, illetve ifjúsági irodalmi alkotások közös előadásában.',
		b: 9,
		c: 2188,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: összefüggő beszéd'])
	},
		{
		a: 'Érti a nyelvtudás fontosságát, és motivációja a nyelvtanulásra tovább erősödik.',
		b: 9,
		c: 2187,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Törekszik a célnyelvi normához illeszkedő kiejtés, beszédtempó és intonáció megközelítésére.',
		b: 9,
		c: 2186,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Alkalmazza nyelvtudását kommunikációra, közvetítésre, szórakozásra, ismeretszerzésre hagyományos és digitális csatornákon.',
		b: 9,
		c: 2185,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Használ életkorának és nyelvi szintjének megfelelő hagyományos és digitális alapú nyelvtanulási forrásokat és eszközöket.',
		b: 9,
		c: 2184,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Nyelvtudását egyre inkább képes fejleszteni tanórán kívüli helyzetekben is különböző eszközökkel és lehetőségekkel.',
		b: 9,
		c: 2183,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Kommunikációs szándékának megfelelően alkalmazza a tanult nyelvi funkciókat és a megszerzett szociolingvisztikai, pragmatikai és interkulturális jártasságát.',
		b: 9,
		c: 2182,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'A tanult nyelvi elemek és kommunikációs stratégiák segítségével írásbeli és szóbeli interakciót folytat, valamint közvetít az élő idegen nyelven.',
		b: 9,
		c: 2181,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Értelmez korának és nyelvi szintjének megfelelő hallott és írott célnyelvi szövegeket az ismert témákban és szövegtípusokban.',
		b: 9,
		c: 2180,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Szóban és írásban létrehoz rövid szövegeket, ismert nyelvi eszközökkel, a korának megfelelő szövegtípusokban.',
		b: 9,
		c: 2179,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Szóban és írásban megold változatos kihívásokat igénylő feladatokat az élő idegen nyelven.',
		b: 9,
		c: 2178,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Létrehoz nagyon egyszerű írott, pár szavas szöveget szóban vagy írásban digitális felületen.',
		b: 9,
		c: 2177,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'digitális eszközök és felületek használata idegen nyelven'])
	},
		{
		a: 'Megérti a tanult nyelvi elemeket életkorának megfelelő digitális tartalmakban, digitális csatornákon olvasott vagy hallott nagyon egyszerű szövegekben is.',
		b: 9,
		c: 2176,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'digitális eszközök és felületek használata idegen nyelven'])
	},
		{
		a: 'Ismeri a főbb, célnyelvi kultúrához tartozó, ünnepekhez kapcsolódó alapszintű kifejezéseket, állandósult szókapcsolatokat és szokásokat.',
		b: 9,
		c: 2175,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Megismeri a főbb, az adott célnyelvi kultúrákhoz tartozó országok nevét, földrajzi elhelyezkedését, főbb országismereti jellemzőit.',
		b: 9,
		c: 2174,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'interkulturalitás, országismeret'])
	},
		{
		a: 'Alapvető célzott információt megszerez a tanult témákban tudásának bővítésére.',
		b: 9,
		c: 2173,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Felhasznál és létrehoz rövid, nagyon egyszerű célnyelvi szövegeket szabadidős tevékenységek során.',
		b: 9,
		c: 2172,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Felismeri az idegen nyelvű írott, olvasott és hallott tartalmakat a tanórán kívül is.',
		b: 9,
		c: 2171,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Egy feladat megoldásának sikerességét segítséggel értékelni tudja.',
		b: 9,
		c: 2170,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'A célok eléréséhez társaival rövid feladatokban együttműködik.',
		b: 9,
		c: 2169,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Szavak, kifejezések tanulásakor felismeri, ha új elemmel találkozik és rákérdez, vagy megfelelő tanulási stratégiával törekszik a megértésre.',
		b: 9,
		c: 2168,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Új szavak, kifejezések tanulásakor ráismer a már korábban tanult szavakra, kifejezésekre.',
		b: 9,
		c: 2167,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'önálló nyelvtanulás'])
	},
		{
		a: 'Közöl alapvető személyes információkat magáról, egyszerű nyelvi elemek segítségével.',
		b: 9,
		c: 2166,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, a nem értés, visszakérdezés és ismétlés, kérés kifejezésére használt mindennapi nyelvi funkciókat életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 9,
		c: 2165,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, a tudás és nem tudás kifejezésére használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 9,
		c: 2164,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, a köszönet és az arra történő reagálás kifejezésére használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 9,
		c: 2163,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, megszólításra használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 9,
		c: 2162,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és alkalmazza a legegyszerűbb, bemutatkozásra használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 9,
		c: 2161,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Felismeri és alkalmazza a legegyszerűbb, üdvözlésre és elköszönésre használt mindennapi nyelvi funkciókat az életkorának és nyelvi szintjének megfelelő, egyszerű helyzetekben.',
		b: 9,
		c: 2160,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'mindennapi idegennyelv-használat', 'interakció idegen nyelven'])
	},
		{
		a: 'Elsajátítja a tanult szavak és állandósult szókapcsolatok célnyelvi normához közelítő kiejtését tanári minta követése által, vagy autentikus hangzó anyag, digitális technológia segítségével.',
		b: 9,
		c: 2159,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Érzéseit egy-két szóval vagy begyakorolt állandósult nyelvi fordulatok segítségével kifejezi, főként rákérdezés alapján, nonverbális eszközökkel kísérve a célnyelvi megnyilatkozást.',
		b: 9,
		c: 2158,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'A tanórán bekapcsolódik a már ismert, szóbeli interakciót igénylő nyelvi tevékenységekbe, a begyakorolt nyelvi elemeket tanári segítséggel a tevékenység céljainak megfelelően alkalmazza.',
		b: 9,
		c: 2157,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Rövid, néhány mondatból álló párbeszédet folytat, felkészülést követően.',
		b: 9,
		c: 2156,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Törekszik arra, hogy a célnyelvet eszközként alkalmazza információszerzésre.',
		b: 9,
		c: 2155,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'A tanórán begyakorolt, nagyon egyszerű, egyértelmű kommunikációs helyzetekben a megtanult, állandósult beszédfordulatok alkalmazásával kérdez vagy reagál, mondanivalóját segítséggel vagy nonverbális eszközökkel kifejezi.',
		b: 9,
		c: 2154,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'interakció idegen nyelven', 'beszédkészség: szóbeli interakció'])
	},
		{
		a: 'Aktívan bekapcsolódik a közös meseolvasásba, a mese tartalmát követi.',
		b: 9,
		c: 2153,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megtapasztalja a közös célnyelvi olvasás élményét.',
		b: 9,
		c: 2152,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Támaszkodik az életkorának és nyelvi szintjének megfelelő írott szövegre az órai játékos alkotó, mozgásos vagy nyelvi fejlesztő tevékenységek során, kooperatív munkaformákban.',
		b: 9,
		c: 2151,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Kiemeli az ismert nyelvi elemeket tartalmazó, egyszerű, írott, pár mondatos szöveg fő mondanivalóját.',
		b: 9,
		c: 2150,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megérti a nyelvi szintjének megfelelő, akár vizuális eszközökkel is támogatott írott utasításokat és kérdéseket, és ezekre megfelelő válaszreakciókat ad.',
		b: 9,
		c: 2149,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Csendes olvasás keretében feldolgozva megért ismert szavakat tartalmazó, pár szóból vagy mondatból álló, akár illusztrációval támogatott szöveget.',
		b: 9,
		c: 2148,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Beazonosítja a célzott információt az életkorának megfelelő szituációkhoz kapcsolódó, rövid, egyszerű, a nyelvtanításhoz készült, illetve eredeti szövegben.',
		b: 9,
		c: 2147,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Megkülönbözteti az anyanyelvi és a célnyelvi írott szövegben a betű- és jelkészlet közti különbségeket.',
		b: 9,
		c: 2146,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'olvasásértés'])
	},
		{
		a: 'Figyel a célnyelvre jellemző hangok kiejtésére.',
		b: 9,
		c: 2145,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Felismeri az anyanyelve és a célnyelv közötti legalapvetőbb kiejtésbeli különbségeket.',
		b: 9,
		c: 2144,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Értelmezi azokat az idegen nyelven szóban elhangzó nyelvórai szituációkat, melyeket anyanyelvén már ismer.',
		b: 9,
		c: 2143,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Felismeri az anyanyelv és az idegen nyelv hangkészletét.',
		b: 9,
		c: 2142,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Támaszkodik az életkorának és nyelvi szintjének megfelelő hangzó szövegre az órai alkotó jellegű nyelvi, mozgásos nyelvi és játékos nyelvi tevékenységek során.',
		b: 9,
		c: 2141,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Azonosítja a célzott információt a nyelvi szintjének és életkorának megfelelő rövid hangzó szövegben.',
		b: 9,
		c: 2140,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Kiszűri a lényeget az ismert nyelvi elemeket tartalmazó, nagyon rövid, egyszerű hangzó szövegből.',
		b: 9,
		c: 2139,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Beazonosítja az életkorának megfelelő szituációkhoz kapcsolódó, rövid, egyszerű szövegben a tanult nyelvi elemeket.',
		b: 9,
		c: 2138,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Megérti az élőszóban elhangzó, ismert témákhoz kapcsolódó, verbális, vizuális vagy nonverbális eszközökkel segített rövid kijelentéseket, kérdéseket.',
		b: 9,
		c: 2137,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegértés idegen nyelven', 'beszédértés'])
	},
		{
		a: 'Írásban megnevezi az ajánlott tématartományokban megjelölt, begyakorolt elemeket.',
		b: 9,
		c: 2136,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Részt vesz kooperatív munkaformában végzett kreatív tevékenységekben, projektmunkában szavak, szószerkezetek, rövid mondatok leírásával, esetleg képi kiegészítéssel.',
		b: 9,
		c: 2135,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Megold játékos írásbeli feladatokat a szavak, szószerkezetek, rövid mondatok szintjén.',
		b: 9,
		c: 2134,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Lemásol tanult szavakat játékos, alkotó nyelvórai tevékenységek során.',
		b: 9,
		c: 2133,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Ismeri az adott nyelv ábécéjét.',
		b: 9,
		c: 2132,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Felismeri az anyanyelvén, illetve a tanult idegen nyelven történő írásmód és betűkészlet közötti különbségeket.',
		b: 9,
		c: 2131,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'íráskészség'])
	},
		{
		a: 'Célnyelven megoszt egyedül vagy társaival együttműködésben megszerzett, alapvető információkat szóban, akár vizuális elemekkel támogatva.',
		b: 9,
		c: 2130,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: szóbeliség'])
	},
		{
		a: 'Lebetűzi a tanult szavakat társaival közösen játékos tevékenységek kapcsán, szükség esetén segítséggel.',
		b: 9,
		c: 2129,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: szóbeliség'])
	},
		{
		a: 'Lebetűzi a nevét.',
		b: 9,
		c: 2128,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: szóbeliség'])
	},
		{
		a: 'Megismétli az élőszóban elhangzó egyszerű szavakat, kifejezéseket játékos, mozgást igénylő, kreatív nyelvórai tevékenységek során.',
		b: 9,
		c: 2127,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['második idegen nyelv', 'szövegalkotás idegen nyelven', 'beszédkészség: szóbeliség'])
	},
		{
		a: 'Célnyelvi tanulmányain keresztül nyitottabbá, a világ felé érdeklődőbbé válik.',
		b: 9,
		c: 2126,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Törekszik a tanult nyelvi elemek megfelelő kiejtésére.',
		b: 9,
		c: 2125,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Ismeri az adott célnyelvi kultúrákhoz tartozó országok fontosabb jellemzőit és a hozzájuk tartozó alapvető nyelvi elemeket.',
		b: 9,
		c: 2124,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Elmondja magáról a legalapvetőbb információkat.',
		b: 9,
		c: 2123,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Felismeri és használja a legegyszerűbb, mindennapi nyelvi funkciókat.',
		b: 9,
		c: 2122,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Követi a szintjének megfelelő, vizuális vagy nonverbális eszközökkel támogatott, ismert célnyelvi óravezetést, utasításokat.',
		b: 9,
		c: 2121,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Lemásol, leír szavakat és rövid, nagyon egyszerű szövegeket.',
		b: 9,
		c: 2120,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Szóban visszaad szavakat, esetleg rövid, nagyon egyszerű szövegeket hoz létre.',
		b: 9,
		c: 2119,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Bekapcsolódik a szóbeliséget, írást, szövegértést vagy interakciót igénylő alapvető és korának megfelelő játékos, élményalapú élő idegen nyelvi tevékenységekbe.',
		b: 9,
		c: 2118,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Megismerkedik az idegen nyelvvel, a nyelvtanulással és örömmel vesz részt az órákon.',
		b: 9,
		c: 2117,
		d: 12,
		e: 'Második idegen nyelv@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'második idegen nyelv'])
	},
		{
		a: 'Alkalmazza a tanult infokommunikációs ismereteket matematikai problémák megoldása során.',
		b: 1,
		c: 1881,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'digitáliseszköz-használat'])
	},
		{
		a: 'Használ matematikai képességfejlesztő számítógépes játékokat, programokat.',
		b: 1,
		c: 1880,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'digitáliseszköz-használat'])
	},
		{
		a: 'Nyelvi szempontból megfelelő választ ad a feladatokban megjelenő kérdésekre.',
		b: 1,
		c: 1879,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'matematikai kommunikáció'])
	},
		{
		a: 'Kérdést fogalmaz meg, ha munkája során nehézségbe ütközik.',
		b: 1,
		c: 1878,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'matematikai kommunikáció'])
	},
		{
		a: 'Játékos feladatokban személyeket, tárgyakat, számokat, formákat néhány meghatározó tulajdonsággal jellemez.',
		b: 1,
		c: 1877,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'matematikai kommunikáció'])
	},
		{
		a: 'Szöveget, ábrát alkot matematikai jelekhez, műveletekhez.',
		b: 1,
		c: 1876,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'matematikai kommunikáció'])
	},
		{
		a: 'Megfelelően használja szóban és írásban a nyelvtani szerkezeteket matematikai tartalmuk szerint.',
		b: 1,
		c: 1875,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'matematikai kommunikáció'])
	},
		{
		a: 'Megfelelő szókincset és jeleket használ mennyiségi viszonyok kifejezésére szóban és írásban.',
		b: 1,
		c: 1874,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'matematikai kommunikáció'])
	},
		{
		a: 'Szöveges feladatokban a különböző kifejezésekkel megfogalmazott műveleteket megérti.',
		b: 1,
		c: 1873,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'matematikai kommunikáció'])
	},
		{
		a: 'Helyesen használja a mennyiségi viszonyokat kifejező szavakat, nyelvtani szerkezeteket.',
		b: 1,
		c: 1872,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'matematikai kommunikáció'])
	},
		{
		a: 'Önállóan értelmezi a hallott, olvasott matematikai tartalmú szöveget.',
		b: 1,
		c: 1871,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'matematikai kommunikáció'])
	},
		{
		a: 'A valószínűségi játékokban, kísérletekben megfogalmazott előzetes sejtését, tippjét összeveti a megfigyelt előfordulásokkal.',
		b: 1,
		c: 1870,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Tetszőleges vagy megadott módszerrel összeszámlálja az egyes kimenetelek előfordulásait olyan egyszerű játékokban, kísérletekben, amelyekben a véletlen szerepet játszik.',
		b: 1,
		c: 1869,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Tapasztalatai alapján tippet fogalmaz meg arról, hogy két esemény közül melyik esemény valószínűbb olyan véletlentől függő szituációk során, melyekben a két esemény valószínűsége között jól belátható a különbség.',
		b: 1,
		c: 1868,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Megítéli „biztos\", „lehetetlen\", „lehetséges, de nem biztos\" eseményekkel kapcsolatos állítások igazságát.',
		b: 1,
		c: 1867,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Különbséget tesz tapasztalatai alapján a „biztos\", „lehetetlen\", „lehetséges, de nem biztos\" események között.',
		b: 1,
		c: 1866,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Részt vesz olyan játékokban, kísérletekben, melyekben a véletlen szerepet játszik.',
		b: 1,
		c: 1865,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Jellemzi az összességeket.',
		b: 1,
		c: 1864,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'adatok megfigyelése'])
	},
		{
		a: 'Adatokat gyűjt ki táblázatból, adatokat olvas le diagramról.',
		b: 1,
		c: 1863,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'adatok megfigyelése'])
	},
		{
		a: 'Gyűjtött adatokat táblázatba rendez, diagramon ábrázol.',
		b: 1,
		c: 1862,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'adatok megfigyelése'])
	},
		{
		a: 'Adatokat rögzít későbbi elemzés céljából.',
		b: 1,
		c: 1861,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'adatok megfigyelése'])
	},
		{
		a: 'Adatokat gyűjt a környezetében.',
		b: 1,
		c: 1860,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'adatok megfigyelése'])
	},
		{
		a: 'A sorozatban, táblázatban, gépjátékokban felismert összefüggést megfogalmazza saját szavaival, nyíljelöléssel vagy nyitott mondattal.',
		b: 1,
		c: 1859,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'szabályok értelmezése'])
	},
		{
		a: 'Szabályjátékok során létrehoz a felismert kapcsolat alapján további elempárokat, elemhármasokat.',
		b: 1,
		c: 1858,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'szabályok értelmezése'])
	},
		{
		a: 'Felismer kapcsolatot elempárok, elemhármasok tagjai között.',
		b: 1,
		c: 1857,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'szabályok értelmezése'])
	},
		{
		a: 'Tárgyakkal, számokkal kapcsolatos gépjátékhoz szabályt alkot; felismeri az egyszerű gép megfordításával nyert gép szabályát.',
		b: 1,
		c: 1856,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'szabályok értelmezése'])
	},
		{
		a: 'Ismert műveletekkel alkotott sorozat, táblázat szabályát felismeri; ismert szabály szerint megkezdett sorozatot, táblázatot helyesen, önállóan folytat.',
		b: 1,
		c: 1855,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'szabályok értelmezése'])
	},
		{
		a: 'Elsorolja az évszakokat, hónapokat, napokat, napszakokat egymás után, tetszőleges kezdőponttól is.',
		b: 1,
		c: 1854,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'szabályok értelmezése'])
	},
		{
		a: 'Tárgyakkal, logikai készletek elemeivel kirakott periodikus sorozatokat folytat.',
		b: 1,
		c: 1853,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'szabályok értelmezése'])
	},
		{
		a: 'Megadott szabály szerint sorozatot alkot; megértett probléma értelmezéséhez, megoldásához sorozatot, táblázatot állít elő modellként.',
		b: 1,
		c: 1852,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'szabályok értelmezése'])
	},
		{
		a: 'Összefüggéseket keres sorozatok elemei között.',
		b: 1,
		c: 1851,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'összefüggések és kapcsolatok felismerése'])
	},
		{
		a: 'Megfogalmazza a felismert összefüggéseket.',
		b: 1,
		c: 1850,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'összefüggések és kapcsolatok felismerése'])
	},
		{
		a: 'Érti a problémákban szereplő adatok viszonyát.',
		b: 1,
		c: 1849,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'összefüggések és kapcsolatok felismerése'])
	},
		{
		a: 'Megfogalmazza a személyek, tárgyak, dolgok, időpontok, számok, testek, síklapok közötti egyszerű viszonyokat, kapcsolatokat.',
		b: 1,
		c: 1848,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'összefüggések és kapcsolatok felismerése'])
	},
		{
		a: 'Részt vesz memóriajátékokban különféle tulajdonságok szerinti párok keresésében.',
		b: 1,
		c: 1847,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'összefüggések és kapcsolatok felismerése'])
	},
		{
		a: 'Térképen, négyzethálón megtalál pontot két adat segítségével.',
		b: 1,
		c: 1846,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'térbeli és síkbeli tájékozódás'])
	},
		{
		a: 'Tájékozódik lakóhelyén, bejárt terepen: bejárt útvonalon visszatalál adott helyre, adott utca és házszám alapján megtalál házat.',
		b: 1,
		c: 1845,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'térbeli és síkbeli tájékozódás'])
	},
		{
		a: 'Helyesen használja az irányokat és távolságokat jelölő kifejezéseket térben és síkon.',
		b: 1,
		c: 1844,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'térbeli és síkbeli tájékozódás'])
	},
		{
		a: 'Térben, síkban az eredetihez hasonló testeket, síkidomokat alkot nagyított vagy kicsinyített elemekből; az eredetihez hasonló síkidomokat rajzol hálón.',
		b: 1,
		c: 1843,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'transzformációk'])
	},
		{
		a: 'Követi a sormintában vagy a síkmintában lévő szimmetriát.',
		b: 1,
		c: 1842,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'transzformációk'])
	},
		{
		a: 'Ellenőrzi a tükrözés, eltolás helyességét tükör vagy másolópapír segítségével.',
		b: 1,
		c: 1841,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'transzformációk'])
	},
		{
		a: 'Megépíti, kirakja, megrajzolja hálón, jelölés nélküli lapon sablonnal, másolópapír segítségével alakzat tükörképét, eltolt képét.',
		b: 1,
		c: 1840,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'transzformációk'])
	},
		{
		a: 'Szimmetrikus alakzatokat hoz létre térben, síkban különböző eszközökkel; felismeri a szimmetriát valóságos dolgokon, síkbeli alakzatokon.',
		b: 1,
		c: 1839,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'transzformációk'])
	},
		{
		a: 'Tapasztalattal rendelkezik mozgással, kirakással a tükörkép előállításáról.',
		b: 1,
		c: 1838,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'transzformációk'])
	},
		{
		a: 'Megfigyelt tulajdonságaival jellemzi a létrehozott síkbeli és térbeli alkotást, mintázatot.',
		b: 1,
		c: 1837,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megnevezi megfigyelt tulajdonságai alapján a téglatestet, kockát, téglalapot, négyzetet.',
		b: 1,
		c: 1836,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megfigyeli a kocka mint speciális téglatest és a négyzet mint speciális téglalap tulajdonságait.',
		b: 1,
		c: 1835,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megmutatja a téglalap azonos hosszúságú oldalait és elhelyezkedésüket, megmutatja és megszámlálja a téglalap átlóit és szimmetriatengelyeit.',
		b: 1,
		c: 1834,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Tudja a téglalap oldalainak és csúcsainak számát, összehajtással megmutatja a téglalap szögeinek egyenlőségét.',
		b: 1,
		c: 1833,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megnevezi a téglatest lapjainak alakját, felismeri a téglatesten az egybevágó lapokat, megkülönbözteti a téglatesten az éleket, csúcsokat.',
		b: 1,
		c: 1832,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megszámlálja az egyszerű szögletes test lapjait.',
		b: 1,
		c: 1831,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megkülönböztet tükrösen szimmetrikus és tükrösen nem szimmetrikus síkbeli alakzatokat.',
		b: 1,
		c: 1830,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megnevezi a háromszögeket, négyszögeket, köröket.',
		b: 1,
		c: 1829,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Kiválasztja megadott síkidomok közül a sokszögeket.',
		b: 1,
		c: 1828,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megnevezi a sík és görbült felületeket, az egyenes és görbe vonalakat, szakaszokat tapasztalati ismeretei alapján.',
		b: 1,
		c: 1827,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Különbséget tesz testek és síkidomok között.',
		b: 1,
		c: 1826,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megnevezi a tevékenységei során előállított, válogatásai során előkerülő alakzatokon megfigyelt tulajdonságokat.',
		b: 1,
		c: 1825,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megtalálja a közös tulajdonsággal nem rendelkező alakzatokat.',
		b: 1,
		c: 1824,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megfigyeli az alakzatok közös tulajdonságát, megfelelő címkéket talál megadott és halmazokba rendezett alakzatokhoz.',
		b: 1,
		c: 1823,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megkülönbözteti és szétválogatja szabadon választott vagy meghatározott geometriai tulajdonságok szerint a gyűjtött, megalkotott testeket, síkidomokat.',
		b: 1,
		c: 1822,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alakzatok geometriai tulajdonságai'])
	},
		{
		a: 'Megfogalmazza az alkotásai közti különbözőséget.',
		b: 1,
		c: 1821,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás térben és síkon'])
	},
		{
		a: 'Megtalálja az összes, több feltételnek megfelelő építményt, síkbeli kirakást.',
		b: 1,
		c: 1820,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás térben és síkon'])
	},
		{
		a: 'Alaklemezt, vonalzót, körzőt használ alkotáskor.',
		b: 1,
		c: 1819,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás térben és síkon'])
	},
		{
		a: 'Síkidomokat hoz létre különféle eszközök segítségével.',
		b: 1,
		c: 1818,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás térben és síkon'])
	},
		{
		a: 'Testeket épít élekből, lapokból; elkészíti a testek élvázát, hálóját; testeket épít képek, alaprajzok alapján; elkészíti egyszerű testek alaprajzát.',
		b: 1,
		c: 1817,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás térben és síkon'])
	},
		{
		a: 'Alkotásában követi az adott feltételeket.',
		b: 1,
		c: 1816,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás térben és síkon'])
	},
		{
		a: 'Sormintát, síkmintát felismer, folytat.',
		b: 1,
		c: 1815,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás térben és síkon'])
	},
		{
		a: 'Minta alapján létrehoz térbeli, síkbeli alkotásokat.',
		b: 1,
		c: 1814,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás térben és síkon'])
	},
		{
		a: 'Szabadon épít, kirak formát, mintát adott testekből, síklapokból.',
		b: 1,
		c: 1813,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás térben és síkon'])
	},
		{
		a: 'Nagyság szerint összehasonlítja a természetes számokat és a negatív egész számokat a használt modellen belül.',
		b: 1,
		c: 1812,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'törtrészek, negatív számok'])
	},
		{
		a: 'A negatív egész számokat irányított mennyiségként (hőmérséklet, tengerszint alatti magasság, idő) és hiányként (adósság) értelmezi.',
		b: 1,
		c: 1811,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'törtrészek, negatív számok'])
	},
		{
		a: 'A kirakást, mérést és a rajzot mint modellt használja a törtrészek összehasonlítására.',
		b: 1,
		c: 1810,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'törtrészek, negatív számok'])
	},
		{
		a: 'Tevékenységekkel megjelenít egységtörteket és azok többszöröseit különféle mennyiségek és többféle egységválasztás esetén.',
		b: 1,
		c: 1809,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'törtrészek, negatív számok'])
	},
		{
		a: 'Helyesen végzi el az írásbeli szorzást egy- és kétjegyű szorzóval, az írásbeli osztást egyjegyű osztóval.',
		b: 1,
		c: 1808,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Helyesen végzi el az írásbeli összeadást, kivonást.',
		b: 1,
		c: 1807,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Teljes négyjegyűek összegét, különbségét százasokra kerekített értékekkel megbecsüli, teljes kétjegyűek két- és egyjegyűvel való szorzatát megbecsüli.',
		b: 1,
		c: 1806,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Elvégzi a feladathoz szükséges észszerű becslést, mérlegeli a becslés során kapott eredményt.',
		b: 1,
		c: 1805,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Érti a 10-zel, 100-zal, 1000-rel való szorzás, osztás kapcsolatát a helyiérték-táblázatban való jobbra, illetve balra tolódással, fejben pontosan számol a 10 000-es számkörben a számok 10-zel, 100-zal, 1000-rel történő szorzásakor és maradék nélküli osztásakor.',
		b: 1,
		c: 1804,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Fejben pontosan számol a 10 000-es számkörben a 100-as számkörben végzett műveletekkel analóg esetekben.',
		b: 1,
		c: 1803,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Fejben pontosan számol a 100-as számkörben egyjegyűvel való szorzás és maradék nélküli osztás során.',
		b: 1,
		c: 1802,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Emlékezetből tudja a kisegyszeregy és a megfelelő bennfoglalások, egyenlő részekre osztások eseteit a számok tízszereséig.',
		b: 1,
		c: 1801,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Érti a szorzó- és bennfoglaló táblák kapcsolatát.',
		b: 1,
		c: 1800,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Fejben pontosan összead és kivon a 100-as számkörben.',
		b: 1,
		c: 1799,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Alkalmazza a számolást könnyítő eljárásokat.',
		b: 1,
		c: 1798,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számolás'])
	},
		{
		a: 'Szöveghez, valós helyzethez kapcsolva zárójelet tartalmazó műveletsort értelmez, elvégez.',
		b: 1,
		c: 1797,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'alapműveletek'])
	},
		{
		a: 'Alkalmazza a műveletekben szereplő számok (kisebbítendő, kivonandó és különbség; tagok és összeg; tényezők és szorzat; osztandó, osztó és hányados) változtatásának következményeit.',
		b: 1,
		c: 1796,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'alapműveletek'])
	},
		{
		a: 'Megold hiányos műveletet, műveletsort az eredmény ismeretében, a műveletek megfordításával is.',
		b: 1,
		c: 1795,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'alapműveletek'])
	},
		{
		a: 'Számolásaiban felhasználja a műveletek közti kapcsolatokat, számolásai során alkalmazza konkrét esetekben a legfontosabb műveleti tulajdonságokat.',
		b: 1,
		c: 1794,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'alapműveletek'])
	},
		{
		a: 'Megérti a következő kifejezéseket: tagok, összeg, kisebbítendő, kivonandó, különbség, tényezők, szorzandó, szorzó, szorzat, osztandó, osztó, hányados, maradék.',
		b: 1,
		c: 1793,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'alapműveletek'])
	},
		{
		a: 'Értelmezi a műveleteket megjelenítéssel, modellezéssel, szöveges feladattal.',
		b: 1,
		c: 1792,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'alapműveletek'])
	},
		{
		a: 'Hozzákapcsolja a megfelelő műveletet adott helyzethez, történéshez, egyszerű szöveges feladathoz.',
		b: 1,
		c: 1791,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'alapműveletek'])
	},
		{
		a: 'Helyesen használja a műveletek jeleit.',
		b: 1,
		c: 1790,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'alapműveletek'])
	},
		{
		a: 'Helyesen értelmezi a 10 000-es számkörben az összeadást, a kivonást, a szorzást, a bennfoglaló és az egyenlő részekre osztást.',
		b: 1,
		c: 1789,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'alapműveletek'])
	},
		{
		a: 'Ismer a terület és kerület mérésére irányuló tevékenységeket.',
		b: 1,
		c: 1788,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Területet mér különböző egységekkel lefedéssel vagy darabolással.',
		b: 1,
		c: 1787,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Megméri különböző sokszögek kerületét különböző egységekkel.',
		b: 1,
		c: 1786,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Összeveti azonos egységgel mért mennyiség és mérőszáma nagyságát, összeveti ugyanannak a mennyiségnek a különböző egységekkel való mérésekor kapott mérőszámait.',
		b: 1,
		c: 1785,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Alkalmazza a felváltást és beváltást különböző pénzcímletek között.',
		b: 1,
		c: 1784,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Ismer hazai és külföldi pénzcímleteket 10 000-es számkörben.',
		b: 1,
		c: 1783,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Ismeri az időmérés szabványegységeit: az órát, a percet, a másodpercet, a napot, a hetet, a hónapot, az évet.',
		b: 1,
		c: 1782,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Helyesen használja a hosszúságmérés, az űrtartalommérés és a tömegmérés szabványegységei közül a következőket: mm, cm, dm, m, km; ml, cl, dl, l; g, dkg, kg.',
		b: 1,
		c: 1781,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Helyesen alkalmazza a mérési módszereket, használ skálázott mérőeszközöket, helyes képzete van a mértékegységek nagyságáról.',
		b: 1,
		c: 1780,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Megbecsül, mér alkalmi és szabványos mértékegységekkel hosszúságot, tömeget, űrtartalmat és időt.',
		b: 1,
		c: 1779,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérőeszköz használata, mérési módszerek'])
	},
		{
		a: 'Helyesen írja és olvassa a számokat a tízes számrendszerben 10 000-ig.',
		b: 1,
		c: 1778,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számok helyi értékes alakja'])
	},
		{
		a: 'Érti a számok számjegyeinek helyi, alaki, valódi értékét.',
		b: 1,
		c: 1777,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számok helyi értékes alakja'])
	},
		{
		a: 'Érti a számok ezresekből, százasokból, tízesekből és egyesekből való épülését, ezresek, százasok, tízesek és egyesek összegére való bontását.',
		b: 1,
		c: 1776,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számok helyi értékes alakja'])
	},
		{
		a: 'Összekapcsolja a tízes számrendszerben a számok épülését a különféle számrendszerekben végzett tevékenységeivel.',
		b: 1,
		c: 1775,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'számok helyi értékes alakja'])
	},
		{
		a: 'Ismeri a római számjelek közül az i, v, x jeleket, hétköznapi helyzetekben felismeri az ezekkel képzett számokat.',
		b: 1,
		c: 1774,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Helyesen írja az arab számjeleket.',
		b: 1,
		c: 1773,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Számot jellemez más számokhoz való viszonyával.',
		b: 1,
		c: 1772,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Számokat jellemez tartalmi és formai tulajdonságokkal.',
		b: 1,
		c: 1771,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Megnevezi a 10 000-es számkör számainak egyes, tízes, százas, ezres szomszédjait, tízesekre, százasokra, ezresekre kerekített értékét.',
		b: 1,
		c: 1770,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Megtalálja a számok helyét, közelítő helyét egyszerű számegyenesen, számtáblázatokban, a számegyenesnek ugyanahhoz a pontjához rendeli a számokat különféle alakjukban a 10 000-es számkörben.',
		b: 1,
		c: 1769,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Megadja és azonosítja számok sokféle műveletes alakját.',
		b: 1,
		c: 1768,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Nagyság szerint sorba rendez számokat, mennyiségeket.',
		b: 1,
		c: 1767,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Ismeri a következő becslési módszereket: közelítő számlálás, közelítő mérés, mérés az egység többszörösével; becslését finomítja újrabecsléssel.',
		b: 1,
		c: 1766,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Megszámlál és leszámlál; adott (alkalmilag választott vagy szabványos) egységgel meg- és kimér a 10 000-es számkörben; oda-vissza számlál kerek tízesekkel, százasokkal, ezresekkel.',
		b: 1,
		c: 1765,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Kis darabszámokat ránézésre felismer többféle rendezett alakban.',
		b: 1,
		c: 1764,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Használja a kisebb, nagyobb, egyenlő kifejezéseket a természetes számok körében.',
		b: 1,
		c: 1763,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Érti és helyesen használja a több, kevesebb, ugyanannyi relációkat halmazok elemszámával kapcsolatban, valamint a kisebb, nagyobb, ugyanakkora relációkat a megismert mennyiségekkel (hosszúság, tömeg, űrtartalom, idő, terület, pénz) kapcsolatban 10 000-es számkörben.',
		b: 1,
		c: 1762,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'természetes számok és kapcsolataik'])
	},
		{
		a: 'Egy- és többszemélyes logikai játékban döntéseit mérlegelve előre gondolkodik.',
		b: 1,
		c: 1761,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'ismeretek felhasználása'])
	},
		{
		a: 'Ismeretei alapján megfogalmaz önállóan is egyszerű állításokat.',
		b: 1,
		c: 1760,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'ismeretek felhasználása'])
	},
		{
		a: 'Egy állításról ismeretei alapján eldönti, hogy igaz vagy hamis.',
		b: 1,
		c: 1759,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'ismeretek felhasználása'])
	},
		{
		a: 'Példákat gyűjt konkrét tapasztalatai alapján matematikai állítások alátámasztására.',
		b: 1,
		c: 1758,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'ismeretek felhasználása'])
	},
		{
		a: 'Összekapcsolja az azonos matematikai tartalmú tevékenységek során szerzett tapasztalatait.',
		b: 1,
		c: 1757,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'ismeretek felhasználása'])
	},
		{
		a: 'Alkalmazza a felismert törvényszerűségeket analógiás esetekben.',
		b: 1,
		c: 1756,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'ismeretek felhasználása'])
	},
		{
		a: 'Kérésre, illetve problémahelyzetben felidézi a kívánt, szükséges emlékképet.',
		b: 1,
		c: 1755,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'ismeretek felhasználása'])
	},
		{
		a: 'Tudatosan emlékezetébe vés szavakat, számokat, utasítást, adott helyzetre vonatkozó megfogalmazást.',
		b: 1,
		c: 1754,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'ismeretek felhasználása'])
	},
		{
		a: 'Tudatosan emlékezetébe vési az észlelt tárgyakat, személyeket, dolgokat, és ezek jellemző tulajdonságait, elrendezését, helyzetét.',
		b: 1,
		c: 1753,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'ismeretek felhasználása'])
	},
		{
		a: 'Választ fogalmaz meg a felvetett kérdésre.',
		b: 1,
		c: 1752,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'szöveges feladatok megoldása'])
	},
		{
		a: 'A modellben kapott megoldást értelmezi az eredeti problémára; arra vonatkoztatva ellenőrzi a megoldást.',
		b: 1,
		c: 1751,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'szöveges feladatok megoldása'])
	},
		{
		a: 'A megválasztott modellen belül meghatározza a keresett adatokat.',
		b: 1,
		c: 1750,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'szöveges feladatok megoldása'])
	},
		{
		a: 'Az értelmezett szöveges feladathoz hozzákapcsol jól megismert matematikai modellt.',
		b: 1,
		c: 1749,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'szöveges feladatok megoldása'])
	},
		{
		a: 'Megkülönbözteti a lényeges és a lényegtelen adatokat.',
		b: 1,
		c: 1748,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'szöveges feladatok megoldása'])
	},
		{
		a: 'Megkülönbözteti az ismert és a keresendő (ismeretlen) adatokat.',
		b: 1,
		c: 1747,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'szöveges feladatok megoldása'])
	},
		{
		a: 'Tevékenység, ábrarajzolás segítségével megold egyszerű, következtetéses, szöveges feladatokat.',
		b: 1,
		c: 1746,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'szöveges feladatok megoldása'])
	},
		{
		a: 'Szöveges feladatokban megfogalmazott hétköznapi problémát megold matematikai ismeretei segítségével.',
		b: 1,
		c: 1745,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'szöveges feladatok megoldása'])
	},
		{
		a: 'Értelmezi, elképzeli, megjeleníti a szöveges feladatban megfogalmazott hétköznapi szituációt.',
		b: 1,
		c: 1744,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'szöveges feladatok megoldása'])
	},
		{
		a: 'Kérdést tesz fel a megfogalmazott probléma kapcsán.',
		b: 1,
		c: 1743,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'Megoldását értelmezi, ellenőrzi.',
		b: 1,
		c: 1742,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'A problémamegoldás során a sorrendben végzett tevékenységeket szükség szerint visszafelé is elvégzi.',
		b: 1,
		c: 1741,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'Az értelmezett problémát megoldja.',
		b: 1,
		c: 1740,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'Megfogalmazott problémát tevékenységgel, megjelenítéssel, átfogalmazással értelmez.',
		b: 1,
		c: 1739,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'A tevékenysége során felmerülő problémahelyzetben megoldást keres.',
		b: 1,
		c: 1738,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'Megkeresi két, három szempont szerint teljes rendszert alkotó, legfeljebb 48 elemű készlet hiányzó elemeit, felismeri az elemek által meghatározott rendszert.',
		b: 1,
		c: 1737,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés, rendszerezés'])
	},
		{
		a: 'Megfogalmazza a rendezés felismert szempontjait.',
		b: 1,
		c: 1736,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés, rendszerezés'])
	},
		{
		a: 'Megkeresi egyszerű esetekben a két, három feltételnek megfelelő összes elemet, alkotást.',
		b: 1,
		c: 1735,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés, rendszerezés'])
	},
		{
		a: 'Két, három szempont szerint elrendez adott elemeket többféleképpen is; segédeszközként használja a táblázatos elrendezést és a fadiagramot.',
		b: 1,
		c: 1734,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés, rendszerezés'])
	},
		{
		a: 'Sorba rendezett elemek közé elhelyez további elemeket a felismert szempont szerint.',
		b: 1,
		c: 1733,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés, rendszerezés'])
	},
		{
		a: 'Adott elemeket elrendez választott és megadott szempont szerint is.',
		b: 1,
		c: 1732,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés, rendszerezés'])
	},
		{
		a: 'Helyesen érti és alkalmazza a feladatokban a „valamennyivel\" több, kevesebb fogalmakat.',
		b: 1,
		c: 1731,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok mint a természetes szám fogalmának egyik tapasztalati alapja'])
	},
		{
		a: 'Helyesen alkalmazza a feladatokban a több, kevesebb, ugyanannyi fogalmakat 10 000-es számkörben.',
		b: 1,
		c: 1730,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok mint a természetes szám fogalmának egyik tapasztalati alapja'])
	},
		{
		a: 'Ismeri két halmaz elemeinek kölcsönösen egyértelmű megfeleltetését (párosítását) az elemszámok szerinti összehasonlításra.',
		b: 1,
		c: 1729,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok mint a természetes szám fogalmának egyik tapasztalati alapja'])
	},
		{
		a: 'Összehasonlít véges halmazokat az elemek száma szerint.',
		b: 1,
		c: 1728,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok mint a természetes szám fogalmának egyik tapasztalati alapja'])
	},
		{
		a: 'Hiányos állításokat igazzá tevő elemeket válogat megadott alaphalmazból.',
		b: 1,
		c: 1727,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Keresi az okát annak, ha a halmazábra valamelyik részébe nem kerülhet egyetlen elem sem.',
		b: 1,
		c: 1726,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Megítéli, hogy adott halmazra vonatkozó állítás igaz-e, vagy hamis.',
		b: 1,
		c: 1725,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Megfogalmazza a halmazábra egyes részeibe kerülő elemek közös, meghatározó tulajdonságát; helyesen használja a logikai „nem\" és a logikai „és\" szavakat, valamint velük azonos értelmű kifejezéseket.',
		b: 1,
		c: 1724,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Felsorol elemeket konkrét halmazok közös részéből.',
		b: 1,
		c: 1723,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Két meghatározott tulajdonság egyszerre történő figyelembevételével szétválogat adott elemeket: tárgyakat, személyeket, szavakat, számokat, alakzatokat.',
		b: 1,
		c: 1722,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Két szempontot is figyelembe vesz egyidejűleg.',
		b: 1,
		c: 1721,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Megfogalmaz adott halmazra vonatkozó állításokat; értelemszerűen használja a „mindegyik\", „nem mindegyik\", „van köztük...\", „\"egyik sem...\" és a velük rokon jelentésű szavakat.',
		b: 1,
		c: 1720,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Talál megfelelő címkéket halmazokba rendezett elemekhez.',
		b: 1,
		c: 1719,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Adott, címkékkel ellátott halmazábrán elhelyezett elemekről eldönti, hogy a megfelelő helyre kerültek-e; a hibás elhelyezést javítja.',
		b: 1,
		c: 1718,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Halmazábrán is elhelyez elemeket adott címkék szerint.',
		b: 1,
		c: 1717,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Barkochbázik valóságos és elképzelt dolgokkal is, kerüli a felesleges kérdéseket.',
		b: 1,
		c: 1716,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Megnevezi egy adott tulajdonság szerint ki nem válogatott elemek közös tulajdonságát a tulajdonság tagadásával.',
		b: 1,
		c: 1715,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Azonosítja a közös tulajdonsággal rendelkező dolgok halmazába nem való elemeket.',
		b: 1,
		c: 1714,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Személyek, tárgyak, dolgok, szavak, számok közül kiválogatja az adott tulajdonsággal rendelkező összes elemet.',
		b: 1,
		c: 1713,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Folytatja a megkezdett válogatást felismert szempont szerint.',
		b: 1,
		c: 1712,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Felismeri a mások válogatásában együvé kerülő dolgok közös és a különválogatottak eltérő tulajdonságát.',
		b: 1,
		c: 1711,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Válogatásokat végez saját szempont szerint személyek, tárgyak, dolgok, számok között.',
		b: 1,
		c: 1710,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Megkülönböztet, azonosít egyedi konkrét látott, hallott, mozgással, tapintással érzékelhető tárgyakat, dolgokat, helyzeteket, jeleket.',
		b: 1,
		c: 1709,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'válogatás, halmazok alkotása, vizsgálata'])
	},
		{
		a: 'Megszerzett ismereteit digitális eszközökön is alkalmazza.',
		b: 1,
		c: 1708,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Érti a korának megfelelő, matematikai tartalmú hallott és olvasott szövegeket.',
		b: 1,
		c: 1707,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Életkorának megfelelően eligazodik környezetének térbeli és időbeli viszonyaiban.',
		b: 1,
		c: 1706,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Megfigyeli jelenségek matematikai tartalmát, és le tudja ezeket írni számokkal, műveletekkel vagy geometriai alakzatokkal.',
		b: 1,
		c: 1705,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Helyesen értelmezi az alapműveleteket tevékenységekkel, szövegekkel, és jártas azok elvégzésében fejben és írásban.',
		b: 1,
		c: 1704,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Helyes képzete van a természetes számokról, érti a számnevek és számjelek épülésének rendjét.',
		b: 1,
		c: 1703,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Jártas a mérőeszközök használatában, a mérési módszerekben.',
		b: 1,
		c: 1702,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'A környezetében lévő dolgokat szétválogatja, összehasonlítja és rendszerezi egy-két szempont alapján.',
		b: 1,
		c: 1701,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Megérti a tanult ismereteket és használja azokat a feladatok megoldása során.',
		b: 1,
		c: 1700,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Tájékozódik a környező világ mennyiségi és formai világában.',
		b: 1,
		c: 1699,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Tudatos megfigyeléseket tesz a környező világ tárgyaira, ezek viszonyára vonatkozóan.',
		b: 1,
		c: 1698,
		d: 4,
		e: 'Matematika@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Alkalmazza a tanult infokommunikációs ismereteket matematikai problémák megoldása során.',
		b: 5,
		c: 1979,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'digitáliseszköz-használat'])
	},
		{
		a: 'Ismer és használ digitális matematikai játékokat, programokat.',
		b: 5,
		c: 1978,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'digitáliseszköz-használat'])
	},
		{
		a: 'Ismer és használ dinamikus geometriai szoftvereket, tisztában van alkalmazási lehetőségeikkel.',
		b: 5,
		c: 1977,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'digitáliseszköz-használat'])
	},
		{
		a: 'A fejszámoláson és az írásban végzendő műveleteken túlmutató számolási feladatokhoz és azok ellenőrzéséhez számológépet használ.',
		b: 5,
		c: 1976,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'digitáliseszköz-használat'])
	},
		{
		a: 'Ismer táblázatkezelő programot, tud adatokat összehasonlítani, elemezni.',
		b: 5,
		c: 1975,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'digitáliseszköz-használat'])
	},
		{
		a: 'Értelmezi a táblázatok adatait, az adatoknak megfelelő ábrázolási módot kiválasztja, és az ábrát elkészíti.',
		b: 5,
		c: 1974,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'matematikai kommunikáció'])
	},
		{
		a: 'Konkrét esetekben halmazokat felismer és ábrázol.',
		b: 5,
		c: 1973,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'matematikai kommunikáció'])
	},
		{
		a: 'Megadott szempont szerint adatokat gyűjt ki táblázatból, olvas le hagyományos vagy digitális forrásból származó diagramról, majd rendszerezés után következtetéseket fogalmaz meg.',
		b: 5,
		c: 1972,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'matematikai kommunikáció'])
	},
		{
		a: 'Különböző típusú diagramokat megfeleltet egymásnak.',
		b: 5,
		c: 1971,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'matematikai kommunikáció'])
	},
		{
		a: 'Adatokat táblázatba rendez, diagramon ábrázol hagyományos és digitális eszközökkel is.',
		b: 5,
		c: 1970,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'matematikai kommunikáció'])
	},
		{
		a: 'Helyesen használja a tanult matematikai fogalmakat megnevező szakkifejezéseket.',
		b: 5,
		c: 1969,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'matematikai kommunikáció'])
	},
		{
		a: 'Ismeri a gyakoriság és a relatív gyakoriság fogalmát. ismereteit felhasználja a „lehetetlen\", a „biztos\" és a „kisebb, nagyobb eséllyel lehetséges\" kijelentések megfogalmazásánál.',
		b: 5,
		c: 1968,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Valószínűségi játékokban érti a lehetséges kimeneteleket, játékában stratégiát követ.',
		b: 5,
		c: 1967,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Néhány tagjával adott sorozat esetén felismer és megfogalmaz képzési szabályt.',
		b: 5,
		c: 1966,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'tájékozódás és alkotás térben és síkon'])
	},
		{
		a: 'Sorozatokat adott szabály alapján folytat.',
		b: 5,
		c: 1965,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'tájékozódás és alkotás térben és síkon'])
	},
		{
		a: 'Felismeri és megalkotja az egyenes arányosság grafikonját.',
		b: 5,
		c: 1964,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'tájékozódás és alkotás térben és síkon'])
	},
		{
		a: 'Egyszerű grafikonokat jellemez.',
		b: 5,
		c: 1963,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'tájékozódás és alkotás térben és síkon'])
	},
		{
		a: 'Értéktáblázatok adatait grafikusan ábrázolja.',
		b: 5,
		c: 1962,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'tájékozódás és alkotás térben és síkon'])
	},
		{
		a: 'Tájékozódik a koordináta-rendszerben: koordinátáival adott pontot ábrázol, megadott pont koordinátáit leolvassa.',
		b: 5,
		c: 1961,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'tájékozódás és alkotás térben és síkon'])
	},
		{
		a: 'Felismeri az egyenes és a fordított arányosságot konkrét helyzetekben.',
		b: 5,
		c: 1960,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'tájékozódás és alkotás térben és síkon'])
	},
		{
		a: 'Konkrét halmazok elemei között megfeleltetést hoz létre.',
		b: 5,
		c: 1959,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'tájékozódás és alkotás térben és síkon'])
	},
		{
		a: 'Geometriai ismereteinek felhasználásával pontosan szerkeszt több adott feltételnek megfelelő ábrát.',
		b: 5,
		c: 1958,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás síkon'])
	},
		{
		a: 'Megszerkeszti alakzatok tengelyes és középpontos tükörképét.',
		b: 5,
		c: 1957,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás síkon'])
	},
		{
		a: 'Ismeri az alapszerkesztéseket: szakaszfelező merőlegest, szögfelezőt, merőleges és párhuzamos egyeneseket szerkeszt, szöget másol.',
		b: 5,
		c: 1956,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás síkon'])
	},
		{
		a: 'A szerkesztéshez tervet, előzetes ábrát készít.',
		b: 5,
		c: 1955,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'alkotás síkon'])
	},
		{
		a: 'Ismeri a négyszögek tulajdonságait: belső és külső szögek összege, konvex és konkáv közti különbség, átló fogalma.',
		b: 5,
		c: 1954,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás síkon'])
	},
		{
		a: 'Ismeri a pitagorasz-tételt és alkalmazza számítási feladatokban.',
		b: 5,
		c: 1953,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás síkon'])
	},
		{
		a: 'Ismeri a háromszögek tulajdonságait: belső és külső szögek összege, háromszög-egyenlőtlenség.',
		b: 5,
		c: 1952,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás síkon'])
	},
		{
		a: 'Ismeri a kör részeit; különbséget tesz egyenes, félegyenes és szakasz között.',
		b: 5,
		c: 1951,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás síkon'])
	},
		{
		a: 'Felismeri a kicsinyítést és a nagyítást hétköznapi helyzetekben.',
		b: 5,
		c: 1950,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás síkon'])
	},
		{
		a: 'Felismeri a síkban az egybevágó alakzatokat.',
		b: 5,
		c: 1949,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás síkon'])
	},
		{
		a: 'Tapasztalatot szerez a síkbeli mozgásokról gyakorlati helyzetekben.',
		b: 5,
		c: 1948,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás síkon'])
	},
		{
		a: 'Ismeri a speciális négyszögeket: trapéz, paralelogramma, téglalap, deltoid, rombusz, húrtrapéz, négyzet.',
		b: 5,
		c: 1947,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás síkon'])
	},
		{
		a: 'Ismeri a tengelyesen szimmetrikus háromszöget.',
		b: 5,
		c: 1946,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás síkon'])
	},
		{
		a: 'Ismeri a kocka, a téglatest, a hasáb és a gúla következő tulajdonságait: határoló lapok típusa, száma, egymáshoz viszonyított helyzete; csúcsok, élek száma; lapátló, testátló.',
		b: 5,
		c: 1945,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás és alkotás térben'])
	},
		{
		a: 'Ismeri a gömb tulajdonságait.',
		b: 5,
		c: 1944,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás és alkotás térben'])
	},
		{
		a: 'Testeket épít képek, nézetek, alaprajzok, hálók alapján.',
		b: 5,
		c: 1943,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás és alkotás térben'])
	},
		{
		a: 'A kocka, a téglatest, a hasáb és a gúla hálóját elkészíti.',
		b: 5,
		c: 1942,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'tájékozódás és alkotás térben'])
	},
		{
		a: 'Meghatározza háromszögek és speciális négyszögek kerületét, területét.',
		b: 5,
		c: 1941,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérés, mértékegységek'])
	},
		{
		a: 'Idő, tömeg, hosszúság, terület, térfogat és űrtartalom mértékegységeket átvált helyi értékes gondolkodás alapján, gyakorlati célszerűség szerint.',
		b: 5,
		c: 1940,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérés, mértékegységek'])
	},
		{
		a: 'Egyenes hasáb, téglatest, kocka alakú tárgyak felszínét és térfogatát méréssel megadja, egyenes hasáb felszínét és térfogatát képlet segítségével kiszámolja; a képleteket megalapozó összefüggéseket érti.',
		b: 5,
		c: 1939,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérés, mértékegységek'])
	},
		{
		a: 'Ismeri az idő, a tömeg, a hosszúság, a terület, a térfogat és az űrtartalom szabványmértékegységeit, használja azokat mérések és számítások esetén.',
		b: 5,
		c: 1938,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérés, mértékegységek'])
	},
		{
		a: 'Elvégzi az alapműveleteket a racionális számok körében, eredményét összeveti előzetes becslésével.',
		b: 5,
		c: 1937,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'A gyakorlati problémákban előforduló mennyiségeket becsülni tudja, feladatmegoldásához ennek megfelelő tervet készít.',
		b: 5,
		c: 1936,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'A műveleti szabályok ismeretében ellenőrzi számolását. a kapott eredményt észszerűen kerekíti.',
		b: 5,
		c: 1935,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Gyakorlati feladatok megoldása során tizedes törtet legfeljebb kétjegyű egész számmal írásban oszt. a hányadost megbecsüli.',
		b: 5,
		c: 1934,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Gyakorlati feladatok megoldása során legfeljebb kétjegyű egész számmal írásban oszt. a hányadost megbecsüli.',
		b: 5,
		c: 1933,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Írásban összead, kivon és szoroz.',
		b: 5,
		c: 1932,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Egy- vagy kéttagú betűs kifejezést számmal szoroz, két tagból közös számtényezőt kiemel.',
		b: 5,
		c: 1931,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Egyszerű betűs kifejezésekkel összeadást, kivonást végez, és helyettesítési értéket számol.',
		b: 5,
		c: 1930,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Négyzetszámok négyzetgyökét meghatározza.',
		b: 5,
		c: 1929,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Pozitív egész számok pozitív egész kitevőjű hatványát kiszámolja.',
		b: 5,
		c: 1928,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Meghatározza természetes számok legnagyobb közös osztóját és legkisebb közös többszörösét.',
		b: 5,
		c: 1927,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Ismeri a prímszám és az összetett szám fogalmakat; el tudja készíteni összetett számok prímtényezős felbontását 1000-es számkörben.',
		b: 5,
		c: 1926,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Ismeri és alkalmazza a 2-vel, 3-mal, 4-gyel, 5-tel, 6-tal, 9-cel, 10-zel, 100-zal való oszthatóság szabályait.',
		b: 5,
		c: 1925,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Ismeri és helyesen alkalmazza a műveleti sorrendre és a zárójelezésre vonatkozó szabályokat fejben, írásban és géppel számolás esetén is a racionális számok körében.',
		b: 5,
		c: 1924,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'műveletek elvégzése'])
	},
		{
		a: 'Meghatározza konkrét számok reciprokát.',
		b: 5,
		c: 1923,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'racionális számok felépítése'])
	},
		{
		a: 'Ismeri a racionális számokat, tud példát végtelen nem szakaszos tizedes törtre.',
		b: 5,
		c: 1922,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'racionális számok felépítése'])
	},
		{
		a: 'Megfelelteti egymásnak a racionális számok közönséges tört és tizedes tört alakját.',
		b: 5,
		c: 1921,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'racionális számok felépítése'])
	},
		{
		a: 'Ábrázol törtrészeket, meghatároz törtrészeknek megfelelő törtszámokat.',
		b: 5,
		c: 1920,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'racionális számok felépítése'])
	},
		{
		a: 'Meghatározza konkrét számok ellentettjét, abszolút értékét.',
		b: 5,
		c: 1919,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'racionális számok felépítése'])
	},
		{
		a: 'Ismeri az egész számokat.',
		b: 5,
		c: 1918,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'racionális számok felépítése'])
	},
		{
		a: 'Ismeri a római számjelek közül az l, c, d, m jeleket, felismeri az ezekkel képzett számokat a hétköznapi helyzetekben.',
		b: 5,
		c: 1917,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'racionális számok felépítése'])
	},
		{
		a: 'Érti és alkalmazza a számok helyi értékes írásmódját tizedes törtek esetén.',
		b: 5,
		c: 1916,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'racionális számok felépítése'])
	},
		{
		a: 'Érti és alkalmazza a számok helyi értékes írásmódját nagy számok esetén.',
		b: 5,
		c: 1915,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'racionális számok felépítése'])
	},
		{
		a: 'Ismeri a százalék fogalmát, gazdasági, pénzügyi és mindennapi élethez kötődő százalékszámítási feladatokat megold.',
		b: 5,
		c: 1914,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'rendszerező gondolkodás'])
	},
		{
		a: 'Megoldását ellenőrzi.',
		b: 5,
		c: 1913,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'rendszerező gondolkodás'])
	},
		{
		a: 'Gyakorlati problémák megoldása során előforduló mennyiségeknél becslést végez.',
		b: 5,
		c: 1912,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'rendszerező gondolkodás'])
	},
		{
		a: 'Gazdasági, pénzügyi témájú egyszerű szöveges feladatokat következtetéssel vagy egyenlettel megold.',
		b: 5,
		c: 1911,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'rendszerező gondolkodás'])
	},
		{
		a: 'Matematikából, más tantárgyakból és a mindennapi életből vett egyszerű szöveges feladatokat következtetéssel vagy egyenlettel megold.',
		b: 5,
		c: 1910,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'rendszerező gondolkodás'])
	},
		{
		a: 'Konkrét adatsor esetén átlagot számol, megállapítja a leggyakoribb adatot (módusz), a középső adatot (medián), és ezeket összehasonlítja.',
		b: 5,
		c: 1909,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'ismeretek alkalmazása'])
	},
		{
		a: 'A kocka, a téglatest, a hasáb, a gúla, a gömb tulajdonságait alkalmazza feladatok megoldásában.',
		b: 5,
		c: 1908,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'ismeretek alkalmazása'])
	},
		{
		a: 'A háromszögek és a speciális négyszögek tulajdonságait alkalmazza feladatok megoldásában.',
		b: 5,
		c: 1907,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'ismeretek alkalmazása'])
	},
		{
		a: 'Összeszámlálási feladatok megoldása során alkalmazza az összes eset áttekintéséhez szükséges módszereket.',
		b: 5,
		c: 1906,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'ismeretek alkalmazása'])
	},
		{
		a: 'Valószínűségi játékokat, kísérleteket végez, ennek során az adatokat tervszerűen gyűjti, rendezi és ábrázolja digitálisan is.',
		b: 5,
		c: 1905,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés'])
	},
		{
		a: 'Ismeri a speciális négyszögek legfontosabb tulajdonságait, ezek alapján elkészíti a halmazábrájukat.',
		b: 5,
		c: 1904,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés'])
	},
		{
		a: 'Csoportosítja a háromszögeket szögeik és oldalaik szerint.',
		b: 5,
		c: 1903,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés'])
	},
		{
		a: 'Síkbeli tartományok közül kiválasztja a szögtartományokat, nagyság szerint összehasonlítja, méri, csoportosítja azokat.',
		b: 5,
		c: 1902,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés'])
	},
		{
		a: 'A természetes számokat osztóik száma alapján és adott számmal való osztási maradékuk szerint csoportosítja.',
		b: 5,
		c: 1901,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés'])
	},
		{
		a: 'Véges halmaz kiegészítő halmazát (komplementerét), véges halmazok közös részét (metszetét), egyesítését (unióját) képezi és ábrázolja konkrét esetekben.',
		b: 5,
		c: 1900,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés'])
	},
		{
		a: 'Részhalmazokat konkrét esetekben felismer és ábrázol.',
		b: 5,
		c: 1899,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés'])
	},
		{
		a: 'Elemeket halmazba rendez több szempont alapján.',
		b: 5,
		c: 1898,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendezés'])
	},
		{
		a: 'A logikus érvelésben a matematikai szaknyelvet következetesen alkalmazza társai meggyőzésére.',
		b: 5,
		c: 1897,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'állítások kezelése'])
	},
		{
		a: 'Tanult minták alapján néhány lépésből álló bizonyítási gondolatsort megért és önállóan összeállít.',
		b: 5,
		c: 1896,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'állítások kezelése'])
	},
		{
		a: 'Igaz és hamis állításokat fogalmaz meg.',
		b: 5,
		c: 1895,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'állítások kezelése'])
	},
		{
		a: 'Állítások logikai értékét (igaz vagy hamis) megállapítja.',
		b: 5,
		c: 1894,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'állítások kezelése'])
	},
		{
		a: 'Egyismeretlenes elsőfokú egyenletet lebontogatással és mérlegelvvel megold.',
		b: 5,
		c: 1893,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'eszközrendszer alkalmazása'])
	},
		{
		a: 'Konkrét szituációkat szemléltet gráfok segítségével.',
		b: 5,
		c: 1892,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'eszközrendszer alkalmazása'])
	},
		{
		a: 'Számokat, számhalmazokat, halmazműveleti eredményeket számegyenesen ábrázol.',
		b: 5,
		c: 1891,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'eszközrendszer alkalmazása'])
	},
		{
		a: 'Különböző szövegekhez megfelelő modelleket készít.',
		b: 5,
		c: 1890,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'eszközrendszer alkalmazása'])
	},
		{
		a: 'Matematikai ismereteit alkalmazza a pénzügyi tudatosság területét érintő feladatok megoldásában.',
		b: 5,
		c: 1889,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Matematikai ismereteit össze tudja kapcsolni más tanulásterületeken szerzett tapasztalatokkal.',
		b: 5,
		c: 1888,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Tanulási módszerei változatosak: szóbeli közlés, írott szöveg és digitális csatornák útján egyaránt képes az ismeretek elsajátítására.',
		b: 5,
		c: 1887,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Olvassa és érti az életkorának megfelelő matematikai tartalmú szövegeket.',
		b: 5,
		c: 1886,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Helyesen használja a matematikai jelöléseket írásban.',
		b: 5,
		c: 1885,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Megfogalmaz sejtéseket, és logikus érveléssel ellenőrzi azokat.',
		b: 5,
		c: 1884,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Felismeri a hétköznapi helyzetekben a matematikai vonatkozásokat, és ezek leírására megfelelő modellt használ.',
		b: 5,
		c: 1883,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Rendelkezik a matematikai problémamegoldáshoz szükséges eszközrendszerrel, melyet az adott problémának megfelelően tud alkalmazni.',
		b: 5,
		c: 1882,
		d: 8,
		e: 'Matematika@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Szerkesztési feladatok euklideszi módon történő megoldásához dinamikus geometriai szoftvert használ.',
		b: 9,
		c: 2116,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'digitáliseszköz-használat', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Kísérletezéshez, sejtés megfogalmazásához, egyenlet grafikus megoldásához és ellenőrzéshez dinamikus geometriai, grafikus és táblázatkezelő szoftvereket használ.',
		b: 9,
		c: 2115,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'digitáliseszköz-használat', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Prezentációhoz informatív diákat készít, ezeket logikusan és következetesen egymás után fűzi és bemutatja.',
		b: 9,
		c: 2114,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'digitáliseszköz-használat', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Ismereteit digitális forrásokból kiegészíti, számítógép segítségével elemzi és bemutatja.',
		b: 9,
		c: 2113,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'digitáliseszköz-használat', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Megfelelő informatikai alkalmazás segítségével szöveget szerkeszt, táblázatkezelő programmal diagramokat készít.',
		b: 9,
		c: 2112,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'digitáliseszköz-használat', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Digitális környezetben matematikai alkalmazásokkal dolgozik.',
		b: 9,
		c: 2111,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'digitáliseszköz-használat', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Számológép segítségével alapműveletekkel felírható számolási eredményt; négyzetgyököt; átlagot; szögfüggvények értékét, illetve abból szöget; logaritmust; faktoriálist; binomiális együtthatót; szórást meghatároz.',
		b: 9,
		c: 2110,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'digitáliseszköz-használat', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Ismer a tananyaghoz kapcsolódó matematikatörténeti vonatkozásokat.',
		b: 9,
		c: 2109,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'matematikai kommunikáció', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Szöveg alapján táblázatot, grafikont készít, ábrát, kapcsolatokat szemléltető gráfot rajzol, és ezeket kombinálva prezentációt készít és mutat be.',
		b: 9,
		c: 2108,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'matematikai kommunikáció', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Matematika feladatok megoldását szakszerűen prezentálja írásban és szóban a szükséges alapfogalmak, azonosságok, definíciók és tételek segítségével.',
		b: 9,
		c: 2107,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'matematikai kommunikáció', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Önállóan kommunikál matematika tartalmú feladatokkal kapcsolatban.',
		b: 9,
		c: 2106,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'matematikai kommunikáció', 'valószínűségi gondolkodás'])
	},
		{
		a: 'A matematikai fogalmakat és jelöléseket megfelelően használja.',
		b: 9,
		c: 2105,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'matematikai kommunikáció', 'valószínűségi gondolkodás'])
	},
		{
		a: 'A megfelelő matematikai tankönyveket, feladatgyűjteményeket, internetes tartalmakat értőn olvassa, a matematikai tartalmat rendszerezetten kigyűjti és megérti.',
		b: 9,
		c: 2104,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'matematikai kommunikáció', 'valószínűségi gondolkodás'])
	},
		{
		a: 'Meghatározza a valószínűséget visszatevéses, illetve visszatevés nélküli mintavétel esetén.',
		b: 9,
		c: 2103,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'valószínűségszámítás'])
	},
		{
		a: 'Ismeri és egyszerű esetekben alkalmazza a valószínűség geometriai modelljét.',
		b: 9,
		c: 2102,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'valószínűségszámítás'])
	},
		{
		a: 'Konkrét valószínűségi kísérletek esetében az esemény, eseménytér, elemi esemény, relatív gyakoriság, valószínűség, egymást kizáró események, független események fogalmát megkülönbözteti és alkalmazza.',
		b: 9,
		c: 2101,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'valószínűségszámítás'])
	},
		{
		a: 'Véletlen kísérletek adatait rendszerezi, relatív gyakoriságokat számol, nagy elemszám esetén számítógépet alkalmaz.',
		b: 9,
		c: 2100,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'valószínűségszámítás'])
	},
		{
		a: 'Ismeri és alkalmazza a klasszikus valószínűségi modellt és a laplace-képletet.',
		b: 9,
		c: 2099,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'valószínűségszámítás'])
	},
		{
		a: 'Tapasztalatai alapján véletlen jelenségek jövőbeni kimenetelére észszerűen tippel.',
		b: 9,
		c: 2098,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'valószínűségszámítás'])
	},
		{
		a: 'Felismer grafikus manipulációkat diagramok esetén.',
		b: 9,
		c: 2097,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'leíró statisztika'])
	},
		{
		a: 'Ismeri és alkalmazza a sodrófa (box-plot) diagramot adathalmazok jellemzésére, összehasonlítására.',
		b: 9,
		c: 2096,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'leíró statisztika'])
	},
		{
		a: 'Adatsokaságból adott szempont szerint oszlop- és kördiagramot készít hagyományos és digitális eszközzel.',
		b: 9,
		c: 2095,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'leíró statisztika'])
	},
		{
		a: 'Hagyományos és digitális forrásból származó adatsokaság alapvető statisztikai jellemzőit meghatározza, értelmezi és értékeli.',
		b: 9,
		c: 2094,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'leíró statisztika'])
	},
		{
		a: 'Adott cél érdekében tudatos adatgyűjtést és rendszerezést végez.',
		b: 9,
		c: 2093,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'valószínűségi gondolkodás', 'leíró statisztika'])
	},
		{
		a: 'Mértani sorozatokra vonatkozó ismereteit használja gazdasági, pénzügyi, természettudományi és társadalomtudományi problémák megoldásában.',
		b: 9,
		c: 2092,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'sorozatok'])
	},
		{
		a: 'A számtani, mértani sorozatok első n tagjának összegét kiszámolja.',
		b: 9,
		c: 2091,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'sorozatok'])
	},
		{
		a: 'A számtani, mértani sorozat n-edik tagját felírja az első tag és a különbség (differencia)/hányados (kvóciens) ismeretében.',
		b: 9,
		c: 2090,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'sorozatok'])
	},
		{
		a: 'Számtani és mértani sorozatokat adott szabály alapján felír, folytat.',
		b: 9,
		c: 2089,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'sorozatok'])
	},
		{
		a: 'A grafikonról megállapítja függvények alapvető tulajdonságait.',
		b: 9,
		c: 2088,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'függvények'])
	},
		{
		a: 'Adott értékkészletbeli elemhez megtalálja az értelmezési tartomány azon elemeit, amelyekhez a függvény az adott értéket rendeli.',
		b: 9,
		c: 2087,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'függvények'])
	},
		{
		a: 'Képlettel adott függvényt hagyományosan és digitális eszközzel ábrázol.',
		b: 9,
		c: 2086,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'függvények'])
	},
		{
		a: 'Táblázattal megadott függvény összetartozó értékeit ábrázolja koordináta-rendszerben.',
		b: 9,
		c: 2085,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'függvények'])
	},
		{
		a: 'Adott képlet alapján helyettesítési értékeket számol, és azokat táblázatba rendezi.',
		b: 9,
		c: 2084,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'függvények'])
	},
		{
		a: 'Megad hétköznapi életben előforduló hozzárendeléseket.',
		b: 9,
		c: 2083,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'függvényszerű gondolkodás', 'függvények'])
	},
		{
		a: 'Megadja és alkalmazza a kör egyenletét a kör sugarának és a középpont koordinátáinak ismeretében.',
		b: 9,
		c: 2082,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'koordinátageometria'])
	},
		{
		a: 'Kiszámítja egyenesek metszéspontjainak koordinátáit az egyenesek egyenletének ismeretében.',
		b: 9,
		c: 2081,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'koordinátageometria'])
	},
		{
		a: 'Egyenesek egyenletéből következtet az egyenesek kölcsönös helyzetére.',
		b: 9,
		c: 2080,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'koordinátageometria'])
	},
		{
		a: 'Ismeri és alkalmazza az egyenes egyenletét.',
		b: 9,
		c: 2079,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'koordinátageometria'])
	},
		{
		a: 'Koordináták alapján számításokat végez szakaszokkal, vektorokkal.',
		b: 9,
		c: 2078,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'koordinátageometria'])
	},
		{
		a: 'Koordináta-rendszerben ábrázol adott feltételeknek megfelelő ponthalmazokat.',
		b: 9,
		c: 2077,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'koordinátageometria'])
	},
		{
		a: 'Megad pontot és vektort koordinátáival a derékszögű koordináta-rendszerben.',
		b: 9,
		c: 2076,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'koordinátageometria'])
	},
		{
		a: 'Ismeri és alkalmazza a hasonló testek felszínének és térfogatának arányára vonatkozó tételeket.',
		b: 9,
		c: 2075,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'térgeometria'])
	},
		{
		a: 'Kiszámítja a speciális testek felszínét és térfogatát egyszerű esetekben.',
		b: 9,
		c: 2074,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'térgeometria'])
	},
		{
		a: 'Lerajzolja a kocka, téglatest, egyenes hasáb, egyenes körhenger, egyenes gúla, forgáskúp hálóját.',
		b: 9,
		c: 2073,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'térgeometria'])
	},
		{
		a: 'Ismeri és alkalmazza a hasáb, a henger, a gúla, a kúp, a gömb, a csonkagúla, a csonkakúp (speciális testek) tulajdonságait.',
		b: 9,
		c: 2072,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'térgeometria'])
	},
		{
		a: 'Ismeri és alkalmazza a szinusz- és a koszinusztételt.',
		b: 9,
		c: 2071,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'trigonometria'])
	},
		{
		a: 'A szögfüggvény értékének ismeretében meghatározza a szöget.',
		b: 9,
		c: 2070,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'trigonometria'])
	},
		{
		a: 'Alkalmazza a szögfüggvényeket egyszerű geometriai számítási feladatokban.',
		b: 9,
		c: 2069,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'trigonometria'])
	},
		{
		a: 'Ismeri a hegyes- és tompaszögek szögfüggvényeinek összefüggéseit.',
		b: 9,
		c: 2068,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'trigonometria'])
	},
		{
		a: 'Ismeri tompaszögek szögfüggvényeinek származtatását a hegyesszögek szögfüggvényei alapján.',
		b: 9,
		c: 2067,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'trigonometria'])
	},
		{
		a: 'Ismeri hegyesszögek szögfüggvényeinek definícióját a derékszögű háromszögben.',
		b: 9,
		c: 2066,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'trigonometria'])
	},
		{
		a: 'Alkalmazza a vektorokat feladatok megoldásában.',
		b: 9,
		c: 2065,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai transzformációk'])
	},
		{
		a: 'Ismer és alkalmaz egyszerű vektorműveleteket.',
		b: 9,
		c: 2064,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai transzformációk'])
	},
		{
		a: 'Ismeri a vektorokkal kapcsolatos alapvető fogalmakat.',
		b: 9,
		c: 2063,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai transzformációk'])
	},
		{
		a: 'Megszerkeszti egy alakzat tengelyes, illetve középpontos tükörképét, pont körüli elforgatottját, párhuzamos eltoltját hagyományosan és digitális eszközzel.',
		b: 9,
		c: 2062,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai transzformációk'])
	},
		{
		a: 'Ismeri és alkalmazza a hasonló síkidomok kerületének és területének arányára vonatkozó tételeket.',
		b: 9,
		c: 2061,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai transzformációk'])
	},
		{
		a: 'Ismeri és alkalmazza a középpontos hasonlósági transzformációt, a hasonlósági transzformációt és az alakzatok hasonlóságát.',
		b: 9,
		c: 2060,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai transzformációk'])
	},
		{
		a: 'Ismeri és alkalmazza a síkbeli egybevágósági transzformációkat és tulajdonságaikat; alakzatok egybevágóságát.',
		b: 9,
		c: 2059,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai transzformációk'])
	},
		{
		a: 'Ismer példákat geometriai transzformációkra.',
		b: 9,
		c: 2058,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai transzformációk'])
	},
		{
		a: 'Ismeri és alkalmazza a thalész-tételt és megfordítását.',
		b: 9,
		c: 2057,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'a kör és részei'])
	},
		{
		a: 'Ismeri a kör érintőjének fogalmát, kapcsolatát az érintési pontba húzott sugárral.',
		b: 9,
		c: 2056,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'a kör és részei'])
	},
		{
		a: 'Ki tudja számolni a kör és részeinek kerületét, területét.',
		b: 9,
		c: 2055,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'a kör és részei'])
	},
		{
		a: 'Átdarabolással kiszámítja sokszögek területét.',
		b: 9,
		c: 2054,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'háromszögek, négyszögek, sokszögek'])
	},
		{
		a: 'Ismeri és alkalmazza a szabályos sokszög fogalmát; kiszámítja a konvex sokszög belső és külső szögeinek összegét.',
		b: 9,
		c: 2053,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'háromszögek, négyszögek, sokszögek'])
	},
		{
		a: 'Ismeri és alkalmazza speciális négyszögek tulajdonságait, területüket kiszámítja.',
		b: 9,
		c: 2052,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'háromszögek, négyszögek, sokszögek'])
	},
		{
		a: 'Kiszámítja háromszögek területét.',
		b: 9,
		c: 2051,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'háromszögek, négyszögek, sokszögek'])
	},
		{
		a: 'Ismeri és alkalmazza a pitagorasz-tételt és megfordítását.',
		b: 9,
		c: 2050,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'háromszögek, négyszögek, sokszögek'])
	},
		{
		a: 'Ismeri és alkalmazza a háromszög nevezetes vonalaira, pontjaira és köreire vonatkozó fogalmakat és tételeket.',
		b: 9,
		c: 2049,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'háromszögek, négyszögek, sokszögek'])
	},
		{
		a: 'Ismeri és alkalmazza a háromszögek oldalai, szögei, oldalai és szögei közötti kapcsolatokat; a speciális háromszögek tulajdonságait.',
		b: 9,
		c: 2048,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'háromszögek, négyszögek, sokszögek'])
	},
		{
		a: 'Ismeri az alapszerkesztéseket, és ezeket végre tudja hajtani hagyományos vagy digitális eszközzel.',
		b: 9,
		c: 2047,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai alapfogalmak'])
	},
		{
		a: 'Ismeri és alkalmazza a nevezetes szögpárok tulajdonságait.',
		b: 9,
		c: 2046,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai alapfogalmak'])
	},
		{
		a: 'Ismeri és feladatmegoldásban alkalmazza a térelemek kölcsönös helyzetét, távolságát és hajlásszögét.',
		b: 9,
		c: 2045,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai alapfogalmak'])
	},
		{
		a: 'Ismeri és használja a pont, egyenes, sík (térelemek) és szög fogalmát.',
		b: 9,
		c: 2044,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás és alkotás térben és síkon', 'geometriai alapfogalmak'])
	},
		{
		a: 'Ismeri és alkalmazza a százalékalap, -érték, -láb, -pont fogalmát.',
		b: 9,
		c: 2043,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'arányosság, százalékszámítás'])
	},
		{
		a: 'Ismeri és alkalmazza az egyenes és a fordított arányosságot.',
		b: 9,
		c: 2042,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'arányosság, százalékszámítás'])
	},
		{
		a: 'Átalakít algebrai kifejezéseket összevonás, szorzattá alakítás, nevezetes azonosságok alkalmazásával.',
		b: 9,
		c: 2041,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'hatvány, gyök, logaritmus'])
	},
		{
		a: 'Ismer és alkalmaz egyszerű algebrai azonosságokat.',
		b: 9,
		c: 2040,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'hatvány, gyök, logaritmus'])
	},
		{
		a: 'Műveleteket végez algebrai kifejezésekkel.',
		b: 9,
		c: 2039,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'hatvány, gyök, logaritmus'])
	},
		{
		a: 'Ismeri és alkalmazza a logaritmus fogalmát.',
		b: 9,
		c: 2038,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'hatvány, gyök, logaritmus'])
	},
		{
		a: 'Ismeri és alkalmazza a racionális kitevőjű hatvány fogalmát és a hatványozás azonosságait.',
		b: 9,
		c: 2037,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'hatvány, gyök, logaritmus'])
	},
		{
		a: 'Ismeri és alkalmazza az egész kitevőjű hatvány fogalmát és a hatványozás azonosságait.',
		b: 9,
		c: 2036,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'hatvány, gyök, logaritmus'])
	},
		{
		a: 'Ismeri és alkalmazza a normálalak fogalmát.',
		b: 9,
		c: 2035,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'hatvány, gyök, logaritmus'])
	},
		{
		a: 'Ismeri és alkalmazza az n-edik gyök fogalmát.',
		b: 9,
		c: 2034,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'hatvány, gyök, logaritmus'])
	},
		{
		a: 'Ismeri és alkalmazza a négyzetgyök fogalmát és azonosságait.',
		b: 9,
		c: 2033,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'hatvány, gyök, logaritmus'])
	},
		{
		a: 'Valós számok közelítő alakjaival számol, és megfelelően kerekít.',
		b: 9,
		c: 2032,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'valós számok'])
	},
		{
		a: 'A számolással kapott eredményeket nagyságrendileg megbecsüli, és így ellenőrzi az eredményt.',
		b: 9,
		c: 2031,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'valós számok'])
	},
		{
		a: 'Ismeri és alkalmazza az abszolút érték, az ellentett és a reciprok fogalmát.',
		b: 9,
		c: 2030,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'valós számok'])
	},
		{
		a: 'Ismeri a valós számok és a számegyenes kapcsolatát.',
		b: 9,
		c: 2029,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'valós számok'])
	},
		{
		a: 'Ismer példákat irracionális számokra.',
		b: 9,
		c: 2028,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'valós számok'])
	},
		{
		a: 'Racionális számokat tizedes tört és közönséges tört alakban is felír.',
		b: 9,
		c: 2027,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'valós számok'])
	},
		{
		a: 'A kommutativitás, asszociativitás, disztributivitás műveleti azonosságokat helyesen alkalmazza különböző számolási helyzetekben.',
		b: 9,
		c: 2026,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'valós számok'])
	},
		{
		a: 'Ismeri a számhalmazok épülésének matematikai vonatkozásait a természetes számoktól a valós számokig.',
		b: 9,
		c: 2025,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'valós számok'])
	},
		{
		a: 'Érti a helyi értékes írásmódot 10-es és más alapú számrendszerekben.',
		b: 9,
		c: 2024,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'a természetes számok halmaza, számelméleti ismeretek'])
	},
		{
		a: 'Ismeri és alkalmazza az oszthatósági szabályokat.',
		b: 9,
		c: 2023,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'a természetes számok halmaza, számelméleti ismeretek'])
	},
		{
		a: 'Meghatározza két természetes szám legnagyobb közös osztóját és legkisebb közös többszörösét, és alkalmazza ezeket egyszerű gyakorlati feladatokban.',
		b: 9,
		c: 2022,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'a természetes számok halmaza, számelméleti ismeretek'])
	},
		{
		a: 'Összetett számokat felbont prímszámok szorzatára.',
		b: 9,
		c: 2021,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'a természetes számok halmaza, számelméleti ismeretek'])
	},
		{
		a: 'Ismeri és alkalmazza az oszthatóság alapvető fogalmait.',
		b: 9,
		c: 2020,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'a természetes számok halmaza, számelméleti ismeretek'])
	},
		{
		a: 'Sík- és térgeometriai feladatoknál a problémának megfelelő mértékegységben adja meg válaszát.',
		b: 9,
		c: 2019,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérés, mértékegységek'])
	},
		{
		a: 'Ismeri a hosszúság, terület, térfogat, űrtartalom, idő mértékegységeit és az átváltási szabályokat. származtatott mértékegységeket átvált.',
		b: 9,
		c: 2018,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérés, mértékegységek'])
	},
		{
		a: 'Ismeri a mérés alapelvét, alkalmazza konkrét alap- és származtatott mennyiségek esetén.',
		b: 9,
		c: 2017,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'tájékozódás mennyiségi viszonyok között', 'mérés, mértékegységek'])
	},
		{
		a: 'Egyenletek megoldását behelyettesítéssel, értékkészlet-vizsgálattal ellenőrzi.',
		b: 9,
		c: 2016,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'egyenletek, egyenlőtlenségek, egyenletrendszerek'])
	},
		{
		a: 'Megold egyszerű, a megfelelő definíció alkalmazását igénylő exponenciális egyenleteket, egyenlőtlenségeket.',
		b: 9,
		c: 2015,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'egyenletek, egyenlőtlenségek, egyenletrendszerek'])
	},
		{
		a: 'Megold másodfokú egyismeretlenes egyenleteket és egyenlőtlenségeket; ismeri és alkalmazza a diszkriminánst, a megoldóképletet és a gyöktényezős alakot.',
		b: 9,
		c: 2014,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'egyenletek, egyenlőtlenségek, egyenletrendszerek'])
	},
		{
		a: 'Megold elsőfokú egyismeretlenes egyenleteket és egyenlőtlenségeket, elsőfokú kétismeretlenes egyenletrendszereket.',
		b: 9,
		c: 2013,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'egyenletek, egyenlőtlenségek, egyenletrendszerek'])
	},
		{
		a: 'Ismeri és alkalmazza a következő egyenletmegoldási módszereket: mérlegelv, grafikus megoldás, szorzattá alakítás.',
		b: 9,
		c: 2012,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'egyenletek, egyenlőtlenségek, egyenletrendszerek'])
	},
		{
		a: 'Geometriai szerkesztési feladatoknál vizsgálja és megállapítja a szerkeszthetőség feltételeit.',
		b: 9,
		c: 2011,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'A modellben kapott megoldását az eredeti problémába visszahelyettesítve értelmezi, ellenőrzi és az észszerűségi szempontokat figyelembe véve adja meg válaszát.',
		b: 9,
		c: 2010,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'A kiválasztott modellben megoldja a problémát.',
		b: 9,
		c: 2009,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'A problémának megfelelő matematikai modellt választ, alkot.',
		b: 9,
		c: 2008,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'Adott problémához megoldási stratégiát, algoritmust választ, készít.',
		b: 9,
		c: 2007,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'problémamegoldó gondolkodás', 'problémamegoldás'])
	},
		{
		a: 'Konkrét szituációkat szemléltet és egyszerű feladatokat megold gráfok segítségével.',
		b: 9,
		c: 2006,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'kombinatorika, gráfok'])
	},
		{
		a: 'Megold sorba rendezési és kiválasztási feladatokat.',
		b: 9,
		c: 2005,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'kombinatorika, gráfok'])
	},
		{
		a: 'Tud egyszerű állításokat indokolni és tételeket bizonyítani.',
		b: 9,
		c: 2004,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Helyesen használja a „minden\" és „van olyan\" kifejezéseket.',
		b: 9,
		c: 2003,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Megállapítja egyszerű „ha ... , akkor ...\" és „akkor és csak akkor\" típusú állítások logikai értékét.',
		b: 9,
		c: 2002,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Megfogalmazza adott állítás megfordítását.',
		b: 9,
		c: 2001,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Ismeri és alkalmazza az „és\", a (megengedő és kizáró) „vagy\" logikai jelentését.',
		b: 9,
		c: 2000,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Alkalmazza a tagadás műveletét egyszerű feladatokban.',
		b: 9,
		c: 1999,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Adott állításról eldönti, hogy igaz vagy hamis.',
		b: 9,
		c: 1998,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Alkalmazza a logikai szita elvét.',
		b: 9,
		c: 1997,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Véges halmazok elemszámát meghatározza.',
		b: 9,
		c: 1996,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Halmazokkal műveleteket végez, azokat ábrázolja és értelmezi.',
		b: 9,
		c: 1995,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Halmazokat különböző módokon megad.',
		b: 9,
		c: 1994,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Látja a halmazműveletek és a logikai műveletek közötti kapcsolatokat.',
		b: 9,
		c: 1993,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'halmazok, matematikai logika'])
	},
		{
		a: 'Felismeri a matematika különböző területei közötti kapcsolatot.',
		b: 9,
		c: 1992,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendszerezés'])
	},
		{
		a: 'Matematikai vagy hétköznapi nyelven megfogalmazott szövegből a matematikai tartalmú információkat kigyűjti, rendszerezi.',
		b: 9,
		c: 1991,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendszerezés'])
	},
		{
		a: 'Adott halmazt diszjunkt részhalmazaira bont, osztályoz.',
		b: 9,
		c: 1990,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['matematika', 'rendszerező gondolkodás', 'rendszerezés'])
	},
		{
		a: 'Matematikai ismereteit alkalmazza a pénzügyi tudatosság területét érintő feladatok megoldásában.',
		b: 9,
		c: 1989,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'A matematikát más tantárgyakhoz kapcsolódó témákban is használja.',
		b: 9,
		c: 1988,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'A matematika tanulása során digitális eszközöket és különböző információforrásokat használ.',
		b: 9,
		c: 1987,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Felismeri a matematika különböző területei közötti kapcsolatokat.',
		b: 9,
		c: 1986,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Megérti a hallott és olvasott matematikai tartalmú szövegeket.',
		b: 9,
		c: 1985,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'A matematikai szakkifejezéseket és jelöléseket helyesen használja írásban és szóban egyaránt.',
		b: 9,
		c: 1984,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Adatokat gyűjt, rendez, ábrázol, értelmez.',
		b: 9,
		c: 1983,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Sejtéseket fogalmaz meg és logikus lépésekkel igazolja azokat.',
		b: 9,
		c: 1982,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Megérti a környezetében jelen lévő logikai, mennyiségi, függvényszerű, térbeli és statisztikai kapcsolatokat.',
		b: 9,
		c: 1981,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Ismeretei segítségével, a megfelelő modell alkalmazásával megold hétköznapi és matematikai problémákat, a megoldást ellenőrzi és értelmezi.',
		b: 9,
		c: 1980,
		d: 12,
		e: 'Matematika@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'matematika'])
	},
		{
		a: 'Tudatosítja magában a nyilvános megszólalás szabadságával és felelősségével járó lehetőségeket és lehetséges következményeket, tisztában van a digitális zaklatás veszélyeivel. tudatos médiahasználóként állást tud foglalni a média által közvetített értékek minőségével kapcsolatban.',
		b: 12,
		c: 2283,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Tisztában van a média véleményformáló szerepével, az alkotók és felhasználók felelősségével, az egyének és közösségek jogaival. ismeri a norma és normaszegés fogalmait a médiában.',
		b: 12,
		c: 2282,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Ismeretekkel rendelkezik a médiaetika és a médiaszabályozás főbb kérdéseit illetően, saját álláspontot tud megfogalmazni azokkal kapcsolatban. érti a média etikai környezetének és jogi szabályozásának tétjeit.',
		b: 12,
		c: 2281,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Ismeri az internet-világ jelenségeit, a globális kommunikáció adta lehetőségeket. érti a hálózati kommunikáció és a közösségi média működési módját, képes abban felelősen részt venni, ismeri és megfelelően használja annak alapvető szövegtípusait. képes igényes önálló tartalmak alkotására és részt venni a lokális nyilvánosságban.',
		b: 12,
		c: 2280,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Tisztában van a médiaipar, médiafogyasztás és -befogadás jellemzőivel. ismeri a kereskedelmi, közszolgálati és nonprofit média, az alkotói szándék, célcsoport, a közönség, mint vevő és áru, a médiafogyasztási szokások jellegzetességeit, a médiafüggőség tüneteit.',
		b: 12,
		c: 2279,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Ismeri a médiareprezentáció, valószerűség és hitelesség kritériumait, a fikciós műfajok illetve a dokumentumjelleg különbségeit, a sztereotípia, tematizáció, valóságábrázolás, hitelesség, hír fogalmait. képes saját értékelő viszonyt kialakítani ezekkel kapcsolatban.',
		b: 12,
		c: 2278,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Értelmezni tudja a valóság és a média által a valóságról kialakított „kép\" viszonyát, ismeri a reprezentáció fogalmát, és rendelkezik a médiatudatosság képességével.',
		b: 12,
		c: 2277,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Ismeri a modern tömegkommunikáció fő működési elveit, jellemző vonásait, érti, milyen társadalmi és kulturális következményekkel járnak a kommunikációs rendszerben bekövetkező változások, ezek hatásait saját környezetében is észreveszi.',
		b: 12,
		c: 2276,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Tisztában van a sztárfogalom kialakulásával és módosulásával. azonosítani tudja a sztárjelenséget a filmen és a médiában. képes ismert filmszereplők és médiaszemélyiségek imázsának elemzésére, a háttérben fellelhető archetípusok meghatározására.',
		b: 12,
		c: 2275,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Ismeri a magyar filmművészet főbb szerzőit, jellegzetességeit és értékeit.',
		b: 12,
		c: 2274,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Ismereteket szerez a filmkultúra területéről: érti a szerzői kultúra és a tömegkultúra eltérő működésmódját; felismeri az elterjedtebb filmműfajok jegyeit; különbséget tud tenni a különböző stílusirányzatokhoz tartozó alkotások között.',
		b: 12,
		c: 2273,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Rövid média-alkotások tervezése és kivitelezése révén szert tesz a különböző médiumok szövegalkotási folyamatainak elemi tapasztalataira, érti a különféle szövegtípusok eltérő működési elvét, s tud azok széles spektrumával kreatív befogadói viszonyt létrehozni.',
		b: 12,
		c: 2272,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Képes különböző médiatermékek értő befogadására. ismeri a különböző médiumok formanyelvének alapvető eszköztárát, érzékeli ezek hatását az értelmezés folyamatára, valamint képes ezeknek az eszközöknek alapszintű alkalmazására is saját környezetében.',
		b: 12,
		c: 2271,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Rendelkezik átfogó egyetemes és magyar médiatörténeti ismeretekkel. ismeri a különböző médiumok specifikumait, a fontosabb műfajokat, szerzőket, műveket.',
		b: 12,
		c: 2270,
		d: 12,
		e: 'Mozgóképkultúra és médiaismeret@12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'mozgóképkultúra és médiaismeret'])
	},
		{
		a: 'Ismeri a környezetében fellelhető, megfigyelhető szakmák, hivatások jellemzőit.',
		b: 1,
		c: 2463,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'felkészülés a munka világára'])
	},
		{
		a: 'Felismeri az egymásért végzett munka fontosságát, a munkamegosztás értékét.',
		b: 1,
		c: 2462,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'felkészülés a munka világára'])
	},
		{
		a: 'Társaival munkamegosztás szerint együttműködik a csoportos munkavégzés során.',
		b: 1,
		c: 2461,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'felkészülés a munka világára'])
	},
		{
		a: 'Az elvárt feladatokban önállóan dolgozik -- elvégzi a műveletet.',
		b: 1,
		c: 2460,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'munkavégzési szokások'])
	},
		{
		a: 'Tudatosan megtartja az egészséges és biztonságos munkakörnyezetét.',
		b: 1,
		c: 2459,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'munkavégzési szokások'])
	},
		{
		a: 'Ismeri és használni, alkalmazni tudja a legfontosabb közlekedési lehetőségeket, szabályokat, viselkedési elvárásokat.',
		b: 1,
		c: 2458,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'környezet- és egészségtudatosság'])
	},
		{
		a: 'Ismeri az egészségmegőrzés tevékenységeit.',
		b: 1,
		c: 2457,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'környezet- és egészségtudatosság'])
	},
		{
		a: 'Ismeri a tudatos vásárlás néhány fontos elemét.',
		b: 1,
		c: 2456,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'fogyasztói, pénzügyi-gazdálkodási tudatosság'])
	},
		{
		a: 'Takarékosan gazdálkodik az anyaggal, energiával, idővel.',
		b: 1,
		c: 2455,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'fogyasztói, pénzügyi-gazdálkodási tudatosság'])
	},
		{
		a: 'Otthoni és iskolai környezetének, tevékenységeinek balesetveszélyes helyzeteit felismeri, és ismeri megelőzésük módját.',
		b: 1,
		c: 2454,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'életvezetés, fenntarthatóság'])
	},
		{
		a: 'Ismeri a családellátó tevékenységeket, melyek keretében vállalt feladatait az iskolai önellátás során munkamegosztásban végzi -- terítés, rendrakás, öltözködés, növények, állatok gondozása stb..',
		b: 1,
		c: 2453,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'életvezetés, fenntarthatóság'])
	},
		{
		a: 'Rendelkezik az életkorának megfelelő szintű probléma-felismerési, probléma-megoldási képességgel.',
		b: 1,
		c: 2452,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'technikai-problémamegoldó gondolkodás', 'probléma-megoldási stratégia'])
	},
		{
		a: 'Szelektíven gyűjti a hulladékot.',
		b: 1,
		c: 2451,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'technikai-problémamegoldó gondolkodás', 'az emberi tevékenység környezete'])
	},
		{
		a: 'Törekszik a takarékos anyagfelhasználásra.',
		b: 1,
		c: 2450,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'technikai-problémamegoldó gondolkodás', 'az emberi tevékenység környezete'])
	},
		{
		a: 'Rendet tart a környezetében.',
		b: 1,
		c: 2449,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'technikai-problémamegoldó gondolkodás', 'az emberi tevékenység környezete'])
	},
		{
		a: 'Felismeri, hogy tevékenysége során tud változtatni közvetlen környezetén, megóvhatja, javíthat annak állapotán.',
		b: 1,
		c: 2448,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'technikai-problémamegoldó gondolkodás', 'az emberi tevékenység környezete'])
	},
		{
		a: 'Értékelés után megfogalmazza tapasztalatait, következtetéseket von le a későbbi eredményesebb munkavégzés érdekében.',
		b: 1,
		c: 2447,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tárgykészítés, tervezés, kivitelezés, értékelés'])
	},
		{
		a: 'Saját és társai tevékenységét a kitűzött célok mentén, megadott szempontok szerint reálisan értékeli.',
		b: 1,
		c: 2446,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tárgykészítés, tervezés, kivitelezés, értékelés'])
	},
		{
		a: 'Alkotótevékenysége során előkészítő, alakító, szerelő és felületkezelő műveleteket végez el.',
		b: 1,
		c: 2445,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tárgykészítés, tervezés, kivitelezés, értékelés'])
	},
		{
		a: 'Egyszerű szöveges, rajzos és képi utasításokat hajt végre a tevékenysége során.',
		b: 1,
		c: 2444,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tárgykészítés, tervezés, kivitelezés, értékelés'])
	},
		{
		a: 'Adott szempontok alapján egyszerűbb tárgyakat önállóan tervez, készít, alkalmazza a tanult munkafolyamatokat.',
		b: 1,
		c: 2443,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tárgykészítés, tervezés, kivitelezés, értékelés'])
	},
		{
		a: 'Alkotótevékenysége során figyelembe veszi az anyag tulajdonságait, felhasználhatóságát.',
		b: 1,
		c: 2442,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'anyagok vizsgálata és kiválasztása'])
	},
		{
		a: 'Az anyagok tulajdonságairól érzékszervi úton, önállóan szerez ismereteket -- szín, alak, átlátszóság, szag, keménység, rugalmasság, felületi minőség.',
		b: 1,
		c: 2441,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'anyagok vizsgálata és kiválasztása'])
	},
		{
		a: 'Felismeri az egymásért végzett munka fontosságát, a munkamegosztás értékét.',
		b: 1,
		c: 2440,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Tevékenysége során munkatapasztalatot szerez, megéli az alkotás örömét, az egyéni és csapatsiker élményét.',
		b: 1,
		c: 2439,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Alkotótevékenysége során megéli, megismeri a jeles napokat, ünnepeket, hagyományokat mint értékeket.',
		b: 1,
		c: 2438,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Megadott szempontok mentén értékeli saját, a társak, a csoport munkáját, viszonyítja a kitűzött célokhoz.',
		b: 1,
		c: 2437,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Munkafolyamatokat, technológiákat segítséggel algoritmizál.',
		b: 1,
		c: 2436,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Szöveg vagy rajz alapján épít, tárgyakat készít, alkalmazza a tanult munkafolyamatokat, terveit megosztja.',
		b: 1,
		c: 2435,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Kitartó a munkavégzésben, szükség esetén segítséget kér, segítséget ad.',
		b: 1,
		c: 2434,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Felismeri, hogy tevékenységei során változtatni tud a közvetlen környezetén.',
		b: 1,
		c: 2433,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Elkülöníti a természeti és mesterséges környezet jellemzőit.',
		b: 1,
		c: 2432,
		d: 4,
		e: 'Technika és tervezés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'A fizikai és digitális környezetből információt gyűjt a számára vonzó foglalkozások alkalmassági és képesítési feltételeiről, keresi a vállalkozási lehetőségeket, a jövedelmezőséget és a jellemző tanulási utakat.',
		b: 5,
		c: 2511,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'felkészülés a munka világára -- pályaorientáció, életpálya-tervezés'])
	},
		{
		a: 'Ismeri az egyes modulokhoz kapcsolódó foglalkozások jellemzőit, ezekkel kapcsolatban megfogalmazza saját preferenciáit.',
		b: 5,
		c: 2510,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'felkészülés a munka világára -- pályaorientáció, életpálya-tervezés'])
	},
		{
		a: 'Érti a társadalmi munkamegosztás lényegét, az egyes foglalkoztatási ágazatok jelentőségét.',
		b: 5,
		c: 2509,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'munkavégzési szokások'])
	},
		{
		a: 'Az egyes részfeladatokat rendszerszinten szemléli.',
		b: 5,
		c: 2508,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'munkavégzési szokások'])
	},
		{
		a: 'Alkalmazza a döntés-előkészítés, döntéshozatal eljárásait, hibás döntésein változtat.',
		b: 5,
		c: 2507,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'munkavégzési szokások'])
	},
		{
		a: 'Alkalmazkodik a változó munkafeladatokhoz, szerepelvárásokhoz; vezetőként tudatosan vezeti a csoport döntési folyamatát.',
		b: 5,
		c: 2506,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'munkavégzési szokások'])
	},
		{
		a: 'Önismeretére építve vállal feladatokat, szem előtt tartva a csapat eredményességét.',
		b: 5,
		c: 2505,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'munkavégzési szokások'])
	},
		{
		a: 'Terv szerint tevékenykedik, probléma esetén észszerű kockázatokat felvállal.',
		b: 5,
		c: 2504,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'munkakultúra', 'munkavégzési szokások'])
	},
		{
		a: 'Egészség- és környezettudatosan dönt és tevékenykedik.',
		b: 5,
		c: 2503,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'fogyasztói, pénzügyi-gazdálkodási, környezet- és egészségtudatosság'])
	},
		{
		a: 'Tisztában van a saját, a családi és a társadalmi erőforrásokkal és az azokkal való hatékony és tudatos gazdálkodás módjaival.',
		b: 5,
		c: 2502,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'fogyasztói, pénzügyi-gazdálkodási, környezet- és egészségtudatosság'])
	},
		{
		a: 'Rendszerszinten végzi az elemzést és az alkalmazást.',
		b: 5,
		c: 2501,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'fogyasztói, pénzügyi-gazdálkodási, környezet- és egészségtudatosság'])
	},
		{
		a: 'Felismeri saját felelősségét életvezetése megtervezésében és megszervezésében, tudatosan gazdálkodik a rendelkezésre álló anyagi és nem anyagi erőforrásokkal.',
		b: 5,
		c: 2500,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'tudatos életvezetés, környezeti, társadalmi és gazdasági fenntarthatóság'])
	},
		{
		a: 'Felismeri a személyes cselekvés jelentőségét a globális problémák megoldásában.',
		b: 5,
		c: 2499,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'tudatos életvezetés, környezeti, társadalmi és gazdasági fenntarthatóság'])
	},
		{
		a: 'Döntéseit tudatosság jellemzi, alternatívákat mérlegel.',
		b: 5,
		c: 2498,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'tudatos életvezetés, környezeti, társadalmi és gazdasági fenntarthatóság'])
	},
		{
		a: 'Holisztikus szemlélettel rendelkezik, az összefüggések megértésére törekszik.',
		b: 5,
		c: 2497,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'életvitel', 'tudatos életvezetés, környezeti, társadalmi és gazdasági fenntarthatóság'])
	},
		{
		a: 'Komplex szempontrendszer mentén választ stratégiát, optimalizál.',
		b: 5,
		c: 2496,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'technikai-problémamegoldó gondolkodás'])
	},
		{
		a: 'A problémamegoldás során önállóan vagy társakkal együtt fogalmaz meg megoldási alternatívákat.',
		b: 5,
		c: 2495,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'technikai-problémamegoldó gondolkodás'])
	},
		{
		a: 'Felismeri a technikai fejlődés és a társadalmi, gazdasági fejlődés kapcsolatát.',
		b: 5,
		c: 2494,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'technikai-problémamegoldó gondolkodás'])
	},
		{
		a: 'Tevékenységének tervezésénél és értékelésénél figyelembe vesz környezeti szempontokat.',
		b: 5,
		c: 2493,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'technikai-problémamegoldó gondolkodás'])
	},
		{
		a: 'Érti és értékeli a globális változásokat érintő lehetséges megoldások és az emberi tevékenység szerepét, jelentőségét.',
		b: 5,
		c: 2492,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'technikai-problémamegoldó gondolkodás'])
	},
		{
		a: 'Szempontokat határoz meg a környezeti állapot felméréséhez, bizonyos eltéréseket számszerűsít.',
		b: 5,
		c: 2491,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'technikai-problémamegoldó gondolkodás'])
	},
		{
		a: 'Megérti az egyén felelősségét a közös értékteremtésben.',
		b: 5,
		c: 2490,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'értékelés'])
	},
		{
		a: 'A használatbavétel során, az eltéréseket kiindulópontként alkalmazva javaslatot tesz produktuma továbbfejlesztésére.',
		b: 5,
		c: 2489,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'értékelés'])
	},
		{
		a: 'Adott szempontok mentén értékeli saját és mások munkáját.',
		b: 5,
		c: 2488,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'értékelés'])
	},
		{
		a: 'A csoportban feladata szerint tevékenykedik, tudását megosztja.',
		b: 5,
		c: 2487,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tervezés, kivitelezés'])
	},
		{
		a: 'Felméri és tervezi a tevékenység munkavédelmi szabályait.',
		b: 5,
		c: 2486,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tervezés, kivitelezés'])
	},
		{
		a: 'Csoportmunkában feladatot vállal, részt vesz a döntéshozatalban, és a döntésnek megfelelően tevékenykedik.',
		b: 5,
		c: 2485,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tervezés, kivitelezés'])
	},
		{
		a: 'Részt vesz a munkavégzési szabályok megalkotásában, betartja azokat.',
		b: 5,
		c: 2484,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tervezés, kivitelezés'])
	},
		{
		a: 'A megismert szerszámokat és eszközöket önállóan, az újakat tanári útmutatással használja.',
		b: 5,
		c: 2483,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tervezés, kivitelezés'])
	},
		{
		a: 'Alkalmazza a forma és funkció összefüggéseit, önállóan választ szerszámot, eszközt.',
		b: 5,
		c: 2482,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tervezés, kivitelezés'])
	},
		{
		a: 'A terv szerinti lépések megtartásával, önellenőrzéssel halad alkotótevékenységében.',
		b: 5,
		c: 2481,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tervezés, kivitelezés'])
	},
		{
		a: 'Terveit a műszaki kommunikáció alkalmazásával osztja meg.',
		b: 5,
		c: 2480,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tervezés, kivitelezés'])
	},
		{
		a: 'Tevékenységét önállóan vagy társakkal együttműködve tervezi.',
		b: 5,
		c: 2479,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'tervezés, kivitelezés'])
	},
		{
		a: 'Környezeti, fenntarthatósági szempontokat is mérlegelve, céljainak megfelelően választ a rendelkezésre álló anyagokból.',
		b: 5,
		c: 2478,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'anyagok vizsgálata és kiválasztása'])
	},
		{
		a: 'Önállóan szerez információt megfigyelés, vizsgálat, adatgyűjtés útján.',
		b: 5,
		c: 2477,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['technika és tervezés', 'alkotótevékenység', 'anyagok vizsgálata és kiválasztása'])
	},
		{
		a: 'Felismeri az emberi cselekvés jelentőségét és felelősségét a környezetalakításban.',
		b: 5,
		c: 2476,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Értékként tekint saját és mások alkotásaira, a létrehozott produktumokra.',
		b: 5,
		c: 2475,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Értékeli az elvégzett munkákat, az értékelésben elhangzottakat felhasználja a későbbi munkavégzés során.',
		b: 5,
		c: 2474,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Az elkészült produktumot használatba veszi, a tervhez viszonyítva értékeli saját, mások és a csoport munkáját.',
		b: 5,
		c: 2473,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'A tevékenység során társaival együttműködik, feladatmegosztás szerint tevékenykedik.',
		b: 5,
		c: 2472,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Ismeri az egyes műveletek jelentőségét a munka biztonságának, eredményességének vonatkozásában.',
		b: 5,
		c: 2471,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Munkavégzéskor szabálykövető, kooperatív magatartást követ.',
		b: 5,
		c: 2470,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Törekszik a balesetmentes tevékenységre, a munkaterületen rendet tart.',
		b: 5,
		c: 2469,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Célszerűen választja ki és rendeltetésszerűen használja a szükséges szerszámokat, eszközöket, digitális alkalmazásokat.',
		b: 5,
		c: 2468,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Tervek mentén folytatja alkotótevékenységét.',
		b: 5,
		c: 2467,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Ismeri és betartja az alapvető munkavédelmi szabályokat.',
		b: 5,
		c: 2466,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Tevékenységét megtervezi, terveit másokkal megosztja.',
		b: 5,
		c: 2465,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Ismeri a felhasznált anyagok vizsgálati lehetőségeit és módszereit, tulajdonságait, és céljainak megfelelően választ a rendelkezésre álló anyagokból.',
		b: 5,
		c: 2464,
		d: 7,
		e: 'Technika és tervezés@5-7',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'technika és tervezés'])
	},
		{
		a: 'Piktogramok alapján megfogalmazza a várható időjárást.',
		b: 5,
		c: 2639,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Értelmezi az időjárás-jelentést.',
		b: 5,
		c: 2638,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Értelmezi az évszakok változását.',
		b: 5,
		c: 2637,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Jellemezi és összehasonlítja az egyes éghajlati övezeteket (forró, mérsékelt, hideg).',
		b: 5,
		c: 2636,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Megnevezi az éghajlat fő elemeit.',
		b: 5,
		c: 2635,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Magyarázza az éghajlat és a folyók vízjárása közötti összefüggéseket.',
		b: 5,
		c: 2634,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Magyarázza a felszín lejtése, a folyó vízhozama, munkavégző képessége és a felszínformálás közti összefüggéseket.',
		b: 5,
		c: 2633,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Felismeri és összehasonlítja a gyűrődés, a vetődés, a földrengés és a vulkáni tevékenység hatásait.',
		b: 5,
		c: 2632,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Ismer olyan módszereket, melyek a talajpusztulás ellen hatnak (tápanyag-visszapótlás, komposztkészítés, ökológiai kertművelés).',
		b: 5,
		c: 2631,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Tisztában van azzal, hogy a talajpusztulás világméretű probléma.',
		b: 5,
		c: 2630,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Példákat hoz a kőzetek tulajdonságai és a felhasználásuk közötti összefüggésekre.',
		b: 5,
		c: 2629,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Megállapítja, összehasonlítja és csoportosítja néhány jellegzetes hazai kőzet egyszerűen vizsgálható tulajdonságait.',
		b: 5,
		c: 2628,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a föld belső és külső erői és folyamatai'])
	},
		{
		a: 'Megismeri az energiatermelés hatását a természetes és a mesterséges környezetre.',
		b: 5,
		c: 2627,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az energia fogalma'])
	},
		{
		a: 'Példákat hoz a megújuló és a nem megújuló energiaforrások felhasználására.',
		b: 5,
		c: 2626,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az energia fogalma'])
	},
		{
		a: 'Csoportosítja az energiahordozókat különböző szempontok alapján.',
		b: 5,
		c: 2625,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az energia fogalma'])
	},
		{
		a: 'Tisztában van a természeti erők szerepével a felszínalakításban.',
		b: 5,
		c: 2624,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élettelen környezet elemei, alapvető folyamatai'])
	},
		{
		a: 'Ismeri a természeti erőforrások energiatermelésben betöltött szerepét.',
		b: 5,
		c: 2623,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élettelen környezet elemei, alapvető folyamatai'])
	},
		{
		a: 'Összetett rendszerként értelmezi az egyes földi szférák működését.',
		b: 5,
		c: 2622,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élettelen környezet elemei, alapvető folyamatai'])
	},
		{
		a: 'Tisztában van az egészséges életmód alapelveivel, azokat igyekszik betartani.',
		b: 5,
		c: 2621,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az emberi szervezet egészséges működése'])
	},
		{
		a: 'Érti a kamaszkori testi és lelki változások folyamatát, élettani hátterét.',
		b: 5,
		c: 2620,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az emberi szervezet egészséges működése'])
	},
		{
		a: 'Látja az összefüggéseket az egyes szervek működése között.',
		b: 5,
		c: 2619,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az emberi szervezet egészséges működése'])
	},
		{
		a: 'Felismeri és megnevezi az emberi test fő részeit, szerveit.',
		b: 5,
		c: 2618,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az emberi szervezet egészséges működése'])
	},
		{
		a: 'Tisztában van az egészséges környezet és az egészségmegőrzés közti összefüggéssel.',
		b: 5,
		c: 2617,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az emberi szervezet egészséges működése'])
	},
		{
		a: 'Tisztában van a testi és lelki egészség védelmének fontosságával.',
		b: 5,
		c: 2616,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az emberi szervezet egészséges működése'])
	},
		{
		a: 'Érti, hogy a szervezet rendszerként működik.',
		b: 5,
		c: 2615,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az emberi szervezet egészséges működése'])
	},
		{
		a: 'Tisztában van a vízi társulások természetvédelmi értékével, fontosnak tartja annak védelmét.',
		b: 5,
		c: 2614,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Példákon keresztül bemutatja a vízhasznosítás és a vízszennyezés életközösségre gyakorolt hatásait.',
		b: 5,
		c: 2613,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Táplálékláncokat és ezekből táplálékhálózatot állít össze a megismert vízi és vízparti növény- és állatfajokból.',
		b: 5,
		c: 2612,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Példákkal bizonyítja, rendszerezi és következtetéseket von le a vízi élőlények környezethez történő alkalmazkodására vonatkozóan.',
		b: 5,
		c: 2611,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Felismeri és magyarázza az élőhely -- életmód - testfelépítés összefüggéseit a vízi és vízparti életközösségek esetén.',
		b: 5,
		c: 2610,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Összehasonlítja a vízi és szárazföldi élőhelyek környezeti tényezőit.',
		b: 5,
		c: 2609,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Megfigyeli hazánk vízi és vízparti élőlénytársulásainak főbb jellemzőit.',
		b: 5,
		c: 2608,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Tisztában van a fátlan társulások természetvédelmi értékével, fontosnak tartja annak védelmét.',
		b: 5,
		c: 2607,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Példákon keresztül mutatja be a mezőgazdasági tevékenységek életközösségre gyakorolt hatásait.',
		b: 5,
		c: 2606,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Táplálékláncokat és azokból táplálékhálózatot állít össze a megismert mezei növény- és állatfajokból.',
		b: 5,
		c: 2605,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Példákkal bizonyítja, rendszerezi és következtetéseket von le a mezei élőlények környezethez történő alkalmazkodására vonatkozóan.',
		b: 5,
		c: 2604,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Felismeri és magyarázza az élőhely - életmód - testfelépítés összefüggéseit a rétek életközössége esetén.',
		b: 5,
		c: 2603,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Megadott szempontok alapján összehasonlítja a rétek és a szántóföldek életközösségeit.',
		b: 5,
		c: 2602,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Megfigyeli hazánk fátlan élőlénytársulásainak főbb jellemzőit.',
		b: 5,
		c: 2601,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Tisztában van az erdő természetvédelmi értékével, fontosnak tartja annak védelmét.',
		b: 5,
		c: 2600,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Példákon keresztül bemutatja az erdőgazdálkodási tevékenységek életközösségre gyakorolt hatásait.',
		b: 5,
		c: 2599,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Táplálékláncokat és azokból táplálékhálózatot állít össze a megismert erdei növény- és állatfajokból.',
		b: 5,
		c: 2598,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Példákkal bizonyítja, rendszerezi és következtetéseket von le az erdei élőlények környezethez történő alkalmazkodására vonatkozóan.',
		b: 5,
		c: 2597,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Felismeri és magyarázza az élőhely -- életmód - testfelépítés összefüggéseit az erdők életközössége esetén.',
		b: 5,
		c: 2596,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Életközösségként értelmezi az erdőt, mezőt, vizes élőhelyeket.',
		b: 5,
		c: 2595,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Megfigyeli hazánk erdei élőlénytársulásainak főbb jellemzőit.',
		b: 5,
		c: 2594,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Mikroszkóp segítségével megfigyel egysejtű élőlényeket.',
		b: 5,
		c: 2593,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az állatok testfelépítése'])
	},
		{
		a: 'Azonosítja a gerinctelen és a gerinces állatok testfelépítése közötti különbségeket.',
		b: 5,
		c: 2592,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az állatok testfelépítése'])
	},
		{
		a: 'Az állatokat különböző szempontok szerint csoportosítja.',
		b: 5,
		c: 2591,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az állatok testfelépítése'])
	},
		{
		a: 'Felismeri és megnevezi az állatok testrészeit, megfigyeli jellemzőiket, megfogalmazza ezek funkcióit.',
		b: 5,
		c: 2590,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az állatok testfelépítése'])
	},
		{
		a: 'Összehasonlít ismert hazai házi vagy vadon élő állatokat adott szempontok (testfelépítés, életfeltételek, szaporodás) alapján.',
		b: 5,
		c: 2589,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az állatok testfelépítése'])
	},
		{
		a: 'Felismeri és megnevezi az állatok életfeltételeit és életjelenségeit.',
		b: 5,
		c: 2588,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az állatok testfelépítése'])
	},
		{
		a: 'Azonosítja a lágyszárú és a fás szárú növények testfelépítése közötti különbségeket.',
		b: 5,
		c: 2587,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a növények testfelépítése'])
	},
		{
		a: 'Ismert hazai termesztett vagy vadon élő növényeket különböző szempontok szerint csoportosít.',
		b: 5,
		c: 2586,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a növények testfelépítése'])
	},
		{
		a: 'Összehasonlítja ismert hazai termesztett vagy vadon élő növények részeit megadott szempontok alapján.',
		b: 5,
		c: 2585,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a növények testfelépítése'])
	},
		{
		a: 'Felismeri és megnevezi a növények részeit, megfigyeli jellemzőiket, megfogalmazza ezek funkcióit.',
		b: 5,
		c: 2584,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a növények testfelépítése'])
	},
		{
		a: 'Összehasonlít ismert hazai termesztett vagy vadon élő növényeket adott szempontok (testfelépítés, életfeltételek, szaporodás) alapján.',
		b: 5,
		c: 2583,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a növények testfelépítése'])
	},
		{
		a: 'Felismeri és megnevezi a növények életfeltételeit, életjelenségeit.',
		b: 5,
		c: 2582,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a növények testfelépítése'])
	},
		{
		a: 'Tisztában van azzal, hogy az élő rendszerekbe történő beavatkozás káros hatásokkal járhat.',
		b: 5,
		c: 2581,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élőlények felépítése és az élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Tisztában van az életfeltételek és a testfelépítés közti kapcsolattal.',
		b: 5,
		c: 2580,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élőlények felépítése és az élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Komplex rendszerként értelmezi az élő szervezeteket és az ezekből felépülő élőlénytársulásokat.',
		b: 5,
		c: 2579,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élőlények felépítése és az élőlénytársulások alapvető folyamatai'])
	},
		{
		a: 'Használni tud néhány egyszerű térinformatikai alkalmazást.',
		b: 5,
		c: 2578,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'gyakorlati jellegű térképészeti ismeretek (az iskola környékének megismerése során, terepi munkában'])
	},
		{
		a: 'Meghatározott szempontok alapján útvonalat tervez a térképen.',
		b: 5,
		c: 2577,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'gyakorlati jellegű térképészeti ismeretek (az iskola környékének megismerése során, terepi munkában'])
	},
		{
		a: 'Tájékozódik a terepen térképvázlat, iránytű és gps segítségével.',
		b: 5,
		c: 2576,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'gyakorlati jellegű térképészeti ismeretek (az iskola környékének megismerése során, terepi munkában'])
	},
		{
		a: 'A valóságban megismert területről egyszerű, jelrendszerrel ellátott útvonaltervet, térképet készít.',
		b: 5,
		c: 2575,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'gyakorlati jellegű térképészeti ismeretek (az iskola környékének megismerése során, terepi munkában'])
	},
		{
		a: 'Bejelöli a térképen budapestet és a saját lakóhelyéhez közeli fontosabb nagyvárosokat és a szomszédos országokat.',
		b: 5,
		c: 2574,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'topográfiai ismeretek'])
	},
		{
		a: 'Felismeri és megnevezi a legjelentősebb hazai álló- és folyóvizeket.',
		b: 5,
		c: 2573,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'topográfiai ismeretek'])
	},
		{
		a: 'Ismeri a főfolyó, a mellékfolyó és a torkolat térképi ábrázolását.',
		b: 5,
		c: 2572,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'topográfiai ismeretek'])
	},
		{
		a: 'Megfogalmazza európa és magyarország tényleges és viszonylagos földrajzi fekvését.',
		b: 5,
		c: 2571,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'topográfiai ismeretek'])
	},
		{
		a: 'Felismeri a nevezetes szélességi köröket a térképen.',
		b: 5,
		c: 2570,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'topográfiai ismeretek'])
	},
		{
		a: 'Felismeri a földrészeket és az óceánokat a különböző méretarányú és ábrázolásmódú térképeken.',
		b: 5,
		c: 2569,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'topográfiai ismeretek'])
	},
		{
		a: 'Felismeri és használja a térképi jelrendszert és a térképfajtákat (domborzati térkép, közigazgatási térkép, autós térkép, turista térkép).',
		b: 5,
		c: 2568,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'alapvető térképészeti ismeretek'])
	},
		{
		a: 'Fő- és mellékégtájak segítségével meghatározza különböző földrajzi objektumok egymáshoz viszonyított helyzetét.',
		b: 5,
		c: 2567,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'alapvető térképészeti ismeretek'])
	},
		{
		a: 'Megérti a méretarány és az ábrázolás részletessége közötti összefüggéseket.',
		b: 5,
		c: 2566,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'alapvető térképészeti ismeretek'])
	},
		{
		a: 'Felismeri a felszínformák ábrázolását a térképen.',
		b: 5,
		c: 2565,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'alapvető térképészeti ismeretek'])
	},
		{
		a: 'Mágneses kölcsönhatásként értelmezi az iránytű működését.',
		b: 5,
		c: 2564,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'alapvető térképészeti ismeretek'])
	},
		{
		a: 'Tájékozódik a térképen és a földgömbön.',
		b: 5,
		c: 2563,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a térbeli tájékozódás fejlesztése'])
	},
		{
		a: 'Érti a térkép és a valóság közötti viszonyt.',
		b: 5,
		c: 2562,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a térbeli tájékozódás fejlesztése'])
	},
		{
		a: 'Meghatározza az irányt a valós térben.',
		b: 5,
		c: 2561,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'a térbeli tájékozódás fejlesztése'])
	},
		{
		a: 'Modellezi a nap és a föld helyzetét a különböző napszakokban és évszakokban.',
		b: 5,
		c: 2560,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'tájékozódás az időben'])
	},
		{
		a: 'Megérti a föld mozgásai és a napi, évi időszámítás közötti összefüggéseket.',
		b: 5,
		c: 2559,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'tájékozódás az időben'])
	},
		{
		a: 'Megfigyeli a természet ciklikus változásait.',
		b: 5,
		c: 2558,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'tájékozódás az időben'])
	},
		{
		a: 'Tervet készít saját időbeosztására vonatkozóan.',
		b: 5,
		c: 2557,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'tájékozódás az időben'])
	},
		{
		a: 'Tudja értelmezni az időt különböző dimenziójú skálákon.',
		b: 5,
		c: 2556,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'tájékozódás az időben'])
	},
		{
		a: 'Felismeri az idő múlásával bekövetkező változásokat és ezek összefüggéseit az élő és élettelen környezet elemein.',
		b: 5,
		c: 2555,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'tájékozódás az időben'])
	},
		{
		a: 'Kísérleti úton megfigyeli az időjárás alapvető folyamatait, magyarázza ezek okait és következményeit.',
		b: 5,
		c: 2554,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'megfigyelés, kísérletezés, tapasztalás'])
	},
		{
		a: 'Kísérletekkel igazolja a növények életfeltételeit.',
		b: 5,
		c: 2553,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'megfigyelés, kísérletezés, tapasztalás'])
	},
		{
		a: 'Megfigyeléseken és kísérleten keresztül megismeri az energiatermelésben szerepet játszó anyagokat és az energiatermelés folyamatát.',
		b: 5,
		c: 2552,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'megfigyelés, kísérletezés, tapasztalás'])
	},
		{
		a: 'Megfigyeli a testek elektromos állapotát és a köztük lévő kölcsönhatásokat, ismeri ennek gyakorlati életben való megjelenését.',
		b: 5,
		c: 2551,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'megfigyelés, kísérletezés, tapasztalás'])
	},
		{
		a: 'Megfigyeli a mágneses kölcsönhatásokat, kísérlettel igazolja a vonzás és a taszítás jelenségét, példákat ismer a mágnesesség gyakorlati életben való felhasználására.',
		b: 5,
		c: 2550,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'megfigyelés, kísérletezés, tapasztalás'])
	},
		{
		a: 'Leolvassa és értékeli a magyarországra vonatkozó éghajlati diagramok és éghajlati térképek adatait.',
		b: 5,
		c: 2549,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'mérések, mértékegységek, mérőeszközök'])
	},
		{
		a: 'Magyarországra vonatkozó adatok alapján kiszámítja a napi középhőmérsékletet, a napi és évi közepes hőingást.',
		b: 5,
		c: 2548,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'mérések, mértékegységek, mérőeszközök'])
	},
		{
		a: 'Észleli, méri az időjárási elemeket, a mért adatokat rögzíti, ábrázolja.',
		b: 5,
		c: 2547,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'mérések, mértékegységek, mérőeszközök'])
	},
		{
		a: 'Önállóan végez becsléseket, méréseket és használ mérőeszközöket a hőmérséklet, a hosszúság, a tömeg, az űrtartalom és az idő meghatározására.',
		b: 5,
		c: 2546,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'mérések, mértékegységek, mérőeszközök'])
	},
		{
		a: 'Megkülönbözteti a hely- és helyzetváltoztatást és példákat keres ezekre megadott szempontok alapján.',
		b: 5,
		c: 2545,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Vizsgálat révén azonosítja a tipikus lágyszárú és fásszárú növények részeit.',
		b: 5,
		c: 2544,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Korábbi tapasztalatai és megfigyelései révén felismeri a levegő egyes tulajdonságait.',
		b: 5,
		c: 2543,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Megfigyeli a talaj élő és élettelen alkotóelemeit, tulajdonságait, összehasonlít különböző típusú talajféleségeket, valamint következtetések révén felismeri a talajnak, mint rendszernek a komplexitását.',
		b: 5,
		c: 2542,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Elsajátítja a tűzveszélyes anyagokkal való bánásmódot, ismeri tűz esetén a szükséges teendőket.',
		b: 5,
		c: 2541,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Felismeri az olvadás és az oldódás közötti különbséget kísérleti tapasztalatok alapján.',
		b: 5,
		c: 2540,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Kísérletek során megfigyeli a különböző halmazállapotú anyagok vízben oldódásának folyamatát.',
		b: 5,
		c: 2539,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Megfigyeli a különböző halmazállapot-változásokhoz (olvadás, fagyás, párolgás, forrás, lecsapódás) kapcsolódó folyamatokat, példákat gyűjt hozzájuk a természetben, a háztartásban és az iparban.',
		b: 5,
		c: 2538,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Bizonyítja, és hétköznapi példákkal alátámasztja a víz fagyásakor történő térfogat-növekedést.',
		b: 5,
		c: 2537,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Korábbi tapasztalatai és megfigyelései révén felismeri a víz különböző tulajdonságait, különböző szempontok alapján rendszerezi a vizek fajtáit.',
		b: 5,
		c: 2536,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'anyagok és tulajdonságaik'])
	},
		{
		a: 'Önállóan végez egyszerű kísérleteket.',
		b: 5,
		c: 2535,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élő és élettelen környezet vizsgálatának alapjai'])
	},
		{
		a: 'Önállóan végez becsléseket, méréseket és használ mérőeszközöket különféle fizikai paraméterek meghatározására.',
		b: 5,
		c: 2534,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élő és élettelen környezet vizsgálatának alapjai'])
	},
		{
		a: 'Felismer és megfigyel különböző természetes és mesterséges anyagokat, ismeri azok tulajdonságait, felhasználhatóságukat, ismeri a természetes és mesterséges környezetre gyakorolt hatásukat.',
		b: 5,
		c: 2533,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élő és élettelen környezet vizsgálatának alapjai'])
	},
		{
		a: 'Felismeri és megfigyeli a környezetben előforduló élő és élettelen anyagokat, megadott vagy önállóan kitalált szempontok alapján csoportosítja azokat.',
		b: 5,
		c: 2532,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['természettudomány', 'az élő és élettelen környezet vizsgálatának alapjai'])
	},
		{
		a: 'Kialakul benne a természettudomány iránti érdeklődés.',
		b: 5,
		c: 2531,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'természettudomány'])
	},
		{
		a: 'Kialakul benne a szűkebb, illetve tágabb környezet iránti felelősségtudat.',
		b: 5,
		c: 2530,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'természettudomány'])
	},
		{
		a: 'Ismeretei bővítéséhez tanári útmutatás mellett kutatásokat végez a nyomtatott és digitális források felhasználásával.',
		b: 5,
		c: 2529,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'természettudomány'])
	},
		{
		a: 'A szöveggel, táblázattal és jelekkel kapott információt önállóan értelmezi, azokból következtetéseket von le.',
		b: 5,
		c: 2528,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'természettudomány'])
	},
		{
		a: 'Megfigyeléseiről, tapasztalatairól szóban, írásban, rajzban beszámol.',
		b: 5,
		c: 2527,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'természettudomány'])
	},
		{
		a: 'Megfigyeléseket, összehasonlításokat, csoportosításokat, méréseket és kísérleteket végez.',
		b: 5,
		c: 2526,
		d: 6,
		e: 'Természettudomány@5-6',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'természettudomány'])
	},
		{
		a: 'Ismeri a kőolaj feldolgozásának módját, fő alkotóit, a szénhidrogéneket, tudja, hogy ezekből számos termék (motorhajtóanyag, kenőanyag, műanyag, textília, mosószer) is készül.',
		b: 7,
		c: 3450,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'elemek és vegyületek'])
	},
		{
		a: 'Tudja, hogy a különféle ásványokból, kőzetekből építőanyagokat (pl. meszet, betont, üveget) és fémeket (pl. vasat és alumíniumot) gyártanak.',
		b: 7,
		c: 3449,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'elemek és vegyületek'])
	},
		{
		a: 'Képes egyszerű kísérletek elvégzésére és elemzésére az elemekkel, vegyületekkel és keverékekkel kapcsolatban.',
		b: 7,
		c: 3448,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'elemek és vegyületek'])
	},
		{
		a: 'Különbséget tesz elem, vegyület és keverék között.',
		b: 7,
		c: 3447,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'elemek és vegyületek'])
	},
		{
		a: 'Ismeri a korrózió fogalmát és a fémek csoportokba sorolását korrózióállóságuk alapján, érti a vas korróziójának lényegét, valamint korrózióvédelmi módjait.',
		b: 7,
		c: 3446,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismer sav-bázis indikátorokat, érti felhasználásuk jelentőségét.',
		b: 7,
		c: 3445,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri a köznapi élet szempontjából legalapvetőbb kémiai reakciókat (pl. égési reakciók, egyesülések, bomlások, savak és bázisok reakciói, fotoszintézis).',
		b: 7,
		c: 3444,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri a katalizátor fogalmát, érti a katalizátorok működési elvének a lényegét.',
		b: 7,
		c: 3443,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri a kémiai reakciók végbemenetelének legalapvetőbb feltételeit (ütközés, energia).',
		b: 7,
		c: 3442,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'A részecskemodell alapján értelmezi az egyszerű kémiai reakciókat.',
		b: 7,
		c: 3441,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Tudja, hogy a keverékek alkotórészeit az alkotórészek egyedi tulajdonságai alapján választhatjuk szét egymástól, ismer konkrét példákat az elválasztási műveletekre (pl. bepárlás, szűrés, ülepítés).',
		b: 7,
		c: 3440,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Példát mond a valódi oldatra és a kolloid oldatra.',
		b: 7,
		c: 3439,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Részecskeszemlélettel értelmezi az oldódás folyamatát és az oldatok összetételét.',
		b: 7,
		c: 3438,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'A részecskemodell alapján értelmezi az oldódást.',
		b: 7,
		c: 3437,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Tudja, hogy melyek az anyag fizikai tulajdonságai.',
		b: 7,
		c: 3436,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Érti, hogy az atomok és ionok között jellemzően erősebb, a molekulák között gyengébb kémiai kötések alakulhatnak ki.',
		b: 7,
		c: 3435,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri a köznapi anyagok molekula- és halmazszerkezetét (hidrogén, oxigén, nitrogén, víz, metán, szén-dioxid, gyémánt, grafit, vas, réz, nátrium-klorid).',
		b: 7,
		c: 3434,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Érti az ionvegyületek képletének megállapítását.',
		b: 7,
		c: 3433,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Érti az egyszerű ionok kialakulását (na+, k+, mg2+, ca2+, al3+, cl-, o2-) és analógiás gondolkodással következtet az egy oszlopban található elemekből képződő ionok képletére.',
		b: 7,
		c: 3432,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Érti egyszerű molekulák kialakulását (h2, cl2, o2, n2, h2o, hcl, ch4, co2) és fel tudja írni a képletüket.',
		b: 7,
		c: 3431,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Tudja, hogy az atom külső elektronjainak jut fontos szerep a molekula- és ionképzés során.',
		b: 7,
		c: 3430,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Fel tudja írni a kisebb atomok elektronszerkezetét a héjakon lévő elektronok számával (bohr-féle atommodell).',
		b: 7,
		c: 3429,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Tudja, hogy az atom atommagból és elektronburokból épül fel.',
		b: 7,
		c: 3428,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Különbséget tesz elemi részecske és kémiai részecske, valamint atom, molekula és ion között.',
		b: 7,
		c: 3427,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri a halmazállapot-változásokat, konkrét példát mond a természetből (légköri jelenségek) és a mindennapokból.',
		b: 7,
		c: 3426,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Egyszerű modelleket (golyómodellt) használ az anyagot felépítő kémiai részecskék modellezésére.',
		b: 7,
		c: 3425,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a részecskemodell'])
	},
		{
		a: 'Ismeri a legfontosabb elemek vegyjelét, illetve vegyületek képletét.',
		b: 7,
		c: 3424,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a kémia jelrendszere'])
	},
		{
		a: 'Szöveges leírás vagy kémiai szimbólum alapján megkülönbözteti az atomokat, a molekulákat és ionokat.',
		b: 7,
		c: 3423,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémiai szakismeretek', 'a kémia jelrendszere'])
	},
		{
		a: 'Képes a forgalomban lévő kemikáliák (növényvédő szerek, háztartási mosó- és tisztítószerek) címkéjén feltüntetett használati útmutató értelmezésére, az azok felelősségteljes használatára.',
		b: 7,
		c: 3422,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a táplálkozás, egészségvédelem kémiai vonatkozásai'])
	},
		{
		a: 'Tud érvelni a változatos táplálkozás és az egészséges életmód mellett.',
		b: 7,
		c: 3421,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a táplálkozás, egészségvédelem kémiai vonatkozásai'])
	},
		{
		a: 'Tisztában van vele, hogy az életfolyamatainkhoz szükséges anyagokat a táplálékunkból vesszük fel zsírok, fehérjék, szénhidrátok, ásványi sók és vitaminok formájában.',
		b: 7,
		c: 3420,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'kémia a mindennapokban', 'a táplálkozás, egészségvédelem kémiai vonatkozásai'])
	},
		{
		a: 'Érti a globális klímaváltozás, a savas esők, az ózonréteg károsodásának, valamint a szmogoknak a kialakulását és emberiségre gyakorolt hatását.',
		b: 7,
		c: 3419,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'globális problémák kémiai vonatkozásai'])
	},
		{
		a: 'Tisztában van azzal, hogy a bennünket körülvevő anyagokat a természetben található anyagokból állítjuk elő.',
		b: 7,
		c: 3418,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: a természeti és épített környezetre veszélyes anyagok'])
	},
		{
		a: 'Ismeri természeti környezetének, azon belül a légkörnek, a kőzetburoknak, a természetes vizeknek és az élővilágnak a legalapvetőbb anyagait.',
		b: 7,
		c: 3417,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: a természeti és épített környezetre veszélyes anyagok'])
	},
		{
		a: 'Azonosítja és példát hoz fel a környezetében előforduló leggyakoribb levegőt, vizet és talajt szennyező forrásokra.',
		b: 7,
		c: 3416,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: a természeti és épített környezetre veszélyes anyagok'])
	},
		{
		a: 'Konkrét lépéseket tesz a saját maga részéről annak érdekében, hogy mérsékelje a környezetszennyezést (pl. energiatakarékosság, szelektív hulladékgyűjtés, tudatos vásárlás).',
		b: 7,
		c: 3415,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: a természeti és épített környezetre veszélyes anyagok'])
	},
		{
		a: 'Kiselőadás keretében beszámol egy a saját települését érintő környezetvédelmi kérdés kémiai vonatkozásairól.',
		b: 7,
		c: 3414,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: a természeti és épített környezetre veszélyes anyagok'])
	},
		{
		a: 'Kiselőadás vagy projektmunka keretében ismerteti a háztartási hulladék összetételét, felhasználásának és csökkentésének lehetőségeit, különös figyelemmel a veszélyes hulladékokra.',
		b: 7,
		c: 3413,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: a természeti és épített környezetre veszélyes anyagok'])
	},
		{
		a: 'Megérti és példákkal szemlélteti az emberi tevékenység és a természeti környezet kölcsönös kapcsolatát kémiai szempontok alapján.',
		b: 7,
		c: 3412,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia társadalmi vonatkozásai, környezet- és természettudatosság', 'környezetvédelem: a természeti és épített környezetre veszélyes anyagok'])
	},
		{
		a: 'Ismeri a természettudományos vizsgálatok során alkalmazott legfontosabb mennyiségeket és azok kapcsolatát.',
		b: 7,
		c: 3411,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'mennyiségi gondolkodás a kémiában: a sztöchiometria alapjai'])
	},
		{
		a: 'Tudja és érti, hogy attól még, hogy egy elem vagy vegyület mesterségesen került előállításra vagy természetes úton került kinyerésre, még ugyanolyan tulajdonságai vannak, ugyanannyira lehet veszélyes vagy veszélytelen, mérgező vagy egészséges.',
		b: 7,
		c: 3410,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a természettudományos gondolkodás alapvető műveletei'])
	},
		{
		a: 'Tudja és érti, hogy a hétköznapi, a mindennapi tapasztalatokon alapuló gondolkodás nem elégséges a tudományos problémák megoldásához.',
		b: 7,
		c: 3409,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a természettudományos gondolkodás alapvető műveletei'])
	},
		{
		a: 'Tudja és érti, hogy közkeletű hiedelmeket nem szabad tényeknek tekinteni.',
		b: 7,
		c: 3408,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a természettudományos gondolkodás alapvető műveletei'])
	},
		{
		a: 'Megismeri néhány köznapi anyag legfontosabb tulajdonságait és az anyagok vizsgálatának egyszerű módszereit.',
		b: 7,
		c: 3407,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a kémiai kísérlet mint megismerési módszer'])
	},
		{
		a: 'Megismeri egy egyszerű laboratórium felépítését, anyagait és eszközeit.',
		b: 7,
		c: 3406,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a kémiai kísérlet mint megismerési módszer'])
	},
		{
		a: 'Megkülönbözteti a kísérletet, a tapasztalatot és a magyarázatot.',
		b: 7,
		c: 3405,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'a kémia mint természettudományos megismerési módszer', 'a kémiai kísérlet mint megismerési módszer'])
	},
		{
		a: 'Ismeri a környezetében végbemenő alapvető folyamatok tudományos hátterét.',
		b: 7,
		c: 3404,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismeri az alapvető laboratóriumi vegyszereket, azok tulajdonságait és felhasználását.',
		b: 7,
		c: 3403,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tisztában van a háztartásban leggyakrabban előforduló anyagok felhasználásának előnyeivel és veszélyeivel, a biztonságos vegyszerhasználat szabályaival',
		b: 7,
		c: 3402,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismeri a kémiának az egyén és a társadalom életében betöltött szerepét, ezt konkrét példákkal tudja alátámasztani.',
		b: 7,
		c: 3401,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tudja használni a részecskemodellt az anyagok tulajdonságainak értelmezésére.',
		b: 7,
		c: 3400,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Képes mobiltelefon, táblagép segítségével a vizsgálatait fénykép, illetve videófelvétel formájában dokumentálni.',
		b: 7,
		c: 3399,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tudja használni az internetet kémiai információk keresésére, képes számítógépes prezentáció formájában kémiával kapcsolatos eredmények, információk bemutatására, megosztására.',
		b: 7,
		c: 3398,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Képes analógiás és mérlegelő gondolkodásra kémiai kontextusban.',
		b: 7,
		c: 3397,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Alkalmazza a természettudományos problémamegoldás lépéseit egyszerű kémiai problémák megoldásában.',
		b: 7,
		c: 3396,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tanári segítséggel vagy csoportban el tud végezni egyszerű kémiai kísérleteket, és precízen meg tudja figyelni és lejegyezni a tapasztalatait.',
		b: 7,
		c: 3395,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['kémia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'A környezeti kérdésekkel, globális problémákkal kapcsolatos álláspontját logikus érvekkel támasztja alá, javaslatot fogalmaz meg a környezeti problémák mérséklésére.',
		b: 7,
		c: 3394,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Érveket fogalmaz meg a tudatos fogyasztói magatartás, a környezettudatos döntések fontossága mellett.',
		b: 7,
		c: 3393,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Ismeri a környezet- és a természetvédelem alapvető feladatait és lehetőségeit a földrajzi, környezeti eredetű problémák mérséklésében, megoldásában.',
		b: 7,
		c: 3392,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Példák alapján megfogalmazza a helyi környezetkárosítás tágabb környezetre kiterjedő következményeit, megnevezi és ok-okozati összefüggéseiben bemutatja a globálissá váló környezeti problémákat.',
		b: 7,
		c: 3391,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Helyi, regionális és a föld egészére jellemző folyamatok közötti hasonlóságokat, összefüggéseket felismer.',
		b: 7,
		c: 3390,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Szűkebb és tágabb környezetében földrajzi eredetű problémákat azonosít, magyarázza kialakulásuk okait.',
		b: 7,
		c: 3389,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a globális problémák összefüggéseiben'])
	},
		{
		a: 'Megnevezi a vállalkozás működését befolyásoló tényezőket.',
		b: 7,
		c: 3388,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Életkori sajátosságainak megfelelő helyzetekben alkalmazza pénzügyi ismereteit (pl. egyszerű költségvetés készítése, valutaváltás, diákvállalkozás tervezése).',
		b: 7,
		c: 3387,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Értelmezi a mindennapi életben jelen lévő pénzügyi tevékenységeket, szolgáltatásokat.',
		b: 7,
		c: 3386,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Foglalkoztatási adatokat értelmez és elemez, következtetéseket von le belőlük.',
		b: 7,
		c: 3385,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Példákat sorol a globalizáció mindennapi életünket befolyásoló folyamataira.',
		b: 7,
		c: 3384,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Népesség- és településföldrajzi információk alapján jellemzőket fogalmaz meg, következtetéseket von le.',
		b: 7,
		c: 3383,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Ismeri és értelmezi a társadalmi-gazdasági fejlettségbeli különbségek leírására alkalmazott mutatókat.',
		b: 7,
		c: 3382,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a világ általános társadalom- és gazdaságföldrajzi folyamataiban'])
	},
		{
		a: 'Az egyes térségek kapcsán földrajzi és környezeti veszélyeket és problémákat fogalmaz meg, valamint reflektál azokra.',
		b: 7,
		c: 3381,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Példákat nevez meg a természeti adottságok gazdálkodást, életvitelt befolyásoló szerepére.',
		b: 7,
		c: 3380,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Összehasonlítja az egyes övezetek, övek jellemzőit, törvényszerűségeket fogalmaz meg velük összefüggésben.',
		b: 7,
		c: 3379,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Bemutatja a földrajzi övezetesség rendszerét, ismerteti az övezetek, övek kialakulásának okait és elhelyezkedésének térbeli jellemzőit.',
		b: 7,
		c: 3378,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a geoszférák jellemzőinek és folyamatainak összefüggéseiben'])
	},
		{
		a: 'Nyitott más országok, nemzetiségek szokásainak, kultúrájának megismerése iránt.',
		b: 7,
		c: 3377,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Reális alapokon nyugvó magyarság- és európa-tudattal rendelkezik.',
		b: 7,
		c: 3376,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Híradásokban közölt regionális földrajzi információkra reflektál.',
		b: 7,
		c: 3375,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Bemutatja a nemzetközi szintű munkamegosztás és fejlettségbeli különbségek kialakulásának okait és következményeit.',
		b: 7,
		c: 3374,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Ismerteti az európai unió társadalmi-gazdasági jellemzőit, példákkal igazolja világgazdasági szerepét.',
		b: 7,
		c: 3373,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Probléma- és értékközpontú megközelítéssel jellemzi európa és az európán kívüli kontinensek tipikus tájait, településeit, térségeit.',
		b: 7,
		c: 3372,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Megnevez az egyes kontinensekre, országcsoportokra, meghatározó jelentőségű országokra jellemző társadalmi-gazdasági folyamatokat, ott előállított termékeket, szolgáltatásokat.',
		b: 7,
		c: 3371,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Elkötelezett szűkebb és tágabb környezete természeti és társadalmi-gazdasági értékeinek megismerése és megőrzése iránt.',
		b: 7,
		c: 3370,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Következtet magyarország és a kárpát-medence térségében előforduló természeti és környezeti veszélyek kialakulásának okaira, várható következményeire, térbeli jellemzőire.',
		b: 7,
		c: 3369,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Összehasonlít, illetve komplex módon, problémaközpontú megközelítéssel vizsgál pl. hazai nagytájakat, tájakat, régiókat, településeket.',
		b: 7,
		c: 3368,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Rendszerezi, csoportosítja és értékeli magyarország és a kárpát-medence térségének természeti és társadalmi-gazdasági erőforrásait, illetve bemutatja a természeti és társadalmi adottságok szerepének, jelentőségének időbeli változásait, a területi fejlettség különbségeit.',
		b: 7,
		c: 3367,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Javaslatot fogalmaz meg lakókörnyezete jövőbeli, környezeti szempontokat szem előtt tartó, fenntartható fejlesztésére.',
		b: 7,
		c: 3366,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Bemutatja és értékeli lakókörnyezetének földrajzi jellemzőit, ismeri annak természeti és társadalmi erőforrásait.',
		b: 7,
		c: 3365,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás regionális földrajzi kérdésekben -- a földrajzi jellemzők elemzése, összefüggések felis'])
	},
		{
		a: 'Képes egyszerű térképvázlatok, útvonaltervek elkészítésére.',
		b: 7,
		c: 3364,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a földrajzi térben és időben'])
	},
		{
		a: 'Tájékozódik különböző típusú és tartalmú térképeken, biztonsággal leolvassa azok információtartalmát, a térképen elhelyez földrajzi elemeket.',
		b: 7,
		c: 3363,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a földrajzi térben és időben'])
	},
		{
		a: 'Gyakorlati feladatokat (pl. távolság- és helymeghatározás, utazástervezés) old meg nyomtatott és digitális térkép segítségével.',
		b: 7,
		c: 3362,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a földrajzi térben és időben'])
	},
		{
		a: 'Használja a földrajzi térben való tájékozódást segítő hagyományos és digitális eszközöket.',
		b: 7,
		c: 3361,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a földrajzi térben és időben'])
	},
		{
		a: 'Azonosítja a jelenségek időbeli jellemzőit.',
		b: 7,
		c: 3360,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'tájékozódás a földrajzi térben és időben'])
	},
		{
		a: 'Közvetlen környezetének földrajzi megismerésére terepvizsgálódást tervez és kivitelez.',
		b: 7,
		c: 3359,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Megadott szempontok alapján tájakkal, országokkal kapcsolatos földrajzi tartalmú szövegeket, képi információhordozókat dolgoz fel.',
		b: 7,
		c: 3358,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Digitális eszközök segítségével bemutatja szűkebb és tágabb környezetének földrajzi jellemzőit.',
		b: 7,
		c: 3357,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Adatokat rendszerez és ábrázol digitális eszközök segítségével.',
		b: 7,
		c: 3356,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Megadott szempontok alapján információkat gyűjt hagyományos és digitális információforrásokból.',
		b: 7,
		c: 3355,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi tartalmú információszerzés és -feldolgozás, digitáliseszköz-használat'])
	},
		{
		a: 'Megismeri hazánk és európa, majd a távoli kontinensek legalapvetőbb természet- és társadalomföldrajzi jellemzőit, melynek során kialakul a földről alkotott, a valóságot visszatükröző kognitív térképe.',
		b: 7,
		c: 3354,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Megkülönbözteti a tényeket a véleményektől.',
		b: 7,
		c: 3353,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Összehasonlít tipikus tájakat, megfogalmazza azok közös és eltérő földrajzi vonásait.',
		b: 7,
		c: 3352,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Megadott szempontok alapján rendszerezi földrajzi ismereteit, rendszerbeli viszonyokat állapít meg.',
		b: 7,
		c: 3351,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Földrajzi tartalmú adatok, adatsorok alapján következtéseket von le, következményeket fogalmaz meg.',
		b: 7,
		c: 3350,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'földrajzi gondolkodás'])
	},
		{
		a: 'Képes földrajzi ismeretei önálló bővítésére és rendszerezésére.',
		b: 7,
		c: 3349,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Érdeklődik más országok földrajzi jellemzői, kultúrája, hagyományai iránt.',
		b: 7,
		c: 3348,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tanulásához tudatosan használja a különböző típusú és tartalmú térképeket.',
		b: 7,
		c: 3347,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Alkalmazza földrajzi tudását a mindennapi életben a környezettudatos döntések meghozatalában, felelősséget érez döntései következményeiért.',
		b: 7,
		c: 3346,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Társaival együttműködésben old meg földrajzi témájú feladatokat, képes a tudásmegosztásra.',
		b: 7,
		c: 3345,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Megold egyszerű földrajzi tartalmú logikai és számítási feladatokat.',
		b: 7,
		c: 3344,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Vitában, szerepjátékban, szituációs játékban szerepének és az adott helyzetnek megfelelő, logikus érveket fogalmaz meg.',
		b: 7,
		c: 3343,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Véleményt alkot földrajzi témájú szövegekben bemutatott jelenségekről, folyamatokról, információkról.',
		b: 7,
		c: 3342,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Önállóan készített prezentációban bemutatja és elemzi egy adott terület természet- és társadalomföldrajzi adottságait, ezekből fakadó környezeti és társadalmi problémáit, és megoldási lehetőség(ek)et kínál.',
		b: 7,
		c: 3341,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Földrajzi tartalmú szövegeket értelmez.',
		b: 7,
		c: 3340,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Különböző földrajzi témákhoz kapcsolódóan adatokat, információkat gyűjt nyomtatott és elektronikus forrásokból, azokat értelmezi, rendszerezi, illetve ábrázolja.',
		b: 7,
		c: 3339,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Céljaival, feladataival összhangban kiválasztja és használja a földrajzi térben való tájékozódást segítő hagyományos és digitális eszközöket.',
		b: 7,
		c: 3338,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['földrajz', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Értelmezi a sportolást segítő kisalkalmazások által mért fizikai adatokat. méréseket végez a mobiltelefon szenzorainak segítségével.',
		b: 7,
		c: 3337,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'mérés, tervezés'])
	},
		{
		a: 'Projektfeladatok megoldása során önállóan, illetve a csoporttagokkal közösen különböző prezentációkat hoz létre a tapasztalatok és eredmények bemutatására.',
		b: 7,
		c: 3336,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'internethasználat, prezentációkészítés'])
	},
		{
		a: 'Egyszerű számítógépes prezentációkat készít egy adott témakör bemutatására.',
		b: 7,
		c: 3335,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'internethasználat, prezentációkészítés'])
	},
		{
		a: 'Ismer megbízható fizikai tárgyú magyar nyelvű internetes forrásokat.',
		b: 7,
		c: 3334,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'internethasználat, prezentációkészítés'])
	},
		{
		a: 'Tanári útmutatás felhasználásával magabiztosan használ magyar nyelvű mobiltelefonos, táblagépes applikációkat fizikai tárgyú információk keresésére.',
		b: 7,
		c: 3333,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'internethasználat, prezentációkészítés'])
	},
		{
		a: 'Az internet segítségével adatokat gyűjt a legfontosabb fizikai jelenségekről.',
		b: 7,
		c: 3332,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'internethasználat, prezentációkészítés'])
	},
		{
		a: 'Fizikai szövegben, videóban el tudja különíteni a számára világos és nem érthető, további magyarázatra szoruló részeket.',
		b: 7,
		c: 3331,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a digitális technológiák használata', 'internethasználat, prezentációkészítés'])
	},
		{
		a: 'Tisztában van a galaxisok mibenlétével, számuk és méretük nagyságrendjével. ismeri a naprendszer bolygóinak fontosabb fizikai jellemzőit.',
		b: 7,
		c: 3330,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'a világegyetem'])
	},
		{
		a: 'Ismeri a csillagok fogalmát, számuk és méretük nagyságrendjét, ismeri a világűr fogalmát, a csillagászati időegységeket (nap, hónap, év) és azok kapcsolatát a föld és hold forgásával és keringésével.',
		b: 7,
		c: 3329,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'a világegyetem'])
	},
		{
		a: 'Érti a nappalok és éjszakák változásának fizikai okát, megfigyelésekkel feltárja a holdfázisok változásának fizikai hátterét, látja a nap szerepét a naprendszerben mint gravitációs centrum és mint energiaforrás.',
		b: 7,
		c: 3328,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'a világegyetem'])
	},
		{
		a: 'Gyakorlati példákon keresztül ismeri a fény és anyag legelemibb kölcsönhatásait (fénytörés, fényvisszaverődés, elnyelés, sugárzás), az árnyékjelenségeket, mint a fény egyenes vonalú terjedésének következményeit, a fehér fény felbonthatóságát.',
		b: 7,
		c: 3327,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Tisztában van a fény egyenes vonalú terjedésével, szabályos visszaverődésének törvényével, erre hétköznapi példákat hoz.',
		b: 7,
		c: 3326,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Tudja, hogy a földnek mágneses tere van, ismeri ennek legegyszerűbb dipól közelítését. ismeri az állandó mágnes sajátságait, az iránytűt.',
		b: 7,
		c: 3325,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Használja a feszültség, áramerősség, ellenállás mennyiségeket egyszerű áramkörök jellemzésére.',
		b: 7,
		c: 3324,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Szemléletes képpel rendelkezik az elektromos áramról, ismeri az elektromos vezetők és szigetelők fogalmát.',
		b: 7,
		c: 3323,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Ismeri az elektromos állapot fogalmát, kialakulását, és magyarázza azt az anyagban lévő töltött részecskék és a közöttük fellépő erőhatások segítségével.',
		b: 7,
		c: 3322,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'elektromos és mágneses jelenségek'])
	},
		{
		a: 'Tisztában van a rugalmasság és rugalmatlanság fogalmával, az erő és az általa okozott deformáció közötti kapcsolat jellegével, be tudja mutatni az anyag belső szerkezetére vonatkozó legegyszerűbb modelleket, kvalitatív jellemzőket.',
		b: 7,
		c: 3321,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Tudja magyarázni a folyadékokban való úszás, lebegés és elmerülés jelenségét, az erre vonatkozó sűrűségfeltételt.',
		b: 7,
		c: 3320,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Kísérletezés közben, illetve a háztartásban megfigyeli a folyadékok és szilárd anyagok melegítésének folyamatát, és szemléletes képet alkot a melegedést kísérő változásokról, a melegedési folyamatot befolyásoló tényezőkről.',
		b: 7,
		c: 3319,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Jellemzi az anyag egyes halmazállapotait, annak sajátságait, ismeri a halmazállapot-változások jellemzőit, a halmazállapot-változások és a hőmérséklet alakulásának kapcsolatát.',
		b: 7,
		c: 3318,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az anyag és halmazállapotai'])
	},
		{
		a: 'Előidéz egyszerű energiaátalakulással járó folyamatokat (melegítés, szabadesés), megnevezi az abban szereplő energiákat.',
		b: 7,
		c: 3317,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az energia'])
	},
		{
		a: 'Kvalitatív ismeretekkel rendelkezik az energia szerepéről, az energiaforrásokról, az energiaátalakulásokról.',
		b: 7,
		c: 3316,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'az energia'])
	},
		{
		a: 'Érti a hullámmozgás lényegét és a jellemző legfontosabb mennyiségeket: frekvencia, amplitúdó, hullámhossz, terjedési sebesség.',
		b: 7,
		c: 3315,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Egyszerű eszközökkel létrehoz periodikus mozgásokat, méri a periódusidőt, fizikai kísérleteket végez azzal kapcsolatban, hogy mitől függ a periódusidő.',
		b: 7,
		c: 3314,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Tisztában van a mozgások kialakulásának okával, ismeri az erő szerepét egy mozgó test megállításában, elindításában, valamilyen külső hatás kompenzálásában.',
		b: 7,
		c: 3313,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Meghatározza az egyenes vonalú egyenletes mozgást végző test sebességét, a megtett utat, az út megtételéhez szükséges időt.',
		b: 7,
		c: 3312,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Megfelelően tudja összekapcsolni a hely- és időadatokat. különbséget tesz az út és elmozdulás fogalma között, ismeri, és ki tudja számítani az átlagsebességet, a mértékegységeket megfelelően használja, tudja, hogy lehetnek egyenletes és nem egyenletes mozgások, ismeri a testek sebességének nagyságrendjét.',
		b: 7,
		c: 3311,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'fizikai szakismeretek', 'mozgások a környezetünkben'])
	},
		{
		a: 'Átlátja a táplálékok energiatartalmának szerepét a szervezet energiaháztartásában és az ideális testsúly megtartásában.',
		b: 7,
		c: 3310,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai háttere'])
	},
		{
		a: 'Ismeri a látás folyamatát, a szem hibáit és a szemüveg szerepét ezek kijavításában, a szem megerőltetésének (például számítógép) következményeit.',
		b: 7,
		c: 3309,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai háttere'])
	},
		{
		a: 'Ismeri a hallás folyamatát, a levegő hullámzásának szerepét a hang továbbításában. meg tudja nevezni a halláskárosodáshoz vezető főbb tényezőket.',
		b: 7,
		c: 3308,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'az egészséges életmód fizikai háttere'])
	},
		{
		a: 'Érti a színek kialakulásának elemi fizikai hátterét.',
		b: 7,
		c: 3307,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai, biztonságos használata'])
	},
		{
		a: 'Ismeri néhány gyakran használt optikai eszköz részeit, átlátja működési elvüket.',
		b: 7,
		c: 3306,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai, biztonságos használata'])
	},
		{
		a: 'Ismeri a villamos energia felhasználását a háztartásban, az energiatakarékosság módozatait, az érintésvédelmi és biztonsági rendszereket és szabályokat.',
		b: 7,
		c: 3305,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai, biztonságos használata'])
	},
		{
		a: 'Ismeri az aktuálisan használt elektromos fényforrásokat, azok fogyasztását és fényerejét meghatározó mennyiségeket, a háztartásban gyakran használt áramforrásokat.',
		b: 7,
		c: 3304,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai, biztonságos használata'])
	},
		{
		a: 'Tisztában van az önvezérelt járművek működésének elvével, illetve néhány járműbiztonsági rendszer működésének fizikai hátterével.',
		b: 7,
		c: 3303,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai, biztonságos használata'])
	},
		{
		a: 'Ismeri a legfontosabb, saját maga által használt eszközök (például közlekedési eszközök, elektromos háztartási eszközök, szerszámok) működésének fizikai lényegét.',
		b: 7,
		c: 3302,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'a gyakran használt technikai eszközök, technológiák fizikai alapjai, biztonságos használata'])
	},
		{
		a: 'Tudja, miben nyilvánulnak meg a kapilláris jelenségek, ismer ezekre példákat a gyakorlatból (pl. növények tápanyagfelvétele a talajból).',
		b: 7,
		c: 3301,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'a legáltalánosabb természeti jelenségek fizikai alapjai'])
	},
		{
		a: 'Ismeri a környezetében előforduló legfontosabb természeti jelenségek (például időjárási jelenségek, fényviszonyok változásai, égi jelenségek) fizikai magyarázatát.',
		b: 7,
		c: 3300,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a természeti jelenségek és a technikai eszközök, technológiák fizikája', 'a legáltalánosabb természeti jelenségek fizikai alapjai'])
	},
		{
		a: 'Felismeri a fizikai kutatás által megalapozott technikai fejlődés egyes fejezeteinek a társadalomra, illetve a történelemre gyakorolt hatását, meg tudja fogalmazni a természettudomány fejlődésével kapcsolatos alapvető etikai kérdéseket.',
		b: 7,
		c: 3299,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizikai ismeretek bővülésének gazdasági, társadalmi hatásai'])
	},
		{
		a: 'Megismeri jelentős fizikusok életének és tevékenységének legfontosabb részleteit, azok társadalmi összefüggéseit (pl. isaac newton, arkhimédész, galileo galilei, jedlik ányos).',
		b: 7,
		c: 3298,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizikai ismeretek bővülésének gazdasági, társadalmi hatásai'])
	},
		{
		a: 'Tisztában van az űrkutatás aktuális céljaival, legérdekesebb eredményeivel.',
		b: 7,
		c: 3297,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a globális problémák egyszerűbb fizikai vonatkozásai'])
	},
		{
		a: 'Tudatában van az emberi tevékenység természetre gyakorolt lehetséges negatív hatásainak és az ezek elkerülésére használható fizikai eszközöknek és eljárásoknak (pl. porszűrés, szennyezők távolról való érzékelése alapján elrendelt forgalomkorlátozás).',
		b: 7,
		c: 3296,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a globális problémák egyszerűbb fizikai vonatkozásai'])
	},
		{
		a: 'Ismeri az éghajlatváltozás problémájának összetevőit, lehetséges okait. tisztában van a hagyományos ipari nyersanyagok földi készleteinek végességével és e tény lehetséges következményeivel.',
		b: 7,
		c: 3295,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a globális problémák egyszerűbb fizikai vonatkozásai'])
	},
		{
		a: 'Ismeri a zöldenergia és fosszilis energia fogalmát, az erőművek energiaátalakításban betöltött szerepét, az energiafelhasználás módjait és a háztartásokra jellemző fogyasztási adatokat.',
		b: 7,
		c: 3294,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'az energiagazdálkodás fizikai vonatkozásai'])
	},
		{
		a: 'Ismeri a jövő tervezett energiaforrásaira vonatkozó legfontosabb elképzeléseket.',
		b: 7,
		c: 3293,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'az energiagazdálkodás fizikai vonatkozásai'])
	},
		{
		a: 'Tisztában van azzal, hogy az energiának ára van, gyakorlati példákon keresztül ismerteti az energiatakarékosság fontosságát, ismeri az energiatermelés környezeti hatásait, az energiabiztonság fogalmát.',
		b: 7,
		c: 3292,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'az energiagazdálkodás fizikai vonatkozásai'])
	},
		{
		a: 'Ismeri az ózonpajzs elvékonyodásának és az ultraibolya sugárzás erősödésének tényét és lehetséges okait.',
		b: 7,
		c: 3291,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizika szerepe a környezet megóvásában'])
	},
		{
		a: 'Környezetében zajszintméréseket végez számítógépes mérőeszközzel, értelmezi a kapott eredményt.',
		b: 7,
		c: 3290,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizika szerepe a környezet megóvásában'])
	},
		{
		a: 'Tudja azonosítani a széles körben használt technológiák környezetkárosító hatásait, és fizikai ismeretei alapján javaslatot tesz a károsító hatások csökkentésének módjára.',
		b: 7,
		c: 3289,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika művelésének társadalmi vonatkozásai, környezet- és természettudatosság', 'a fizika szerepe a környezet megóvásában'])
	},
		{
		a: 'Tájékozott a fizika néhány új eredményével kapcsolatban.',
		b: 7,
		c: 3288,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'a fizika tudományának területei, legújabb eredményei'])
	},
		{
		a: 'Ismeri a fizika fontosabb szakterületeit.',
		b: 7,
		c: 3287,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'a fizika tudományának területei, legújabb eredményei'])
	},
		{
		a: 'Értelmezni tud egy jelenséget, megfigyelést valamilyen korábban megismert vagy saját maga által alkotott egyszerű elképzelés segítségével.',
		b: 7,
		c: 3286,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Jó becsléseket tud adni egyszerű számítás, következtetés segítségével.',
		b: 7,
		c: 3285,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Hétköznapi eszközökkel méréseket végez, rögzíti a mérések eredményeit, leírja a méréssorozatokban megfigyelhető tendenciákat, ennek során helyesen használja a közismert mértékegységeket.',
		b: 7,
		c: 3284,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Felismeri a tudomány által vizsgálható jelenségeket, azonosítani tudja a tudományos érvelést, kritikusan vizsgálja egy elképzelés tudományos megalapozottságát.',
		b: 7,
		c: 3283,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Megfigyeléseket és kísérleteket végez a környezetében, az abból származó tapasztalatokat rögzíti.',
		b: 7,
		c: 3282,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'a fizika mint természettudományos megismerési módszer', 'fizikai megfigyelések, kísérletek végzése, az eredmények értelmezése'])
	},
		{
		a: 'Önállóan keres és olvas fizikai témájú ismeretterjesztő szövegeket, törekszik a lényeg kiemelésére.',
		b: 7,
		c: 3281,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Szemléletes példákon keresztül felismeri a fizikai ismeretek bővülése és a társadalmi-gazdasági folyamatok, történelmi események közötti kapcsolatot.',
		b: 7,
		c: 3280,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Egyszerű, a megértést segítő számítási feladatokat old meg.',
		b: 7,
		c: 3279,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismeri a világot leíró fontos fizikai mennyiségeket, azok jelentését, jellemző nagyságrendjeit a mindennapokban.',
		b: 7,
		c: 3278,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Felismeri, hogyan jelennek meg a fizikai ismeretek a gyakran használt technikai eszközök működésében.',
		b: 7,
		c: 3277,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tájékozott a földünket és környezetünket fenyegető globális problémákban, ismeri az emberi tevékenység lehetséges szerepét ezek kialakulásában.',
		b: 7,
		c: 3276,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Értelmezni tudja lakóhelyét a földön, a föld helyét a naprendszerben, a naprendszer helyét a galaxisunkban és az univerzumban.',
		b: 7,
		c: 3275,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Ismeri a legfontosabb természeti jelenségeket, azok életkorának megfelelően egyszerűsített, a fizikai mennyiségeken és törvényeken alapuló magyarázatait.',
		b: 7,
		c: 3274,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['fizika', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tényekkel igazolja a testi és lelki egészség közötti kapcsolatot, tud ennek egyéni és társadalmi összefüggéseiről, érvel az egészségkárosító szokások és függőségek ellen.',
		b: 7,
		c: 3273,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Tudja alkalmazni az alapszintű újraélesztést mellkas kompressziók és lélegeztetés (cpr) kivitelezésével, felismeri ennek szükségességét és vállalja a beavatkozást.',
		b: 7,
		c: 3272,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'A bekövetkezett balesetet, rosszullétet felismeri, segítséget (szükség esetén mentőt) tud hívni, valamint a tőle elvárható módon (életkori sajátosságainak megfelelően) elsősegélyt tud nyújtani.',
		b: 7,
		c: 3271,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Felméri a baleseti sérülések kockázatait, igyekszik ezeket elkerülni, a bekövetkezett balesetek esetében felismeri a sérülés, vérzés vagy mérgezés jeleit, ezekről megfelelő beszámolót tud adni.',
		b: 7,
		c: 3270,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Érti az orvosi diagnosztikai eljárások célját, ismeri ennek elvét és főbb módszereit, értékeli a megfelelő diagnózis felállításának jelentőségét.',
		b: 7,
		c: 3269,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Tudja, hogy a daganatos betegségek kialakulását az életmód és a környezet is befolyásolja, és hogy gyógyításuk esélyét a korai felismerés nagymértékben növeli.',
		b: 7,
		c: 3268,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Ismeri a szív- és érrendszeri betegségek kockázati tényezőit, igyekszik tudatosan alakítani étkezési szokásait, törekszik az életmódjának megfelelő energia- és tápanyagbevitelre, a normál testsúly megőrzésére.',
		b: 7,
		c: 3267,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Ismeri a kórokozó, a fertőzés és a járvány fogalmait, megkülönbözteti a vírusos és bakteriális fertőző betegségeket, felismeri az antibiotikumok helyes használatának fontosságát.',
		b: 7,
		c: 3266,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Értékeli a személyi és környezeti higiénia egészségmegőrzéssel kapcsolatos jelentőségét, ennek alapelveit személyes környezetében is igyekszik alkalmazni.',
		b: 7,
		c: 3265,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Az egészséget személyes és közösségi értékként értelmezi, érdeklődik az egészségmegőrzéssel kapcsolatos információk iránt, mérlegeli azok tudományos hitelességét, kritikusan kezeli a gyógyszerekkel, gyógyászattal kapcsolatos reklámokat.',
		b: 7,
		c: 3264,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Az egészséggel, életmóddal foglalkozó weboldalak, tematikus média források információit kritikusan elemzi, igyekszik tudományos bizonyítékokra alapozott híreket, érveket és tanácsokat elfogadni.',
		b: 7,
		c: 3263,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'egészségmegőrzés, elsősegély'])
	},
		{
		a: 'Azonosítja az emberi egyedfejlődés főbb szakaszait, bemutatja az emberi nemek testi különbözőségének kialakulását, tisztában van a felelős szexuális magatartás ismérveivel, értékeli a szexualitás egyéni életviteli és párkapcsolati jelentőségét.',
		b: 7,
		c: 3262,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'szervrendszerek és szervek felépítése és működése'])
	},
		{
		a: 'Felismeri, hogy az immunrendszer is információkat dolgoz fel, azonosítja a rendszer főbb szerveit, sejtes elemeit és kémiai összetevőit.',
		b: 7,
		c: 3261,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'szervrendszerek és szervek felépítése és működése'])
	},
		{
		a: 'Ábrák, makettek alapján felismeri az ember ideg- és hormonrendszerének fontosabb szerveit, folyamatvázlatok, videók és szimulációk alapján azonosítja az alapvető biológiai funkcióit, értelmezi a szabályozás elvét.',
		b: 7,
		c: 3260,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'szervrendszerek és szervek felépítése és működése'])
	},
		{
		a: 'Szövegek, ábrák, folyamatvázlatok, videók és szimulációk alapján azonosítja a táplálkozási-, keringési-, légzési-, kiválasztási szervrendszerek alapvető biológiai funkcióit, az életfolyamatok lépéseit.',
		b: 7,
		c: 3259,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'szervrendszerek és szervek felépítése és működése'])
	},
		{
		a: 'Felismeri a gyakorolt sportok testi és lelki fejlesztő hatását és a velük járó terheléseket, baleseti veszélyeket, tanácsokat fogalmaz meg ezek elkerülésére.',
		b: 7,
		c: 3258,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'szervrendszerek és szervek felépítése és működése'])
	},
		{
		a: 'Felismeri az emberi bőr, a csontváz és vázizomzat főbb elemeit, ezek kapcsolódási módjait, értelmezi a mozgási szervrendszer felépítése és az ember mozgásképessége közötti összefüggéseket.',
		b: 7,
		c: 3257,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'szervrendszerek és szervek felépítése és működése'])
	},
		{
		a: 'Az emberi test megfigyelése alapján azonosítja a főbb testtájakat és testrészeket, elemzi ezek arányait és szimmetria viszonyait.',
		b: 7,
		c: 3256,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'szervrendszerek és szervek felépítése és működése'])
	},
		{
		a: 'Kiegyensúlyozott saját testképpel rendelkezik, amely figyelembe veszi az egyéni adottságokat, a nem és a korosztály fejlődési jellegzetességeit, valamint ezek sokféleségét.',
		b: 7,
		c: 3255,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'szervrendszerek és szervek felépítése és működése'])
	},
		{
		a: 'Ismeri és megfelelő szempontok szerint értékeli az emberi szervezet állapotát, folyamatait jellemző fontosabb adatokat, azokat összefüggésbe hozza a testi és lelki állapotával, egészségével.',
		b: 7,
		c: 3254,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az ember szervezete, az egészséges életmód', 'szervrendszerek és szervek felépítése és működése'])
	},
		{
		a: 'Kritikusan vizsgálja a haszonállatok tartási módjai és a fajra jellemző igények közötti ellentmondásokat, ismeri és érti a nagyüzemi technológiák és a humánus állattartási módok közötti különbségeket.',
		b: 7,
		c: 3253,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Ismeri a növények gondozásának biológiai alapjait, több szempontot is figyelembe véve értékeli a növények, a növénytermesztés élelmezési, ipari és környezeti jelentőségét.',
		b: 7,
		c: 3252,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Egységben látja az életközösségek múltbeli, jelenkori és várható jövőbeli állapotát, azok jövőbeli állapotára valószínűségi előrejelzést fogalmaz meg, felismeri és vállalja a jövőjük iránti egyéni és közösségi felelősséget.',
		b: 7,
		c: 3251,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Kritikusan és önkritikusan értékeli az emberi tevékenység természeti környezetre gyakorolt hatását, életvitelében tudatosan követi a természet- és környezetvédelem szempontjait.',
		b: 7,
		c: 3250,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Tájékozódik a környezetében található védett fajokról, életközösségekről, ezek eszmei értékéről és biológiai jelentőségéről, ismeri a hazai nemzeti parkok számát, területi elhelyezkedését, bemutatja védendő életközösségeik alapvető jellemzőit.',
		b: 7,
		c: 3249,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Az életformák sokféleségét megőrzendő értékként kezeli, felismeri a benne rejlő esztétikai szépséget, érvel a biológiai sokféleség veszélyeztetése ellen.',
		b: 7,
		c: 3248,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Érti és elfogadja, hogy az élő természet rendelkezik olyan értékekkel, amelyeket törvényi eszközökkel is védeni kell, ismeri ennek formáit, felhívja a figyelmet az általa észlelt természetkárosításra.',
		b: 7,
		c: 3247,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Esetleírások, filmek és saját megfigyelései alapján felismeri az adott életközösségek biológiai értékeit, értékeli a lakókörnyezetében található életközösségek környezeti állapot és életminőség-javító hatását.',
		b: 7,
		c: 3246,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Leírások, filmek és saját megfigyelései alapján elemzi az állatok viselkedésének alaptípusait, ezek lényegi jellemzőit konkrét példák alapján bemutatja.',
		b: 7,
		c: 3245,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Leírások, fotók, ábrák, filmek alapján értelmezi és bemutatja az élőlények környezethez való alkalmazkodásának jellegzetes módjait és példáit.',
		b: 7,
		c: 3244,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Másokkal együttműködve vizsgál környezetében található életközösségeket, az elkészített rajzok, fotók, videók és adatok alapján elemzi az élettelen környezeti tényezők és az élőlények közötti kapcsolatokat.',
		b: 7,
		c: 3243,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'életközösségek vizsgálata'])
	},
		{
		a: 'Néhány tengeri növény és állatfaj megismerése során felismeri, hogy bolygónk legnagyobb életközössége a világtengerekben él.',
		b: 7,
		c: 3242,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'bolygónk élővilága'])
	},
		{
		a: 'Néhány jellegzetes faj példáján keresztül felismeri a kontinensek jellegzetes növényei és az ott élő állatvilág közötti kapcsolatot.',
		b: 7,
		c: 3241,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'bolygónk élővilága'])
	},
		{
		a: 'Néhány jellegzetes faj példáján keresztül felismeri a kontinensek éghajlati övezetei, kialakult talajtípusai és az ott élő növényvilág közötti kapcsolatokat.',
		b: 7,
		c: 3240,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'bolygónk élővilága'])
	},
		{
		a: 'A földrészek természetes növényzetét ábrázoló tematikus térképek, fényképek, ábrák segítségével azonosítja bolygónk biomjait.',
		b: 7,
		c: 3239,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'bolygónk élővilága'])
	},
		{
		a: 'Alapfokon ismeri a földrészek, óceánok legjellegzetesebb növény és állatfajait.',
		b: 7,
		c: 3238,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'környezet és élővilág kapcsolata, fenntarthatóság', 'bolygónk élővilága'])
	},
		{
		a: 'Konkrét példák vizsgálata alapján összehasonlítja a gombák, a növények és az állatok testfelépítését, életműködéseit és életmódját, ennek alapján érvel az önálló rendszertani csoportba sorolásuk mellett.',
		b: 7,
		c: 3237,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése és szerveződése', 'az élővilág országai'])
	},
		{
		a: 'Alaktani jellemzők összehasonlítása alapján felismer néhány fontosabb növény- és állatcsoportot, ezekbe besorolást végez.',
		b: 7,
		c: 3236,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése és szerveződése', 'az élővilág országai'])
	},
		{
		a: 'Értelmezi a rátermettség és a természetes szelekció fogalmát, tudja, hogy azt a véletlenszerű események és az önszerveződés is befolyásolhatják.',
		b: 7,
		c: 3235,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése és szerveződése', 'az élővilág fejlődése'])
	},
		{
		a: 'A biológiai problémák vizsgálatában figyelembe veszi az evolúciós fejlődés szempontjait, a földtörténeti időskálán el tudja helyezni ennek mérföldköveit.',
		b: 7,
		c: 3234,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése és szerveződése', 'az élővilág fejlődése'])
	},
		{
		a: 'Képek, videók és mikroszkópos megfigyelések alapján összehasonlítja a növényi és az állati sejtek felépítését és működését, példák alapján értelmezi az egysejtű életmód jellegzetességeit.',
		b: 7,
		c: 3233,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése és szerveződése', 'az élet legegyszerűbb formái'])
	},
		{
		a: 'Vázlatrajz, fotó vagy mikroszkópos megfigyelés alapján felismeri és megnevezi a sejtmagvas sejttípus legfontosabb alkotórészeit, megfogalmazza a sejtekben zajló életfolyamatok lényegi jellemzőit.',
		b: 7,
		c: 3232,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése és szerveződése', 'az élet legegyszerűbb formái'])
	},
		{
		a: 'Tényekre alapozott érveket fogalmaz meg a baktériumok jelentőségével kapcsolatban, értékeli egészségügyi, környezeti és biotechnológiai jelentőségüket.',
		b: 7,
		c: 3231,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'az élővilág fejlődése és szerveződése', 'az élet legegyszerűbb formái'])
	},
		{
		a: 'Önállóan vagy másokkal együttműködve kivitelez tanulási projekteket.',
		b: 7,
		c: 3230,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'Természetvédelmi, bioetikai, egészségműveltségi témákban tényekre alapozottan érvel, vitákban többféle nézőpontot is figyelembe vesz.',
		b: 7,
		c: 3229,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'A vizsgált biológiai jelenségekkel kapcsolatos megfigyeléseit, következtetéseit és érveit érthetően és pontosan fogalmazza meg, ezeket szükség esetén rajzokkal, fotókkal, videókkal egészíti ki.',
		b: 7,
		c: 3228,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'Biológiai rendszerekkel, jelenségekkel kapcsolatos képi információkat szóban vagy írásban értelmez, alkalmazza a vizualizálás, az ábrákban való összefoglalás módszerét.',
		b: 7,
		c: 3227,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'Megkülönbözteti a bulvár, a népszerűsítő és a tudományos típusú közléseket, médiatermékeket, törekszik a megtévesztés, az áltudományosság leleplezésére.',
		b: 7,
		c: 3226,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'Digitális eszközökkel képeket, videókat, adatokat rögzít, keres és értelmez, kritikus és etikus módon használ fel, alkotásokat készít.',
		b: 7,
		c: 3225,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'Alapfokon alkalmazza a rendszerszintű gondolkodás műveleteit, azonosítani tudja egy biológiai rendszer részeit, kapcsolatait és funkcióit, érti a csoportképzés jelentőségét, a tanult csoportokba besorolást végez.',
		b: 7,
		c: 3224,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'Tisztában van a mérhetőség jelentőségével, törekszik az elérhető legnagyobb pontosságra, de tisztában van ennek korlátaival is.',
		b: 7,
		c: 3223,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'Útmutató alapján, másokkal együttműködve kísérleteket hajt végre, azonosítja és beállítja a kísérleti változókat, a kapott adatok alapján következtetéseket fogalmaz meg.',
		b: 7,
		c: 3222,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'A biológiai jelenségekkel kapcsolatban kérdéseket, előfeltevéseket fogalmaz meg, tudja, hogy ezek akkor vizsgálhatók tudományosan, ha lehetőség van a bizonyításra vagy cáfolatra.',
		b: 7,
		c: 3221,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia tanulásához szükséges készségek, képességek'])
	},
		{
		a: 'Érti és példákkal igazolja, hogy a tudományos elképzelések az adott kor tudásán és világképén nyugszanak, fejlődésük és cseréjük a megismerési folyamat természetes jellemzője.',
		b: 7,
		c: 3220,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia kutatási céljai és módszerei'])
	},
		{
		a: 'Ismeri a biológia tudományának kutatási céljait, elismeri a tudósok munkáját és felelősségét, képet alkot a biológia fejlődéséről, érti a jelenkori kutatások jelentőségét.',
		b: 7,
		c: 3219,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'a biológia tudománya', 'a biológia kutatási céljai és módszerei'])
	},
		{
		a: 'Életmódját az egészségmegőrzés szempontjait figyelembe véve, tudatosabban alakítja.',
		b: 7,
		c: 3218,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Több szempont figyelembevételével elemzi a környezet- és természetvédelmi problémákat, felismeri a védelemre szoruló élő természeti értékeket, ismeri az ezt szolgáló törvényi hátteret és a közösségi cselekvési lehetőségeket.',
		b: 7,
		c: 3217,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Felismeri és példákkal bizonyítja az élőlények és környezetük közötti kölcsönhatásokat, az alkalmazkodás evolúciós jelentőségét.',
		b: 7,
		c: 3216,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Lényegi biológiai jellemzőik alapján megkülönbözteti az élővilág főbb csoportjait, a mikrobákat, növényeket és állatokat, tényekre alapozottan magyarázza a közöttük fennálló kapcsolatokat.',
		b: 7,
		c: 3215,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Felismeri és a biológiai jelenségek vizsgálata során figyelembe veszi az élő rendszerek egymásba épülő szerveződési szintjeit.',
		b: 7,
		c: 3214,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'A biológiai problémák vizsgálata során keresi a kapcsolatot az okok és okozatok, az egyedi jelenségek és az általános törvényszerűségek között.',
		b: 7,
		c: 3213,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Megkülönbözteti a természettudományosan vizsgálható és nem vizsgálható problémákat, ismeri és megfelelő támogatás mellett alkalmazza a megfigyelés, mérés, kísérletezés módszereit.',
		b: 7,
		c: 3212,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['biológia', 'átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények'])
	},
		{
		a: 'Tanulási eredmény',
		b: 7,
		c: 3211,
		d: 8,
		e: 'Természttudomány@7-8',
		f: _List_fromArray(
			['nat tantárgy', 'főtéma', 'altéma'])
	},
		{
		a: 'Ismeri a helyes testtartás egészségre gyakorolt pozitív hatásait.',
		b: 1,
		c: 2684,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'A szabadban végzett foglalkozások során nem csupán ügyel környezete tisztaságára és rendjére, hanem erre felhívja társai figyelmét is.',
		b: 1,
		c: 2683,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'Megismeri az életkorának megfelelő sporttáplálkozás alapelveit, képes különbséget tenni egészséges és egészségtelen tápanyagforrások között.',
		b: 1,
		c: 2682,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'Az öltözködés és a higiéniai szokások terén teljesen önálló, adott esetben segíti társait.',
		b: 1,
		c: 2681,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'A családi háttere és a közvetlen környezete adta lehetőségeihez mérten rendszeresen végez testmozgást.',
		b: 1,
		c: 2680,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Aktívan vesz részt az uszodában végzett mozgásformák elsajátításában, gyakorlásában.',
		b: 1,
		c: 2679,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Tanári irányítással, ellenőrzött formában végzi a testnevelés -- számára nem ellenjavallt -- mozgásanyagát.',
		b: 1,
		c: 2678,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Tanári segítséggel megvalósít a biomechanikailag helyes testtartás kialakítását elősegítő gyakorlatokat.',
		b: 1,
		c: 2677,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Ismeri a keringési, légzési és mozgatórendszerét fejlesztő alapvető mozgásformákat.',
		b: 1,
		c: 2676,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Felismeri a különböző veszély- és baleseti forrásokat, elkerülésükhöz tanári segítséget kér.',
		b: 1,
		c: 2675,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Felismeri a sportszerű és sportszerűtlen magatartásformákat, betartja a sportszerű magatartás alapvető szabályait.',
		b: 1,
		c: 2674,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'A szabályjátékok közben törekszik az egészséges versenyszellem megőrzésére.',
		b: 1,
		c: 2673,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'A versengések és a versenyek tudatos szereplője, a közösséget pozitívan alakító résztvevő.',
		b: 1,
		c: 2672,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'A versengések és a versenyek közben toleráns a csapattársaival és az ellenfeleivel szemben.',
		b: 1,
		c: 2671,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'Játéktevékenysége közben a tanult szabályokat betartja.',
		b: 1,
		c: 2670,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'játékok'])
	},
		{
		a: 'Célszerűen alkalmaz sportági jellegű mozgásformákat sportjáték-előkészítő kisjátékokban.',
		b: 1,
		c: 2669,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'játékok'])
	},
		{
		a: 'Játék közben az egyszerű alaptaktikai elemek tudatos alkalmazására törekszik, játék- és együttműködési készsége megmutatkozik.',
		b: 1,
		c: 2668,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'játékok'])
	},
		{
		a: 'A testnevelési és népi játékokban tudatosan, célszerűen alkalmazza az alapvető mozgásformákat.',
		b: 1,
		c: 2667,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'játékok'])
	},
		{
		a: 'Az elsajátított egy (vagy több) úszásnemben helyes technikával úszik.',
		b: 1,
		c: 2666,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Ellenőrzött tevékenység keretében mozog a szabad levegőn, egyúttal tudatosan felkészül az időjárás kellemetlen hatásainak elviselésére sportolás közben.',
		b: 1,
		c: 2665,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Ismeri és képes megnevezni a küzdőfeladatok, esések, tompítások játék- és baleset-megelőzési szabályait.',
		b: 1,
		c: 2664,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Vállalja a társakkal szembeni fizikai kontaktust, sportszerű test-test elleni küzdelmet valósít meg.',
		b: 1,
		c: 2663,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A megtanultak birtokában örömmel, a csapat teljes jogú tagjaként vesz részt a játékokban.',
		b: 1,
		c: 2662,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Labdás ügyességi szintje lehetővé teszi az egyszerű taktikai helyzetekre épülő folyamatos, célszerű játéktevékenységet.',
		b: 1,
		c: 2661,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A funkcionális hely- és helyzetváltoztató mozgásformáinak kombinációit változó feltételek között koordináltan hajtja végre.',
		b: 1,
		c: 2660,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A támasz- és függésgyakorlatok végrehajtásában a testtömegéhez igazodó erő- és egyensúlyozási képességgel rendelkezik.',
		b: 1,
		c: 2659,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A különböző dobásmódok alaptechnikáit és előkészítő mozgásformáit a vezető műveletek ismeretében tudatosan és koordináltan hajtja végre.',
		b: 1,
		c: 2658,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A különböző ugrásmódok alaptechnikáit és előkészítő mozgásformáit a vezető műveletek ismeretében tudatosan és koordináltan hajtja végre.',
		b: 1,
		c: 2657,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Az egyszerűbb mozgásformákat jól koordináltan hajtja végre, a hasonló mozgások szimultán és egymást követő végrehajtásában megfelelő szintű tagoltságot mutat.',
		b: 1,
		c: 2656,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Különböző mozgásai jól koordináltak, a hasonló mozgások szimultán és egymást követő végrehajtása jól tagolt.',
		b: 1,
		c: 2655,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Futását összerendezettség, lépésszabályozottság, ritmusosság jellemzi.',
		b: 1,
		c: 2654,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Mozgásműveltsége szintjénél fogva pontosan hajtja végre a keresztező mozgásokat.',
		b: 1,
		c: 2653,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Az alapvető mozgásformákat külsőleg meghatározott ritmushoz, a társak mozgásához igazított sebességgel és dinamikával képes végrehajtani.',
		b: 1,
		c: 2652,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Megfelelő általános állóképesség-fejlődést mutat.',
		b: 1,
		c: 2651,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'Olyan szintű relatív erővel rendelkezik, amely lehetővé teszi összefüggő cselekvéssorok kidolgozását, az elemek közötti összhang megteremtését.',
		b: 1,
		c: 2650,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'Megfelelő motoros képességszinttel rendelkezik az alapvető mozgásformák viszonylag önálló és tudatos végrehajtásához.',
		b: 1,
		c: 2649,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'Nyitott az alapvető mozgásformák újszerű és alternatív környezetben történő alkalmazására, végrehajtására.',
		b: 1,
		c: 2648,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'A sportjátékok, a testnevelési és népi játékok művelése során egyaránt törekszik a szabályok betartására.',
		b: 1,
		c: 2647,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'A tanult mozgásforma könnyed és pontos kivitelezésének elsajátításáig fenntartja érzelmi-akarati erőfeszítéseit.',
		b: 1,
		c: 2646,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'A tanult mozgásformákat összefüggő cselekvéssorokban, jól koordináltan kivitelezi.',
		b: 1,
		c: 2645,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'Önismerete, érzelmi-akarati készségei és képességei a testmozgás, a testnevelés és a sport eszközei által megfelelően fejlődtek.',
		b: 1,
		c: 2644,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Megismeri az elsősegélynyújtás jelentőségét, felismeri a baleseti és egészségkárosító veszélyforrásokat, képes azonnali segítséget kérni.',
		b: 1,
		c: 2643,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Ismeri a testnevelés életkorához igazodó elméleti ismeretanyagát, szakkifejezéseit, helyes terminológiáját, érti azok szükségességét.',
		b: 1,
		c: 2642,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Mozgáskultúrája olyan szintre fejlődött, hogy képes a hatékony mozgásos cselekvéstanulásra, testedzésre.',
		b: 1,
		c: 2641,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Életkorának és testi adottságának megfelelően fejlődött motoros teljesítőképessége a hozzá kapcsolódó ismeretekkel olyan mérvű, hogy képes a saját teljesítménye tudatos befolyásolására.',
		b: 1,
		c: 2640,
		d: 4,
		e: 'Testnevelés és egészségfejlesztés@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'A helyes testtartás egészségre gyakorolt pozitív hatásai ismeretében önállóan is kezdeményez ilyen tevékenységet.',
		b: 5,
		c: 2725,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'A szabadban végzett foglalkozások során nem csupán ügyel környezete tisztaságára és rendjére, hanem erre felhívja társai figyelmét is.',
		b: 5,
		c: 2724,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'Az életkorának és alkati paramétereinek megfelelően tervezett, rendezett és rendszeres, testmozgással összefüggő táplálkozási szokásokat alakít ki.',
		b: 5,
		c: 2723,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'A higiéniai szokások terén teljesen önálló, adott esetben segíti társait.',
		b: 5,
		c: 2722,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'A családi háttere és a közvetlen környezete adta lehetőségeihez mérten tervezetten, rendezetten és rendszeresen végez testmozgást.',
		b: 5,
		c: 2721,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Megnevez és bemutat egyszerű relaxációs gyakorlatokat.',
		b: 5,
		c: 2720,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Ismeri a tanult mozgásformák gerinc- és ízületvédelmi szempontból helyes végrehajtását.',
		b: 5,
		c: 2719,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Rendszeresen végez számára megfelelő vízi játékokat, és hajt végre úszástechnikákat.',
		b: 5,
		c: 2718,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Tudatosan, összehangoltan végzi a korrekciós gyakorlatait és uszodai tevékenységét, azok megvalósítása automatikussá, mindennapi életének részévé válik.',
		b: 5,
		c: 2717,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'A mindennapi sporttevékenységébe tudatosan beépíti a korrekciós gyakorlatokat.',
		b: 5,
		c: 2716,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Tervezetten, rendezetten és rendszeresen végez a biomechanikailag helyes testtartás kialakítását elősegítő gyakorlatokat.',
		b: 5,
		c: 2715,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Tanári segítséggel, egyéni képességeihez mérten, tervezetten, rendezetten és rendszeresen fejleszti keringési, légzési és mozgatórendszerét.',
		b: 5,
		c: 2714,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Megoldást keres a különböző veszély- és baleseti források elkerülésére.',
		b: 5,
		c: 2713,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Egészséges versenyszellemmel rendelkezik, és tanári irányítás vagy ellenőrzés mellett képes a játékvezetésre.',
		b: 5,
		c: 2712,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'A versengések és a versenyek közben közösségformáló, csapatkohéziót kialakító játékosként viselkedik.',
		b: 5,
		c: 2711,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'A versengések és a versenyek közben toleráns a csapattársaival és az ellenfeleivel szemben, ezt tőlük is elvárja.',
		b: 5,
		c: 2710,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'A küzdő jellegű feladatokban életkorának megfelelő asszertivitást mutatva tudatosan és célszerűen alkalmazza a támadó és védő szerepeknek megfelelő technikai és taktikai elemeket.',
		b: 5,
		c: 2709,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'játékok'])
	},
		{
		a: 'A sportjátékok előkészítő kisjátékaiban tudatosan és célszerűen alkalmazza a technikai és taktikai elemeket.',
		b: 5,
		c: 2708,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'játékok'])
	},
		{
		a: 'A tanult testnevelési és népi játékok mellett folyamatosan, jól koordináltan végzi a választott sportjátékokat.',
		b: 5,
		c: 2707,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'játékok'])
	},
		{
		a: 'Az elsajátított egy (vagy több) úszásnemben helyes technikával, készségszinten úszik.',
		b: 5,
		c: 2706,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Ellenőrzött tevékenység keretében rendszeresen mozog, edz, sportol a szabad levegőn, egyúttal tudatosan felkészül az időjárás kellemetlen hatásainak elviselésére sportolás közben.',
		b: 5,
		c: 2705,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A tanári irányítást követve, a mozgás sebességét növelve hajt végre önvédelmi fogásokat, ütéseket, rúgásokat, védéseket és ellentámadásokat.',
		b: 5,
		c: 2704,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A testnevelési és sportjáték közben célszerű, hatékony játék- és együttműködési készséget mutat.',
		b: 5,
		c: 2703,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A torna, a ritmikus gimnasztika, tánc és aerobik jellegű mozgásformákon keresztül tanári irányítás mellett fejleszti esztétikai-művészeti tudatosságát és kifejezőképességét.',
		b: 5,
		c: 2702,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Segítségadással képes egy-egy általa kiválasztott tornaelem bemutatására és a tanult elemekből önállóan alkotott gyakorlatsor kivitelezésére.',
		b: 5,
		c: 2701,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Magabiztosan alkalmazza a távol- és magasugrás, valamint a kislabdahajítás és súlylökés -- számára megfelelő -- technikáit.',
		b: 5,
		c: 2700,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A rajttechnikákat a játékok, a versengések és a versenyek közben készségszinten használja.',
		b: 5,
		c: 2699,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Futótechnikája -- összefüggő cselekvéssor részeként -- eltérést mutat a vágta- és a tartós futás közben.',
		b: 5,
		c: 2698,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A (meg)tanult erő-, gyorsaság-, állóképesség- és ügyességfejlesztő eljárásokat tanári irányítással tudatosan alkalmazza.',
		b: 5,
		c: 2697,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'Az alapvető mozgásainak koordinációjában megfelelő begyakorlottságot mutat, és képes a változó környezeti feltételekhez célszerűen illeszkedő végrehajtásra.',
		b: 5,
		c: 2696,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'Relatív erejének birtokában képes a sportágspecifikus mozgástechnikák koordinált, készségszintű kivitelezésére.',
		b: 5,
		c: 2695,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'A motoros képességeinek fejlődési szintje révén képes az összhang megteremtésére a cselekvéssorainak elemei között.',
		b: 5,
		c: 2694,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'Nyitott az alapvető mozgásformák újszerű és alternatív környezetben történő felhasználására, végrehajtására.',
		b: 5,
		c: 2693,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'Minden sporttevékenységében forma- és szabálykövető attitűddel rendelkezik, ez tevékenységének automatikus részévé válik.',
		b: 5,
		c: 2692,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'A tanult mozgásforma készségszintű kivitelezése közben fenntartja érzelmi-akarati erőfeszítéseit.',
		b: 5,
		c: 2691,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'A tanult alapvető mozgásformák kombinációiból álló cselekvéssorokat változó térbeli, időbeli, dinamikai feltételek mellett készségszinten kivitelezi.',
		b: 5,
		c: 2690,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'Képes értelmezni az életben adódó baleseti forrásokat és az egészséget károsító, veszélyes szokásokat, tevékenységeket.',
		b: 5,
		c: 2689,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Önismerete, érzelmi-akarati készségei és képességei a testmozgás, a testnevelés és a sport eszközei által megfelelően fejlődtek.',
		b: 5,
		c: 2688,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Ismeri és használja a testnevelés életkorához igazodó elméleti ismeretanyagát, szakkifejezéseit, helyes terminológiáját.',
		b: 5,
		c: 2687,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Sokoldalú mozgásműveltségének birtokában eredményesen tanul összetett mozgásformákat.',
		b: 5,
		c: 2686,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Életkorának és testi adottságának megfelelően fejlődött motoros teljesítőképessége olyan mérvű, hogy képes a saját teljesítménye és fittségi szintje tudatos befolyásolására.',
		b: 5,
		c: 2685,
		d: 8,
		e: 'Testnevelés és egészségfejlesztés@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Megoldást keres a testtartási rendellenesség kialakulásának megakadályozására, erre társait is motiválja.',
		b: 9,
		c: 2767,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'A szabadban végzett foglalkozások során nem csupán ügyel környezete tisztaságára és rendjére, hanem erre felhívja társai figyelmét is.',
		b: 9,
		c: 2766,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'Az életkorának és alkati paramétereinek megfelelő pozitív, egészégtudatos, testmozgással összefüggő táplálkozási szokásokat alakít ki.',
		b: 9,
		c: 2765,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'Mindennapi életének részeként kezeli a testmozgás, a sportolás közbeni higiéniai és tisztálkodási szabályok betartását.',
		b: 9,
		c: 2764,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'egészséges testi fejlődés, egészségfejlesztés'])
	},
		{
		a: 'Ismer és alkalmaz alapvető relaxációs technikákat.',
		b: 9,
		c: 2763,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'A családi háttere és a közvetlen környezete adta lehetőségeihez mérten, belső igénytől vezérelve, alkotó módon, rendszeresen végez testmozgást.',
		b: 9,
		c: 2762,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Önállóan, de tanári ellenőrzés mellett végez számára megfelelő uszodai tevékenységet.',
		b: 9,
		c: 2761,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'A szárazföldi és az uszodai korrekciós gyakorlatait készségszinten sajátítja el, azokat tudatosan rögzíti.',
		b: 9,
		c: 2760,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Mindennapi tevékenységének tudatos részévé válik a korrekciós gyakorlatok végzése.',
		b: 9,
		c: 2759,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Belső igénytől vezérelve rendszeresen végez a biomechanikailag helyes testtartás kialakítását elősegítő gyakorlatokat.',
		b: 9,
		c: 2758,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Az egyéni képességeihez mérten, mindennapi szokásrendszerébe építve fejleszti keringési, légzési és mozgatórendszerét.',
		b: 9,
		c: 2757,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'Megoldást keres a különböző veszély- és baleseti források elkerülésére, erre társait is motiválja.',
		b: 9,
		c: 2756,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'prevenció, életvitel'])
	},
		{
		a: 'A szabályjátékok alkotó részese, képes szabálykövető játékvezetésre.',
		b: 9,
		c: 2755,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'A versengések és a versenyek közben közösségformáló, csapatkohéziót kialakító játékosként viselkedik.',
		b: 9,
		c: 2754,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'A versengések és a versenyek közben toleráns a csapattársaival és az ellenfeleivel szemben, ezt tőlük is elvárja.',
		b: 9,
		c: 2753,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'versengések, versenyek'])
	},
		{
		a: 'Játéktevékenységét kreativitást mutató játék- és együttműködési készség jellemzi.',
		b: 9,
		c: 2752,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'játékok'])
	},
		{
		a: 'A tanult testnevelési, népi és sportjátékok összetett technikai és taktikai elemeit kreatívan, az adott játékhelyzetnek megfelelően, célszerűen, készségszinten alkalmazza.',
		b: 9,
		c: 2751,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'játékok'])
	},
		{
		a: 'Önállóan képes az elkerülhetetlen vízi veszélyhelyzetek célszerű kezelésére.',
		b: 9,
		c: 2750,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Az elsajátított egy (vagy több) úszásnemben vízbiztosan, készségszinten úszik, a természetes vizekben is.',
		b: 9,
		c: 2749,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Rendszeresen mozog, edz, sportol a szabad levegőn, erre − lehetőségeihez mérten − társait is motiválja.',
		b: 9,
		c: 2748,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A különböző eséstechnikák készségszintű elsajátítása mellett a választott küzdősport speciális mozgásformáit célszerűen alkalmazza.',
		b: 9,
		c: 2747,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Önállóan képes az életben adódó, elkerülhetetlen veszélyhelyzetek célszerű hárítására.',
		b: 9,
		c: 2746,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A zenei ütemnek megfelelően, készségszintű koordinációval végzi a kiválasztott ritmikus gimnasztika, illetve aerobik mozgásformákat.',
		b: 9,
		c: 2745,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A torna, ritmikus gimnasztika, aerobik és tánc jellegű mozgásformákon keresztül fejleszti esztétikai-művészeti tudatosságát és kifejezőképességét.',
		b: 9,
		c: 2744,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Önállóan képes az általa kiválasztott elemkapcsolatokból tornagyakorlatot összeállítani, majd bemutatni.',
		b: 9,
		c: 2743,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Képes a kiválasztott ugró- és dobótechnikákat az ilyen jellegű játékok, versengések és versenyek közben, az eredményesség érdekében, egyéni sajátosságaihoz formálva hatékonyan alkalmazni.',
		b: 9,
		c: 2742,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A rajtolási módokat a játékok, versenyek, versengések közben hatékonyan, kreatívan alkalmazza.',
		b: 9,
		c: 2741,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'A korábbi évfolyamokon elért eredményeihez képest folyamatosan javítja futóteljesítményét, amelyet önmaga is tudatosan nyomon követ.',
		b: 9,
		c: 2740,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskészség-kialakítás -- mozgástanulás'])
	},
		{
		a: 'Tanári ellenőrzés mellett digitálisan méri és értékeli a kondicionális és koordinációs képességeinek változásait, ezekből kiindulva felismeri saját motoros képességbeli hiányosságait, és ezeket a képességeket tudatosan és rendszeresen fejleszti.',
		b: 9,
		c: 2739,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'A (meg)tanult erő-, gyorsaság-, állóképesség- és ügyességfejlesztő eljárásokat önállóan, tanári ellenőrzés nélkül alkalmazza.',
		b: 9,
		c: 2738,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'A különböző sportágspecifikus mozgásformákat változó környezeti feltételek mellett, hatékonyan és készségszinten hajtja végre.',
		b: 9,
		c: 2737,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'Relatív erejének birtokában a tanult mozgásformákat változó környezeti feltételek mellett, hatékonyan és készségszinten kivitelezi.',
		b: 9,
		c: 2736,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'Olyan szintű motoros képességekkel rendelkezik, amelyek lehetővé teszik a tanult mozgásformák alkotó módon történő végrehajtását.',
		b: 9,
		c: 2735,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'motorosképesség-fejlesztés'])
	},
		{
		a: 'Nyitott az alapvető és sportágspecifikus mozgásformák újszerű és alternatív környezetben történő felhasználására, végrehajtására.',
		b: 9,
		c: 2734,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'Sporttevékenységében spontán, automatikus forma- és szabálykövető attitűdöt követ.',
		b: 9,
		c: 2733,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'A testedzéshez, a sportoláshoz kívánatosnak tartott jellemzőknek megfelelően (fegyelmezetten, határozottan, lelkiismeretesen, innovatívan és kezdeményezően) törekszik végrehajtani az elsajátított mozgásformákat.',
		b: 9,
		c: 2732,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'A tanult mozgásformákat alkotó módon, a testedzés és a sportolás minden területén használja.',
		b: 9,
		c: 2731,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['testnevelés és egészségfejlesztés', 'mozgáskultúra-fejlesztés'])
	},
		{
		a: 'Toleráns a testi és más fogyatékossággal élő személyek iránt, megismeri és tiszteletben tartja a szexuális kultúra alapelveit, elfogadja az egészségügyi szűrések és a környezetvédelem fontosságát.',
		b: 9,
		c: 2730,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Társas-közösségi kapcsolatai, valamint stressztűrő és -kezelő képességei megfelelő szintre fejlődtek.',
		b: 9,
		c: 2729,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Képes elhárítani a baleseti és veszélyforrásokat, magabiztosan segíteni és elsősegélyt nyújtani embertársainak.',
		b: 9,
		c: 2728,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Megismeri és mindennapjai részévé teszi a mozgáshoz kapcsolódó helyes attitűdöket, a fizikailag aktív életmód és a társas-érzelmi jóllét élethosszig tartó jótékony hatásait.',
		b: 9,
		c: 2727,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Alkotó módon használja a testnevelés életkorához igazodó ismeretanyagát, szakkifejezéseit, helyes terminológiáját.',
		b: 9,
		c: 2726,
		d: 12,
		e: 'Testnevelés és egészségfejlesztés@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'testnevelés és egészségfejlesztés'])
	},
		{
		a: 'Felismeri, hogy az emberi cselekedet és annak következménye között szoros kapcsolat van.',
		b: 5,
		c: 2834,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Több szempontból képes megkülönböztetni a történelmi jelenségek és események okait és következményeit (pl. hosszú vagy rövid távú, gazdasági, társadalmi vagy politikai).',
		b: 5,
		c: 2833,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Feltételezéseket fogalmaz meg néhány fontos történelmi esemény és folyamat feltételeiről, okairól és következményeiről, és tényekkel alátámasztja azokat.',
		b: 5,
		c: 2832,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Példákat hoz a történelmi jelenségekre, folyamatokra.',
		b: 5,
		c: 2831,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Társadalmi és erkölcsi problémákat azonosít adott történetek, történelmi események, különböző korok szokásai alapján.',
		b: 5,
		c: 2830,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Felismeri és értékeli a különböző korokra és régiókra jellemző tárgyakat, alkotásokat, életmódokat, szokásokat, változásokat, képes azokat összehasonlítani egymással, illetve a mai korral.',
		b: 5,
		c: 2829,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Önálló véleményt képes megfogalmazni történelmi szereplőkről, eseményekről, folyamatokról.',
		b: 5,
		c: 2828,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'A történelmi szereplők megnyilvánulásainak szándékot tulajdonít, álláspontjukat azonosítja.',
		b: 5,
		c: 2827,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Feltételezéseket fogalmaz meg történelmi személyek cselekedeteinek mozgatórugóiról, és adatokkal, érvekkel alátámasztja azokat.',
		b: 5,
		c: 2826,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'A történelmi eseményekkel, folyamatokkal és személyekkel kapcsolatban önálló kérdéseket fogalmaz meg.',
		b: 5,
		c: 2825,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Képes megfigyelni, értelmezni és összehasonlítani a történelemben előforduló különböző emberi magatartásformákat és élethelyzeteket.',
		b: 5,
		c: 2824,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Adott történetben különbséget tud tenni fiktív és valós, irreális és reális elemek között.',
		b: 5,
		c: 2823,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Tanári segítséggel dramatikusan, szerepjáték formájában tud megjeleníteni történelmi eseményeket, jelenségeket, személyiségeket.',
		b: 5,
		c: 2822,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Meghallgatja és megérti -- adott esetben elfogadja -- mások véleményét, érveit.',
		b: 5,
		c: 2821,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Egyszerű történelmi kérdésekről tárgyilagos véleményt tud megfogalmazni, állításait alátámasztja.',
		b: 5,
		c: 2820,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Egyszerű történelmi témáról tanári útmutatás segítségével kiselőadást és digitális prezentációt állít össze és mutat be.',
		b: 5,
		c: 2819,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Tud egyszerű vizuális rendezőket készíteni és kiegészíteni hagyományos vagy digitális módon (táblázatok, ábrák, tablók, rajzok, vázlatok) egy történelmi témáról.',
		b: 5,
		c: 2818,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Különböző történelmi korszakok, történelmi és társadalmi kérdések tárgyalása során szakszerűen alkalmazza az értelmező és tartalmi kulcsfogalmakat, továbbá használja a témához kapcsolódó történelmi fogalmakat.',
		b: 5,
		c: 2817,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Képes rövid fogalmazások készítésére egy-egy történetről, történelmi témáról.',
		b: 5,
		c: 2816,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Képes önálló kérdések megfogalmazására a tárgyalt történelmi témával, eseményekkel, folyamatokkal kapcsolatban.',
		b: 5,
		c: 2815,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Az általa gyűjtött történelmi adatokból, szövegekből rövid tartalmi ismertetőt tud készíteni.',
		b: 5,
		c: 2814,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Össze tudja foglalni saját szavaival hosszabb elbeszélő vagy leíró, valamint rövidebb magyarázó szövegek tartalmát.',
		b: 5,
		c: 2813,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Önállóan, folyamatos beszéddel képes eseményeket, történeteket elmondani, történelmi személyeket bemutatni, saját véleményt megfogalmazni.',
		b: 5,
		c: 2812,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Egyszerű alaprajzokat, modelleket, térképvázlatokat (pl. települések, épületek, csaták) tervez és készít.',
		b: 5,
		c: 2811,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Biztonsággal használ különböző történelmi térképeket a fontosabb történelmi események helyszíneinek azonosítására, egyszerű jelenségek, folyamatok leolvasására, értelmezésére, vaktérképen való elhelyezésére.',
		b: 5,
		c: 2810,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'A tanult történelmi eseményeket, jelenségeket, személyeket, ikonikus szimbólumokat, tárgyakat, képeket hozzá tudja rendelni egy adott történelmi korhoz, régióhoz, államhoz.',
		b: 5,
		c: 2809,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Ismeretei segítségével időrendbe tud állítani történelmi eseményeket, képes az idő ábrázolására pl. időszalag segítségével.',
		b: 5,
		c: 2808,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Biztonsággal használja az idő tagolására szolgáló kifejezéseket, történelmi eseményre, jelenségre, folyamatra, korszakra való utalással végez időmeghatározást.',
		b: 5,
		c: 2807,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Ismeri a nagy történelmi korszakok elnevezését és időhatárait, néhány kiemelten fontos esemény, jelenség és történelmi folyamat időpontját.',
		b: 5,
		c: 2806,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Egyszerű következtetéseket von le, és véleményt tud alkotni különböző források hitelességéről és releváns voltáról.',
		b: 5,
		c: 2805,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Meg tudja vizsgálni, hogy a történet szerzője résztvevője vagy kortársa volt-e az eseményeknek.',
		b: 5,
		c: 2804,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Össze tudja vetni a forrásokban található információkat az ismereteivel, párhuzamot tud vonni különböző típusú (pl. szöveges és képi) történelmi források tartalma között.',
		b: 5,
		c: 2803,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Képes egyszerű esetekben forráskritikát végezni, valamint különbséget tenni források között típus és szövegösszefüggés alapján.',
		b: 5,
		c: 2802,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Képen, egyszerű ábrán, grafikonon, diagramon ábrázolt folyamatot, jelenséget saját szavaival le tud írni.',
		b: 5,
		c: 2801,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Felismeri, hogy melyik szöveg, kép, egyszerű ábra, grafikon vagy diagram kapcsolódik az adott történelmi témához.',
		b: 5,
		c: 2800,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Megadott szempontok alapján tudja értelmezni és rendszerezni a történelmi információkat.',
		b: 5,
		c: 2799,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Képes élethelyzetek, magatartásformák megfigyelése által értelmezni azokat.',
		b: 5,
		c: 2798,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Megadott szempontok alapján, tanári útmutatás segítségével történelmi információkat gyűjt különböző médiumokból és forrásokból (könyvek, atlaszok, kronológiák, könyvtárak, múzeumok, médiatárak, filmek; nyomtatott és digitális, szöveges és vizuális források).',
		b: 5,
		c: 2797,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Kiemel lényeges információkat (kulcsszavakat, tételmondatokat) elbeszélő vagy leíró, illetve rövidebb magyarázó írott és hallott szövegekből és az ezek alapján megfogalmazott kérdésekre egyszerű válaszokat adni.',
		b: 5,
		c: 2796,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Képes ismereteket szerezni személyes beszélgetésekből, tárgyak, épületek megfigyeléséből, olvasott és hallott, valamint a különböző médiumok által felkínált szöveges és képi anyagokból.',
		b: 5,
		c: 2795,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Ismeri hazája államszervezetét.',
		b: 5,
		c: 2794,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Valós képet alkotva képes elhelyezni magyarországot a globális folyamatokban a történelem során és napjainkban.',
		b: 5,
		c: 2793,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri a magyarság, illetve a kárpát-medence népei együttélésének jellemzőit néhány történelmi korszakban, beleértve a határon kívüli magyarság sorsát, megmaradásáért folytatott küzdelmét, példákat hoz a magyar nemzet és a közép-európai régió népeinek kapcsolatára és együttműködésére.',
		b: 5,
		c: 2792,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Példákat tud felhozni arra, hogy a történelem során miként járultak hozzá a magyarok európa és a világ kulturális, tudományos és politikai fejlődéséhez.',
		b: 5,
		c: 2791,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Felismeri a különbségeket a demokratikus és a diktatórikus berendezkedések között, érvel a demokrácia értékei mellett.',
		b: 5,
		c: 2790,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri a nemzetiszocialista és a kommunista diktatúrák főbb jellemzőit, az emberiség ellen elkövetett bűneiket, ellentmondásaikat és ezek következményeit, továbbá a velük szembeni ellenállás példáit.',
		b: 5,
		c: 2789,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Fel tudja idézni az első és második világháború borzalmait, érveket tud felsorakoztatni a békére való törekvés mellett.',
		b: 5,
		c: 2788,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri a különböző korok hadviselési szokásait, az első és a második világháború legfontosabb eseményeit, jellemzőit, valamint napjainkra is hatással bíró következményeit.',
		b: 5,
		c: 2787,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri és be tudja mutatni a 19. és 20. századi modernizáció gazdasági, társadalmi és kulturális hatásait magyarországon és a világban.',
		b: 5,
		c: 2786,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Tisztában van a középkor és újkor világképének fő vonásaival, a 19. és 20. század fontosabb politikai eszméivel és azok hatásaival.',
		b: 5,
		c: 2785,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Képes felidézni a magyar nemzet honvédő és szabadságharcait, példákat hoz a hazaszeretet, önfeláldozás és hősiesség megnyilvánulásaira.',
		b: 5,
		c: 2784,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Fel tudja idézni a magyar történelem legfontosabb eseményeit, jelenségeit, folyamatait és fordulópontjait a honfoglalástól napjainkig.',
		b: 5,
		c: 2783,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri a magyar történelem kiemelkedő alakjait, cselekedeteiket, illetve szerepüket a magyar nemzet történetében.',
		b: 5,
		c: 2782,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Tisztában van a kereszténység kialakulásának főbb állomásaival, ismeri a legfontosabb tanításait és hatását az európai civilizációra és magyarországra.',
		b: 5,
		c: 2781,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Be tudja mutatni a különböző korok életmódjának és kultúrájának főbb vonásait és az egyes történelmi korszakokban élt emberek életét befolyásoló tényezőket.',
		b: 5,
		c: 2780,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri és fel tudja idézni a magyar és az európai történelmi hagyományhoz kapcsolódó legfontosabb mítoszokat, mondákat, történeteket, elbeszéléseket.',
		b: 5,
		c: 2779,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Kialakulnak a társadalmi felelősség és normakövetés, az egyéni kezdeményezőkészség és a hazája, közösségei és embertársai iránti felelősségvállalás, az aktív állampolgárság, valamint a demokratikus elkötelezettség alapelemei.',
		b: 5,
		c: 2778,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Kialakulnak az európai civilizációs identitás alapelemei.',
		b: 5,
		c: 2777,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Megerősödik benne a nemzeti identitás és hazaszeretet érzése; büszke népe múltjára, ápolja hagyományait, és méltón emlékezik meg hazája nagyjairól.',
		b: 5,
		c: 2776,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Kialakul a múlt iránti érdeklődés.',
		b: 5,
		c: 2775,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Alapvető ismereteket szerez a demokratikus államszervezetről, a társadalmi együttműködés szabályairól és a piacgazdaságról.',
		b: 5,
		c: 2774,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Megérti és méltányolja, hogy a múlt viszonyai, az emberek gondolkodása, értékítélete eltért a maitól.',
		b: 5,
		c: 2773,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Különbséget tud tenni a múltról szóló fiktív történetek és a történelmi tények között.',
		b: 5,
		c: 2772,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Ismeri a közös magyar nemzeti, az európai, valamint az egyetemes emberi civilizáció kulturális örökségének, kódrendszerének legalapvetőbb elemeit.',
		b: 5,
		c: 2771,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Megérti, hogy minden történelmi eseménynek és folyamatnak okai és következményei vannak.',
		b: 5,
		c: 2770,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Képes a múlt és jelen alapvető társadalmi, gazdasági, politikai és kulturális folyamatairól, jelenségeiről véleményt alkotni.',
		b: 5,
		c: 2769,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Alapvető ismeretekkel rendelkezik a magyar nemzet, magyarország és az európai civilizáció és földünk legfontosabb régióinak múltjáról és jelenéről.',
		b: 5,
		c: 2768,
		d: 8,
		e: 'Történelem@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Felismeri, hogy a jelen társadalmi, gazdasági, politikai és kulturális viszonyai a múltbeli események, tényezők következményeiként alakultak ki.',
		b: 9,
		c: 2913,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Feltevéseket fogalmaz meg, azok mellett érveket gyűjt, illetve mérlegeli az ellenérveket.',
		b: 9,
		c: 2912,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Összehasonlítja és kritikusan értékeli az egyes történelmi folyamatokkal, eseményekkel és személyekkel kapcsolatos eltérő álláspontokat.',
		b: 9,
		c: 2911,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Képes felismerni konkrét történelmi helyzetekben, jelenségekben és folyamatokban valamely általános szabályszerűség érvényesülését.',
		b: 9,
		c: 2910,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Összehasonlít különböző, egymáshoz hasonló történeti helyzeteket, folyamatokat, jelenségeket.',
		b: 9,
		c: 2909,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Képes összevetni, csoportosítani és súlyozni az egyes történelmi folyamatok, jelenségek, események okait, következményeit, és ítéletet alkotni azokról, valamint a benne résztvevők szándékairól.',
		b: 9,
		c: 2908,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'A változás és a fejlődés fogalma közötti különbséget ismerve képes felismerni és bemutatni azokat azonos korszakon belül, vagy azokon átívelően.',
		b: 9,
		c: 2907,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Képes különböző élethelyzetek, magatartásformák megfigyelése által következtetések levonására, erkölcsi kérdéseket is felvető történelmi helyzetek felismerésére és megítélésére.',
		b: 9,
		c: 2906,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Önálló véleményt tud alkotni történelmi eseményekről, folyamatokról, jelenségekről és személyekről.',
		b: 9,
		c: 2905,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Önálló kérdéseket fogalmaz meg történelmi folyamatok, jelenségek és események feltételeiről, okairól és következményeiről.',
		b: 9,
		c: 2904,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Hipotéziseket alkot történelmi személyek, társadalmi csoportok és intézmények viselkedésének mozgatórugóiról.',
		b: 9,
		c: 2903,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'A tanult ismereteket problémaközpontúan tudja rendezni.',
		b: 9,
		c: 2902,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Képes felismerni, megfogalmazni és összehasonlítani különböző társadalmi és történelmi problémákat, értékrendeket, jelenségeket, folyamatokat.',
		b: 9,
		c: 2901,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi gondolkodás'])
	},
		{
		a: 'Társaival képes megvitatni történelmi kérdéseket, amelynek során bizonyítékokon alapuló érvekkel megindokolja a véleményét, és választékosan reflektál mások véleményére, árnyalja saját álláspontját.',
		b: 9,
		c: 2900,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Megadott szempontok alapján történelmi tárgyú szerkesztett szöveget (esszét) tud alkotni, amelynek során tételmondatokat fogalmaz meg, állításait több szempontból indokolja és következtetéseket von le.',
		b: 9,
		c: 2899,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Történelmi tárgyú folyamatábrákat, digitális táblázatokat, diagramokat készít, történelmi, gazdasági, társadalmi és politikai modelleket vizuálisan is meg tud jeleníteni.',
		b: 9,
		c: 2898,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Történelmi témáról kiselőadást, digitális prezentációt alkot és mutat be.',
		b: 9,
		c: 2897,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Képes egy-egy korszakot átfogó módon bemutatni.',
		b: 9,
		c: 2896,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Képes önállóan vázlatot készíteni és jegyzetelni.',
		b: 9,
		c: 2895,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Össze tudja foglalni rövid és egyszerű szaktudományos szöveg tartalmát.',
		b: 9,
		c: 2894,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Képes kiválasztani, rendezni és alkalmazni az azonos korhoz, témához kapcsolható fogalmakat.',
		b: 9,
		c: 2893,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Fel tud ismerni fontosabb történelmi fogalmakat meghatározás alapján.',
		b: 9,
		c: 2892,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Képes a történelmi jelenségeket általános és konkrét történelmi fogalmak, tartalmi és értelmező kulcsfogalmak felhasználásával értelmezni és értékelni.',
		b: 9,
		c: 2891,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'szaktárgyi kommunikáció'])
	},
		{
		a: 'Képes különböző időszakok történelmi térképeinek összehasonlítására, a történelmi tér változásainak és a történelmi mozgások követésére megadott szempontok alapján a változások hátterének feltárásával.',
		b: 9,
		c: 2890,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'A földrajzi környezet és a történeti folyamatok összefüggéseit példákkal képes alátámasztani.',
		b: 9,
		c: 2889,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Egyszerű történelmi térképvázlatot alkot hagyományos és digitális eljárással.',
		b: 9,
		c: 2888,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Felismeri, hogy a magyar történelem az európai történelem része, és példákat tud hozni a magyar és európai történelem kölcsönhatásaira.',
		b: 9,
		c: 2887,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Képes azonosítani a tanult egyetemes és magyar történelmi személyiségek közül a kortársakat.',
		b: 9,
		c: 2886,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Össze tudja hasonlítani megadott szempontok alapján az egyes történelmi korszakok, időszakok jellegzetességeit az egyetemes és a magyar történelem egymáshoz kapcsolódó eseményeit.',
		b: 9,
		c: 2885,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Az egyes események, folyamatok idejét konkrét történelmi korhoz, időszakhoz kapcsolja vagy viszonyítja, ismeri néhány kiemelten fontos esemény, jelenség időpontját, kronológiát használ és készít.',
		b: 9,
		c: 2884,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Ismeri a magyar és az európai történelem tanult történelmi korszakait, időszakait, és képes azokat időben és térben elhelyezni.',
		b: 9,
		c: 2883,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'tájékozódás időben és térben'])
	},
		{
		a: 'Képes kiválasztani a megfelelő forrást valamely történelmi állítás, vélemény alátámasztására vagy cáfolására.',
		b: 9,
		c: 2882,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Összehasonlítja a forrásokban talált információkat saját ismereteivel, illetve más források információival és megmagyarázza az eltérések okait.',
		b: 9,
		c: 2881,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Képes a szándékainak megfelelő információkat kiválasztani különböző műfajú forrásokból.',
		b: 9,
		c: 2880,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Képes azonosítani a különböző források szerzőinek a szándékát, bizonyítékok alapján értékeli egy forrás hitelességét.',
		b: 9,
		c: 2879,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Tud forráskritikát végezni és különbséget tenni a források között hitelesség, típus és szövegösszefüggés alapján.',
		b: 9,
		c: 2878,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Önállóan információkat tud gyűjteni, áttekinteni, rendszerezni és értelmezni különböző médiumokból és írásos vagy képi forrásokból, statisztikákból, diagramokból, térképekről, nyomtatott és digitális felületekről.',
		b: 9,
		c: 2877,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Önállóan tud használni általános és történelmi, nyomtatott és digitális információforrásokat (tankönyv, kézikönyvek, szakkönyvek, lexikonok, képzőművészeti alkotások, könyvtár és egyéb adatbázisok, filmek, keresők).',
		b: 9,
		c: 2876,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'ismeretszerzés és forráshasználat'])
	},
		{
		a: 'Ismeri hazája államszervezetét, választási rendszerét.',
		b: 9,
		c: 2875,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri a magyarság, illetve a kárpát-medence népei együttélésének jellemzőit, példákat hoz a magyar nemzet és a közép-európai régió népeinek kapcsolatára, különös tekintettel a visegrádi együttműködésre.',
		b: 9,
		c: 2874,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri a magyar cigányság történetének főbb állomásait, bemutatja jelenkori helyzetét.',
		b: 9,
		c: 2873,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Bemutatja a határon túli magyarság helyzetét, a megmaradásért való küzdelmét trianontól napjainkig.',
		b: 9,
		c: 2872,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri és reálisan látja a többpólusú világ jellemzőit napjainkban, elhelyezi magyarországot a globális világ folyamataiban.',
		b: 9,
		c: 2871,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Bemutatja a gyarmati rendszer felbomlásának következményeit, india, kína és a közel-keleti régió helyzetét és jelentőségét.',
		b: 9,
		c: 2870,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Bemutatja a kommunista diktatúra magyarországi kiépítését, működését és változatait, az 1956-os forradalom és szabadságharc okait, eseményeit és hőseit, összefüggéseiben szemléli a rendszerváltoztatás folyamatát, felismerve annak történelmi jelentőségét.',
		b: 9,
		c: 2869,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Össze tudja hasonlítani a nyugati demokratikus világ és a kommunista szovjet blokk politikai és társadalmi berendezkedését, képes jellemezni a hidegháború időszakát, bemutatni a gyarmati rendszer felbomlását és az európai kommunista rendszerek összeomlását.',
		b: 9,
		c: 2868,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Bemutatja magyarország revíziós lépéseit, a háborús részvételét, az ország német megszállását, a magyar zsidóság tragédiáját, a szovjet megszállást, a polgári lakosság szenvedését, a hadifoglyok embertelen sorsát.',
		b: 9,
		c: 2867,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Képes felidézni a második világháború okait, a háború jellemzőit és fontosabb fordulópontjait, ismeri a holokausztot és a hozzávezető okokat.',
		b: 9,
		c: 2866,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Össze tudja hasonlítani a nemzetiszocialista és a kommunista ideológiát és diktatúrát, példák segítségével bemutatja a rendszerek embertelenségét és a velük szembeni ellenállás formáit.',
		b: 9,
		c: 2865,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Tisztában van a trianoni békediktátum tartalmával és következményeivel, be tudja mutatni az ország talpra állását, a horthy-korszak politikai, gazdasági, társadalmi és kulturális viszonyait, felismeri a magyar külpolitika mozgásterének korlátozottságát.',
		b: 9,
		c: 2864,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Képes felidézni azokat az okokat és körülményeket, amelyek a történelmi magyarország felbomlásához vezettek.',
		b: 9,
		c: 2863,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Bemutatja az első világháború magyar vonatkozásait, a háborús vereség következményeit; példákat tud hozni a háborús helytállásra.',
		b: 9,
		c: 2862,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Fel tudja idézni az első világháború előzményeit, a háború jellemzőit és fontosabb fordulópontjait, értékeli a háborúkat lezáró békék tartalmát, és felismeri a háborúnak a 20. század egészére gyakorolt hatását.',
		b: 9,
		c: 2861,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Fel tudja idézni az ipari forradalom szakaszait, illetve azok gazdasági, társadalmi, kulturális és politikai hatásait; képes bemutatni a modern polgári társadalom és állam jellemzőit és a 19. század főbb politikai eszméit, valamint felismeri a hasonlóságot és különbséget azok mai formái között.',
		b: 9,
		c: 2860,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri és értékeli a magyar nemzetnek a polgári átalakulás és nemzeti függetlenség elérésére tett erőfeszítéseit a reformkor, az 1848/49-es forradalom és szabadságharc, illetve az azt követő időszakban; a kor kiemelkedő magyar politikusait és azok nézeteit, véleményt tud formálni a kiegyezésről.',
		b: 9,
		c: 2859,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Összefüggéseiben és folyamatában fel tudja idézni, miként hatott a magyar történelemre a habsburg birodalomhoz való tartozás, bemutatja az együttműködés és konfrontáció megnyilvánulásait, a függetlenségi törekvéseket és értékeli a rákóczi-szabadságharc jelentőségét.',
		b: 9,
		c: 2858,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Be tudja mutatni a kora újkor fő gazdasági és társadalmi folyamatait, ismeri a felvilágosodás eszméit, illetve azok kulturális és politikai hatását, valamint véleményt formál a francia forradalom európai hatásáról.',
		b: 9,
		c: 2857,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri a magyarság törökellenes küzdelmeit, fordulópontjait és hőseit; felismeri, hogy a magyar és az európai történelem alakulását meghatározóan befolyásolta a török megszállás.',
		b: 9,
		c: 2856,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Felidézi a középkori magyar állam történetének fordulópontjait, legfontosabb uralkodóink tetteit.',
		b: 9,
		c: 2855,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Értékeli az államalapítás, valamint a kereszténység felvételének jelentőségét.',
		b: 9,
		c: 2854,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri a magyar nép őstörténetére és a honfoglalásra vonatkozó tudományos elképzeléseket és tényeket, tisztában van legfőbb vitatott kérdéseivel, a különböző tudományterületek kutatásainak főbb eredményeivel.',
		b: 9,
		c: 2853,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Képes felidézni a középkor gazdasági és kulturális jellemzőit, világképét, a kor meghatározó birodalmait és bemutatni a rendi társadalmat.',
		b: 9,
		c: 2852,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Bemutatja a keresztény vallás civilizációformáló hatását, a középkori egyházat, valamint a reformáció és a katolikus megújulás folyamatát és kulturális hatásait; érvel a vallási türelem, illetve a vallásszabadság mellett.',
		b: 9,
		c: 2851,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Felidézi a monoteista vallások kialakulását, legfontosabb jellemzőiket, tanításaik főbb elemeit, és bemutatja terjedésüket.',
		b: 9,
		c: 2850,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Ismeri az ókori civilizációk legfontosabb jellemzőit, valamint az athéni demokrácia és a római állam működését, hatásukat az európai civilizációra.',
		b: 9,
		c: 2849,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['történelem', 'történelmi ismeretek'])
	},
		{
		a: 'Megerősödik és elmélyül a társadalmi felelősség és normakövetés, az egyéni kezdeményezőkészség, a hazája, közösségei és embertársai iránt való felelősségvállalás, valamint a demokratikus elkötelezettség.',
		b: 9,
		c: 2848,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Megerősödnek az európai civilizációs identitás alapelemei.',
		b: 9,
		c: 2847,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Elmélyül a nemzeti identitás és hazaszeretet, büszke népe múltjára, ápolja hagyományait, és méltón emlékezik meg hazája nagyjairól.',
		b: 9,
		c: 2846,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Kialakulnak a saját értékrend és történelemszemlélet alapjai.',
		b: 9,
		c: 2845,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Kialakul és megerősödik a történelmi múlt, illetve a társadalmi, politikai, gazdasági és kulturális kérdések iránti érdeklődés.',
		b: 9,
		c: 2844,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Ismeri a demokratikus államszervezet működését, a társadalmi együttműködés szabályait, a piacgazdaság alapelveit; autonóm és felelős állampolgárként viselkedik.',
		b: 9,
		c: 2843,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Képes a múlt eseményeit és jelenségeit a saját történelmi összefüggésükben értelmezni, illetve a jelen viszonyait kapcsolatba hozni a múltban történtekkel.',
		b: 9,
		c: 2842,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Kialakul a hiteles és tárgyilagos forráshasználat és kritika igénye.',
		b: 9,
		c: 2841,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Képes a tanulási célhoz megfelelő információforrást választani, a források között szelektálni, azokat szakszerűen feldolgozni és értelmezni.',
		b: 9,
		c: 2840,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Képes következtetni történelmi események, folyamatok és jelenségek okaira és következményeire.',
		b: 9,
		c: 2839,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Különbséget tud tenni történelmi tények és történelmi interpretáció, illetve vélemény között.',
		b: 9,
		c: 2838,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Ismeri a közös magyar nemzeti és európai, valamint az egyetemes emberi civilizáció kulturális örökségének, kódrendszerének lényeges elemeit.',
		b: 9,
		c: 2837,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Képes a múlt és jelen társadalmi, gazdasági, politikai és kulturális folyamatairól, jelenségeiről többszempontú, tárgyilagos érveléssel alátámasztott véleményt alkotni, ezekkel kapcsolatos problémákat megfogalmazni.',
		b: 9,
		c: 2836,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Megbízható ismeretekkel bír az európai, valamint az egyetemes történelem és mélyebb tudással rendelkezik a magyar történelem fontosabb eseményeiről, történelmi folyamatairól, fordulópontjairól.',
		b: 9,
		c: 2835,
		d: 12,
		e: 'Történelem@9-12',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'történelem'])
	},
		{
		a: 'Különböző egyszerű anyagokkal kísérletezik, szabadon épít, saját célok érdekében konstruál.',
		b: 1,
		c: 2959,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'kreativitás', 'alkotótevékenység közben saját ötletek bátor használata'])
	},
		{
		a: 'Adott álló- vagy mozgóképi megjelenéseket egyéni elképzelés szerint átértelmez.',
		b: 1,
		c: 2958,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'kreativitás', 'alkotótevékenység közben saját ötletek bátor használata'])
	},
		{
		a: 'Saját történetet alkot, és azt vizuális eszközökkel is tetszőlegesen megjeleníti.',
		b: 1,
		c: 2957,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'kreativitás', 'alkotótevékenység közben saját ötletek bátor használata'])
	},
		{
		a: 'Időbeli történéseket egyszerű vizuális eszközökkel, segítséggel megjelenít.',
		b: 1,
		c: 2956,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'adott cél érdekében egyszerű vizuális kommunikációs megjelenés létrehozása'])
	},
		{
		a: 'Saját kommunikációs célból egyszerű térbeli tájékozódást segítő ábrát -- alaprajz, térkép -- készít.',
		b: 1,
		c: 2955,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'adott cél érdekében egyszerű vizuális kommunikációs megjelenés létrehozása'])
	},
		{
		a: 'Adott cél érdekében egyszerű vizuális kommunikációt szolgáló megjelenéseket -- jel, meghívó, plakát -- készít egyénileg vagy csoportmunkában.',
		b: 1,
		c: 2954,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'adott cél érdekében egyszerű vizuális kommunikációs megjelenés létrehozása'])
	},
		{
		a: 'Azonosítja a nonverbális kommunikáció eszközeit: mimika, gesztus, ezzel kapcsolatos tapasztalatait közlési és kifejezési helyzetekben használja.',
		b: 1,
		c: 2953,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'adott cél érdekében egyszerű vizuális kommunikációs megjelenés létrehozása'])
	},
		{
		a: 'Azonosítja a gyerekeknek szóló vagy fogyasztásra ösztönző, célzottan kommunikációs szándékú vizuális közléseket.',
		b: 1,
		c: 2952,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'célzottan kommunikációs szándékú vizuális közlések értelmezése, megvitatása'])
	},
		{
		a: 'Az adott életkornak megfelelő tájékoztatást, meggyőzést, figyelemfelkeltést szolgáló, célzottan kommunikációs szándékú vizuális közléseket segítséggel értelmez.',
		b: 1,
		c: 2951,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'célzottan kommunikációs szándékú vizuális közlések értelmezése, megvitatása'])
	},
		{
		a: 'Pontosan ismeri államcímerünk és nemzeti zászlónk felépítését, összetevőit, színeit.',
		b: 1,
		c: 2950,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'célzottan kommunikációs szándékú vizuális közlések értelmezése, megvitatása'])
	},
		{
		a: 'Egyszerű, mindennapok során használt jeleket felismer.',
		b: 1,
		c: 2949,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'célzottan kommunikációs szándékú vizuális közlések értelmezése, megvitatása'])
	},
		{
		a: 'Az adott életkornak megfelelő rövid mozgóképi közléseket segítséggel elemez a vizuális kifejezőeszközök használatának tudatosítása érdekében.',
		b: 1,
		c: 2948,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'célzottan kommunikációs szándékú vizuális közlések értelmezése, megvitatása'])
	},
		{
		a: 'A vizuális nyelv elemeinek értelmezésével és használatával kísérletezik.',
		b: 1,
		c: 2947,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'megadott szempontok segítségével vizuális kommunikációs elemek azonosítása'])
	},
		{
		a: 'Valós vagy digitális játékélményeit vizuálisan és dramatikusan feldolgozza: rajzol, fest, formáz, nyomtat, eljátszik, elmesél.',
		b: 1,
		c: 2946,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'saját élmények megfogalmazása és vizuális megjelenítése'])
	},
		{
		a: 'Korábban átélt eseményeket, tapasztalatokat, élményeket különböző vizuális eszközökkel, élményszerűen megjelenít: rajzol, fest, nyomtat, formáz, épít, fotóz és magyarázza azt.',
		b: 1,
		c: 2945,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'saját élmények megfogalmazása és vizuális megjelenítése'])
	},
		{
		a: 'Saját és mások érzelmeit, hangulatait segítséggel megfogalmazza és egyszerű dramatikus eszközökkel eljátssza, vizuális eszközökkel megjeleníti.',
		b: 1,
		c: 2944,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'érzelmek, hangulatok azonosítása és megjelenítése különböző eszközökkel'])
	},
		{
		a: 'A tanulás során szerzett tapasztalatait, saját céljait, gondolatait vizuális megjelenítés segítségével magyarázza, illusztrálja egyénileg és csoportmunkában.',
		b: 1,
		c: 2943,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'magyarázat érdekében gondolatok vizuális bemutatása'])
	},
		{
		a: 'Gyűjtött természeti vagy mesterséges formák egyszerűsítésével, vagy a magyar díszítőművészet általa megismert mintakincsének felhasználásával mintát tervez.',
		b: 1,
		c: 2942,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'adott vagy választott inspirációforrás alapján mintatervezés'])
	},
		{
		a: 'Adott cél érdekében alkalmazza a térbeli formaalkotás különböző technikáit egyénileg és csoportmunkában.',
		b: 1,
		c: 2941,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'önállóan választott eszközökkel térbeli forma, építmény, tárgy tervezése, létrehozása'])
	},
		{
		a: 'Alkalmazza az egyszerű tárgykészítés legfontosabb technikáit: vág, ragaszt, tűz, varr, kötöz, fűz, mintáz.',
		b: 1,
		c: 2940,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'önállóan választott eszközökkel térbeli forma, építmény, tárgy tervezése, létrehozása'])
	},
		{
		a: 'Adott cél érdekében fotót vagy rövid mozgóképet készít.',
		b: 1,
		c: 2939,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'látvány változatos megjelenítése'])
	},
		{
		a: 'Különböző alakzatokat, motívumokat, egyszerű vizuális megjelenéseket látvány alapján, különböző vizuális eszközökkel, viszonylagos pontossággal megjelenít: rajzol, fest, nyomtat, formáz, épít.',
		b: 1,
		c: 2938,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'látvány változatos megjelenítése'])
	},
		{
		a: 'Képek, műalkotások, mozgóképi közlések megtekintése után adott szempontok szerint következtetést fogalmaz meg, megállapításait társaival is megvitatja.',
		b: 1,
		c: 2937,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'megadott szempontok segítségével vizuális megjelenések, képek, mozgóképek értelmezése, összehasonlí'])
	},
		{
		a: 'Képek, műalkotások, mozgóképi közlések megtekintése után önállóan megfogalmazza és indokolja tetszésítéletét.',
		b: 1,
		c: 2936,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'megadott szempontok segítségével vizuális megjelenések, képek, mozgóképek értelmezése, összehasonlí'])
	},
		{
		a: 'Saját munkákat, képeket, műalkotásokat, mozgóképi részleteket szereplők karaktere, szín-, fényhatás, kompozíció, kifejezőerő szempontjából szövegesen elemez, összehasonlít.',
		b: 1,
		c: 2935,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'megadott szempontok segítségével vizuális megjelenések, képek, mozgóképek értelmezése, összehasonlí'])
	},
		{
		a: 'Saját és társai vizuális munkáit szövegesen értelmezi, kiegészíti, magyarázza.',
		b: 1,
		c: 2934,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'megadott szempontok segítségével vizuális megjelenések, képek, mozgóképek értelmezése, összehasonlí'])
	},
		{
		a: 'Elképzelt történeteket, irodalmi alkotásokat bemutat, dramatizál, ehhez egyszerű eszközöket: bábot, teret, díszletet, kelléket, egyszerű jelmezt készít csoportmunkában, és élményeit szövegesen megfogalmazza.',
		b: 1,
		c: 2933,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'elképzelt dolgok önálló vizuális megjelenítése és szöveges magyarázata'])
	},
		{
		a: 'Egyszerű eszközökkel és anyagokból elképzelt teret rendez, alakít, egyszerű makettet készít egyénileg vagy csoportmunkában, és az elképzelést szövegesen is bemutatja, magyarázza.',
		b: 1,
		c: 2932,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'elképzelt dolgok önálló vizuális megjelenítése és szöveges magyarázata'])
	},
		{
		a: 'Rövid szövegekhez, egyéb tananyagtartalmakhoz síkbeli és térbeli vizuális illusztrációt készít különböző vizuális eszközökkel: rajzol, fest, nyomtat, fotóz, formáz, épít és a képet, tárgyat szövegesen értelmezi.',
		b: 1,
		c: 2931,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'elképzelt dolgok önálló vizuális megjelenítése és szöveges magyarázata'])
	},
		{
		a: 'Élmények, elképzelt vagy hallott történetek, szövegek részleteit különböző vizuális eszközökkel egyszerűen megjeleníti: rajzol, fest, nyomtat, fotóz, formáz, épít.',
		b: 1,
		c: 2930,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'elképzelt dolgok önálló vizuális megjelenítése és szöveges magyarázata'])
	},
		{
		a: 'Vizuális jelenségeket, egyszerű látott képi elemeket különböző vizuális eszközökkel megjelenít: rajzol, fest, nyomtat, formáz, épít.',
		b: 1,
		c: 2929,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'látott dolgok egyszerű megjelenítése különböző eszközökkel'])
	},
		{
		a: 'Látványt, vizuális jelenségeket, képeket viszonylagos pontossággal emlékezetből azonosít, kiválaszt, megnevez, különböző szempontok szerint rendez.',
		b: 1,
		c: 2928,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'megadott szempontok segítségével, irányított megfigyelések alapján vizuális megjelenések azonosítás'])
	},
		{
		a: 'Közvetlen vizuális megfigyeléssel leolvasható egyszerű jellemzők alapján vizuális jelenségeket, képeket méret, irány, elhelyezkedés, mennyiség, szín szempontjából azonosít, kiválaszt, rendez, szövegesen pontosan leír és összehasonlít.',
		b: 1,
		c: 2927,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'megadott szempontok segítségével, irányított megfigyelések alapján vizuális megjelenések azonosítás'])
	},
		{
		a: 'Érzékszervi tapasztalatokat pontosan megfogalmaz mérettel, formával, színnel, felülettel, illattal, hanggal, mozgással kapcsolatban.',
		b: 1,
		c: 2926,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'irányított megfigyelések tárgyának jellemzése, leírása'])
	},
		{
		a: 'Véleményét önállóan megfogalmazza.',
		b: 1,
		c: 2925,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Egyszerű, begyakorolt feladatokat önállóan is elvégez.',
		b: 1,
		c: 2924,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Feladatmegoldás során betartja az előre ismertetett szabályokat.',
		b: 1,
		c: 2923,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Csoportban végzett feladatmegoldás közben képes érvelésre.',
		b: 1,
		c: 2922,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Csoportban végzett feladatmegoldás során részt vállal a feladatmegoldásban, és figyelembe veszi társai álláspontját.',
		b: 1,
		c: 2921,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Önálló döntéseket hoz a környezet alakításának szempontjából.',
		b: 1,
		c: 2920,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Példák alapján azonosítja a médiafogyasztás mindennapi jelenségeit.',
		b: 1,
		c: 2919,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Gondolatait vizuálisan is érthetően megmagyarázza.',
		b: 1,
		c: 2918,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Példák alapján különbséget tesz a hétköznapi és a művészi között.',
		b: 1,
		c: 2917,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Megérti és végrehajtja a feladatokat.',
		b: 1,
		c: 2916,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Különböző eszközöket rendeltetésszerűen használ.',
		b: 1,
		c: 2915,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Alkotó tevékenység közben bátran kísérletezik.',
		b: 1,
		c: 2914,
		d: 4,
		e: 'Vizuális kultúra@1-4',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Nem konvencionális feladatok kapcsán egyéni elképzeléseit, ötleteit rugalmasan alkalmazva megoldást talál.',
		b: 5,
		c: 3007,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'kreativitás', 'problémamegoldás érdekében rugalmas és nyitott kísérletezés, többféle megoldás keresése'])
	},
		{
		a: 'Adott téma vizuális feldolgozása érdekében problémákat vet fel, megoldási lehetőségeket talál, javasol, a probléma megoldása érdekében kísérletezik.',
		b: 5,
		c: 3006,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'kreativitás', 'problémamegoldás érdekében rugalmas és nyitott kísérletezés, többféle megoldás keresése'])
	},
		{
		a: 'Nem vizuális információkat (pl. számszerű adat, absztrakt fogalom) különböző célok (pl. tudományos, gazdasági, turisztikai) érdekében vizuális, képi üzenetté alakít.',
		b: 5,
		c: 3005,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'direkt, célzott vizuális közlés', 'adott cél érdekében vizuális kommunikációs megjelenés létrehozása többféle, adekvát technikával'])
	},
		{
		a: 'Adott témát, időbeli, térbeli folyamatokat, történéseket közvetít újabb médiumok képírási formáinak segítségével egyénileg vagy csoportmunkában is.',
		b: 5,
		c: 3004,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'direkt, célzott vizuális közlés', 'adott cél érdekében vizuális kommunikációs megjelenés létrehozása többféle, adekvát technikával'])
	},
		{
		a: 'A helyzetek, történetek ábrázolása, dokumentálása során egyénileg vagy csoportmunkában is felhasználja a kép és szöveg, a kép és hang viszonyában rejlő lehetőségeket.',
		b: 5,
		c: 3003,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'direkt, célzott vizuális közlés', 'adott cél érdekében vizuális kommunikációs megjelenés létrehozása többféle, adekvát technikával'])
	},
		{
		a: 'Célzottan vizuális kommunikációt szolgáló megjelenéseket értelmez és tervez a kommunikációs szándék és a hatáskeltés szempontjait kiemelve.',
		b: 5,
		c: 3002,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'direkt, célzott vizuális közlés', 'célzottan kommunikációs szándékú vizuális közlések értelmezése, elemzése, megvitatása'])
	},
		{
		a: 'Egyszerű tájékoztató, magyarázó rajzok, ábrák, jelek, szimbólumok tervezése érdekében önállóan információt gyűjt.',
		b: 5,
		c: 3001,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'direkt, célzott vizuális közlés', 'célzottan kommunikációs szándékú vizuális közlések értelmezése, elemzése, megvitatása'])
	},
		{
		a: 'Vizuális megjelenítés során egyénileg és csoportmunkában is használja a kiemelés, figyelemirányítás, egyensúlyteremtés vizuális eszközeit.',
		b: 5,
		c: 3000,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'személyes témák, gondolatok vizuális megjelenítése kifejezési szándéknak megfelelő vizuális eszközö'])
	},
		{
		a: 'Látványok, képek, médiaszövegek, történetek, szituációk feldolgozása kapcsán személyes módon kifejezi, megjeleníti felszínre kerülő érzéseit, gondolatait, asszociációit.',
		b: 5,
		c: 2999,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'személyes témák, gondolatok vizuális megjelenítése kifejezési szándéknak megfelelő vizuális eszközö'])
	},
		{
		a: 'Megfogalmazza személyes viszonyulását, értelmezését adott vagy választott művész alkotásai, társadalmi reflexiói kapcsán.',
		b: 5,
		c: 2998,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'alkotások elemzése kifejezőerő és a közvetített hatás értelmezésével'])
	},
		{
		a: 'Tetszésítélete alapján alkotásokról információkat gyűjt, kifejezőerő és a közvetített hatás szempontjából csoportosítja, és megállapításait felhasználja más szituációban.',
		b: 5,
		c: 2997,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'alkotások elemzése kifejezőerő és a közvetített hatás értelmezésével'])
	},
		{
		a: 'Gondolatait, terveit, észrevételeit, véleményét változatos vizuális eszközök segítségével prezentálja.',
		b: 5,
		c: 2996,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'magyarázat érdekében gondolatok vizuális bemutatása, illusztrálása'])
	},
		{
		a: 'Ismeri a térábrázolás alapvető módszereit (pl. axonometria, perspektíva) és azokat alkotómunkájában felhasználja.',
		b: 5,
		c: 2995,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'térbeli és időbeli változások álló és mozgóképi megjelenítése'])
	},
		{
		a: 'Adott cél és szempontok figyelembevételével térbeli, időbeli viszonyokat, változásokat, eseményeket, történeteket egyénileg és csoportmunkában is rögzít, megjelenít.',
		b: 5,
		c: 2994,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'térbeli és időbeli változások álló és mozgóképi megjelenítése'])
	},
		{
		a: 'Adott koncepció figyelembevételével, tudatos anyag- és eszközhasználattal tárgyakat, tereket tervez és hoz létre, egyénileg vagy csoportmunkában is.',
		b: 5,
		c: 2993,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'adott cél érdekében térbeli forma, építmény, tárgy tervezése, létrehozása'])
	},
		{
		a: 'Különböző művészettörténeti korokban, stílusokban készült alkotásokat, építményeket összehasonlít, megkülönböztet és összekapcsol más jelenségekkel, fogalmakkal, alkotásokkal, melyek segítségével alkotótevékenysége során újrafogalmazza a látványt.',
		b: 5,
		c: 2992,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'különböző művészettörténeti korokban, stílusokban készült alkotások megkülönböztetése'])
	},
		{
		a: 'Különböző korok és kultúrák szimbólumai és motívumai közül adott cél érdekében gyűjtést végez, és alkotó tevékenységében felhasználja a gyűjtés eredményeit.',
		b: 5,
		c: 2991,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'vizuális megjelenések, képek, mozgóképek önálló értelmezése, összehasonlítása többféle szempont sze'])
	},
		{
		a: 'Vizuális megjelenések, képek, mozgóképek, médiaszövegek vizsgálata, összehasonlítása során feltárt következtetéseit megfogalmazza, és alkotó tevékenységében, egyénileg és csoportmunkában is felhasználja.',
		b: 5,
		c: 2990,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'vizuális megjelenések, képek, mozgóképek önálló értelmezése, összehasonlítása többféle szempont sze'])
	},
		{
		a: 'Vizuális problémák vizsgálata során összegyűjtött információkat, gondolatokat különböző szempontok szerint rendez és összehasonlít, a tapasztalatait különböző helyzetekben a megoldás érdekében felhasználja.',
		b: 5,
		c: 2989,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'vizuális megfigyelések eredményeinek felhasználása különböző elemzési helyzetekben'])
	},
		{
		a: 'A látványokkal kapcsolatos objektív és szubjektív észrevételeket pontosan szétválasztja.',
		b: 5,
		c: 2988,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'vizuális megfigyelések eredményeinek felhasználása különböző elemzési helyzetekben'])
	},
		{
		a: 'Látványok, képek részeinek, részleteinek alapján elképzeli a látvány egészét, fogalmi és vizuális eszközökkel bemutatja és megjeleníti, rekonstruálja azt.',
		b: 5,
		c: 2987,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'belső képek és elképzelések önálló vagy adott szempontoknak megfelelő bemutatása, vizuális megjelen'])
	},
		{
		a: 'Szöveges vagy egyszerű képi inspiráció alapján elképzeli és megjeleníti a látványt, egyénileg és csoportmunkában is.',
		b: 5,
		c: 2986,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'belső képek és elképzelések önálló vagy adott szempontoknak megfelelő bemutatása, vizuális megjelen'])
	},
		{
		a: 'A valóság vagy a vizuális alkotások, illetve azok elemei által felidézett asszociatív módon generált képeket, történeteket szövegesen megfogalmaz, vizuálisan megjelenít, egyénileg és csoportmunkában is.',
		b: 5,
		c: 2985,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'belső képek és elképzelések önálló vagy adott szempontoknak megfelelő bemutatása, vizuális megjelen'])
	},
		{
		a: 'Adott tartalmi keretek figyelembevételével karaktereket, tereket, tárgyakat, helyzeteket, történeteket részletesen elképzel, fogalmi és vizuális eszközökkel bemutat és megjelenít, egyénileg és csoportmunkában is.',
		b: 5,
		c: 2984,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'belső képek és elképzelések önálló vagy adott szempontoknak megfelelő bemutatása, vizuális megjelen'])
	},
		{
		a: 'Elvont fogalmakat, művészeti tartalmakat belső képek összekapcsolásával bemutat, magyaráz és különböző vizuális eszközökkel megjelenít.',
		b: 5,
		c: 2983,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'elvont fogalmak és belső képek összekapcsolása, megjelenítése és magyarázat'])
	},
		{
		a: 'Különböző érzetek kapcsán belső képeinek, képzeteinek megfigyelésével tapasztalatait vizuálisan megjeleníti.',
		b: 5,
		c: 2982,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'elvont fogalmak és belső képek összekapcsolása, megjelenítése és magyarázat'])
	},
		{
		a: 'Megfigyeléseit, tapasztalatait, gondolatait vizuálisan rögzíti, mások számára érthető vázlatot készít.',
		b: 5,
		c: 2981,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'megfigyelések értelmezhető vizuális rögzítése'])
	},
		{
		a: 'Adott témával, feladattal kapcsolatos vizuális információkat és képi inspirációkat keres többféle forrásból.',
		b: 5,
		c: 2980,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'feladatmegoldás során vizuális információk adekvát keresése, felidézése'])
	},
		{
		a: 'Alkotómunka során felhasználja a már látott képi inspirációkat.',
		b: 5,
		c: 2979,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'feladatmegoldás során vizuális információk adekvát keresése, felidézése'])
	},
		{
		a: 'Látványok, vizuális jelenségek, alkotások lényeges, egyedi jellemzőit kiemeli, bemutatja.',
		b: 5,
		c: 2978,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'önálló vizuális megfigyelések leírása, bemutatása'])
	},
		{
		a: 'Látványt, vizuális jelenségeket, műalkotásokat önállóan is pontosan, részletgazdagon szövegesen jellemez, bemutat.',
		b: 5,
		c: 2977,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'önálló vizuális megfigyelések leírása, bemutatása'])
	},
		{
		a: 'Önállóan véleményt alkot, és azt röviden indokolja.',
		b: 5,
		c: 2976,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Feladatmegoldás során megállapodásokat köt, szabályt alkot, és betartja a közösen meghatározott feltételeket.',
		b: 5,
		c: 2975,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Csoportban végzett feladatmegoldás során részt vállal a feladatmegoldásban, önállóan megtalálja saját feladatát, és figyelembe veszi társai álláspontját is.',
		b: 5,
		c: 2974,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Érti a magyar és egyetemes kulturális örökség és hagyomány jelentőségét.',
		b: 5,
		c: 2973,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Példák alapján meg tudja magyarázni a tervezett, épített környezet és a funkció összefüggéseit.',
		b: 5,
		c: 2972,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Példák alapján megérti a képmanipuláció és befolyásolás összefüggéseit.',
		b: 5,
		c: 2971,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Használja a térbeli és az időbeli viszonyok megjelenítésének különböző vizuális lehetőségeit.',
		b: 5,
		c: 2970,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Felismeri az egyes témakörök szemléltetésére használt műalkotásokat, alkotókat az ajánlott képanyag alapján.',
		b: 5,
		c: 2969,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Megkülönböztet művészettörténeti korszakokat, stílusokat.',
		b: 5,
		c: 2968,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Használja és megkülönbözteti a különböző vizuális médiumok kifejezőeszközeit.',
		b: 5,
		c: 2967,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Felismeri a vizuális művészeti műfajok példáit.',
		b: 5,
		c: 2966,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Feladatmegoldásai során felidézi és alkalmazza a korábban szerzett ismereteket, illetve kapcsolódó információkat keres.',
		b: 5,
		c: 2965,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Adott tanulási helyzetben a tanulási előrehaladás érdekében adekvát kérdést tesz fel.',
		b: 5,
		c: 2964,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Feladatokat önállóan megold, eredményeit érthetően bemutatja.',
		b: 5,
		c: 2963,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Anyaghasználata gazdaságos, átgondolt.',
		b: 5,
		c: 2962,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Eszközhasználata begyakorlott, szakszerű.',
		b: 5,
		c: 2961,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Alkotó tevékenység közben önállóan kísérletezik, különböző megoldási utakat keres.',
		b: 5,
		c: 2960,
		d: 8,
		e: 'Vizuális kultúra@5-8',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'A leghatékonyabb megoldás megtalálása érdekében felméri a lehetőségeket és azok feltételeit, amelyek komplex mérlegelésével döntést hoz az adott feladatokban.',
		b: 9,
		c: 3052,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'kreativitás', 'feladatmegoldás során stratégiai szempontból is értelmezhető döntés elérése'])
	},
		{
		a: 'Egyéni munkáját hajlandó a közösségi alkotás érdekei alá rendelni, a hatékonyság érdekében az együttműködésre törekszik.',
		b: 9,
		c: 3051,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'kreativitás', 'kreatív problémamegoldás során hatékony együttműködés a társakkal'])
	},
		{
		a: 'Adott feladatmegoldás érdekében ötleteiből rendszert alkot, a célok érdekében alkalmas kifejezési eszközöket és technikákat választ, az újszerű ötletek megvalósítása érdekében szabályokat újraalkot.',
		b: 9,
		c: 3050,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'kreativitás', 'kísérletezés problémamegoldás érdekében az ötletek vizuális magyarázatával és részletes kidolgozásá'])
	},
		{
		a: 'Technikai képnél és a számítógépes környezetben felismeri a manipuláció lehetőségét, és érti a befolyásolás vizuális eszközeinek jelentőségét.',
		b: 9,
		c: 3049,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'mediális üzenetek mérlegelő szemléletű értelmezése'])
	},
		{
		a: 'Adott téma újszerű megjelenítéséhez illő technikai lehetőségeket kiválaszt, és adott vizuális feladatmegoldás érdekében megfelelően felhasznál.',
		b: 9,
		c: 3048,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'könnyen elérhető, egyszerű technikai lehetőségek, alkalmazások használata a képalkotás érdekében'])
	},
		{
		a: 'Szabadon választott témában, társaival együtt ok-okozati összefüggéseken alapuló történetet alkot, amelynek részleteit vizuális eszközökkel is magyarázza, bemutatja.',
		b: 9,
		c: 3047,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'közlési szándéknak megfelelő álló és mozgóképi közlés létrehozása'])
	},
		{
		a: 'Valós célokat szolgáló, saját kommunikációs helyzetnek megfelelő, képes és szöveges üzenetet felhasználó vizuális közlést hoz létre társaival együttműködésben is.',
		b: 9,
		c: 3046,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'célzott, direkt vizuális közlés', 'közlési szándéknak megfelelő álló és mozgóképi közlés létrehozása'])
	},
		{
		a: 'Vizuális megjelenéseket, alkotásokat újraértelmez, áttervez és módosított kifejezési szándék vagy funkció érdekében újraalkot.',
		b: 9,
		c: 3045,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'személyes témák, gondolatok hiteles vizuális megjelenítése a választott médium sajátosságainak érvé'])
	},
		{
		a: 'Saját munkáit bátran újraértelmezi és felhasználja további alkotótevékenység során.',
		b: 9,
		c: 3044,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'személyes témák, gondolatok hiteles vizuális megjelenítése a választott médium sajátosságainak érvé'])
	},
		{
		a: 'Képalkotás és tárgyformálás során autonóm módon felhasználja személyes tapasztalatait a hiteles kifejezési szándék érdekében a választott médiumnak is megfelelően.',
		b: 9,
		c: 3043,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális kifejezés', 'személyes témák, gondolatok hiteles vizuális megjelenítése a választott médium sajátosságainak érvé'])
	},
		{
		a: 'Tervezési folyamat során a gondolkodás szemléltetése érdekében gondolatait mások számára is érthetően, szövegesen és képpel dokumentálja.',
		b: 9,
		c: 3042,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'gondolatok vizuális bemutatása, illusztrálása magyarázat céljából'])
	},
		{
		a: 'Adott feladatnak megfelelően alkalmazza az analóg és a digitális prezentációs technikákat, illetve az ezekhez kapcsolható álló- és mozgóképi lehetőségeket.',
		b: 9,
		c: 3041,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'gondolatok vizuális bemutatása, illusztrálása magyarázat céljából'])
	},
		{
		a: 'Bemutatás, felhívás, történetmesélés céljából térbeli és időbeli folyamatokat, történéseket, cselekményeket különböző eszközök segítségével rögzít.',
		b: 9,
		c: 3040,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'adott koncepció érdekében térbeli és időbeli változások mozgóképi megjelenítése'])
	},
		{
		a: 'Az adott vagy a választott célnak megfelelően környezetátalakítás érdekében, társaival együttműködésben, környezetfelméréssel alátámasztva tervet készít, amelyet indokolni is tud.',
		b: 9,
		c: 3039,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'megjelenítés, ábrázolás, konstruálás', 'saját koncepciónak megfelelő téralakítás'])
	},
		{
		a: 'Személyes élményei alapján elemzi a tárgy- és környezetkultúra, valamint a fogyasztói szokások mindennapi életre gyakorolt hatásait és veszélyeit, és ezeket társaival megvitatja.',
		b: 9,
		c: 3038,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'az épített, tervezett környezet elemző vizsgálata'])
	},
		{
		a: 'Adott szempontok alapján érti és megkülönbözteti a történeti korok és a modern társadalmak tárgyi és épített környezetének legfontosabb jellemzőit, miközben értelmezi kulturális örökségünk jelentőségét is.',
		b: 9,
		c: 3037,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'kultúrák, művészettörténeti korok és stílusok legfontosabb jellemzőinek és összefüggéseinek értelme'])
	},
		{
		a: 'Adott vagy választott kortárs művészeti üzenetet személyes viszonyulás alapján, a társadalmi reflexiók kiemelésével értelmez.',
		b: 9,
		c: 3036,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'kultúrák, művészettörténeti korok és stílusok legfontosabb jellemzőinek és összefüggéseinek értelme'])
	},
		{
		a: 'Érti és megkülönbözteti a klasszikus és a modern művészet kultúrtörténeti összetevőit, közlésformáinak azonosságait és különbségeit.',
		b: 9,
		c: 3035,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'kultúrák, művészettörténeti korok és stílusok legfontosabb jellemzőinek és összefüggéseinek értelme'])
	},
		{
		a: 'Különböző mediális produktumokat vizuális jelrendszer, kommunikációs szándék és hatáskeltés szempontjából elemez, összehasonlít, és következtetéseit társaival is megvitatja.',
		b: 9,
		c: 3034,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'vizuális megjelenések, képek, mozgóképek adott szempont szerinti értelmezése, összehasonlítása, köv'])
	},
		{
		a: 'Megfelelő érvekkel alátámasztva, mérlegelő szemlélettel viszonyul az őt körülvevő kulturális környezet vizuális értelmezéseinek mediális csatornáihoz, amit társaival is megvitat.',
		b: 9,
		c: 3033,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'vizuális megjelenések, képek, mozgóképek adott szempont szerinti értelmezése, összehasonlítása, köv'])
	},
		{
		a: 'Adott és választott vizuális művészeti témában önállóan gyűjtött képi és szöveges információk felhasználásával részletesebb helyzetfeltáró, elemző, összehasonlító projektmunkát végez.',
		b: 9,
		c: 3032,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'vizuális elemzési, értelmezési lehetőségek tanulási célnak megfelelő felhasználása'])
	},
		{
		a: 'A művészi hatás megértése és magyarázata érdekében összehasonlít, és következtetéseket fogalmaz meg a különböző művészeti ágak kifejezési formáival kapcsolatban.',
		b: 9,
		c: 3031,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'vizuális tapasztalatok és tudás eredményeinek felhasználása különböző elemzési helyzetekben bonyolu'])
	},
		{
		a: 'A vizuális megjelenések elemzése és értelmezése során a befogadó és az alkotó szerepkört egyaránt megismerve reflexióit szemléletesen és szakszerűen fogalmazza meg szövegesen és képi megjelenítéssel is.',
		b: 9,
		c: 3030,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'vizuális elemzés, vizuális értelmezés', 'vizuális tapasztalatok és tudás eredményeinek felhasználása különböző elemzési helyzetekben bonyolu'])
	},
		{
		a: 'Új ötleteket is felhasznál képek, tárgyak, terek megjelenítésének, átalakításának, rekonstruálásának megvalósításánál síkbeli, térbeli és időbeli produktumok létrehozása esetében.',
		b: 9,
		c: 3029,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'feladatmegoldás során a belső képalkotás lehetőségének szabad felhasználása és hiteles magyarázata'])
	},
		{
		a: 'Alkotó feladatmegoldásai során az elraktározott, illetve a folyamatosan újraalkotott belső képeit, képzeteit szabadon párosítja a felkínált tartalmi elemek és látványok újrafogalmazásakor, amelyet indokolni is tud.',
		b: 9,
		c: 3028,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'belső képalkotás, képzetek', 'feladatmegoldás során a belső képalkotás lehetőségének szabad felhasználása és hiteles magyarázata'])
	},
		{
		a: 'Az alkotótevékenység során szerzett tapasztalatait önálló feladatmegoldás során beépíti, és az eredményes feladatmegoldás érdekében szükség szerint továbbfejleszti.',
		b: 9,
		c: 3027,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'feladatmegoldás során vizuális információk felidézése és következetes összegyűjtése a megoldás érde'])
	},
		{
		a: 'Adott feladatmegoldás érdekében meglévő vizuális ismeretei között megfelelően szelektál, a további szakszerű információszerzés érdekében adekvátan keres.',
		b: 9,
		c: 3026,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'feladatmegoldás során vizuális információk felidézése és következetes összegyűjtése a megoldás érde'])
	},
		{
		a: 'A vizuális megjelenések mintáinak önálló megfigyelése és felismerése által konstrukciókat alkot, e megfigyelések szempontjainak összekapcsolásával definiál és következtet, mindezt társaival együttműködve alkotótevékenységébe is beilleszti.',
		b: 9,
		c: 3025,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'feladatmegoldás során vizuális információk felidézése és következetes összegyűjtése a megoldás érde'])
	},
		{
		a: 'Alkotó és befogadó tevékenységei során érti, és komplex módon használja a vizuális nyelv eszközeit.',
		b: 9,
		c: 3024,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'önálló feladatmegoldásokban a vizuális megfigyelés és megismerés adekvát használata'])
	},
		{
		a: 'A látható világ vizuális összefüggéseinek megfigyeléseit ok-okozati viszonyoknak megfelelően rendszerezi.',
		b: 9,
		c: 3023,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['vizuális kultúra', 'megfigyelés, vizuális emlékezet', 'önálló feladatmegoldásokban a vizuális megfigyelés és megismerés adekvát használata'])
	},
		{
		a: 'Adott területen megtalálja a számára érdekes és fontos kihívásokat.',
		b: 9,
		c: 3022,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Önálló véleményt alkot, és azt meggyőzően indokolja.',
		b: 9,
		c: 3021,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Csoportban végzett feladatmegoldás során részt vállal a feladatmegoldásban, önállóan megtalálja saját feladatát, figyelembe veszi társai álláspontját, de az optimális eredmény érdekében hatékonyan érvényesíti érdekeit.',
		b: 9,
		c: 3020,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'A fenntarthatóság érdekében felelős döntéseket hoz a saját tervezett, épített környezetével kapcsolatban.',
		b: 9,
		c: 3019,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Felismeri a designgondolkodás sajátosságait az őt körülvevő tárgy- és környezetkultúra produktumaiban.',
		b: 9,
		c: 3018,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Ismeri néhány példáját a digitális képalkotás közösségi médiában használt lehetőségének.',
		b: 9,
		c: 3017,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Érti és meg tudja magyarázni a célzott vizuális közlések hatásmechanizmusát.',
		b: 9,
		c: 3016,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Példák alapján felismeri és értelmezi a kortárs művészetben a társadalmi reflexiókat.',
		b: 9,
		c: 3015,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Példák alapján művészettörténeti korszakokat, stílusokat felismer és egymáshoz képest időben viszonylagos pontossággal elhelyez.',
		b: 9,
		c: 3014,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Példák alapján érti a művészet kultúraközvetítő szerepét.',
		b: 9,
		c: 3013,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Feladatmegoldásai során saját ötleteit és eredményeit bátran bemutatja.',
		b: 9,
		c: 3012,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Feladatmegoldásai során a felhasználás érdekében hatékonyan szelektálja a korábban szerzett ismereteket.',
		b: 9,
		c: 3011,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Adott tanulási helyzetben a tanulási előrehaladás érdekében problémákat keres, kérdéseket tesz fel, és ezekre önállóan is keresi a megoldásokat és válaszokat.',
		b: 9,
		c: 3010,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Eszköz- és anyaghasználat során adekvát döntést hoz.',
		b: 9,
		c: 3009,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	},
		{
		a: 'Feladatmegoldás közben kísérletezik, különböző megoldási utakat keres, és törekszik az egyéni megoldás igényes kivitelezésére.',
		b: 9,
		c: 3008,
		d: 10,
		e: 'Vizuális kultúra@9-10',
		f: _List_fromArray(
			['átfogó célként kitűzött, valamint a fejlesztési területekhez kapcsolódó tanulási eredmények', 'vizuális kultúra'])
	}
	]);
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $author$project$LoSearch$emptyQuery = {ab: $elm$core$Maybe$Nothing, ac: $elm$core$Maybe$Nothing, ad: $elm$core$Maybe$Nothing, e: $elm$core$Maybe$Nothing, ag: $elm$core$Maybe$Nothing};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {D: frag, F: params, C: unvisited, w: value, G: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.C;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.w);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.w);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.aD),
					$elm$url$Url$Parser$prepareQuery(url.g),
					url.aw,
					$elm$core$Basics$identity)));
	});
var $author$project$LoSearch$Query = F5(
	function (tag, lo, firstYear, lastYear, subject) {
		return {ab: firstYear, ac: lastYear, ad: lo, e: subject, ag: tag};
	});
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$int = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$String$toInt(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$Query$map5 = F6(
	function (func, _v0, _v1, _v2, _v3, _v4) {
		var a = _v0;
		var b = _v1;
		var c = _v2;
		var d = _v3;
		var e = _v4;
		return function (dict) {
			return A5(
				func,
				a(dict),
				b(dict),
				c(dict),
				d(dict),
				e(dict));
		};
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.G;
		var unvisited = _v1.C;
		var params = _v1.F;
		var frag = _v1.D;
		var value = _v1.w;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.G;
		var unvisited = _v0.C;
		var params = _v0.F;
		var frag = _v0.D;
		var value = _v0.w;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $author$project$LoSearch$queryParser = A2(
	$elm$url$Url$Parser$questionMark,
	$elm$url$Url$Parser$s('search'),
	A6(
		$elm$url$Url$Parser$Query$map5,
		$author$project$LoSearch$Query,
		$elm$url$Url$Parser$Query$string('tag'),
		$elm$url$Url$Parser$Query$string('lo'),
		$elm$url$Url$Parser$Query$int('first'),
		$elm$url$Url$Parser$Query$int('last'),
		$elm$url$Url$Parser$Query$string('subject')));
var $author$project$Main$init = F3(
	function (_v0, url, key) {
		var initialQuery = function () {
			var _v1 = A2($elm$url$Url$Parser$parse, $author$project$LoSearch$queryParser, url);
			if (!_v1.$) {
				var q = _v1.a;
				return q;
			} else {
				return $author$project$LoSearch$emptyQuery;
			}
		}();
		return _Utils_Tuple2(
			{
				aj: $elm$core$Maybe$Nothing,
				J: false,
				K: key,
				aA: $elm$core$Maybe$Nothing,
				s: $author$project$LoList$all_los,
				T: 1,
				g: initialQuery,
				W: initialQuery.ad,
				v: $elm$core$Set$empty,
				A: $elm$core$Maybe$Nothing,
				X: initialQuery.ag,
				aP: _List_fromArray(
					['írás', 'olvasás', 'szövegértés']),
				B: false,
				aq: url,
				Q: false
			},
			$elm$core$Platform$Cmd$none);
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $andrewMacmurray$elm_delay$Delay$Second = 1;
var $author$project$Main$SubjectFilterSet = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$TimeOut = function (a) {
	return {$: 5, a: a};
};
var $andrewMacmurray$elm_delay$Delay$Duration = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Process$sleep = _Process_sleep;
var $andrewMacmurray$elm_delay$Delay$after_ = F2(
	function (time, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(time));
	});
var $andrewMacmurray$elm_delay$Delay$Minute = 2;
var $andrewMacmurray$elm_delay$Delay$toMillis = function (_v0) {
	var t = _v0.a;
	var u = _v0.b;
	switch (u) {
		case 0:
			return t;
		case 1:
			return 1000 * t;
		case 2:
			return $andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, 60 * t, 1));
		default:
			return $andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, 60 * t, 2));
	}
};
var $andrewMacmurray$elm_delay$Delay$after = F3(
	function (time, unit, msg) {
		return A2(
			$andrewMacmurray$elm_delay$Delay$after_,
			$andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, time, unit)),
			msg);
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Ports$copyToClipBoard = _Platform_outgoingPort('copyToClipBoard', $elm$json$Json$Encode$string);
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Ports$copyIdLIstToClipBoard = function (ids) {
	return $author$project$Ports$copyToClipBoard(
		A2(
			$elm$json$Json$Encode$encode,
			1,
			A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, ids)));
};
var $author$project$Main$emptyIsNothing = function (s) {
	return (s === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(s);
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Basics$not = _Basics_not;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$LoSearch$queryToUrl = function (query) {
	return A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			['search']),
		_Utils_ap(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					function (x) {
						return $elm$core$List$singleton(
							A2($elm$url$Url$Builder$string, 'tag', x));
					},
					query.ag)),
			_Utils_ap(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						function (x) {
							return $elm$core$List$singleton(
								A2($elm$url$Url$Builder$string, 'lo', x));
						},
						query.ad)),
				_Utils_ap(
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (x) {
								return $elm$core$List$singleton(
									A2($elm$url$Url$Builder$int, 'first', x));
							},
							query.ab)),
					_Utils_ap(
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								function (x) {
									return $elm$core$List$singleton(
										A2($elm$url$Url$Builder$int, 'last', x));
								},
								query.ac)),
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								function (x) {
									return $elm$core$List$singleton(
										A2($elm$url$Url$Builder$string, 'subject', x));
								},
								query.e)))))));
};
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Main$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 1:
					var url = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aq: url}),
						$elm$core$Platform$Cmd$none);
				case 2:
					var tag = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aj: $elm$core$Maybe$Just(tag)
							}),
						$elm$core$Platform$Cmd$none);
				case 3:
					var newContent = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								J: true,
								W: $author$project$Main$emptyIsNothing(newContent)
							}),
						A3(
							$andrewMacmurray$elm_delay$Delay$after,
							1,
							1,
							$author$project$Main$TimeOut(
								_Utils_Tuple2(
									$author$project$Main$emptyIsNothing(newContent),
									$elm$core$Maybe$Nothing))));
				case 4:
					var newContent = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								J: true,
								X: $author$project$Main$emptyIsNothing(newContent)
							}),
						A3(
							$andrewMacmurray$elm_delay$Delay$after,
							1,
							1,
							$author$project$Main$TimeOut(
								_Utils_Tuple2(
									$elm$core$Maybe$Nothing,
									$author$project$Main$emptyIsNothing(newContent)))));
				case 5:
					var _v1 = msg.a;
					var newQueryValue = _v1.a;
					var newTagValue = _v1.b;
					var hasDryed = F3(
						function (newValue, oldValue, valueOnScreen) {
							if (newValue.$ === 1) {
								return _Utils_Tuple2(false, oldValue);
							} else {
								var realNewValue = newValue.a;
								return _Utils_eq(realNewValue, valueOnScreen) ? _Utils_Tuple2(true, realNewValue) : _Utils_Tuple2(false, oldValue);
							}
						});
					var _v2 = A3(
						hasDryed,
						$elm$core$Maybe$Just(newTagValue),
						model.g.ag,
						model.X);
					var tagDryed = _v2.a;
					var newTag = _v2.b;
					var _v3 = A3(
						hasDryed,
						$elm$core$Maybe$Just(newQueryValue),
						model.g.ad,
						model.W);
					var queryDryed = _v3.a;
					var newQuery = _v3.b;
					return (tagDryed || queryDryed) ? _Utils_Tuple2(
						_Utils_update(
							model,
							{
								J: false,
								g: {
									ab: $elm$core$Maybe$Just(1),
									ac: $elm$core$Maybe$Just(12),
									ad: newQuery,
									e: $elm$core$Maybe$Nothing,
									ag: newTag
								}
							}),
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.K,
							$author$project$LoSearch$queryToUrl(
								{ab: $elm$core$Maybe$Nothing, ac: $elm$core$Maybe$Nothing, ad: newQuery, e: $elm$core$Maybe$Nothing, ag: newTag}))) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 6:
					var yf = msg.a;
					var query = model.g;
					var query2 = _Utils_update(
						query,
						{
							ab: $elm$core$Maybe$Just(yf.a),
							ac: $elm$core$Maybe$Just(yf.b),
							e: $elm$core$Maybe$Nothing
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: query2, Q: false}),
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.K,
							$author$project$LoSearch$queryToUrl(query2)));
				case 7:
					var sf = msg.a;
					var query = model.g;
					var query2 = _Utils_update(
						query,
						{e: sf});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: query2, B: false}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$elm$browser$Browser$Navigation$pushUrl,
									model.K,
									$author$project$LoSearch$queryToUrl(query2)),
									$author$project$Ports$copyIdLIstToClipBoard(
									_List_fromArray(
										[1, 2, 3]))
								])));
				case 8:
					var id = msg.a;
					var checked = msg.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								v: checked ? A2($elm$core$Set$insert, id, model.v) : A2($elm$core$Set$remove, id, model.v)
							}),
						$author$project$Ports$copyIdLIstToClipBoard(
							$elm$core$Set$toList(model.v)));
				case 9:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{v: $elm$core$Set$empty}),
						$elm$core$Platform$Cmd$none);
				case 10:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								s: A2(
									$elm$core$List$map,
									function (lo) {
										return A2($elm$core$Set$member, lo.c, model.v) ? _Utils_update(
											lo,
											{
												f: A2(
													$elm$core$List$append,
													lo.f,
													_List_fromArray(
														['ujtag']))
											}) : lo;
									},
									model.s)
							}),
						$elm$core$Platform$Cmd$none);
				case 11:
					if (msg.a.$ === 1) {
						var _v5 = msg.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var tag = msg.a.a;
						var lo = msg.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									s: A2(
										$elm$core$List$map,
										function (lox) {
											return ((!_Utils_eq(lox.c, lo.c)) || A2($elm$core$List$member, tag, lox.f)) ? lox : _Utils_update(
												lox,
												{
													f: A2(
														$elm$core$List$append,
														lo.f,
														_List_fromArray(
															[tag]))
												});
										},
										model.s)
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 12:
					var lo = msg.a;
					var tag = msg.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								s: A2(
									$elm$core$List$map,
									function (lox) {
										return (!_Utils_eq(lox.c, lo.c)) ? lox : _Utils_update(
											lox,
											{
												f: A2(
													$elm$core$List$filter,
													function (t) {
														return !_Utils_eq(t, tag);
													},
													lo.f)
											});
									},
									model.s)
							}),
						$elm$core$Platform$Cmd$none);
				case 13:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{A: $elm$core$Maybe$Nothing, Q: !model.Q}),
						$elm$core$Platform$Cmd$none);
				case 15:
					var mode = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								T: mode,
								g: (mode === 1) ? _Utils_update(
									$author$project$LoSearch$emptyQuery,
									{
										ab: $elm$core$Maybe$Just(1),
										ac: $elm$core$Maybe$Just(4)
									}) : $author$project$LoSearch$emptyQuery,
								B: false
							}),
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.K,
							$author$project$LoSearch$queryToUrl($author$project$LoSearch$emptyQuery)));
				case 14:
					var subject = msg.a;
					if (_Utils_eq(
						model.g.e,
						$elm$core$Maybe$Just(subject))) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{A: $elm$core$Maybe$Nothing, B: !model.B}),
							$elm$core$Platform$Cmd$none);
					} else {
						var $temp$msg = $author$project$Main$SubjectFilterSet(
							$elm$core$Maybe$Just(subject)),
							$temp$model = _Utils_update(
							model,
							{A: $elm$core$Maybe$Nothing});
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					}
				default:
					var topic = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								A: $elm$core$Maybe$Just(topic)
							}),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $author$project$Main$get_subjects = function (los) {
	return $elm$core$Set$toList(
		A3(
			$elm$core$List$foldl,
			$elm$core$Set$insert,
			$elm$core$Set$empty,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.e;
				},
				los)));
};
var $author$project$LoSearch$between = F3(
	function (x, first, last) {
		return (_Utils_cmp(first, x) < 1) && (_Utils_cmp(x, last) < 1);
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $author$project$LoSearch$n_matching_ngrams = F2(
	function (query, doc) {
		var aux = F2(
			function (n, q) {
				aux:
				while (true) {
					if (q.b) {
						var h = q.a;
						var tail = q.b;
						if (A2($elm$core$List$member, h, doc)) {
							var $temp$n = n + 1,
								$temp$q = tail;
							n = $temp$n;
							q = $temp$q;
							continue aux;
						} else {
							var $temp$n = n,
								$temp$q = tail;
							n = $temp$n;
							q = $temp$q;
							continue aux;
						}
					} else {
						return n;
					}
				}
			});
		return A2(aux, 0, query);
	});
var $author$project$LoSearch$ngram = F2(
	function (n, xs) {
		return (_Utils_cmp(
			n,
			$elm$core$String$length(xs)) < 0) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$left, n, xs),
			A2(
				$author$project$LoSearch$ngram,
				n,
				A2($elm$core$String$dropLeft, 1, xs))) : _List_Nil;
	});
var $author$project$LoSearch$filter_los = F4(
	function (_v0, query, tagq, los) {
		var first = _v0.a;
		var last = _v0.b;
		var losInThatYears = A2(
			$elm$core$List$filter,
			function (lo) {
				return A3($author$project$LoSearch$between, lo.b, first, last) || A3($author$project$LoSearch$between, lo.d, first, last);
			},
			los);
		var losWithMatchingTags = function () {
			if (!tagq.$) {
				var tag = tagq.a;
				return A2(
					$elm$core$List$filter,
					function (lo) {
						return A2($elm$core$List$member, tag, lo.f);
					},
					losInThatYears);
			} else {
				return losInThatYears;
			}
		}();
		if (query.$ === 1) {
			return A2(
				$elm$core$List$map,
				function (lo) {
					return _Utils_Tuple2(1.0, lo);
				},
				losWithMatchingTags);
		} else {
			var q = query.a;
			var ngram_query = A2($author$project$LoSearch$ngram, 4, q);
			return A2(
				$elm$core$List$filterMap,
				function (_v2) {
					var n = _v2.a;
					var lo = _v2.b;
					return (n < 1) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
						_Utils_Tuple2(
							$elm$core$List$length(
								A2($author$project$LoSearch$ngram, 4, lo.a)) / (n / $elm$core$List$length(ngram_query)),
							lo));
				},
				A2(
					$elm$core$List$map,
					function (lo) {
						return _Utils_Tuple2(
							A2(
								$author$project$LoSearch$n_matching_ngrams,
								ngram_query,
								A2($author$project$LoSearch$ngram, 4, lo.a)),
							lo);
					},
					losWithMatchingTags));
		}
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$LoSearch$searchResult = F2(
	function (db, query) {
		var subjectFilter = F2(
			function (sf, los) {
				if (sf.$ === 1) {
					return los;
				} else {
					var s = sf.a;
					return A2(
						$elm$core$List$filter,
						function (lo) {
							return _Utils_eq(lo.e, s);
						},
						los);
				}
			});
		var lastYear = A2($elm$core$Maybe$withDefault, 12, query.ac);
		var firstYear = A2($elm$core$Maybe$withDefault, 1, query.ab);
		return A2(
			$elm$core$List$sortBy,
			$elm$core$Tuple$first,
			A4(
				$author$project$LoSearch$filter_los,
				_Utils_Tuple2(firstYear, lastYear),
				query.ad,
				query.ag,
				A2(subjectFilter, query.e, db)));
	});
var $author$project$Main$SubjectClicked = function (a) {
	return {$: 14, a: a};
};
var $author$project$Main$ToggleYearDropdown = {$: 13};
var $author$project$Main$TopicSelected = function (a) {
	return {$: 16, a: a};
};
var $author$project$Main$YearFilterSet = function (a) {
	return {$: 6, a: a};
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$aside = _VirtualDom_node('aside');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$unique = function (l) {
	var aux = F2(
		function (tag, acc) {
			if (acc.b) {
				var head = acc.a;
				var tail = acc.b;
				return _Utils_eq(head, tag) ? acc : A2($elm$core$List$cons, tag, acc);
			} else {
				return _List_fromArray(
					[tag]);
			}
		});
	return A3($elm$core$List$foldl, aux, _List_Nil, l);
};
var $author$project$Main$get_topics = function (los) {
	var get_last_tag = function (tags) {
		return A2(
			$elm$core$Maybe$withDefault,
			'default',
			$elm$core$List$head(
				$elm$core$List$reverse(tags)));
	};
	return $author$project$Main$unique(
		A2(
			$elm$core$List$map,
			get_last_tag,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.f;
				},
				los)));
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Main$viewMenu = F3(
	function (model, subjects, los) {
		var yearFilterToText = function (yf) {
			return $elm$html$Html$text(
				$elm$core$String$fromInt(yf.a) + ('-' + $elm$core$String$fromInt(yf.b)));
		};
		var activeYearFilter = _Utils_Tuple2(
			A2($elm$core$Maybe$withDefault, 1, model.g.ab),
			A2($elm$core$Maybe$withDefault, 12, model.g.ac));
		return A2(
			$elm$html$Html$aside,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('menu')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('menu-panel')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Catalog')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('menu-label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Évfolyam')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('dropdown', true),
									_Utils_Tuple2('is-active', model.Q)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-trigger')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'aria-controls', 'dropdown-menu'),
											A2($elm$html$Html$Attributes$attribute, 'aria-haspopup', 'true'),
											$elm$html$Html$Attributes$class('button'),
											$elm$html$Html$Events$onClick($author$project$Main$ToggleYearDropdown)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													yearFilterToText(activeYearFilter)
												])),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('icon is-small')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
															$elm$html$Html$Attributes$class('fas fa-angle-down')
														]),
													_List_Nil)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-menu'),
									$elm$html$Html$Attributes$id('dropdown-menu'),
									A2($elm$html$Html$Attributes$attribute, 'role', 'menu')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('dropdown-content')
										]),
									A2(
										$elm$core$List$map,
										function (yf) {
											return A2(
												$elm$html$Html$a,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$classList(
														_List_fromArray(
															[
																_Utils_Tuple2('dropdown-item', true),
																_Utils_Tuple2(
																'is-active',
																_Utils_eq(yf, activeYearFilter))
															])),
														$elm$html$Html$Attributes$href('#'),
														$elm$html$Html$Events$onClick(
														$author$project$Main$YearFilterSet(yf))
													]),
												_List_fromArray(
													[
														yearFilterToText(yf)
													]));
										},
										_List_fromArray(
											[
												_Utils_Tuple2(1, 4),
												_Utils_Tuple2(5, 8),
												_Utils_Tuple2(9, 12)
											])))
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('menu-label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Tantárgyak')
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('menu-list')
						]),
					A2(
						$elm$core$List$map,
						function (subject) {
							var is_active = _Utils_eq(
								model.g.e,
								$elm$core$Maybe$Just(subject));
							return A2(
								$elm$html$Html$li,
								_List_Nil,
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													$author$project$Main$SubjectClicked(subject)),
													$elm$html$Html$Attributes$classList(
													_List_fromArray(
														[
															_Utils_Tuple2('is-active', is_active)
														]))
												]),
											A2(
												$elm$core$List$cons,
												$elm$html$Html$text(
													A3($elm$core$String$replace, '@', ' ', subject)),
												is_active ? _List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('icon is-small is-pulled-right')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
																		model.B ? $elm$html$Html$Attributes$class('fas fa-angle-up') : $elm$html$Html$Attributes$class('fas fa-angle-down')
																	]),
																_List_Nil)
															]))
													]) : _List_Nil))
										]),
									(is_active && model.B) ? _List_fromArray(
										[
											A2(
											$elm$html$Html$ul,
											_List_Nil,
											A2(
												$elm$core$List$map,
												function (topic) {
													return A2(
														$elm$html$Html$li,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$a,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class(
																		_Utils_eq(
																			model.A,
																			$elm$core$Maybe$Just(topic)) ? 'is-active' : ''),
																		$elm$html$Html$Events$onClick(
																		$author$project$Main$TopicSelected(topic))
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(topic)
																	]))
															]));
												},
												$author$project$Main$get_topics(los)))
										]) : _List_Nil));
						},
						subjects))
				]));
	});
var $author$project$Main$LoChecked = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Main$viewResultList2 = F3(
	function (model, results, maybeTopic) {
		var topics = $author$project$Main$get_topics(results);
		var renderTopic = function (topic) {
			var los_in_the_topic = A2(
				$elm$core$List$filter,
				function (lo) {
					return _Utils_eq(
						$elm$core$List$head(
							$elm$core$List$reverse(lo.f)),
						$elm$core$Maybe$Just(topic));
				},
				results);
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('card')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('card-header-title')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('subtitle')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(topic)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('card-content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$ul,
										_List_Nil,
										A2(
											$elm$core$List$indexedMap,
											F2(
												function (index, lo) {
													return A2($elm$core$Set$member, lo.c, model.v) ? A2(
														$elm$html$Html$li,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('has-text-info'),
																$elm$html$Html$Events$onClick(
																A2($author$project$Main$LoChecked, lo.c, false))
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(lo.a)
															])) : A2(
														$elm$html$Html$li,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class(''),
																$elm$html$Html$Events$onClick(
																A2($author$project$Main$LoChecked, lo.c, true))
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(lo.a)
															]));
												}),
											los_in_the_topic))
									]))
							]))
					]));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'scroll')
				]),
			A2($elm$core$List$map, renderTopic, topics));
	});
var $author$project$Main$Danger = 1;
var $author$project$Main$Success = 2;
var $author$project$Main$Warning = 3;
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $author$project$LoSearch$get_years = function (los) {
	var min_year = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$min,
		12,
		A2(
			$elm$core$List$map,
			function ($) {
				return $.b;
			},
			los));
	var max_year = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$max,
		1,
		A2(
			$elm$core$List$map,
			function ($) {
				return $.d;
			},
			los));
	return _Utils_Tuple2(min_year, max_year);
};
var $author$project$Main$backgroundVariant = function (colorv) {
	switch (colorv) {
		case 0:
			return $elm$html$Html$Attributes$class('has-background-primary');
		case 1:
			return $elm$html$Html$Attributes$class('has-background-danger');
		case 2:
			return $elm$html$Html$Attributes$class('has-background-success');
		default:
			return $elm$html$Html$Attributes$class('has-background-warning');
	}
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Main$viewHorizontalDashboard = function (contents) {
	var card = function (_v0) {
		var n = _v0.a;
		var title = _v0.b;
		var style_ = _v0.c;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('column is-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('box'),
							$author$project$Main$backgroundVariant(style_)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('title is-2 has-text-white')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(n)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('subtitle has-text-white')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								]))
						]))
				]));
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('columns has-text-centered')
			]),
		A2($elm$core$List$map, card, contents));
};
var $author$project$Main$viewSubjectInfo = F2(
	function (model, results) {
		var l = $elm$core$List$length(results);
		var _v0 = $author$project$LoSearch$get_years(results);
		var miny = _v0.a;
		var maxy = _v0.b;
		var yspan = (maxy - miny) + 1;
		var avg_per_trimester = l / (yspan * 3);
		return $author$project$Main$viewHorizontalDashboard(
			_List_fromArray(
				[
					_Utils_Tuple3(
					$elm$core$String$fromInt(
						$elm$core$List$length(results)),
					'Total LO',
					2),
					_Utils_Tuple3(
					$elm$core$String$fromInt(yspan),
					'Years',
					3),
					_Utils_Tuple3(
					$elm$core$String$concat(
						_List_fromArray(
							[
								$elm$core$String$fromInt(
								$elm$core$Basics$ceiling(avg_per_trimester * 0.4)),
								'-',
								$elm$core$String$fromInt(
								$elm$core$Basics$ceiling(avg_per_trimester * 0.7))
							])),
					'Per Trimester',
					1)
				]));
	});
var $author$project$Main$viewBrowser = function (model) {
	var q = _Utils_update(
		$author$project$LoSearch$emptyQuery,
		{ab: model.g.ab, ac: model.g.ac});
	var selectedLos = A2(
		$elm$core$List$map,
		$elm$core$Tuple$second,
		A2($author$project$LoSearch$searchResult, model.s, q));
	var results = A2(
		$elm$core$List$map,
		$elm$core$Tuple$second,
		A2($author$project$LoSearch$searchResult, selectedLos, model.g));
	var availableSubjects = $author$project$Main$get_subjects(selectedLos);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('section has-text-left')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('columns')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('column is-one-quarter')
							]),
						_List_fromArray(
							[
								A3($author$project$Main$viewMenu, model, availableSubjects, results)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('column ')
							]),
						_List_fromArray(
							[
								A2($author$project$Main$viewSubjectInfo, model, results),
								A3($author$project$Main$viewResultList2, model, results, model.A)
							]))
					]))
			]));
};
var $author$project$Main$ModeClicked = function (a) {
	return {$: 15, a: a};
};
var $author$project$Main$Search = 0;
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $elm$html$Html$section = _VirtualDom_node('section');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Main$viewNav = function (model) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('hero is-primary is-medium')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero-head')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$nav,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navbar')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('container')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('navbar-brand')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$a,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('navbar-item')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$img,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$alt('Logo'),
																$elm$html$Html$Attributes$src('https://www.budapestschool.org/static/logo.png')
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('navbar-burger burger'),
														A2($elm$html$Html$Attributes$attribute, 'data-target', 'navbarMenuHeroA')
													]),
												_List_fromArray(
													[
														A2($elm$html$Html$span, _List_Nil, _List_Nil),
														A2($elm$html$Html$span, _List_Nil, _List_Nil),
														A2($elm$html$Html$span, _List_Nil, _List_Nil)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('navbar-menu'),
												$elm$html$Html$Attributes$id('navbarMenuHeroA')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('navbar-end')
													]),
												A2(
													$elm$core$List$map,
													function (_v0) {
														var mode = _v0.a;
														var title = _v0.b;
														return A2(
															$elm$html$Html$a,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('navbar-item'),
																	$elm$html$Html$Attributes$classList(
																	_List_fromArray(
																		[
																			_Utils_Tuple2(
																			'is-active',
																			_Utils_eq(model.T, mode))
																		])),
																	$elm$html$Html$Events$onClick(
																	$author$project$Main$ModeClicked(mode))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(title)
																]));
													},
													_List_fromArray(
														[
															_Utils_Tuple2(1, 'Browse'),
															_Utils_Tuple2(0, 'Search')
														])))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Main$QueryInputChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$TagInputChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$between = F3(
	function (x, first, last) {
		return (_Utils_cmp(first, x) < 1) && (_Utils_cmp(x, last) < 1);
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$virtual_dom$VirtualDom$lazy = _VirtualDom_lazy;
var $elm$html$Html$Lazy$lazy = $elm$virtual_dom$VirtualDom$lazy;
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Main$AddTagToLo = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $elm$html$Html$article = _VirtualDom_node('article');
var $author$project$Main$viewResultList = function (results) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(' content')
			]),
		A2(
			$elm$core$List$map,
			function (_v0) {
				var lo = _v0.b;
				return A2(
					$elm$html$Html$article,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('media has-text-left')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('media-left')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('media-content')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('content')
										]),
									A2(
										$elm$core$List$cons,
										A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													A2(
														$author$project$Main$AddTagToLo,
														$elm$core$Maybe$Just('tagged'),
														lo))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(lo.a),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('tag is-light')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(lo.e)
														]))
												])),
										A2(
											$elm$core$List$map,
											function (tag) {
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('tag is-primary mr-2')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(tag)
														]));
											},
											lo.f)))
								]))
						]));
			},
			results));
};
var $author$project$Main$viewYearFilterTab = F2(
	function (activeYf, yf) {
		return A2(
			$elm$html$Html$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_Utils_ap(
						_Utils_eq(activeYf, yf) ? _List_fromArray(
							[
								$elm$html$Html$Attributes$class('is-active')
							]) : _List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Main$YearFilterSet(yf))
							])),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(yf.a) + ('-' + $elm$core$String$fromInt(yf.b)))
						]))
				]));
	});
var $author$project$Main$panel = function (model) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('section')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control has-icons-left py-2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Events$onInput($author$project$Main$QueryInputChanged),
									$elm$html$Html$Attributes$class('input is-primary'),
									$elm$html$Html$Attributes$value(
									A2($elm$core$Maybe$withDefault, '', model.W)),
									$elm$html$Html$Attributes$placeholder('Search'),
									$elm$html$Html$Attributes$type_('text')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon is-left')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
											$elm$html$Html$Attributes$class('fas fa-search')
										]),
									_List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control has-icons-left py-2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Events$onInput($author$project$Main$TagInputChanged),
									$elm$html$Html$Attributes$class('input is-secondary'),
									$elm$html$Html$Attributes$value(
									A2($elm$core$Maybe$withDefault, '', model.X)),
									$elm$html$Html$Attributes$placeholder('Tags'),
									$elm$html$Html$Attributes$type_('text')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon is-left')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
											$elm$html$Html$Attributes$class('fas fa-tags')
										]),
									_List_Nil)
								]))
						]))
				]),
			function () {
				if (model.J) {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('searching')
										]))
								]))
						]);
				} else {
					var resultList = A2($author$project$LoSearch$searchResult, model.s, model.g);
					var results = A2($elm$core$List$map, $elm$core$Tuple$second, resultList);
					var subject_related_to_results = $author$project$Main$get_subjects(results);
					var _v0 = function () {
						var _v1 = $author$project$LoSearch$get_years(results);
						var x = _v1.a;
						var y = _v1.b;
						return _Utils_Tuple2(
							A2($elm$core$Maybe$withDefault, x, model.g.ab),
							A2($elm$core$Maybe$withDefault, y, model.g.ac));
					}();
					var relavant_first_year = _v0.a;
					var relevant_last_year = _v0.b;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('tabs py-2')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$ul,
											_List_Nil,
											A2(
												$elm$core$List$map,
												$author$project$Main$viewYearFilterTab(
													_Utils_Tuple2(
														A2($elm$core$Maybe$withDefault, 1, model.g.ab),
														A2($elm$core$Maybe$withDefault, 12, model.g.ac))),
												A2(
													$elm$core$List$cons,
													_Utils_Tuple2(1, 12),
													A2(
														$elm$core$List$filter,
														function (_v2) {
															var f = _v2.a;
															var l = _v2.b;
															return A3($author$project$Main$between, f, relavant_first_year, relevant_last_year) || A3($author$project$Main$between, l, relavant_first_year, relevant_last_year);
														},
														_List_fromArray(
															[
																_Utils_Tuple2(1, 4),
																_Utils_Tuple2(5, 8),
																_Utils_Tuple2(7, 8),
																_Utils_Tuple2(9, 10),
																_Utils_Tuple2(11, 12)
															])))))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('tabs py-2')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$ul,
											_List_Nil,
											A2(
												$elm$core$List$cons,
												A2(
													$elm$html$Html$li,
													_List_fromArray(
														[
															$elm$html$Html$Events$onClick(
															$author$project$Main$SubjectFilterSet($elm$core$Maybe$Nothing))
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$a,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Minden tantárgy')
																]))
														])),
												A2(
													$elm$core$List$map,
													function (sub) {
														return A2(
															$elm$html$Html$li,
															_List_fromArray(
																[
																	$elm$html$Html$Events$onClick(
																	$author$project$Main$SubjectFilterSet(
																		$elm$core$Maybe$Just(sub)))
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$a,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text(sub)
																		]))
																]));
													},
													subject_related_to_results)))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('container pt-5')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$Lazy$lazy,
											$author$project$Main$viewResultList,
											A2($elm$core$List$take, 100, resultList))
										]))
								]))
						]);
				}
			}()));
};
var $author$project$Main$viewContent = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('column is-four-fifths')
					]),
				_List_fromArray(
					[
						$author$project$Main$panel(model)
					]))
			]));
};
var $author$project$Main$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('columns')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('column is-10 is-offset-1')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$section,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('section')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('container')
									]),
								_List_fromArray(
									[
										$author$project$Main$viewContent(model)
									]))
							]))
					]))
			]));
};
var $author$project$Main$viewMain = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Main$viewNav(model),
				(model.T === 1) ? $author$project$Main$viewBrowser(model) : $author$project$Main$viewSearch(model)
			]));
};
var $author$project$Main$view = function (model) {
	return {
		aU: _List_fromArray(
			[
				$author$project$Main$viewMain(model)
			]),
		a8: 'Browse NAT learning outcomes'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		a0: $author$project$Main$init,
		a3: $author$project$Main$UrlChanged,
		a4: $author$project$Main$LinkClicked,
		a7: $elm$core$Basics$always($elm$core$Platform$Sub$none),
		a9: $author$project$Main$update,
		ba: $author$project$Main$view
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}});}(this));