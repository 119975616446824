import "./main.css";
import { Elm } from "./Main.elm";
import registerServiceWorker from "./registerServiceWorker";

const app = Elm.Main.init({
  node: document.getElementById("root"),
});

app.ports.copyToClipBoard.subscribe(function (data) {
  navigator.clipboard.writeText(data).then(
    function () {
      console.log("wrote to the clipbaord", data);
    },
    function (error) {
      console.error("failed to write to the clipboard", error);
    }
  );
});

registerServiceWorker();
